import {SOPostItem} from '../../core/item/so-post-item';
import {ItemCore} from './item.core';
import {SOGetItem} from '../../core/item/so-get-item';
import {FormField, SelectionOption} from '../../../engine/shared/form-field';
import {postItemFormFieldOptions} from './post-item-form-fields';
import {AdapterProtocol} from '../../../engine/shared/entity-construction/adapter-protocol';
import {BasePost} from '../../../engine/shared/entity-construction/base-post';
import {OrganizationCore} from '../bv-organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';

export class RAPostItem extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postItemFormFieldOptions);

  RequestClass = SOPostItem

  constructor(
    public entityCore: ItemCore
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
      this.getFormValueFromIdentifier('description'),
      this.getFormValueFromIdentifier('barCode'),
      this.getFormValueFromIdentifier('retailPrice'),
      this.getFormValueFromIdentifier('cost'),
    )
  }

  prepareRequestWithQuickCreate(value) {
    this.prepareRequest();
    this.request.name = value
  }

  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}