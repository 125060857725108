import {SORespViewGrant} from '../../core/view-grant/so-resp-view-grant';
import {ViewGrantCore} from './view-grant.core';
import {ListEntry} from '../../../engine/shared/list-entry';
import {EntityAttributesForDisplay} from '../../../engine/shared/entity-attributes-for-display';
import {DetailEntry, DetailFactory, DetailFactoryProtocol, EditableType} from '../../../engine/shared/detail-entry';
import {ActiveUserService} from '../../../engine/shared/active-user/active-user.service';
import {BaseDetailType} from "../../../engine/shared/base-detail";
import {EntityViewSpecProtocol} from "../../../engine/shared/entity-view/entity-view-spec-protocol";
import {getHeading} from "../../../engine/shared/display-option-utilities";
import {putViewGrantFormFieldOptions} from './put-view-grant-form-fields';
import {SOGetViewGrant} from '../../core/view-grant/so-get-view-grant';
import {SOGetRole} from '../../core/role/so-get-role';
import {SOGetView} from '../../core/view/so-get-view';
import {DisplayVectorStringFactory} from "../../../engine/config/display-vector";
import {displayOptions} from "../../display-options";
import {ListSpecType} from "../../../engine/shared/entity-view/list-spec";
import {SPECTER_SPEC} from "../../specter-spec";
import {getCore} from "../../../engine/shared/entity-view/get-core";

export class ViewGrantDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespViewGrant = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(displayOptions);
    let heading;

    const attributes = []


    const roleCore = getCore(SPECTER_SPEC, activeUserService, listEntry.entityCore.httpClient, 'RoleCore');
    roleCore.soGet = SOGetRole.construct({
    });
    heading = getHeading(entityViewSpec.entityCore, roleCore)
    roleCore.title = heading ? heading : roleCore.title
    roleCore.listTitle = 'Role'

    if (entity.role) {
        displayStringFactory.reconfigure(entity.role, entityViewSpec.entityCore.type)
    }
    roleCore.configureForDisplay(entityViewSpec)

    const roleDetail = new DetailEntry(
      heading ? heading : roleCore.listTitle,
      entity.role ? displayStringFactory.primaryString : null,
      'role',
      BaseDetailType.pushedDetail,
      roleCore,
      EditableType.never
    )
    if (entity.role) {
      roleDetail.cachedListEntry = roleCore.makeListEntry(entityViewSpec, roleCore, entity.role, 0)
    }
    roleDetail.singleFieldSelect = true
    attributes.push(roleDetail);

    const viewCore = getCore(SPECTER_SPEC, activeUserService, listEntry.entityCore.httpClient, 'ViewCore');
    viewCore.soGet = SOGetView.construct({
    });
    heading = getHeading(entityViewSpec.entityCore, viewCore)
    viewCore.title = heading ? heading : viewCore.title
    viewCore.listTitle = 'View'

    if (entity.view) {
        displayStringFactory.reconfigure(entity.view, entityViewSpec.entityCore.type)
    }
    viewCore.configureForDisplay(entityViewSpec)

    const viewDetail = new DetailEntry(
      heading ? heading : viewCore.listTitle,
      entity.view ? displayStringFactory.primaryString : null,
      'view',
      BaseDetailType.pushedDetail,
      viewCore,
      EditableType.never
    )
    if (entity.view) {
      viewDetail.cachedListEntry = viewCore.makeListEntry(entityViewSpec, viewCore, entity.view, 0)
    }
    viewDetail.singleFieldSelect = true
    attributes.push(viewDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}