import {SOPostCustomField} from '../../core/custom-field/so-post-custom-field';
import {CustomFieldCore} from './custom-field.core';
import {SOGetCustomField} from '../../core/custom-field/so-get-custom-field';
import {FormField, SelectionOption} from '../../../engine/shared/form-field';
import {postCustomFieldFormFieldOptions} from './post-custom-field-form-fields';
import {AdapterProtocol} from '../../../engine/shared/entity-construction/adapter-protocol';
import {BasePost} from '../../../engine/shared/entity-construction/base-post';
import {OrganizationCore} from '../bv-organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';

export class RAPostCustomField extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postCustomFieldFormFieldOptions);

  RequestClass = SOPostCustomField

  constructor(
    public entityCore: CustomFieldCore
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
      this.getFormValueFromIdentifier('autoApply'),
    )
  }

  prepareRequestWithQuickCreate(value) {
    this.prepareRequest();
    this.request.name = value
  }

  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}