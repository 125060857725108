import { Input, Directive } from "@angular/core";
import {AttributeViewSpec} from "./attribute-view-spec";
import {FormGroup} from "@angular/forms";
import {DetailEntry} from "./detail-entry";
import {FormViewProtocol} from "../protocols/form-view-protocol";
import {FormField} from "./form-field";
import {autoCompleteSpecFactory} from "./entity-view/auto-complete-spec";

@Directive()
export class AbstractAttributeView {

  parentEntity;

  initialized = false;

  get hideAttributeLabels(): boolean {
    return this.parentView.entityViewSpec ? this.parentView.entityViewSpec.entityCore.hideAttributeLabels : false;
  }

  @Input()
  set attributeViewSpec(value: AttributeViewSpec) {
    this.form = value.from
    this.detail = value.detailEntry
    this.parentView = value.parent
    this.parentEntity = this.parentView.parentEntity;
    this.compact = value.compact
    this.chipListAdd = value.chipListAdd
    if (value && !this.initialized) {
      this.initialized = true;
      this.attributeSpecSet();
    }
  }

  form: FormGroup;
  detail: DetailEntry;
  parentView: FormViewProtocol;
  compact = false;
  chipListAdd = false;

  constructor() { }

  onSelectionChange() {
    this.parentView.onSelectionChange(this.detail, null)
  }

  attributeSpecSet() {

  }

  getAutoCompleteListSpec(formField: FormField) {
    const resp = autoCompleteSpecFactory(
      formField,
      this.parentView.entityViewSpec,
      this.parentView.entityViewSpec.parentPanelEntityViewSpec,
      this.parentView.navigator
    )
    resp.compact = this.compact
    resp.chipListAdd = this.chipListAdd
    return resp
  }
}
