<div class="list-container" fxLayout="column">
  <app-sv-header
    *ngIf="entityViewSpec.entityCore.showHeader"
    [headerSpec]="headerSpec"
    [parent]="this"
  ></app-sv-header>
  <mat-progress-bar *ngIf="loading" class="progress-bar" mode="indeterminate" color="primary"></mat-progress-bar>
  <div *ngIf="!loading" class="progress-bar"></div>

  <div class="detail-content" *ngIf="putHelper && attributesLayout === AttributesLayout.vertical" fxLayout="column">
    <div *ngIf="!showTwoColumns">
      <div *ngFor="let attributeViewSpec of entityAttributeViewSpecs">
        <app-sv-attribute-view *ngIf="
        (this.showSecondaryDetails && attributeViewSpec.detailEntry.showAsSecondaryDetail)
        || (!this.showSecondaryDetails && !attributeViewSpec.detailEntry.showAsSecondaryDetail)"
                               [attributeViewSpec]="attributeViewSpec"></app-sv-attribute-view>
      </div>
    </div>
    <div *ngIf="showTwoColumns" fxLayout="row" class="single-details-container">
      <div fxLayout="column" class="left-col">
        <div *ngFor="let attributeViewSpec of entityAttributeViewSpecs">
          <app-sv-attribute-view *ngIf="
        (this.showSecondaryDetails && attributeViewSpec.detailEntry.showAsSecondaryDetail)
        || (!this.showSecondaryDetails && !attributeViewSpec.detailEntry.showAsSecondaryDetail)"
                                 [attributeViewSpec]="attributeViewSpec"></app-sv-attribute-view>
        </div>
      </div>
      <div fxLayout="column" class="right-col">
        <div *ngFor="let attributeViewSpec of entityAttributeViewSpecs">
          <app-sv-attribute-view *ngIf="
        (this.showSecondaryDetails && attributeViewSpec.detailEntry.showAsSecondaryDetail)
        || (!this.showSecondaryDetails && !attributeViewSpec.detailEntry.showAsSecondaryDetail)"
                                 [attributeViewSpec]="attributeViewSpec"></app-sv-attribute-view>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="putHelper && attributesLayout === AttributesLayout.horizontal" fxLayout="row" fxLayoutAlign="start center">
    <div *ngFor="let attributeViewSpec of entityAttributeViewSpecs" class="attribute-horizontal">
      <app-sv-attribute-view *ngIf="
        (this.showSecondaryDetails && attributeViewSpec.detailEntry.showAsSecondaryDetail)
        || (!this.showSecondaryDetails && !attributeViewSpec.detailEntry.showAsSecondaryDetail)"
                             [attributeViewSpec]="attributeViewSpec"></app-sv-attribute-view>
    </div>
  </div>
</div>
