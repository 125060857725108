import {FormField, FormFieldType, SelectionOption} from '../../../engine/shared/form-field';
import {EntityType} from "../../entity-types";


const itemField = new FormField(
  'item',
  'Item',
  EntityType.item,
  FormFieldType.autoCompleteList,
  false,
)
const tagField = new FormField(
  'tag',
  'Tag',
  EntityType.tag,
  FormFieldType.autoCompleteList,
  false,
)


export const putTemagaFormFieldOptions: {[key: string]: FormField} = {
  item: itemField,
  tag: tagField,
};