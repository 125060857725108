<div class="fill-container">
  <div class="center-box-outer">
    <mat-progress-bar *ngIf="loading" class="progress-bar" mode="indeterminate" color="primary"></mat-progress-bar>
    <div *ngIf="!loading" class="progress-bar"></div>
    <div *ngIf="!tokenSuccess" class="center-box-inner" fxLayout="column">
      <h1>Login</h1>
      <form class="main-form" [formGroup]="entityCore.postHelper.form" fxLayout="column" (ngSubmit)="clickSubmitFormButton()">
        <app-sv-attribute-view *ngFor="let formField of entityCore.postHelper.formFields"
                               class="field" [attributeViewSpec]="makeAttributeViewSpec(formField)">
        </app-sv-attribute-view>
        <a routerLink="/reset-password">Forgot password</a>
        <button mat-stroked-button type="submit"
                color="accent" [disabled]="!entityCore.postHelper.form.valid || entityCore.postHelper.submitDisabled">Submit</button>
      </form>
      <button mat-stroked-button class="cancel-button" (click)="cancel()" color="primary">Cancel</button>
      <h6 *ngIf="errorMessage">{{errorMessage}}</h6>
    </div>
    <div *ngIf="tokenSuccess" class="center-box-inner" fxLayout="column">
      <mat-progress-spinner mode="indeterminate" color="primary"></mat-progress-spinner>
    </div>
  </div>
</div>
