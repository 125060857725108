import { Type } from 'class-transformer';
import { SORespConstraint } from '../constraint/so-resp-constraint';
import { SORespProduct } from '../product/so-resp-product';
import {ObjectiveProtocol} from '../../../engine/protocols/objective-protocol';
import {EntityType} from "../../entity-types";


export class SORespAppliedConstraint implements ObjectiveProtocol {

  entityType: EntityType = EntityType.appliedConstraint;
  uid: string;
  @Type(() => SORespConstraint)
  constraint: SORespConstraint;
  @Type(() => SORespProduct)
  product: SORespProduct;
  quantity: number;
}