<form [formGroup]="form">
  <a mat-stroked-button
     *ngIf="!detail.editEnabled"
     href="{{detail.value}}" target="_blank" rel="noopener noreferrer">
    {{detail.name}}
  </a>

  <mat-form-field
    *ngIf="detail.editEnabled"
    appearance="{{detail.editableFormField.appearance}}"
    color="{{detail.editableFormField.color}}">
    <mat-label>{{detail.editableFormField.getLabel(parentView.entityViewSpec)}}</mat-label>
    <input matInput
           [type]="detail.editableFormField.inputType"
           [id]="detail.editableFormField.identifierString"
           [name]="detail.editableFormField.identifierString"
           [formControlName]="detail.editableFormField.identifierString"
           (change)="onSelectionChange()">
  </mat-form-field>
</form>
