import {ActiveUserService} from '../../../engine/shared/active-user/active-user.service';
import {HttpClient} from '@angular/common/http';
import {EOPaymentIntentService} from '../../core/payment-intent/eo-payment-intent';
import {PaymentIntentListEntryFactory} from './payment-intent-list-entry-factory';
import {PaymentIntentDetailFactory} from './payment-intent-detail-factory';
import {SOGetPaymentIntent} from '../../core/payment-intent/so-get-payment-intent';
import {RAPutPaymentIntent} from './ra-put-payment-intent';
import {RAPostPaymentIntent} from './ra-post-payment-intent';
import {RADeletePaymentIntent} from './ra-delete-payment-intent';
import {AbstractEntityCore} from "../../../engine/shared/entity-view/abstract-entity-core";
import {EntityCoreProtocol} from "../../../engine/shared/entity-view/entity-core-protocol";
import {EntityType} from '../../entity-types';
import {SPECTER_SPEC} from "../../specter-spec";

export class PaymentIntentCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = EntityType.paymentIntent;
  title = 'Payment intent';
  listEntryFactory = new PaymentIntentListEntryFactory();
  detailFactory = new PaymentIntentDetailFactory();

  entityService: EOPaymentIntentService;
  soGetClass = SOGetPaymentIntent;
  soGet: SOGetPaymentIntent;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOPaymentIntentService
    );
    this.adapters = [
      new RAPostPaymentIntent(this),
      new RAPutPaymentIntent(this),
      new RADeletePaymentIntent(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetPaymentIntent();
    this.soGet.queryOptions.limit = this.limit;
  }


}