import {SOPostDefaultCustomField} from '../../core/default-custom-field/so-post-default-custom-field';
import {DefaultCustomFieldCore} from './default-custom-field.core';
import {SOGetDefaultCustomField} from '../../core/default-custom-field/so-get-default-custom-field';
import {FormField, SelectionOption} from '../../../engine/shared/form-field';
import {postDefaultCustomFieldFormFieldOptions} from './post-default-custom-field-form-fields';
import {AdapterProtocol} from '../../../engine/shared/entity-construction/adapter-protocol';
import {BasePost} from '../../../engine/shared/entity-construction/base-post';

export class RAPostDefaultCustomField extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postDefaultCustomFieldFormFieldOptions);

  RequestClass = SOPostDefaultCustomField

  constructor(
    public entityCore: DefaultCustomFieldCore
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('name'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}