import {SORespLine} from '../../core/line/so-resp-line';
import {LineCore} from './line.core';
import {ListEntry} from '../../../engine/shared/list-entry';
import {EntityAttributesForDisplay} from '../../../engine/shared/entity-attributes-for-display';
import {DetailEntry, DetailFactory, DetailFactoryProtocol, EditableType} from '../../../engine/shared/detail-entry';
import {ActiveUserService} from '../../../engine/shared/active-user/active-user.service';
import {BaseDetailType} from "../../../engine/shared/base-detail";
import {EntityViewSpecProtocol} from "../../../engine/shared/entity-view/entity-view-spec-protocol";
import {getHeading} from "../../../engine/shared/display-option-utilities";
import {putLineFormFieldOptions} from './put-line-form-fields';
import {SOGetLine} from '../../core/line/so-get-line';
import {SOGetItem} from '../../core/item/so-get-item';
import {SOGetOrder} from '../../core/order/so-get-order';
import {SOGetDispatch} from '../../core/dispatch/so-get-dispatch';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {DisplayVectorStringFactory} from "../../../engine/config/display-vector";
import {displayOptions} from "../../display-options";
import {ListSpecType} from "../../../engine/shared/entity-view/list-spec";
import {SPECTER_SPEC} from "../../specter-spec";
import {getCore} from "../../../engine/shared/entity-view/get-core";

export class LineDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespLine = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(displayOptions);
    let heading;

    const attributes = []

    const dispatchStatusDetail = new DetailEntry(
        'Dispatch status',
        entity.dispatchStatus,
        'dispatchStatus',
        BaseDetailType.toggleGroup,
        null,
        EditableType.never,
    )
    attributes.push(dispatchStatusDetail)

    const quantityDetail = new DetailEntry(
        'Quantity',
        entity.quantity,
        'quantity',
        BaseDetailType.text,
        null,
        EditableType.always,
        putLineFormFieldOptions.quantity
    )
    attributes.push(quantityDetail)

    const unitSalePriceDetail = new DetailEntry(
        'Unit sale price',
        entity.unitSalePrice,
        'unitSalePrice',
        BaseDetailType.text,
        null,
        EditableType.always,
        putLineFormFieldOptions.unitSalePrice
    )
    attributes.push(unitSalePriceDetail)

    const unitCostDetail = new DetailEntry(
        'Unit cost',
        entity.unitCost,
        'unitCost',
        BaseDetailType.text,
        null,
        EditableType.always,
        putLineFormFieldOptions.unitCost
    )
    attributes.push(unitCostDetail)

    const notesDetail = new DetailEntry(
        'Notes',
        entity.notes,
        'notes',
        BaseDetailType.text,
        null,
        EditableType.always,
        putLineFormFieldOptions.notes
    )
    attributes.push(notesDetail)


    const itemCore = getCore(SPECTER_SPEC, activeUserService, listEntry.entityCore.httpClient, 'ItemCore');
    itemCore.soGet = SOGetItem.construct({
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, itemCore)
    itemCore.title = heading ? heading : itemCore.title
    itemCore.listTitle = 'Item'

    if (entity.item) {
        displayStringFactory.reconfigure(entity.item, entityViewSpec.entityCore.type)
    }
    itemCore.configureForDisplay(entityViewSpec)

    const itemDetail = new DetailEntry(
      heading ? heading : itemCore.listTitle,
      entity.item ? displayStringFactory.primaryString : null,
      'item',
      BaseDetailType.pushedDetail,
      itemCore,
      EditableType.never
    )
    if (entity.item) {
      itemDetail.cachedListEntry = itemCore.makeListEntry(entityViewSpec, itemCore, entity.item, 0)
    }
    itemDetail.singleFieldSelect = true
    attributes.push(itemDetail);

    const orderCore = getCore(SPECTER_SPEC, activeUserService, listEntry.entityCore.httpClient, 'OrderCore');
    orderCore.soGet = SOGetOrder.construct({
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, orderCore)
    orderCore.title = heading ? heading : orderCore.title
    orderCore.listTitle = 'Order'

    if (entity.order) {
        displayStringFactory.reconfigure(entity.order, entityViewSpec.entityCore.type)
    }
    orderCore.configureForDisplay(entityViewSpec)

    const orderDetail = new DetailEntry(
      heading ? heading : orderCore.listTitle,
      entity.order ? displayStringFactory.primaryString : null,
      'order',
      BaseDetailType.pushedDetail,
      orderCore,
      EditableType.never
    )
    if (entity.order) {
      orderDetail.cachedListEntry = orderCore.makeListEntry(entityViewSpec, orderCore, entity.order, 0)
    }
    orderDetail.singleFieldSelect = true
    attributes.push(orderDetail);

    const dispatchesCore = getCore(SPECTER_SPEC, activeUserService, listEntry.entityCore.httpClient, 'DispatchCore');
    dispatchesCore.soGet = SOGetDispatch.construct({
      line: new SOGetLine(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, dispatchesCore)
    dispatchesCore.title = heading ? heading : dispatchesCore.title
    dispatchesCore.listTitle = 'Dispatches'

    dispatchesCore.configureForDisplay(entityViewSpec)

    const dispatchesDetail = new DetailEntry(
      heading ? heading : dispatchesCore.listTitle,
      null,
      'dispatches',
      BaseDetailType.embeddedList,
      dispatchesCore,
      EditableType.never
    )
    attributes.push(dispatchesDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}