import {ActiveUserService} from '../../../engine/shared/active-user/active-user.service';
import {HttpClient} from '@angular/common/http';
import {EODefaultCustomFieldService} from '../../core/default-custom-field/eo-default-custom-field';
import {DefaultCustomFieldListEntryFactory} from './default-custom-field-list-entry-factory';
import {DefaultCustomFieldDetailFactory} from './default-custom-field-detail-factory';
import {SOGetDefaultCustomField} from '../../core/default-custom-field/so-get-default-custom-field';
import {RAPutDefaultCustomField} from './ra-put-default-custom-field';
import {RAPostDefaultCustomField} from './ra-post-default-custom-field';
import {RADeleteDefaultCustomField} from './ra-delete-default-custom-field';
import {AbstractEntityCore} from "../../../engine/shared/entity-view/abstract-entity-core";
import {EntityCoreProtocol} from "../../../engine/shared/entity-view/entity-core-protocol";
import {EntityType} from '../../entity-types';
import {SPECTER_SPEC} from "../../specter-spec";

export class DefaultCustomFieldCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = EntityType.defaultCustomField;
  title = 'Default custom field';
  listEntryFactory = new DefaultCustomFieldListEntryFactory();
  detailFactory = new DefaultCustomFieldDetailFactory();

  entityService: EODefaultCustomFieldService;
  soGetClass = SOGetDefaultCustomField;
  soGet: SOGetDefaultCustomField;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EODefaultCustomFieldService
    );
    this.adapters = [
      new RAPostDefaultCustomField(this),
      new RAPutDefaultCustomField(this),
      new RADeleteDefaultCustomField(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetDefaultCustomField();
    this.soGet.queryOptions.limit = this.limit;
  }


}