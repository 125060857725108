import { SOGetItem } from '../item/so-get-item';
import { SOGetDistributor } from '../distributor/so-get-distributor';
import { SOGetOrganization } from '../organization/so-get-organization';


export class SOPostTemdisa {

  constructor(
    public item: SOGetItem,
    public distributor: SOGetDistributor,
    public organization: SOGetOrganization,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostTemdisa(
      'item' in params ? params.item : null,
      'distributor' in params ? params.distributor : null,
      'organization' in params ? params.organization : null,
    );
  }
}
