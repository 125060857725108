import {SOPutPrice} from '../../core/price/so-put-price';
import {PriceCore} from './price.core';
import {SOGetPrice} from '../../core/price/so-get-price';
import {FormField, SelectionOption} from '../../../engine/shared/form-field';
import {putPriceFormFieldOptions} from './put-price-form-fields';
import {AdapterProtocol} from '../../../engine/shared/entity-construction/adapter-protocol';
import {BasePut} from '../../../engine/shared/entity-construction/base-put';
import {ProductCore} from '../bv-product/product.core';
import {SOGetProduct} from '../../core/product/so-get-product';

export class RAPutPrice extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putPriceFormFieldOptions);

  RequestClass = SOPutPrice

  constructor(
    public entityCore: PriceCore
  ) {
    super();
    if (putPriceFormFieldOptions.product) {
      putPriceFormFieldOptions.product.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier('sku'),
      this.getFormValueFromIdentifier('trailDays'),
      this.getFormValueFromIdentifier('exRef'),
      this.getFormValueFromIdentifier('amount'),
      this.getFormValueFromIdentifier('interval'),
      this.getFormValueFromIdentifier('active'),
      this.getFormValueFromIdentifier(
        'product',
        SOGetProduct,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}