import {FormField, FormFieldType, SelectionOption} from '../../../engine/shared/form-field';
import {EntityType} from "../../entity-types";


const dataField = new FormField(
  'data',
  'Data',
  null,
  FormFieldType.text,
  true,
)


export const postBulkitemFormFieldOptions: {[key: string]: FormField} = {
  data: dataField,
};