

export class SOPostSection {

  constructor(
    public title: string,
    public number: number,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostSection(
      'title' in params ? params.title : null,
      'number' in params ? params.number : null,
    );
  }
}
