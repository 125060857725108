import {ActiveUserService} from '../../../engine/shared/active-user/active-user.service';
import {HttpClient} from '@angular/common/http';
import {EOBulkitemService} from '../../core/bulkitem/eo-bulkitem';
import {BulkitemListEntryFactory} from './bulkitem-list-entry-factory';
import {BulkitemDetailFactory} from './bulkitem-detail-factory';
import {SOGetBulkitem} from '../../core/bulkitem/so-get-bulkitem';
import {RAPutBulkitem} from './ra-put-bulkitem';
import {RAPostBulkitem} from './ra-post-bulkitem';
import {RADeleteBulkitem} from './ra-delete-bulkitem';
import {AbstractEntityCore} from "../../../engine/shared/entity-view/abstract-entity-core";
import {EntityCoreProtocol} from "../../../engine/shared/entity-view/entity-core-protocol";
import {EntityType} from '../../entity-types';
import {SPECTER_SPEC} from "../../specter-spec";
import {SOGetOrganization} from '../../core/organization/so-get-organization'

export class BulkitemCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = EntityType.bulkitem;
  title = 'Bulkitem';
  listEntryFactory = new BulkitemListEntryFactory();
  detailFactory = new BulkitemDetailFactory();

  entityService: EOBulkitemService;
  soGetClass = SOGetBulkitem;
  soGet: SOGetBulkitem;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOBulkitemService
    );
    this.adapters = [
      new RAPostBulkitem(this),
      new RAPutBulkitem(this),
      new RADeleteBulkitem(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetBulkitem();
    this.soGet.organization = new SOGetOrganization(
      this.activeUserService.getScope('organization').uid
    );
    this.soGet.queryOptions.limit = this.limit;
  }


}