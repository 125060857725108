import { Component, OnInit } from '@angular/core';
import {AbstractAttributeView} from "../../../shared/abstract-attribute-view";
import {MediaDef, SOMediaSpec} from "../../../multiverse/so-media-spec";

@Component({
  selector: 'app-sv-pushed-entity-list-attribute-view',
  templateUrl: './sv-pushed-entity-list-attribute-view.component.html',
  styleUrls: ['./sv-pushed-entity-list-attribute-view.component.scss']
})
export class SvPushedEntityListAttributeViewComponent extends AbstractAttributeView {
  get displayValue(): string {
    return this.detail.editableFormField ? this.detail.editableFormField.displayValue : this.detail.value
  }

  get selectedMedia() {
    return this.detail && this.detail.editableFormField && this.detail.editableFormField.selectedListEntry && this.detail.editableFormField.selectedListEntry.entity.links != null
  };

  get imageUrl() {
    const links = this.detail.editableFormField.selectedListEntry.entity.links;
    let spec: SOMediaSpec = null
    spec = links.find(obj => obj.definition == MediaDef.px200)
    if (spec == null) {
      spec = links.find(obj => obj.definition == MediaDef.px400)
    }
    if (spec == null) {
      spec = links.find(obj => obj.definition == MediaDef.px800)
    }
    if (spec == null) {
      spec = links.find(obj => obj.definition == MediaDef.pxOrig)
    }
    return spec != null ? spec.url : null
  }

}
