import {FormField, FormFieldType, SelectionOption} from '../../../engine/shared/form-field';
import {EntityType} from "../../entity-types";


const amountField = new FormField(
  'amount',
  'Amount',
  null,
  FormFieldType.text,
  true,
)
const quantityField = new FormField(
  'quantity',
  'Quantity',
  null,
  FormFieldType.text,
  true,
)
const dateCreatedField = new FormField(
  'dateCreated',
  'Date created',
  null,
  FormFieldType.datetime,
  true,
)
const descriptionField = new FormField(
  'description',
  'Description',
  null,
  FormFieldType.text,
  true,
  true,
  ''
)
const priceField = new FormField(
  'price',
  'Price',
  EntityType.price,
  FormFieldType.pushedSelectionList,
  true,
)
const invoiceField = new FormField(
  'invoice',
  'Invoice',
  EntityType.invoice,
  FormFieldType.pushedSelectionList,
  true,
)


export const postInvoiceLineFormFieldOptions: {[key: string]: FormField} = {
  amount: amountField,
  quantity: quantityField,
  dateCreated: dateCreatedField,
  description: descriptionField,
  price: priceField,
  invoice: invoiceField,
};