import {FormField, FormFieldType, SelectionOption} from '../../../engine/shared/form-field';
import {EntityType} from "../../entity-types";


const itemField = new FormField(
  'item',
  'Item',
  EntityType.item,
  FormFieldType.autoCompleteList,
  false,
)
const distributorField = new FormField(
  'distributor',
  'Distributor',
  EntityType.distributor,
  FormFieldType.autoCompleteList,
  false,
)


export const putTemdisaFormFieldOptions: {[key: string]: FormField} = {
  item: itemField,
  distributor: distributorField,
};