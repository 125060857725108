import {SOPutInvoiceLine} from '../../core/invoice-line/so-put-invoice-line';
import {InvoiceLineCore} from './invoice-line.core';
import {SOGetInvoiceLine} from '../../core/invoice-line/so-get-invoice-line';
import {FormField, SelectionOption} from '../../../engine/shared/form-field';
import {putInvoiceLineFormFieldOptions} from './put-invoice-line-form-fields';
import {AdapterProtocol} from '../../../engine/shared/entity-construction/adapter-protocol';
import {BasePut} from '../../../engine/shared/entity-construction/base-put';
import {PriceCore} from '../bv-price/price.core';
import {SOGetPrice} from '../../core/price/so-get-price';
import {InvoiceCore} from '../bv-invoice/invoice.core';
import {SOGetInvoice} from '../../core/invoice/so-get-invoice';

export class RAPutInvoiceLine extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putInvoiceLineFormFieldOptions);

  RequestClass = SOPutInvoiceLine

  constructor(
    public entityCore: InvoiceLineCore
  ) {
    super();
    if (putInvoiceLineFormFieldOptions.price) {
      putInvoiceLineFormFieldOptions.price.makeCore = true;
    }
    if (putInvoiceLineFormFieldOptions.invoice) {
      putInvoiceLineFormFieldOptions.invoice.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('amount'),
      this.getFormValueFromIdentifier('quantity'),
      this.getFormValueFromIdentifier('description'),
      this.getFormValueFromIdentifier(
        'price',
        SOGetPrice,
      ),
      this.getFormValueFromIdentifier(
        'invoice',
        SOGetInvoice,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}