import {Component, Input, OnInit} from '@angular/core';
import {AppComponent} from "../../../../app/app.component";
import {SPECTER_SPEC} from "../../../../app/specter-spec";

@Component({
  selector: 'app-sv-facade',
  templateUrl: './sv-facade.component.html',
  styleUrls: ['./sv-facade.component.scss']
})
export class SvFacadeComponent implements OnInit {

  title = SPECTER_SPEC.title
  summary = SPECTER_SPEC.summary
  company = SPECTER_SPEC.company
  year = new Date().getFullYear()

  constructor() { }

  ngOnInit(): void {
  }
}
