import {ActiveUserService} from '../../../engine/shared/active-user/active-user.service';
import {HttpClient} from '@angular/common/http';
import {EOTemdisaService} from '../../core/temdisa/eo-temdisa';
import {TemdisaListEntryFactory} from './temdisa-list-entry-factory';
import {TemdisaDetailFactory} from './temdisa-detail-factory';
import {SOGetTemdisa} from '../../core/temdisa/so-get-temdisa';
import {RAPutTemdisa} from './ra-put-temdisa';
import {RAPostTemdisa} from './ra-post-temdisa';
import {RADeleteTemdisa} from './ra-delete-temdisa';
import {AbstractEntityCore} from "../../../engine/shared/entity-view/abstract-entity-core";
import {EntityCoreProtocol} from "../../../engine/shared/entity-view/entity-core-protocol";
import {EntityType} from '../../entity-types';
import {SPECTER_SPEC} from "../../specter-spec";
import {SOGetOrganization} from '../../core/organization/so-get-organization'

export class TemdisaCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = EntityType.temdisa;
  title = 'Temdisa';
  listEntryFactory = new TemdisaListEntryFactory();
  detailFactory = new TemdisaDetailFactory();

  entityService: EOTemdisaService;
  soGetClass = SOGetTemdisa;
  soGet: SOGetTemdisa;

  showQuickAdd = true;

  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOTemdisaService
    );
    this.adapters = [
      new RAPostTemdisa(this),
      new RAPutTemdisa(this),
      new RADeleteTemdisa(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetTemdisa();
    this.soGet.organization = new SOGetOrganization(
      this.activeUserService.getScope('organization').uid
    );
    this.soGet.queryOptions.limit = this.limit;
  }


}