import { SOGetDistributor } from '../distributor/so-get-distributor';
import {ENOrderStatus} from '../../enums';


export class SOPutOrder {

  constructor(
    public uid: string,
    public ref: string = null,
    public name: string = null,
    public dispatchStatus: ENOrderStatus = null,
    public distributor: SOGetDistributor = null,
    public notes: string = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutOrder(
      'uid' in params ? params.uid : null,
      'ref' in params ? params.ref : null,
      'name' in params ? params.name : null,
      'dispatchStatus' in params ? params.dispatchStatus : null,
      'distributor' in params ? params.distributor : null,
      'notes' in params ? params.notes : null,
    );
  }
}
