import {SORespItemImportLine} from '../../core/item-import-line/so-resp-item-import-line';
import {ItemImportLineCore} from './item-import-line.core';
import {ListEntry} from '../../../engine/shared/list-entry';
import {EntityAttributesForDisplay} from '../../../engine/shared/entity-attributes-for-display';
import {DetailEntry, DetailFactory, DetailFactoryProtocol, EditableType} from '../../../engine/shared/detail-entry';
import {ActiveUserService} from '../../../engine/shared/active-user/active-user.service';
import {BaseDetailType} from "../../../engine/shared/base-detail";
import {EntityViewSpecProtocol} from "../../../engine/shared/entity-view/entity-view-spec-protocol";
import {getHeading} from "../../../engine/shared/display-option-utilities";
import {putItemImportLineFormFieldOptions} from './put-item-import-line-form-fields';
import {SOGetItemImportLine} from '../../core/item-import-line/so-get-item-import-line';
import {SOGetItemImport} from '../../core/item-import/so-get-item-import';
import {SOGetDispatch} from '../../core/dispatch/so-get-dispatch';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {DisplayVectorStringFactory} from "../../../engine/config/display-vector";
import {displayOptions} from "../../display-options";
import {ListSpecType} from "../../../engine/shared/entity-view/list-spec";
import {SPECTER_SPEC} from "../../specter-spec";
import {getCore} from "../../../engine/shared/entity-view/get-core";

export class ItemImportLineDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespItemImportLine = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(displayOptions);
    let heading;

    const attributes = []

    const dateCreatedDetail = new DetailEntry(
        'Date created',
        activeUserService.time.niceDate(entity.dateCreated),
        'dateCreated',
        BaseDetailType.text,
        null,
        EditableType.never,
    )
    attributes.push(dateCreatedDetail)


    const itemImportCore = getCore(SPECTER_SPEC, activeUserService, listEntry.entityCore.httpClient, 'ItemImportCore');
    itemImportCore.soGet = SOGetItemImport.construct({
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, itemImportCore)
    itemImportCore.title = heading ? heading : itemImportCore.title
    itemImportCore.listTitle = 'Item import'

    if (entity.itemImport) {
        displayStringFactory.reconfigure(entity.itemImport, entityViewSpec.entityCore.type)
    }
    itemImportCore.configureForDisplay(entityViewSpec)

    const itemImportDetail = new DetailEntry(
      heading ? heading : itemImportCore.listTitle,
      entity.itemImport ? displayStringFactory.primaryString : null,
      'itemImport',
      BaseDetailType.pushedDetail,
      itemImportCore,
      EditableType.never
    )
    if (entity.itemImport) {
      itemImportDetail.cachedListEntry = itemImportCore.makeListEntry(entityViewSpec, itemImportCore, entity.itemImport, 0)
    }
    itemImportDetail.singleFieldSelect = true
    attributes.push(itemImportDetail);

    const dispatchCore = getCore(SPECTER_SPEC, activeUserService, listEntry.entityCore.httpClient, 'DispatchCore');
    dispatchCore.soGet = SOGetDispatch.construct({
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, dispatchCore)
    dispatchCore.title = heading ? heading : dispatchCore.title
    dispatchCore.listTitle = 'Dispatch'

    if (entity.dispatch) {
        displayStringFactory.reconfigure(entity.dispatch, entityViewSpec.entityCore.type)
    }
    dispatchCore.configureForDisplay(entityViewSpec)

    const dispatchDetail = new DetailEntry(
      heading ? heading : dispatchCore.listTitle,
      entity.dispatch ? displayStringFactory.primaryString : null,
      'dispatch',
      BaseDetailType.pushedDetail,
      dispatchCore,
      EditableType.never
    )
    if (entity.dispatch) {
      dispatchDetail.cachedListEntry = dispatchCore.makeListEntry(entityViewSpec, dispatchCore, entity.dispatch, 0)
    }
    dispatchDetail.singleFieldSelect = true
    attributes.push(dispatchDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}