import {SORespCustomField} from '../../core/custom-field/so-resp-custom-field';
import {CustomFieldCore} from './custom-field.core';
import {ListEntry} from '../../../engine/shared/list-entry';
import {EntityAttributesForDisplay} from '../../../engine/shared/entity-attributes-for-display';
import {DetailEntry, DetailFactory, DetailFactoryProtocol, EditableType} from '../../../engine/shared/detail-entry';
import {ActiveUserService} from '../../../engine/shared/active-user/active-user.service';
import {BaseDetailType} from "../../../engine/shared/base-detail";
import {EntityViewSpecProtocol} from "../../../engine/shared/entity-view/entity-view-spec-protocol";
import {getHeading} from "../../../engine/shared/display-option-utilities";
import {putCustomFieldFormFieldOptions} from './put-custom-field-form-fields';
import {SOGetCustomField} from '../../core/custom-field/so-get-custom-field';
import {SOGetTemfield} from '../../core/temfield/so-get-temfield';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {DisplayVectorStringFactory} from "../../../engine/config/display-vector";
import {displayOptions} from "../../display-options";
import {ListSpecType} from "../../../engine/shared/entity-view/list-spec";
import {SPECTER_SPEC} from "../../specter-spec";
import {getCore} from "../../../engine/shared/entity-view/get-core";

export class CustomFieldDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespCustomField = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(displayOptions);
    let heading;

    const attributes = []

    const nameDetail = new DetailEntry(
        'Name',
        entity.name,
        'name',
        BaseDetailType.text,
        null,
        EditableType.always,
        putCustomFieldFormFieldOptions.name
    )
    attributes.push(nameDetail)

    const autoApplyDetail = new DetailEntry(
        'Auto apply',
        entity.autoApply,
        'autoApply',
        BaseDetailType.toggle,
        null,
        EditableType.always,
        putCustomFieldFormFieldOptions.autoApply
    )
    attributes.push(autoApplyDetail)

    const activeDetail = new DetailEntry(
        'Active',
        entity.active,
        'active',
        BaseDetailType.toggle,
        null,
        EditableType.always,
        putCustomFieldFormFieldOptions.active
    )
    attributes.push(activeDetail)


    const itemsCore = getCore(SPECTER_SPEC, activeUserService, listEntry.entityCore.httpClient, 'TemfieldCore');
    itemsCore.soGet = SOGetTemfield.construct({
      customField: new SOGetCustomField(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, itemsCore)
    itemsCore.title = heading ? heading : itemsCore.title
    itemsCore.listTitle = 'Items'

    itemsCore.configureForDisplay(entityViewSpec)

    const itemsDetail = new DetailEntry(
      heading ? heading : itemsCore.listTitle,
      null,
      'items',
      BaseDetailType.embeddedList,
      itemsCore,
      EditableType.never
    )
    attributes.push(itemsDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}