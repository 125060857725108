import {ActiveUserService} from '../../../engine/shared/active-user/active-user.service';
import {HttpClient} from '@angular/common/http';
import {EOItemImportService} from '../../core/item-import/eo-item-import';
import {ItemImportListEntryFactory} from './item-import-list-entry-factory';
import {ItemImportDetailFactory} from './item-import-detail-factory';
import {SOGetItemImport} from '../../core/item-import/so-get-item-import';
import {RAPutItemImport} from './ra-put-item-import';
import {RAPostItemImport} from './ra-post-item-import';
import {RADeleteItemImport} from './ra-delete-item-import';
import {AbstractEntityCore} from "../../../engine/shared/entity-view/abstract-entity-core";
import {EntityCoreProtocol} from "../../../engine/shared/entity-view/entity-core-protocol";
import {EntityType} from '../../entity-types';
import {SPECTER_SPEC} from "../../specter-spec";
import {SOGetOrganization} from '../../core/organization/so-get-organization'

export class ItemImportCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = EntityType.itemImport;
  title = 'Item import';
  listEntryFactory = new ItemImportListEntryFactory();
  detailFactory = new ItemImportDetailFactory();

  entityService: EOItemImportService;
  soGetClass = SOGetItemImport;
  soGet: SOGetItemImport;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOItemImportService
    );
    this.adapters = [
      new RAPostItemImport(this),
      new RAPutItemImport(this),
      new RADeleteItemImport(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetItemImport();
    this.soGet.organization = new SOGetOrganization(
      this.activeUserService.getScope('organization').uid
    );
    this.soGet.queryOptions.limit = this.limit;
  }


}