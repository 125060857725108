<div *ngIf="!parent.entityViewSpec.listBody.isEmpty" class="selection-list-body">
  <div *ngFor="let section of parent.entityViewSpec.listBody.sections" class="section-header" style="width: 100%"
       gdGap="10px"
       gdColumns="repeat(auto-fit, minmax(200px, 1fr))">
    <div *ngFor="let listEntry of section.listEntries">
      <img *ngIf="listEntry.mediaLink" [src]="listEntry.mediaLink"
           style="width: 200px"
           (click)="parent.selectListEntry(listEntry)">
    </div>
  </div>
  <button *ngIf="parent.hasMore" mat-stroked-button (click)="parent.fetchMore()"
          style="min-height: 50px; width: 100%">Load more
  </button>
</div>
