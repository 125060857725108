import {FormField, FormFieldType, SelectionOption} from '../../../engine/shared/form-field';
import {EntityType} from "../../entity-types";


const constraintField = new FormField(
  'constraint',
  'Constraint',
  EntityType.constraint,
  FormFieldType.pushedSelectionList,
  true,
)
const productField = new FormField(
  'product',
  'Product',
  EntityType.product,
  FormFieldType.pushedSelectionList,
  true,
)
const quantityField = new FormField(
  'quantity',
  'Quantity',
  null,
  FormFieldType.text,
  true,
)


export const postAppliedConstraintFormFieldOptions: {[key: string]: FormField} = {
  constraint: constraintField,
  product: productField,
  quantity: quantityField,
};