import {ActiveUserService} from '../../../engine/shared/active-user/active-user.service';
import {HttpClient} from '@angular/common/http';
import {EORoleGrantService} from '../../core/role-grant/eo-role-grant';
import {RoleGrantListEntryFactory} from './role-grant-list-entry-factory';
import {RoleGrantDetailFactory} from './role-grant-detail-factory';
import {SOGetRoleGrant} from '../../core/role-grant/so-get-role-grant';
import {RAPutRoleGrant} from './ra-put-role-grant';
import {RAPostRoleGrant} from './ra-post-role-grant';
import {RADeleteRoleGrant} from './ra-delete-role-grant';
import {AbstractEntityCore} from "../../../engine/shared/entity-view/abstract-entity-core";
import {EntityCoreProtocol} from "../../../engine/shared/entity-view/entity-core-protocol";
import {EntityType} from '../../entity-types';
import {SPECTER_SPEC} from "../../specter-spec";

export class RoleGrantCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = EntityType.roleGrant;
  title = 'Role grant';
  listEntryFactory = new RoleGrantListEntryFactory();
  detailFactory = new RoleGrantDetailFactory();

  entityService: EORoleGrantService;
  soGetClass = SOGetRoleGrant;
  soGet: SOGetRoleGrant;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EORoleGrantService
    );
    this.adapters = [
      new RAPostRoleGrant(this),
      new RAPutRoleGrant(this),
      new RADeleteRoleGrant(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetRoleGrant();
    this.soGet.queryOptions.limit = this.limit;
  }


}