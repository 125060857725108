<form [formGroup]="form">

  <h3 *ngIf="!detail.editEnabled"
      [ngClass]="{'warn': detail.warn}">
    {{detail.name}}: {{detail.value}}
  </h3>

  <mat-form-field
    *ngIf="detail.editEnabled"
    appearance="{{detail.editableFormField.appearance}}"
    color="{{detail.editableFormField.color}}">
    <mat-label *ngIf="!hideAttributeLabels">{{detail.editableFormField.getLabel(parentView.entityViewSpec)}}</mat-label>
    <input matInput
           [type]="showPassword || detail.editableFormField.inputType !== 'password' ? 'text' : 'password'"
           [id]="detail.editableFormField.identifierString"
           [name]="detail.editableFormField.identifierString"
           [formControlName]="detail.editableFormField.identifierString"
           (keyup)="keyup($event)">
    <mat-icon matSuffix *ngIf="detail.editableFormField.inputType === 'password'" (click)="showPassword = !showPassword">{{showPassword ? 'visibility': 'visibility_off'}}</mat-icon>
  </mat-form-field>

</form>
