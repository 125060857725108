import { Type } from 'class-transformer';
import {ObjectiveProtocol} from '../../../engine/protocols/objective-protocol';
import {EntityType} from "../../entity-types";


export class SORespProduct implements ObjectiveProtocol {

  entityType: EntityType = EntityType.product;
  uid: string;
  name: string;
  sku: string;
  exRef: string = null;
  @Type(() => Date)
  dateCreated: Date;
  @Type(() => Date)
  dateUpdated: Date;
  active: boolean = true;
}