<div class="list-container" fxLayout="column">
  <!--  <app-sv-header-->
  <!--    [header]="header"-->
  <!--    [parent]="this"-->
  <!--  ></app-sv-header>-->
  <div class="body-container" fxLayout="column">

    <div fxLayout="row">
    </div>
    <div fxLayout="row">
      <div class="calendar">
        <div fxLayout="row" class="control-bar">
          <div class="cal-buttons" fxLayout="row" fxLayoutAlign="space-between center">
            <div fxLayout="row" fxLayoutAlign="start center">
              <h2>{{month}} {{year}}</h2>
              <button mat-stroked-button class="arrow" (click)="back()">
                <
              </button>
              <button mat-stroked-button color="primary" (click)="pressTodayButton()">
                Today
              </button>
              <button mat-stroked-button class="arrow" (click)="next()">
                >
              </button>
            </div>
            <div class="accessory-buttons">
              <input hidden type="file" accept=".xls,.xlsx,.csv" #uploader (change)=uploadFile($event)>
              <button mat-stroked-button *ngIf="showDownload" (click)="downloadSchedule()">
                Download template
              </button>
              <button mat-stroked-button *ngIf="showUpload" (click)="uploader.click()">
                Upload
              </button>
<!--              <button mat-stroked-button *ngIf="isScheduler" (click)="startPost(null)">-->
<!--                Add event-->
<!--              </button>-->
            </div>
          </div>
        </div>

        <div class="core-days" fxLayout="column" *ngIf="entityViewSpec.listBody">
          <div class="day-names" fxLayout="row">
            <p class="day-col" *ngFor="let section of entityViewSpec.listBody.sections">{{section.heading}}</p>
          </div>
          <div class="account-rows">
            <div fxLayout="row" class="customer-loads">
              <div *ngFor="let section of entityViewSpec.listBody.sections" class="days day-col" fxLayout="column">
                <div *ngFor="let listEntry of section.listEntries">
                  <app-sv-event [listEntry]="listEntry" [parent]="this"></app-sv-event>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
