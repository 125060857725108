import {Injectable} from '@angular/core';

import {Router} from '@angular/router';
import {UserLoaded} from '../../protocols/int-user-loaded';
import {HttpClient} from '@angular/common/http';
import {DisplayTimeHelper} from '../display-time-helper';
import {DetailEntry} from '../detail-entry';
import {EntityCoreProtocol} from '../entity-view/entity-core-protocol';
import {EntityType} from '../../../app/entity-types';
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";
import {SPECTER_SPEC} from "../../../app/specter-spec";
import {SORespToken} from "../../../app/core/token/so-resp-token";
import {SORespUser} from "../../../app/core/user/so-resp-user";
import {SORespOrganization} from "../../../app/core/organization/so-resp-organization";
import {classToPlain, plainToClass} from "class-transformer";
import {TokenCore} from "../../../app/entities/bv-token/token.core";
import {SOGetToken} from "../../../app/core/token/so-get-token";
import {SORespRoleGrant} from "../../../app/core/role-grant/so-resp-role-grant";
import {RoleGrantCore} from "../../../app/entities/bv-role-grant/role-grant.core";
import {SOGetRoleGrant} from "../../../app/core/role-grant/so-get-role-grant";
import {SOGetUser} from "../../../app/core/user/so-get-user";
import {SODeleteToken} from "../../../app/core/token/so-delete-token";
import {SvLoginComponent} from "../../views/pre-built/sv-login/sv-login.component";
import {browser} from "protractor";
import {SOPostToken} from "../../../app/core/token/so-post-token";


@Injectable({
  providedIn: 'root'
})
export class ActiveUserService {


  time = new DisplayTimeHelper();
  isHandset = false;
  private registeredUserLoadedViews: UserLoaded[] = []
  private scopes = {};

  private _roleGrants: SORespRoleGrant[] = [];
  initialRoot: string;
  loginView: SvLoginComponent;

  loadingUser = false;

  emailVerified = false;
  hasPaymentAccount = false

  get roleGrants(): SORespRoleGrant[] {
    return this._roleGrants
  };

  set roleGrants(value: SORespRoleGrant[]) {
    if (value) {
      this._roleGrants = value;
    } else {
      this._roleGrants = []
    }
  }

  private _activeGrant: SORespRoleGrant
  get activeGrant() {
    return this._activeGrant
  }

  set activeGrant(value: SORespRoleGrant) {
    this._activeGrant = value
    if (value) {
      this.setScope('organization', value.organization)
    }
  }

  private _token: SORespToken
  get token(): SORespToken {
    return this._token
  }

  set token(value: SORespToken) {
    this._token = value
    if (value) {
      ActiveUserService.tokenToSessionStorage(value)
      this.user = value.roleGrant.user
      this.activeGrant = value.roleGrant
      this.configureBanner(value);

    } else {
      sessionStorage.removeItem('token')
    }
  }

  private _user: SORespUser
  get user(): SORespUser {
    return this._user
  }

  set user(value: SORespUser) {
    this._user = value
    if (value) {

    } else {
      sessionStorage.removeItem('drawerOpen');
    }
  }

  get displayName() {
    return `${this.user.surname}, ${this.user.givenName}`
  }

  get userLoaded(): boolean {
    return this.user && this.user.uid !== null
  }

  get activeOrganization(): SORespOrganization {
    return this.activeGrant.organization
  }

  constructor(
    private router: Router,
    private httpClient: HttpClient,
    private breakpointObserver: BreakpointObserver
  ) {
    this.setLayoutParameter();
    this.initUser()
  }

  getScope(identifier) {
    return this.scopes[identifier]
  }

  setScope(identifier: string, scope) {
    console.log(identifier)
    console.log(scope)
    this.scopes[identifier] = scope
  }

  removeScope(identifier: string) {
    delete this.scopes[identifier]
  }

  private configureBanner(value: SORespToken) {
    if (['sysadmin'].includes(value.roleGrant.role.identifier)) {
      this.emailVerified = true
      this.hasPaymentAccount = true
      SPECTER_SPEC.topBanner.disabled = true;
    } else {
      this.emailVerified = value.roleGrant.user.emailVerified
      this.hasPaymentAccount = !!value.roleGrant.organization.exRefPaymentsLink;
      SPECTER_SPEC.topBanner.disabled = this.emailVerified && this.hasPaymentAccount;

      if (this.emailVerified && !this.hasPaymentAccount) {
        SPECTER_SPEC.topBanner.text = 'Thanks for verifying your email, please add payment details.';
        SPECTER_SPEC.topBanner.actionText = 'Add payment details';
        SPECTER_SPEC.topBanner.showAction = true;
      }
    }
  }

  private setLayoutParameter() {
    this.breakpointObserver.observe([
      Breakpoints.HandsetLandscape,
      Breakpoints.HandsetPortrait
    ]).subscribe(result => {
      if (result.matches) {
        this.activateHandsetLayout();
      }
    });
  }

  private initUser() {
    this.loadingUser = true
    console.log('[INFO]: init user')
    const tokenStr = sessionStorage.getItem('token')
    const tokenObj = JSON.parse(tokenStr)
    if (!!tokenStr && tokenObj.roleGrant) {
      console.log('[INFO]: have local token')
      this.token = plainToClass(SORespToken, JSON.parse(tokenStr))
      if (!this.token) {
        this.logout();
      } else {
        this.checkToken()
        this.notifyUserLoadedSubscribersLoaded()
      }
    } else {
      console.log('[INFO]: no token logging out')
      this.logout()
    }
  }

  canEdit(entityCore: EntityCoreProtocol, detail: DetailEntry) {
    return true;
  }

  canDelete(type: EntityType) {
    return true;
  }

  registerUserLoadedView(view: UserLoaded) {
    if (this.userLoaded) {
      view.userLoaded()
    }
    this.registeredUserLoadedViews.push(
      view
    )
  }

  unregisterUserLoadedView(view: UserLoaded) {
    console.log(`[INFO]: unregistering user loaded view ${view.id}`)
    console.log(this.registeredUserLoadedViews);
    const dex = this.registeredUserLoadedViews.findIndex(obj => obj.id === view.id)
    this.registeredUserLoadedViews.splice(dex, 1)
  }

  notifyUserLoadedSubscribersLoaded() {
    for (const view of this.registeredUserLoadedViews) {
      view.userLoaded()
    }
    this.loadingUser = false;
    if (this.emailVerified && !this.hasPaymentAccount) {
      SPECTER_SPEC.topBanner.handleAction();
    }
  }

  notifyUserLoadedSubscribersUnloaded() {
    for (const view of this.registeredUserLoadedViews) {
      view.userUnloaded()
    }
  }

  loginSuccess(token: SORespToken) {
    console.log('INFO: login success');
    console.log(token)
    this.token = token
    this.getAndSetRoleGrants(true)
  }

  logout() {
    this.loadingUser = false
    console.log('[INFO]: Logging user out')
    const tokeCore = new TokenCore(this, this.httpClient)
    tokeCore.entityService.post(new SOPostToken(null, null, 'guest')).subscribe(resp => {
      if (resp) {
        console.log('[INFO]: Finishing logout')
        this.finishLogout()
      }
    })
  }

  private finishLogout() {
    console.log('[INFO]: finishing logout')
    this.user = null
    this.roleGrants = null
    this.activeGrant = null
    this.token = null
    this.notifyUserLoadedSubscribersUnloaded()
    if (['terms', 'privacy'].includes(this.initialRoot)) {
      console.log(`[INFO]: logout complete navigating to ${this.initialRoot}`)
      this.router.navigate([this.initialRoot]);
    } else if (['reset-password', 'verify-email'].includes(this.initialRoot)) {
      console.log(`[INFO]: logout complete staying on ${this.initialRoot}`)
      // this is set back to root '' to prevent the app from thinking that's it's still
      // on reset-password or verify-email after logout (results in a bad redirect)
      this.initialRoot = '';
    } else {
      console.log('[INFO]: logout complete navigating home')
      this.router.navigateByUrl('');
    }
  }

  private activateHandsetLayout() {
    this.isHandset = true;
  }

  private checkToken() {
    const tokeCore = new TokenCore(this, this.httpClient)
    tokeCore.entityService.get(new SOGetToken(this.token.uid)).subscribe(resp => {
      if (resp && resp.objects && resp.objects.length > 0) {
        this.token = resp.objects[0]
        this.getAndSetRoleGrants();
      } else {
        this.logout()
      }
    })
  }

  private getAndSetRoleGrants(initialLogin: boolean = false) {
    const roleGrantCore = new RoleGrantCore(this, this.httpClient)
    roleGrantCore.entityService.get(
      new SOGetRoleGrant(null, null, null, new SOGetUser(this.user.uid))
    ).subscribe(resp => {
      if (resp) {
        this.roleGrants = resp.objects
        if (initialLogin) {
          this.finalizeInitialLogin();
        }
      }
    })
  }

  private finalizeInitialLogin() {
    this.activeGrant = this.roleGrants[0]
    this.notifyUserLoadedSubscribersLoaded()
    if (this.loginView != null) {
      this.loginView.loading = false;
    }
    this.loadingUser = false;
    this.router.navigateByUrl(
      this.initialRoot && !['login', 'register', 'reset-password', 'verify-email'].includes(this.initialRoot) ? this.initialRoot : SPECTER_SPEC.home
    )
  }

  private static tokenToSessionStorage(value: SORespToken) {
    sessionStorage.setItem('token', JSON.stringify(classToPlain(value)))
  }
}
