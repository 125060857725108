import {FormField, FormFieldType, SelectionOption} from '../../../engine/shared/form-field';
import {EntityType} from "../../entity-types";
import {ENSubscriptionStatus} from '../../enums';


const priceField = new FormField(
  'price',
  'Price',
  EntityType.price,
  FormFieldType.pushedSelectionList,
  true,
)
const organizationField = new FormField(
  'organization',
  'Organization',
  EntityType.organization,
  FormFieldType.pushedSelectionList,
  true,
)
const exRefField = new FormField(
  'exRef',
  'Ex ref',
  null,
  FormFieldType.text,
  false,
)
const statusField = new FormField(
  'status',
  'Status',
  null,
  FormFieldType.enumeration,
  true,
)
statusField.enumeration = ENSubscriptionStatus


export const postSubscriptionFormFieldOptions: {[key: string]: FormField} = {
  price: priceField,
  organization: organizationField,
  exRef: exRefField,
  status: statusField,
};