import {FormField, FormFieldType, SelectionOption} from '../../../engine/shared/form-field';
import {EntityType} from "../../entity-types";
import {ENOrderStatus} from '../../enums';


const refField = new FormField(
  'ref',
  'Order id',
  null,
  FormFieldType.text,
  false,
)
const nameField = new FormField(
  'name',
  'Name',
  null,
  FormFieldType.text,
  false,
)
const dispatchStatusField = new FormField(
  'dispatchStatus',
  'Dispatch status',
  null,
  FormFieldType.enumeration,
  false,
  true,
  ENOrderStatus.needsDispatchAssignment
)
dispatchStatusField.enumeration = ENOrderStatus
const distributorField = new FormField(
  'distributor',
  'Distributor',
  EntityType.distributor,
  FormFieldType.autoCompleteList,
  false,
)
const notesField = new FormField(
  'notes',
  'Notes',
  null,
  FormFieldType.text,
  false,
)


export const putOrderFormFieldOptions: {[key: string]: FormField} = {
  ref: refField,
  name: nameField,
  dispatchStatus: dispatchStatusField,
  distributor: distributorField,
  notes: notesField,
};