

export class SOPostDefaultCustomField {

  constructor(
    public name: string,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostDefaultCustomField(
      'name' in params ? params.name : null,
    );
  }
}
