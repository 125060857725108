import {FormField, FormFieldType, SelectionOption} from '../form-field';
import {FormGroup} from '@angular/forms';
import {Action} from '../action';
import {EntityCoreProtocol} from '../entity-view/entity-core-protocol';
import {AdapterType} from "./adapter-type";
import {EntityType} from "../../../app/entity-types";
import {ListEntry} from "../list-entry";
import {FormViewProtocol} from "../../protocols/form-view-protocol";
import {EntityViewSpecProtocol} from "../entity-view/entity-view-spec-protocol";
import {SPECTER_SPEC} from "../../../app/specter-spec";
import {FileUploadService} from "../file-upload/file-upload.service";
import {DetailEntry} from "../detail-entry";
import {ObjectiveProtocol} from "../../protocols/objective-protocol";
import {SOGetOrganization} from "../../../app/core/organization/so-get-organization";


export class AbstractAdapter {

  public entityCore: EntityCoreProtocol;
  requestInProgress = false;
  errorMessage: string;

  adapterType: AdapterType
  file: File;

  forceOverrideFormValues = {};
  patchFormDisplayValues = {};

  form: FormGroup;
  formFields: FormField[] = [];
  formFieldOptions: FormField[] = [];
  actions: Action[] = [];

  isDefault = true;

  request
  RequestClass

  parentEntityViewSpec: EntityViewSpecProtocol;

  submitDisabled = false;

  initialized = false;

  fileUploadService: FileUploadService;

  get activeUserService() {
    return this.entityCore.activeUserService;
  }

  selectedEntity

  get parentListEntry(): ListEntry {
    if (
      this.parentEntityViewSpec
      && this.parentEntityViewSpec.listEntry
    ) {
      return this.parentEntityViewSpec.listEntry;
    } else {
      return null;
    }
  }

  get parentEntity() {
    if (
      this.parentListEntry
    ) {
      return this.parentListEntry.entity;
    } else {
      return null;
    }
  }

  get parentCore(): EntityCoreProtocol {
    if (
      this.parentListEntry
    ) {
      return this.parentListEntry.entityCore;
    } else {
      return null;
    }
  }

  _delegate: FormViewProtocol;
  public get delegate(): FormViewProtocol {
    return this._delegate;
  }

  public set delegate(value: FormViewProtocol) {
    this._delegate = value;
    this.selectedEntity = value.selectedEntity;
    if (!this.initialized) {
      this.initialized = true;
      this.setParentEntityViewSpec()
      this.onSet();
    }
  }

  setParentEntityViewSpec() {

  }

  onSet() {
    this.resetRequest();
    if (!this.delegate.formInitialized) {
      this.formFields = []
      for (const field of this.formFieldOptions) {
        if (!field.isEntity) {
          this.formFields.push(field)
        } else {
          if (
            !this.parentCore
            || (this.parentCore && this.parentCore.type != field.entityType)
          ) {
            this.formFields.push(field)
          }
        }
      }
      for (const field of this.formFields) {
        field.initCore(this.entityCore.activeUserService, this.entityCore.httpClient)
        if (
          field.isEntity
          && (
            !field.dependents
            || field.dependents.length === 0
            || (this.parentCore && field.dependents && field.dependents.find(obj => obj == this.parentCore.type))
          )
        ) {
          this.fetchOptions(field)
        }
      }
      this.resetForm();
      this.patchFormOverride();
    }
  }

  onSelectionChange(field: FormField) {
    for (const formField of this.formFields) {
      for (const dependent of formField.dependents) {
        if (field.entityType && field.entityType == dependent) {
          this.fetchOptions(formField)
        }
      }
    }
  }

  fetchOptions(field: FormField) {
    if (this.parentEntity && this.parentCore) {
      for (const key of Object.keys(this.parentEntity)) {
        const scope = this.activeUserService.getScope(key)
        if (field.entityCore.soGet.hasOwnProperty(key) && this.parentEntity[key] && this.parentEntity[key].uid && !scope) {

          const core = SPECTER_SPEC.makeCore(
            this.activeUserService, this.parentCore.httpClient, this.parentEntity[key].entityType
          )
          field.entityCore.soGet[key] = new core.soGetClass(this.parentEntity[key].uid)
        }
      }
    }
    field.entityCore.entityService.get(field.entityCore.soGet).subscribe(resp => {
      if (resp) {
        field.resetSelectionOptions()
        for (const entity of resp.objects) {
          field.selectionOptions.push(
            this.getSelectionOption(field, entity)
          );
        }
      }
    });
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    throw new Error("Method not implemented.");
  }

  getFormValueFromIdentifier(key: string, entityGetClass = null, isScope: boolean = false) {

    if (entityGetClass && isScope) {

      return new entityGetClass(
        this.activeUserService.getScope('organization').uid
      )

    } else if (entityGetClass && !isScope) {

      const value = this.parentCore && this.parentCore.type == EntityType[key] ? this.parentEntity.uid : this.form.value[key]
      if (value && (!this.selectedEntity || this.selectedEntity && this.selectedEntity[key] && this.selectedEntity[key].uid !== value)) {
        return new entityGetClass(value)
      } else {
        return null
      }

    } else {

      return this.getValueIfForceOverride(key)

    }
  }

  private getValueIfForceOverride(key) {
    if (Object.keys(this.forceOverrideFormValues).includes(key)) {
      return this.forceOverrideFormValues[key]
    } else {
      return this.form.value[key]
    }
  }

  patchFormOverride() {
    for (const field of this.formFields) {
      if (this.patchFormDisplayValues[field.identifierString]) {
        field.value = this.patchFormDisplayValues[field.identifierString]
      }
    }
  }

  prepareRequest() {

  }

  post(request) {

  }

  put(request) {

  }

  submitForm() {
  }

  resetForm() {
    this.resetRequest();
    this.form = this.getFormGroup();
    this.registerFormFields()
    this.delegate.formInitialized = true;
  }

  getFormGroup() {
    const formGroup = new FormGroup({});
    for (const field of this.formFields) {
      field.reset();
      formGroup.addControl(
        field.identifierString,
        field.formControl
      )
    }
    return formGroup
  }

  registerFormFields() {
    for (const field of this.formFields) {
      if (field.entityType && field.type == FormFieldType.selection) {
        field.resetSelectionOptions();
      }
      if (field.type == FormFieldType.enumeration) {
        field.resetEnumerationOptions();
      }
      field.formGroup = this.form;
    }
  }

  requestError(request, message) {
    console.log('post request error');
    console.log(message)
    this.resetRequest();
    this.requestInProgress = false;
    this.errorMessage = message;
    this.delegate.submitError(message);
  }

  prepareRequestWithQuickCreate(value) {

  }

  checkCheckbox(entity, checked: boolean) {

  }

  unregisterDelegate() {
    this.initialized = false
  }

  removeDetail(detail: DetailEntry) {

  };

  clearErrorMessage() {
    this.errorMessage = null;
  }

  resetRequest() {
    this.request = null;
  }

  updateParent(resp) {
    if (
      this.delegate.entityViewSpec
      && this.delegate.entityViewSpec.parentEntityViewSpec
      && this.delegate.entityViewSpec.parentEntityViewSpec.parentEntityViewSpec
      && this.delegate.entityViewSpec.parentEntityViewSpec.parentEntityViewSpec.parentEntityViewSpec
    ) {
      for (const value of Object.values(resp)) {
        const parentObjViewSpec = this.delegate.entityViewSpec.parentEntityViewSpec.parentEntityViewSpec;
        if (value && value['entityType'] && value['entityType'] === parentObjViewSpec.entityCore.type) {

          const dex = parentObjViewSpec.parentEntityViewSpec.entityCore.entities.findIndex(
            obj => obj.uid === value['uid']
          )

          parentObjViewSpec.parentEntityViewSpec.entityCore.entities.splice(dex, 1, value)

          parentObjViewSpec.parentEntityViewSpec.listBody.resetListBodyFromEntities(
            parentObjViewSpec.parentEntityViewSpec.entityCore.entities,
            parentObjViewSpec.parentEntityViewSpec
          )

          const entry = parentObjViewSpec.parentEntityViewSpec.listBody.sections[0].listEntries.find(
            obj => obj.entity.uid === value['uid']
          )
          this.delegate.entityViewSpec.parentEntityViewSpec.parentEntityViewSpec.listEntry = entry;

          // TODO - this should probably be done in a more elegant way as it's not converting the obj value to a display value
          for (const attrSpec of parentObjViewSpec.childrenEntityAttributeViewSpecs) {
            if (value[attrSpec.detailEntry.identifier]) {
              attrSpec.detailEntry.value = value[attrSpec.detailEntry.identifier]
            }
          }
        }
      }
    }
  }
}
