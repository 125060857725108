import {SORespRole} from '../../core/role/so-resp-role';
import {RoleCore} from './role.core';
import {ListEntry} from '../../../engine/shared/list-entry';
import {EntityAttributesForDisplay} from '../../../engine/shared/entity-attributes-for-display';
import {DetailEntry, DetailFactory, DetailFactoryProtocol, EditableType} from '../../../engine/shared/detail-entry';
import {ActiveUserService} from '../../../engine/shared/active-user/active-user.service';
import {BaseDetailType} from "../../../engine/shared/base-detail";
import {EntityViewSpecProtocol} from "../../../engine/shared/entity-view/entity-view-spec-protocol";
import {getHeading} from "../../../engine/shared/display-option-utilities";
import {putRoleFormFieldOptions} from './put-role-form-fields';
import {SOGetRole} from '../../core/role/so-get-role';
import {SOGetRoleGrant} from '../../core/role-grant/so-get-role-grant';
import {SOGetViewGrant} from '../../core/view-grant/so-get-view-grant';
import {SOGetOrganizationInvite} from '../../core/organization-invite/so-get-organization-invite';
import {DisplayVectorStringFactory} from "../../../engine/config/display-vector";
import {displayOptions} from "../../display-options";
import {ListSpecType} from "../../../engine/shared/entity-view/list-spec";
import {SPECTER_SPEC} from "../../specter-spec";
import {getCore} from "../../../engine/shared/entity-view/get-core";

export class RoleDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespRole = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(displayOptions);
    let heading;

    const attributes = []

    const nameDetail = new DetailEntry(
        'Name',
        entity.name,
        'name',
        BaseDetailType.text,
        null,
        EditableType.never,
    )
    attributes.push(nameDetail)

    const identifierDetail = new DetailEntry(
        'Identifier',
        entity.identifier,
        'identifier',
        BaseDetailType.text,
        null,
        EditableType.never,
    )
    attributes.push(identifierDetail)


    const roleGrantsCore = getCore(SPECTER_SPEC, activeUserService, listEntry.entityCore.httpClient, 'RoleGrantCore');
    roleGrantsCore.soGet = SOGetRoleGrant.construct({
      role: new SOGetRole(entity.uid),
    });
    heading = getHeading(entityViewSpec.entityCore, roleGrantsCore)
    roleGrantsCore.title = heading ? heading : roleGrantsCore.title
    roleGrantsCore.listTitle = 'Role grants'

    roleGrantsCore.configureForDisplay(entityViewSpec)

    const roleGrantsDetail = new DetailEntry(
      heading ? heading : roleGrantsCore.listTitle,
      null,
      'roleGrants',
      BaseDetailType.embeddedList,
      roleGrantsCore,
      EditableType.never
    )
    attributes.push(roleGrantsDetail);

    const viewGrantsCore = getCore(SPECTER_SPEC, activeUserService, listEntry.entityCore.httpClient, 'ViewGrantCore');
    viewGrantsCore.soGet = SOGetViewGrant.construct({
      role: new SOGetRole(entity.uid),
    });
    heading = getHeading(entityViewSpec.entityCore, viewGrantsCore)
    viewGrantsCore.title = heading ? heading : viewGrantsCore.title
    viewGrantsCore.listTitle = 'View grants'

    viewGrantsCore.configureForDisplay(entityViewSpec)

    const viewGrantsDetail = new DetailEntry(
      heading ? heading : viewGrantsCore.listTitle,
      null,
      'viewGrants',
      BaseDetailType.embeddedList,
      viewGrantsCore,
      EditableType.never
    )
    attributes.push(viewGrantsDetail);

    const invitesCore = getCore(SPECTER_SPEC, activeUserService, listEntry.entityCore.httpClient, 'OrganizationInviteCore');
    invitesCore.soGet = SOGetOrganizationInvite.construct({
      role: new SOGetRole(entity.uid),
    });
    heading = getHeading(entityViewSpec.entityCore, invitesCore)
    invitesCore.title = heading ? heading : invitesCore.title
    invitesCore.listTitle = 'Invites'

    invitesCore.configureForDisplay(entityViewSpec)

    const invitesDetail = new DetailEntry(
      heading ? heading : invitesCore.listTitle,
      null,
      'invites',
      BaseDetailType.embeddedList,
      invitesCore,
      EditableType.never
    )
    attributes.push(invitesDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}