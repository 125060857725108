import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {SbNavItem} from '../../../shared/sb-nav-item/sb-nav-item';
import {ActiveUserService} from '../../../shared/active-user/active-user.service';
import {MatSidenav} from '@angular/material/sidenav';

import {navItems} from '../../../../app/navi-items';
import {Router} from '@angular/router';
import {UserLoaded} from "../../../protocols/int-user-loaded";
import {SvContainerComponent} from "../sv-container/sv-container.component";
import {InnerSidenavHandler} from "../../../shared/inner-sidenav-handler.service";
import {SideNavItem} from "../../../shared/side-nav-item";
import {SPECTER_SPEC} from "../../../../app/specter-spec";
import {HttpClient} from "@angular/common/http";
import {ViewGrantCore} from "../../../../app/entities/bv-view-grant/view-grant.core";
import {SOGetViewGrant} from "../../../../app/core/view-grant/so-get-view-grant";
import {SOGetRole} from "../../../../app/core/role/so-get-role";


@Component({
  selector: 'app-sv-sidenav',
  templateUrl: './sv-sidenav.component.html',
  styleUrls: ['./sv-sidenav.component.css']
})
export class SvSidenavComponent implements UserLoaded {

  id = 'sideNav';

  navItems: SbNavItem[] = [];
  innerItems: SideNavItem[] = [];
  innerNavLoading = false;

  @Input()
  parent: SvContainerComponent

  @ViewChild('innerNav') innerNav: MatSidenav;
  showSettings = SPECTER_SPEC.showSettings;
  settingsPath = SPECTER_SPEC.settingsPath;

  viewGrantCore: ViewGrantCore

  constructor(
    public activeUserService: ActiveUserService,
    public innerSidenavHandler: InnerSidenavHandler,
    private router: Router,
    private httpClient: HttpClient
  ) {
    this.activeUserService.registerUserLoadedView(this)
    innerSidenavHandler.delegate = this
  }

  userLoaded(): void {
    this.setNavItems();
    if (this.innerNav && this.innerNav.opened) {
      this.innerNav.toggle()
    }
  }

  setNavItems() {
    this.viewGrantCore = new ViewGrantCore(this.activeUserService, this.httpClient)
    this.viewGrantCore.entityService.get(
      new SOGetViewGrant(null, new SOGetRole(this.activeUserService.activeGrant.role.uid))
    ).subscribe( resp => {
      if (resp) {
        this.navItems = []
        for (const navItem of Object.values(navItems)) {
          const viewGrant = resp.objects.find(obj => obj.view.identifier === navItem.id.toLowerCase())
          if (viewGrant) {
            this.navItems.push(navItem)
          }
        }
      }
    })
  }

  navItemSelect(navItem: SbNavItem) {
    if (this.activeUserService.isHandset) {
      this.parent.sidenav.toggle();
    }
    this.router.initialNavigation()
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(r => {
        this.router.navigate([navItem.routerLink]).then(e => {
        })
      }
    );
  }

  showInnerNavItems() {
    this.innerNav.toggle();
    this.innerSidenavHandler.startOpen();
  }

  setInnerItems(items) {
    this.innerNavLoading = false;
    this.innerItems = items
  }

  roleSelect(role) {
    if (role) {
      this.navItems = [];
      this.innerNav.toggle();
      this.setNavItems();
      this.navItemSelect(navItems[role.navroas[0].navi.identifier]);
    }
  }

  userUnloaded() {

  }

  innerNavItemSelect(navItem: SideNavItem) {
    this.innerNav.close()
    this.innerSidenavHandler.navItemSelected(navItem)
  }

  settingsClick() {
    this.innerNav.close();
    this.router.navigate([this.settingsPath])

  }

  getNavItemDisplayText(navItem: SbNavItem) {
    const core = SPECTER_SPEC.makeCore(this.activeUserService, this.httpClient, navItem.entityType)
    return core.navTitle ? core.navTitle : core.title + 's';

  }

  logoutClick() {
    this.innerNavLoading = true;
    this.activeUserService.logout();
  }
}
