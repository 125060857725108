import {Component, OnInit} from '@angular/core';
import {EntityViewSpecProtocol} from "../../../shared/entity-view/entity-view-spec-protocol";
import {SvEntityViewComponent} from "../../entity/sv-entity-view/sv-entity-view.component";
import {HttpClient} from "@angular/common/http";
import {ActiveUserService} from "../../../shared/active-user/active-user.service";
import {ActivatedRoute, Router} from "@angular/router";
import {SOGetUser} from "../../../../app/core/user/so-get-user";
import {UserCore} from "../../../../app/entities/bv-user/user.core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {matchValues} from "../../../shared/form-field";
import {TokenCore} from "../../../../app/entities/bv-token/token.core";
import {SOPostToken} from "../../../../app/core/token/so-post-token";
import {SOPostRoleGrant} from "../../../../app/core/role-grant/so-post-role-grant";
import {SOGetRoleGrant} from "../../../../app/core/role-grant/so-get-role-grant";
import {SOGetToken} from "../../../../app/core/token/so-get-token";
import {SOPutUser} from "../../../../app/core/user/so-put-user";
import {SORespToken} from "../../../../app/core/token/so-resp-token";
import {SPECTER_SPEC} from "../../../../app/specter-spec";
import {UserLoaded} from "../../../protocols/int-user-loaded";


@Component({
  selector: 'app-sv-password-reset',
  templateUrl: './sv-password-reset.component.html',
  styleUrls: ['./sv-password-reset.component.scss']
})
export class SvPasswordResetComponent implements UserLoaded {

  id = 'passwordReset';

  entityCore: TokenCore;
  entityViewSpec: EntityViewSpecProtocol;
  parentEntityViewComponent: SvEntityViewComponent;
  loading: boolean;
  parentEntity;
  selectedEntity;
  formInitialized = false

  isPost = true;

  errorMessage: string;

  passwordResetToken: string = ''

  emailFormGroup: FormGroup;
  passwordFormGroup: FormGroup;

  success = false;

  token: SORespToken;

  constructor(
    public httpClient: HttpClient,
    public activeUserService: ActiveUserService,
    private activatedRoute: ActivatedRoute,
    public router: Router
  ) {
    this.entityCore = new TokenCore(activeUserService, httpClient)
    this.entityCore.autoSave = false;
    this.activeUserService.registerUserLoadedView(this)
    this.passwordResetToken = this.activatedRoute.snapshot.paramMap.get("token")
    console.log('[INFO] init reset password')
    console.log(this.passwordResetToken)
    if (!!this.activeUserService.loginView) {
      this.userUnloaded()
    }
  }

  userLoaded() {
    console.log('[INFO] User loaded in reset password')
    this.router.navigate([SPECTER_SPEC.home])
  }

  clickSubmitFormButton() {
    this.loading = true

    if (this.passwordResetToken) {
      const userCore = new UserCore(this.activeUserService, this.httpClient)
      userCore.entityService.put(
        new SOPutUser(
          this.token.roleGrant.user.uid,
          this.passwordFormGroup.value.password
        )
      ).subscribe(resp =>{
        this.loading = false;
        if (resp) {
          this.activeUserService.loginSuccess(this.token);
        }
      })
    } else {
      this.entityCore.entityService.post(
        new SOPostToken(
          null,
          new SOGetRoleGrant(
            null,
            null,
            null,
            new SOGetUser(null, null, null, this.emailFormGroup.value.email)
          )
        )
      ).subscribe(resp => {
        this.loading = false;
        this.success = true;
        if (resp) {

        }
      })
    }
  }

  submitError(message): void {
    this.entityCore.postHelper.submitDisabled = false
    for (const field of this.entityCore.postHelper.formFields) {
      field.disabled = false
    }
    this.loading = false
    this.errorMessage = message
  }


  cancel() {
    this.router.navigate(['/login'])
  }

  keyup($event: KeyboardEvent) {

  }

  userUnloaded() {
    console.log('[INFO]: Reset-password - user unloaded')
    if (this.passwordResetToken) {
      console.log('[INFO]: Reset-password - posting reset password token')
      this.entityCore.entityService.post(
        new SOPostToken(null, null, this.passwordResetToken)
      ).subscribe(resp => {
        console.log('[INFO]: Reset-password - post token complete')
        if (resp) {
          console.log('[INFO]: Reset-password - post token success showing password form group')
          this.token = resp;
          this.passwordFormGroup = new FormGroup({
            password: new FormControl(null, [Validators.required]),
            passwordTwo: new FormControl(null, [Validators.required, matchValues('password')])
          })
          this.activeUserService.unregisterUserLoadedView(this)
        }
      })
    } else {
      console.log('[INFO]: Reset-password - showing email form group')
      this.emailFormGroup = new FormGroup({
        email: new FormControl(null, [Validators.required])
      })
    }
  }
}
