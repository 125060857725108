import {SORespIngest} from '../../core/ingest/so-resp-ingest';
import {IngestCore} from './ingest.core';
import {ListEntry} from '../../../engine/shared/list-entry';
import {EntityAttributesForDisplay} from '../../../engine/shared/entity-attributes-for-display';
import {DetailEntry, DetailFactory, DetailFactoryProtocol, EditableType} from '../../../engine/shared/detail-entry';
import {ActiveUserService} from '../../../engine/shared/active-user/active-user.service';
import {BaseDetailType} from "../../../engine/shared/base-detail";
import {EntityViewSpecProtocol} from "../../../engine/shared/entity-view/entity-view-spec-protocol";
import {getHeading} from "../../../engine/shared/display-option-utilities";
import {putIngestFormFieldOptions} from './put-ingest-form-fields';
import {SOGetIngest} from '../../core/ingest/so-get-ingest';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {DisplayVectorStringFactory} from "../../../engine/config/display-vector";
import {displayOptions} from "../../display-options";
import {ListSpecType} from "../../../engine/shared/entity-view/list-spec";
import {SPECTER_SPEC} from "../../specter-spec";
import {getCore} from "../../../engine/shared/entity-view/get-core";

export class IngestDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespIngest = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(displayOptions);
    let heading;

    const attributes = []

    const entityNameDetail = new DetailEntry(
        'Entity name',
        entity.entityName,
        'entityName',
        BaseDetailType.text,
        null,
        EditableType.always,
        putIngestFormFieldOptions.entityName
    )
    attributes.push(entityNameDetail)

    const dataDetail = new DetailEntry(
        'Data',
        entity.data,
        'data',
        BaseDetailType.text,
        null,
        EditableType.always,
        putIngestFormFieldOptions.data
    )
    attributes.push(dataDetail)


    const organizationCore = getCore(SPECTER_SPEC, activeUserService, listEntry.entityCore.httpClient, 'OrganizationCore');
    organizationCore.soGet = SOGetOrganization.construct({
    });
    heading = getHeading(entityViewSpec.entityCore, organizationCore)
    organizationCore.title = heading ? heading : organizationCore.title
    organizationCore.listTitle = 'Organization'

    if (entity.organization) {
        displayStringFactory.reconfigure(entity.organization, entityViewSpec.entityCore.type)
    }
    organizationCore.configureForDisplay(entityViewSpec)

    const organizationDetail = new DetailEntry(
      heading ? heading : organizationCore.listTitle,
      entity.organization ? displayStringFactory.primaryString : null,
      'organization',
      BaseDetailType.pushedDetail,
      organizationCore,
      EditableType.always,
      putIngestFormFieldOptions.organization
    )
    if (entity.organization) {
      organizationDetail.cachedListEntry = organizationCore.makeListEntry(entityViewSpec, organizationCore, entity.organization, 0)
    }
    organizationDetail.singleFieldSelect = true
    attributes.push(organizationDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}