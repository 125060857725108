import {ActiveUserService} from '../../../engine/shared/active-user/active-user.service';
import {HttpClient} from '@angular/common/http';
import {EOTemfieldService} from '../../core/temfield/eo-temfield';
import {TemfieldListEntryFactory} from './temfield-list-entry-factory';
import {TemfieldDetailFactory} from './temfield-detail-factory';
import {SOGetTemfield} from '../../core/temfield/so-get-temfield';
import {RAPutTemfield} from './ra-put-temfield';
import {RAPostTemfield} from './ra-post-temfield';
import {RADeleteTemfield} from './ra-delete-temfield';
import {AbstractEntityCore} from "../../../engine/shared/entity-view/abstract-entity-core";
import {EntityCoreProtocol} from "../../../engine/shared/entity-view/entity-core-protocol";
import {EntityType} from '../../entity-types';
import {SPECTER_SPEC} from "../../specter-spec";
import {SOGetOrganization} from '../../core/organization/so-get-organization'

export class TemfieldCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = EntityType.temfield;
  title = 'Temfield';
  listEntryFactory = new TemfieldListEntryFactory();
  detailFactory = new TemfieldDetailFactory();

  entityService: EOTemfieldService;
  soGetClass = SOGetTemfield;
  soGet: SOGetTemfield;

  showQuickAdd = true;

  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOTemfieldService
    );
    this.adapters = [
      new RAPostTemfield(this),
      new RAPutTemfield(this),
      new RADeleteTemfield(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetTemfield();
    this.soGet.organization = new SOGetOrganization(
      this.activeUserService.getScope('organization').uid
    );
    this.soGet.queryOptions.limit = this.limit;
  }


}