import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {ActiveUserService} from "../../../shared/active-user/active-user.service";
import {UserCore} from "../../../../app/entities/bv-user/user.core";
import {SOGetUser} from "../../../../app/core/user/so-get-user";
import {SPECTER_SPEC} from "../../../../app/specter-spec";
import {UserLoaded} from "../../../protocols/int-user-loaded";
import {TokenCore} from "../../../../app/entities/bv-token/token.core";
import {SOGetToken} from "../../../../app/core/token/so-get-token";
import {SOPostToken} from "../../../../app/core/token/so-post-token";
import {EntityServiceErrorHandler} from "../../../multiverse/mm-entity-service-base";


@Component({
  selector: 'app-sv-email-verification',
  templateUrl: './sv-email-verification.component.html',
  styleUrls: ['./sv-email-verification.component.scss']
})
export class SvEmailVerificationComponent implements UserLoaded, EntityServiceErrorHandler {

  id = 'emailVerification';

  loading = false;

  verificationToken: string = ''
  private tokenCore: TokenCore;
  success = false;
  fail = false;
  failMessage = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    private httpClient: HttpClient,
    private activeUserService: ActiveUserService,
    public router: Router
  ) {
    activeUserService.registerUserLoadedView(this)
    this.tokenCore = new TokenCore(this.activeUserService, this.httpClient);
    this.tokenCore.entityService.errorHandler = this;
  }

  userLoaded() {
  }

  userUnloaded() {
    console.log('[INFO] User unloaded in email verification')
    this.verificationToken = this.activatedRoute.snapshot.paramMap.get("token")

    this.loading = true;
    this.tokenCore.entityService.post(
      new SOPostToken(null, null, this.verificationToken)
    ).subscribe(resp => {
      this.loading = false;
      if (resp) {
        console.log('[INFO] Post email verify token success')
        this.success = true;
        SPECTER_SPEC.topBanner.disabled = true;
        this.activeUserService.loginSuccess(resp);
        this.activeUserService.unregisterUserLoadedView(this);
        this.router.navigateByUrl(SPECTER_SPEC.home)
      }
    })
  }

  processError(request, message) {
    console.log('[INFO] Post email verify token failure')
    this.fail = true;
    this.failMessage = message.split(': ')[1]
  }
}
