import {ListBody} from '../list-body';
import {SvListComponent} from '../../views/entity/sv-list/sv-list.component';
import {EntityCoreProtocol} from './entity-core-protocol';
import {SvNavigatorComponent} from '../../views/structure/sv-navigator/sv-navigator.component';
import {DetailEntry} from '../detail-entry';
import {EntityViewSpecType} from './entity-view-spec-type';
import {ListEntry} from '../list-entry';
import {EntityViewSpecProtocol} from './entity-view-spec-protocol';
import {EntityViewSpec} from './entity-view-spec';
import {PostSpec} from "./post-spec";


export enum ListSpecType {
  selection,
  checklist,
  simple,
  column,
  chipList,
  thumbList,
  editableList,
  cardList,
}


export class ListSpec extends EntityViewSpec implements EntityViewSpecProtocol {

  type = EntityViewSpecType.list;
  compact = false;
  chipListAdd = false;
  fromListFetched = false;

  get subType() {
    return this._subType ? this._subType : this.entityCore.listSpecType
  };
  set subType(value: ListSpecType) {
    this._subType = value;
  }
  _subType: ListSpecType

  listBody = new ListBody();
  avList: SvListComponent;

  get selectionList() {
    return this.entityCore.newIsSelectionFromList
  };

  get index() {
    return this.navigator.entityViewSpecs.findIndex(obj => obj === this);
  }

  constructor(
    private entityCoreInput: EntityCoreProtocol,
    private parentEntityViewSpecInput: EntityViewSpecProtocol = null,
    public parentPanelEntityViewSpec: EntityViewSpecProtocol,
    public navigator: SvNavigatorComponent,
    public showClose: boolean = false,
    public detailEntry: DetailEntry = null,
  ) {
    super();
    this._entityCore = entityCoreInput
    this.parentEntityViewSpec = parentEntityViewSpecInput
    this.childrenEntityViewSpecs.push(parentEntityViewSpecInput);
  }

  addEntryToList(listEntry: ListEntry, identifier = null) {
    console.log('INFO - CORE-SPEC: Adding list entry to entityCore.entities');
    console.log(listEntry);
    console.log(this.parentEntityViewSpec);
    this.entityCore.entities.push(listEntry.entity);

    if (this.parentEntityViewSpec && this.parentEntityViewSpec.listEntry.entityCore.onlyShowOne) {
      this.listBody.reset();
    }

    this.listBody.addEntry(listEntry);

    // Checking if the parent detail has the list in the response and updating accordingly
    if (this.parentEntityViewSpec && this.parentEntityViewSpec.type === EntityViewSpecType.detail) {
      const detailName = this.detailEntry.identifier;
      console.log(detailName);
      console.log(this.entityCore);
      console.log(this.entityCore.entities.length);
      console.log(this.parentEntityViewSpec.listEntry.entity[detailName]);
      if (
        (this.parentEntityViewSpec.listEntry.entity[detailName] &&
          this.entityCore.entities.length > this.parentEntityViewSpec.listEntry.entity[detailName].length)
        || this.entityCore.onlyShowOne
      ) {
        console.log('INFO - CORE-SPEC: Adding list entry to parent core spec list entry');
        if (this.entityCore.onlyShowOne && this.parentEntityViewSpec.listEntry.entity[detailName]) {
          this.parentEntityViewSpec.listEntry.entity[detailName].push(listEntry.entity);
          console.log(this.parentEntityViewSpec.listEntry.entity[detailName]);
        }


        // note parent name in panel is kinda a hack, it's looking for parentPanel.entity.[name].[detailName]
        if (
          this.parentEntityViewSpec.listEntry.entity[this.detailEntry.parentNameInPanel]
          && this.parentEntityViewSpec.listEntry.entity[this.detailEntry.parentNameInPanel][detailName]
        ) {
          this.parentEntityViewSpec.listEntry.entity[this.detailEntry.parentNameInPanel][detailName].push(listEntry.entity);
        }

        if (this.parentEntityViewSpec.listEntry.entity[detailName]) {
          this.parentEntityViewSpec.listEntry.entity[detailName].push(listEntry.entity);
        }

      }
    }
    console.log('Add to list complete this view spec is')
    console.log(this)
  }

  removeEntryFromList(listEntry: ListEntry, identifier = null) {
    console.log('removing entry from list');
    console.log(listEntry);
    console.log(this.entityCore);
    console.log(this.parentEntityViewSpec);
    this.listBody.splice(listEntry);
    const index = this.entityCore.entities.findIndex(obj => obj.uid === listEntry.entity.uid);
    if (index !== -1) {
      this.entityCore.entities.splice(index, 1);
    }

    // Checking if the parent detail has the list in the response and updating accordingly
    if (this.parentEntityViewSpec && this.parentEntityViewSpec.type === EntityViewSpecType.detail) {
      const detailName = this.detailEntry.identifier;
      console.log(detailName);
      if (
        this.parentEntityViewSpec.listEntry.entity[detailName]
        && this.entityCore.entities.length < this.parentEntityViewSpec.listEntry.entity[detailName].length
      ) {
        this.parentEntityViewSpec.listEntry.entity[detailName].splice(listEntry.entity);
      }
    }
  }

  updateEntryInList(existingListEntry: ListEntry, updatedListEntry: ListEntry) {
    // console.log('updating entity in list');
    this.listBody.splice(existingListEntry, updatedListEntry);
    // this.entityCore.entities.splice(existingListEntry.entity, updatedListEntry.entity)
    // console.log('about to splice entities');
    const index = this.entityCore.entities.findIndex(obj => obj.uid === existingListEntry.entity.uid);
    // console.log(index);
    if (index !== -1) {
      this.entityCore.entities.splice(index, 1, updatedListEntry.entity);
      // this.entityCore.entities.splice(index, 1);
      // this.entityCore.entities.splice(0, 0, updatedListEntry.entity);
    }

    if (this.parentEntityViewSpec && this.parentEntityViewSpec.type === EntityViewSpecType.detail) {
      if (this.parentEntityViewSpec.listEntry.entity[this.detailEntry.identifier]) {
        this.parentEntityViewSpec.listEntry.entity[this.detailEntry.identifier].splice(existingListEntry.entity, updatedListEntry.entity);
      }
    }
    // console.log(this);
  }

  makeEmbeddedPostSpec() {
    const spec = new PostSpec(this.entityCore, this, this.navigator);
    spec.showHeader = false;
    spec.compact = true;
    spec.embedded = true;
    spec.horizontalForm = true;
    spec.narrow = this.entityCore.quickAddNarrow;
    return spec;
  }

}
