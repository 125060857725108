import { Component, OnInit } from '@angular/core';
import {AbstractAttributeView} from "../../../shared/abstract-attribute-view";

@Component({
  selector: 'app-sv-pushed-entity-attributes-attribute-view',
  templateUrl: './sv-pushed-entity-attributes-attribute-view.component.html',
  styleUrls: ['./sv-pushed-entity-attributes-attribute-view.component.scss']
})
export class SvPushedEntityAttributesAttributeViewComponent extends AbstractAttributeView {

  remove() {
    this.parentView.entityViewSpec.entityCore.putHelper.removeDetail(this.detail)
  }

}
