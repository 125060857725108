import {Component, Input, OnInit} from '@angular/core';
import {ActiveUserService} from '../../../shared/active-user/active-user.service';
import {UserLoaded} from "../../../protocols/int-user-loaded";
import {SvContainerComponent} from "../sv-container/sv-container.component";
import {SPECTER_SPEC} from "../../../../app/specter-spec";

@Component({
  selector: 'app-sv-nav-bar',
  templateUrl: './sv-nav-bar.component.html',
  styleUrls: ['./sv-nav-bar.component.scss']
})
export class SvNavBarComponent implements UserLoaded {

  id = 'navBar';

  @Input()
  parent: SvContainerComponent

  @Input()
  appName = ''

  hasNotifications = false
  helpPath: string;
  notificationRoute: string;

  get showAppName() {
    if (!this.activeUserService.isHandset) {
      return true
    } else {
      return !this.activeUserService.userLoaded
    }
  }

  constructor(
    public activeUserService: ActiveUserService
  ) {
    this.activeUserService.registerUserLoadedView(this)

    if (SPECTER_SPEC.help) {
      this.helpPath = SPECTER_SPEC.helpPath
    }
    if (SPECTER_SPEC.notifications) {
      this.helpPath = SPECTER_SPEC.notificationPath
    }
  }

  userLoaded() {

  }

  userUnloaded() {

  }
}
