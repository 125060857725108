export enum ENViewType {
  primary = 'primary',
  secondary = 'secondary',
}


export enum ENProgress {
  pending = 'pending',
  active = 'active',
  completed = 'completed',
  archived = 'archived',
}


export enum ENActive {
  active = 'active',
  archived = 'archived',
}


export enum ENOrderStatus {
  needsDispatchAssignment = 'needsDispatchAssignment',
  pendingDispatch = 'pendingDispatch',
  dispatched = 'dispatched',
  archived = 'archived',
}


export enum ENDispatchStatus {
  needsDispatchAssignment = 'needsDispatchAssignment',
  pendingDispatch = 'pendingDispatch',
  dispatched = 'dispatched',
}


export enum ENMediaStatus {
  pending = 'pending',
  uploaded = 'uploaded',
}


export enum ENMediaTypePrimary {
  img = 'img',
  video = 'video',
  pdf = 'pdf',
}


export enum ENMediaTypeSecondary {
  jpeg = 'jpeg',
  png = 'png',
}


export enum ENInviteStatus {
  sent = 'sent',
  accepted = 'accepted',
  denied = 'denied',
  ignored = 'ignored',
}


export enum ENSubscriptionStatus {
  active = 'active',
  trialing = 'trialing',
  canceled = 'canceled',
  pastDue = 'pastDue',
  unpaid = 'unpaid',
}


export enum ENInterval {
  month = 'month',
  year = 'year',
  eon = 'eon',
}


export enum ENPaymentIntentStatus {
  complete = 'complete',
}


export enum ENRoleGrantStatus {
  active = 'active',
  inActive = 'inActive',
  deleted = 'deleted',
}


export enum ENTokenStatus {
  available = 'available',
  deleted = 'deleted',
}


export enum ENSesMessageType {
  bounce = 'bounce',
  complaint = 'complaint',
  delivery = 'delivery',
}


