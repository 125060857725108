

export class SOPostHello {

  constructor(
    public temloca: string,
    public loginStyle: number,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostHello(
      'temloca' in params ? params.temloca : null,
      'loginStyle' in params ? params.loginStyle : null,
    );
  }
}
