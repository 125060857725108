import {AbstractAdapter} from './abstract-adapter';
import {AdapterType} from './adapter-type';
import {FormField, FormFieldType, SelectionOption} from "../form-field";
import {DisplayVectorStringFactory} from "../../config/display-vector";
import {displayOptions} from "../../../app/display-options";
import {DetailEntry} from "../detail-entry";


export class BasePut extends AbstractAdapter {

  adapterType = AdapterType.put;
  private removing = false;

  setParentEntityViewSpec() {
    super.setParentEntityViewSpec();
    if (
      this.delegate.entityViewSpec.parentEntityViewSpec
      && this.adapterType.valueOf() === AdapterType.put.valueOf()
    ) {
      this.parentEntityViewSpec = this.delegate.entityViewSpec.parentEntityViewSpec
    }
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    const factory = new DisplayVectorStringFactory(
      displayOptions,
      entity,
      this.delegate.entityViewSpec && this.delegate.entityViewSpec.listEntry ? this.delegate.entityViewSpec.listEntry.entityCore.type : null
    )
    return new SelectionOption(factory.combinedString, entity.uid)
  }

  submitForm() {
    if (!this.request) {
      this.prepareRequest()
    }
    this.put(this.request);
  }

  put(request, section: number = 0) {
    this.requestInProgress = true;
    this.entityCore.entityService.put(
      request
    ).subscribe(resp => {
      console.log(resp);
      this.finishRequest(resp, section);
    });
  }

  private finishRequest(resp, section: number) {
    console.log('finishing put requests')
    this.requestInProgress = false;
    if (resp) {
      console.log('put complete')
      this.postRequestSuccessfullyCompleteProcessing(resp, section)
    }
  }

  patchFormOverride() {

  }

  removeDetail(detail: DetailEntry) {
    this.removing = true;
    this.prepareRequest();
    this.request[detail.identifier] = new detail.entityCore.soGetClass();
    this.request[detail.identifier].predicate = 'Remove';
    this.submitForm();
  };

  postRequestSuccessfullyCompleteProcessing(resp, section) {
    this.resetRequest();
    this.clearErrorMessage();
    this.updateParent(resp)
    this.entityCore.putCompletePreListEntryReturn(resp, this.delegate.entityViewSpec.parentEntityViewSpec);
    this.delegate.submitComplete(
      this.entityCore.makeListEntry(
        this.delegate.entityViewSpec.parentEntityViewSpec,
        this.delegate.entityViewSpec.entityCore,
        resp,
        section
      ),
      false
    );
  }
}
