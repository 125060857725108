export class MMQueryOptions {
  constructor(
    public datetimeStart: Date = null,
    public datetimeEnd: Date = null,
    public limit: number = 50,
    public offset: number = 0,
    public pagination: string = null,
    public descending: boolean = false,
    public sortKey: string = null,
  ) {
  }
}
