import {SORespLocation} from '../../core/location/so-resp-location';
import {LocationCore} from './location.core';
import {ListEntry} from '../../../engine/shared/list-entry';
import {EntityAttributesForDisplay} from '../../../engine/shared/entity-attributes-for-display';
import {DetailEntry, DetailFactory, DetailFactoryProtocol, EditableType} from '../../../engine/shared/detail-entry';
import {ActiveUserService} from '../../../engine/shared/active-user/active-user.service';
import {BaseDetailType} from "../../../engine/shared/base-detail";
import {EntityViewSpecProtocol} from "../../../engine/shared/entity-view/entity-view-spec-protocol";
import {getHeading} from "../../../engine/shared/display-option-utilities";
import {putLocationFormFieldOptions} from './put-location-form-fields';
import {SOGetLocation} from '../../core/location/so-get-location';
import {SOGetTemloca} from '../../core/temloca/so-get-temloca';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {DisplayVectorStringFactory} from "../../../engine/config/display-vector";
import {displayOptions} from "../../display-options";
import {ListSpecType} from "../../../engine/shared/entity-view/list-spec";
import {SPECTER_SPEC} from "../../specter-spec";
import {getCore} from "../../../engine/shared/entity-view/get-core";

export class LocationDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespLocation = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(displayOptions);
    let heading;

    const attributes = []

    const nameDetail = new DetailEntry(
        'Name',
        entity.name,
        'name',
        BaseDetailType.text,
        null,
        EditableType.always,
        putLocationFormFieldOptions.name
    )
    attributes.push(nameDetail)

    const quantityDetail = new DetailEntry(
        'Quantity',
        entity.quantity,
        'quantity',
        BaseDetailType.text,
        null,
        EditableType.never,
    )
    attributes.push(quantityDetail)

    const descriptionDetail = new DetailEntry(
        'Description',
        entity.description,
        'description',
        BaseDetailType.text,
        null,
        EditableType.always,
        putLocationFormFieldOptions.description
    )
    attributes.push(descriptionDetail)

    const dateCreatedDetail = new DetailEntry(
        'Date created',
        activeUserService.time.niceDate(entity.dateCreated),
        'dateCreated',
        BaseDetailType.text,
        null,
        EditableType.never,
    )
    attributes.push(dateCreatedDetail)

    const dateUpdatedDetail = new DetailEntry(
        'Date updated',
        activeUserService.time.niceDate(entity.dateUpdated),
        'dateUpdated',
        BaseDetailType.text,
        null,
        EditableType.never,
    )
    attributes.push(dateUpdatedDetail)


    const itemsCore = getCore(SPECTER_SPEC, activeUserService, listEntry.entityCore.httpClient, 'TemlocaCore');
    itemsCore.soGet = SOGetTemloca.construct({
      location: new SOGetLocation(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, itemsCore)
    itemsCore.title = heading ? heading : itemsCore.title
    itemsCore.listTitle = 'Items'
    itemsCore.listSpecType = ListSpecType.chipList

    itemsCore.configureForDisplay(entityViewSpec)

    const itemsDetail = new DetailEntry(
      heading ? heading : itemsCore.listTitle,
      null,
      'items',
      BaseDetailType.embeddedList,
      itemsCore,
      EditableType.never
    )
    attributes.push(itemsDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}