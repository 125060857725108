export enum AttributeViewType {
  checkbox,
  chips,
  date,
  datetime,
  embeddedEntity,
  embeddedList,
  file,
  link,
  markdown,
  pushedEntity,
  pushedEntityList,
  selection,
  signature,
  text,
  textarea,
  toggle,
  toggleGroup,
}
