import { Type } from 'class-transformer';
import { SORespDistributor } from '../distributor/so-resp-distributor';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ENOrderStatus} from '../../enums';
import {ObjectiveProtocol} from '../../../engine/protocols/objective-protocol';
import {EntityType} from "../../entity-types";


export class SORespOrder implements ObjectiveProtocol {

  entityType: EntityType = EntityType.order;
  uid: string;
  ref: string;
  name: string;
  dispatchStatus: ENOrderStatus = ENOrderStatus.needsDispatchAssignment;
  @Type(() => Date)
  dateCreated: Date;
  salePrice: number = 0.0;
  cost: number = 0.0;
  net: number = 0.0;
  @Type(() => SORespDistributor)
  distributor: SORespDistributor;
  notes: string = null;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;
}