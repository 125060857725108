import { SOGetLine } from '../line/so-get-line';
import { SOGetTemloca } from '../temloca/so-get-temloca';
import { SOGetPickup } from '../pickup/so-get-pickup';
import { SOGetOrganization } from '../organization/so-get-organization';


export class SOPostDispatch {

  constructor(
    public quantity: number,
    public temloca: SOGetTemloca,
    public organization: SOGetOrganization,
    public isAllocation: boolean = false,
    public line: SOGetLine = null,
    public notes: string = null,
    public pickup: SOGetPickup = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostDispatch(
      'isAllocation' in params ? params.isAllocation : null,
      'quantity' in params ? params.quantity : null,
      'line' in params ? params.line : null,
      'temloca' in params ? params.temloca : null,
      'notes' in params ? params.notes : null,
      'pickup' in params ? params.pickup : null,
      'organization' in params ? params.organization : null,
    );
  }
}
