import {SORespDistributor} from '../../core/distributor/so-resp-distributor';
import {DistributorCore} from './distributor.core';
import {ListEntry} from '../../../engine/shared/list-entry';
import {EntityAttributesForDisplay} from '../../../engine/shared/entity-attributes-for-display';
import {DetailEntry, DetailFactory, DetailFactoryProtocol, EditableType} from '../../../engine/shared/detail-entry';
import {ActiveUserService} from '../../../engine/shared/active-user/active-user.service';
import {BaseDetailType} from "../../../engine/shared/base-detail";
import {EntityViewSpecProtocol} from "../../../engine/shared/entity-view/entity-view-spec-protocol";
import {getHeading} from "../../../engine/shared/display-option-utilities";
import {putDistributorFormFieldOptions} from './put-distributor-form-fields';
import {SOGetDistributor} from '../../core/distributor/so-get-distributor';
import {SOGetOrder} from '../../core/order/so-get-order';
import {SOGetTemdisa} from '../../core/temdisa/so-get-temdisa';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {DisplayVectorStringFactory} from "../../../engine/config/display-vector";
import {displayOptions} from "../../display-options";
import {ListSpecType} from "../../../engine/shared/entity-view/list-spec";
import {SPECTER_SPEC} from "../../specter-spec";
import {getCore} from "../../../engine/shared/entity-view/get-core";

export class DistributorDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespDistributor = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(displayOptions);
    let heading;

    const attributes = []

    const nameDetail = new DetailEntry(
        'Name',
        entity.name,
        'name',
        BaseDetailType.text,
        null,
        EditableType.always,
        putDistributorFormFieldOptions.name
    )
    attributes.push(nameDetail)


    const ordersCore = getCore(SPECTER_SPEC, activeUserService, listEntry.entityCore.httpClient, 'OrderCore');
    ordersCore.soGet = SOGetOrder.construct({
      distributor: new SOGetDistributor(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, ordersCore)
    ordersCore.title = heading ? heading : ordersCore.title
    ordersCore.listTitle = 'Orders'

    ordersCore.configureForDisplay(entityViewSpec)

    const ordersDetail = new DetailEntry(
      heading ? heading : ordersCore.listTitle,
      null,
      'orders',
      BaseDetailType.pushedList,
      ordersCore,
      EditableType.never
    )
    attributes.push(ordersDetail);

    const itemsCore = getCore(SPECTER_SPEC, activeUserService, listEntry.entityCore.httpClient, 'TemdisaCore');
    itemsCore.soGet = SOGetTemdisa.construct({
      distributor: new SOGetDistributor(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, itemsCore)
    itemsCore.title = heading ? heading : itemsCore.title
    itemsCore.listTitle = 'Items'
    itemsCore.listSpecType = ListSpecType.chipList

    itemsCore.configureForDisplay(entityViewSpec)

    const itemsDetail = new DetailEntry(
      heading ? heading : itemsCore.listTitle,
      null,
      'items',
      BaseDetailType.pushedList,
      itemsCore,
      EditableType.never
    )
    attributes.push(itemsDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}