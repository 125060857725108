import {getListEntryHeading, getListEntrySublines} from "./display-option-utilities";
import {EntityCoreProtocol} from "./entity-view/entity-core-protocol";
import {ObjectiveProtocol} from "../protocols/objective-protocol";


export class Line {

  constructor(
    public text: string,
    public color: string = 'black'
  ) {
  }
}

export interface ListEntryFactoryProtocol {

  makeListEntry(parentEntityViewSpec, entityCore: EntityCoreProtocol, entity, section: number): ListEntry

}


export class ListEntryFactory implements ListEntryFactoryProtocol {

  makeListEntry(parentEntityViewSpec, entityCore: EntityCoreProtocol, entity, section: number): ListEntry {

    return new ListEntry(
      entityCore,
      entity,
      [getListEntryHeading(parentEntityViewSpec, entityCore, entity)],
      getListEntrySublines(parentEntityViewSpec, entityCore, entity),
      ``,
      section
    );
  }
}



export class ListEntry {

  onlyShowOne = false;

  icon: string;
  borderColor: string;
  snackBarText;
  showAfterPost = false;

  trailingNumber: number;
  trailingColor: string;

  boldLineHeading: string;

  lines: Line[] = [];
  disabled = false;

  checked = false;
  mediaLink: string;
  link: string = '';
  linkText: string = '';
  linkDisabled = false;
  tooltip = '';
  get fullHeading(): string {
    let heading = ''
    for (const h of this.lineHeading) {
      heading = `${heading}  ${h}`;
    }
    return heading
  };

  constructor(
    public entityCore: EntityCoreProtocol,
    public entity,
    public lineHeading: string[],
    public subLines: string[] = [],
    public trailingText: string = '',
    public section: number = 0,
  ) {
    for (const line of subLines) {
      this.lines.push(new Line(line));
    }
  }

  get hasSubLines() {
    return this.subLines.length > 0;
  }

  get showLineThree() {
    return this.subLines.length > 0;
  }

}
