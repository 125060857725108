import {Component, ViewChild} from '@angular/core';
import {AbstractAttributeView} from "../../../shared/abstract-attribute-view";
import {MenuItem, MenuItemType} from "../../../protocols/header-protocol";
import {MatButtonToggleGroup} from "@angular/material/button-toggle";
import {EntityViewSpecType} from "../../../shared/entity-view/entity-view-spec-type";

@Component({
  selector: 'app-sv-toggle-group-attribute-view',
  templateUrl: './sv-toggle-group-attribute-view.component.html',
  styleUrls: ['./sv-toggle-group-attribute-view.component.scss']
})
export class SvToggleGroupAttributeViewComponent extends AbstractAttributeView {

  toggleButtons: MenuItem[] = []
  multiple = false;

  @ViewChild('toggleGroup')
  toggleGroup: MatButtonToggleGroup

  attributeSpecSet() {
    this.setToggleButtons()
  }

  setToggleButtons(): MenuItem[] {

    this.form.enable()
    this.toggleButtons = []
    if (this.detail.editableFormField) {
      const enumeration: { [key: string]: any } = this.detail.editableFormField.enumeration
      for (let num = 0; num < Object.values(enumeration).length; num++) {
        const value = Object.values(enumeration)[num]
        const checked = this.detail.value == value ? true : false;
        this.toggleButtons.push(
          new MenuItem(value, value, this.parentView.entityViewSpec.entityCore, MenuItemType.button, checked)
        )
      }
    }
    return this.toggleButtons
  }

  get checked() {
    return !!this.detail.value
  }

  clickMenuItem(button: MenuItem) {

    for (const btn of this.toggleButtons) {
      btn.value = false
    }
    button.value = true;

    this.form.patchValue({
      [this.detail.identifier]: button.identifier
    })
    this.onSelectionChange()
    if (this.parentView.entityViewSpec.type !== EntityViewSpecType.post) {
      this.parentView.clickSubmitFormButton();
    }
  }
}
