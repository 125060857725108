import {ListSpecType} from "../../../../engine/shared/entity-view/list-spec";
import {TemfieldCore} from "../../../entities/bv-temfield/temfield.core";
import {EntityAttributesForDisplay} from "../../../../engine/shared/entity-attributes-for-display";
import {EntityViewSpecProtocol} from "../../../../engine/shared/entity-view/entity-view-spec-protocol";
import {ListEntry} from "../../../../engine/shared/list-entry";
import {AttributesLayout} from "../../../../engine/shared/attributes-layout";
import {EntityType} from "../../../entity-types";

export class TemfieldProcessor extends TemfieldCore {


  attributesLayout = AttributesLayout.horizontal;

  configureForDisplay(entityViewSpec: EntityViewSpecProtocol) {
    super.configureForDisplay(entityViewSpec);
    if (entityViewSpec.entityCore.type === EntityType.item) {
      this.listSpecType = ListSpecType.editableList;
    } else {
      this.showAdd = false;
      this.showQuickAdd = false;
      this.listHeaderSpec.showPostButton = false;
      this.listSpecType = ListSpecType.selection;
    }
  }

  makeEntityAttributesForDisplay(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry): EntityAttributesForDisplay {
    const resp = super.makeEntityAttributesForDisplay(entityViewSpec, listEntry);

    if (
      entityViewSpec.parentEntityViewSpec &&
      entityViewSpec.parentEntityViewSpec.parentEntityViewSpec &&
      entityViewSpec.parentEntityViewSpec.parentEntityViewSpec.entityCore.type === EntityType.item
    ) {
      const fieldDex = resp.attributes.findIndex(obj => obj.identifier === 'customField')
      const field = resp.attributes[fieldDex]
      resp.attributes.splice(fieldDex, 1)
      resp.attributes.splice(0, 0, field)
    }

    this.hideAttributeLabels = true


    return resp
  }

}
