<mat-selection-list
  class="selection-list-body"
  *ngIf="!parent.entityViewSpec.listBody.isEmpty"
  [multiple]="false">

  <mat-divider></mat-divider>
  <div *ngIf="parent.entityViewSpec.listBody.titleHeading" fxLayout="row" fxLayoutAlign="space-between center"
       class="list-header">
    <h3 matSubheader>{{parent.entityViewSpec.listBody.titleHeading}}</h3>
    <h3 *ngIf="parent.entityViewSpec.listBody.trailingTitleHeading"
        matSubheader>{{parent.entityViewSpec.listBody.trailingTitleHeading}}</h3>
  </div>
  <div *ngFor="let section of parent.entityViewSpec.listBody.sections" class="section-header">
    <mat-divider></mat-divider>
    <div *ngIf="section.heading" fxLayout="row" fxLayoutAlign="space-between start">
      <div fxLayout="column">
        <h2>{{section.heading}}</h2>
        <h3 *ngFor="let line of section.subLines">{{line}}</h3>
      </div>
      <div fxLayout="column" fxLayoutAlign="start">
        <h2 *ngIf="section.trailingHeading">{{section.trailingHeading}}</h2>
      </div>
    </div>
    <div *ngFor="let listEntry of section.listEntries" [ngClass]="parent.border(listEntry)">
      <mat-list-option
        (click)="parent.selectListEntry(listEntry)"
        [disabled]="listEntry.disabled">
        <mat-icon mat-list-icon *ngIf="parent.entityViewSpec.listBody.listIcon">{{listEntry.icon}}</mat-icon>
        <div fxLayout="column">
          <div mat-line fxLayout="row" fxLayoutAlign="space-between start">
            <div fxLayout="row">
              <div>{{listEntry.lineHeading}}</div>
              <div style="font-weight: bold">{{listEntry.boldLineHeading}}</div>
            </div>
            <div>{{listEntry.trailingText}}</div>
          </div>
          <div mat-line *ngFor="let text of listEntry.subLines">{{text}}</div>
        </div>
      </mat-list-option>
      <mat-divider *ngIf="section.showItemDivider"></mat-divider>
    </div>
  </div>
  <button *ngIf="parent.hasMore" mat-stroked-button (click)="parent.fetchMore()"
          style="min-height: 50px; width: 100%">Load more
  </button>
</mat-selection-list>
