import {SOPutDispatch} from '../../core/dispatch/so-put-dispatch';
import {DispatchCore} from './dispatch.core';
import {SOGetDispatch} from '../../core/dispatch/so-get-dispatch';
import {FormField, SelectionOption} from '../../../engine/shared/form-field';
import {putDispatchFormFieldOptions} from './put-dispatch-form-fields';
import {AdapterProtocol} from '../../../engine/shared/entity-construction/adapter-protocol';
import {BasePut} from '../../../engine/shared/entity-construction/base-put';
import {PickupCore} from '../bv-pickup/pickup.core';
import {SOGetPickup} from '../../core/pickup/so-get-pickup';

export class RAPutDispatch extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putDispatchFormFieldOptions);

  RequestClass = SOPutDispatch

  constructor(
    public entityCore: DispatchCore
  ) {
    super();
    if (putDispatchFormFieldOptions.pickup) {
      putDispatchFormFieldOptions.pickup.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('complete'),
      this.getFormValueFromIdentifier('notes'),
      this.getFormValueFromIdentifier(
        'pickup',
        SOGetPickup,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}