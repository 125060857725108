import {ActiveUserService} from '../../../engine/shared/active-user/active-user.service';
import {HttpClient} from '@angular/common/http';
import {EOViewService} from '../../core/view/eo-view';
import {ViewListEntryFactory} from './view-list-entry-factory';
import {ViewDetailFactory} from './view-detail-factory';
import {SOGetView} from '../../core/view/so-get-view';
import {RAPutView} from './ra-put-view';
import {RAPostView} from './ra-post-view';
import {RADeleteView} from './ra-delete-view';
import {AbstractEntityCore} from "../../../engine/shared/entity-view/abstract-entity-core";
import {EntityCoreProtocol} from "../../../engine/shared/entity-view/entity-core-protocol";
import {EntityType} from '../../entity-types';
import {SPECTER_SPEC} from "../../specter-spec";

export class ViewCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = EntityType.view;
  title = 'View';
  listEntryFactory = new ViewListEntryFactory();
  detailFactory = new ViewDetailFactory();

  entityService: EOViewService;
  soGetClass = SOGetView;
  soGet: SOGetView;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOViewService
    );
    this.adapters = [
      new RAPostView(this),
      new RAPutView(this),
      new RADeleteView(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetView();
    this.soGet.queryOptions.limit = this.limit;
  }


}