import { Type } from 'class-transformer';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ObjectiveProtocol} from '../../../engine/protocols/objective-protocol';
import {EntityType} from "../../entity-types";


export class SORespLocation implements ObjectiveProtocol {

  entityType: EntityType = EntityType.location;
  uid: string;
  name: string;
  quantity: number = 0;
  description: string = null;
  @Type(() => Date)
  dateCreated: Date;
  @Type(() => Date)
  dateUpdated: Date;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;
}