import {ActiveUserService} from '../../../engine/shared/active-user/active-user.service';
import {HttpClient} from '@angular/common/http';
import {EOMediaService} from '../../core/media/eo-media';
import {MediaListEntryFactory} from './media-list-entry-factory';
import {MediaDetailFactory} from './media-detail-factory';
import {SOGetMedia} from '../../core/media/so-get-media';
import {RAPutMedia} from './ra-put-media';
import {RAPostMedia} from './ra-post-media';
import {RADeleteMedia} from './ra-delete-media';
import {AbstractEntityCore} from "../../../engine/shared/entity-view/abstract-entity-core";
import {EntityCoreProtocol} from "../../../engine/shared/entity-view/entity-core-protocol";
import {EntityType} from '../../entity-types';
import {SPECTER_SPEC} from "../../specter-spec";
import {SOGetOrganization} from '../../core/organization/so-get-organization'

export class MediaCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = EntityType.media;
  title = 'Image';
  listEntryFactory = new MediaListEntryFactory();
  detailFactory = new MediaDetailFactory();

  entityService: EOMediaService;
  soGetClass = SOGetMedia;
  soGet: SOGetMedia;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOMediaService
    );
    this.adapters = [
      new RAPostMedia(this),
      new RAPutMedia(this),
      new RADeleteMedia(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetMedia();
    this.soGet.organization = new SOGetOrganization(
      this.activeUserService.getScope('organization').uid
    );
    this.soGet.queryOptions.limit = this.limit;
    this.soGet.queryOptions.sortKey = 'dateCreated';
    this.soGet.queryOptions.descending = true;
  }


}