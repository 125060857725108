import {AbstractControl, FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {EntityType} from "../../app/entity-types";
import {EntityCoreProtocol} from "./entity-view/entity-core-protocol";
import {ListEntry} from "./list-entry";
import {ListSpec} from "./entity-view/list-spec";
import {getHeading} from "./display-option-utilities";
import {EntityViewSpecProtocol} from "./entity-view/entity-view-spec-protocol";
import {BaseDetailType} from "./base-detail";
import {SPECTER_SPEC} from "../../app/specter-spec";


export class SelectionOption {
  constructor(
    public displayName: string,
    public value: any,
  ) {
  }
}

export enum FormFieldType {
  text,
  bigText,
  selection,
  date,
  boolean,
  checkitem,
  info,
  multiLineInfo,
  fetch,
  datetime,
  markdown,
  enumeration,
  password,
  pushedSelectionList,
  autoCompleteList,
  file
}

const fieldToDetail = {
  text: BaseDetailType.text,
  bigText: BaseDetailType.bigText,
  selection: BaseDetailType.embeddedList,
  date: BaseDetailType.datetime,
  boolean: BaseDetailType.toggle,
  checkitem: BaseDetailType.checkitem,
  datetime: BaseDetailType.datetime,
  dateMonth: BaseDetailType.text,
  toggleGroup: BaseDetailType.toggleGroup,
};

const detailToField = {
  text: FormFieldType.text,
  bigText: FormFieldType.bigText,
  embeddedList: FormFieldType.selection,
  pushedList: FormFieldType.selection,
  checkitem: FormFieldType.checkitem,
};

export function getDetailTypeFromFormField(formFieldType: FormFieldType): BaseDetailType {
  if (Object.keys(fieldToDetail).includes(FormFieldType[formFieldType])) {
    return fieldToDetail[FormFieldType[formFieldType]];
  } else {
    return null;
  }
}

export function getFormFieldFromDetailType(detailType: BaseDetailType): FormFieldType {
  if (Object.keys(detailToField).includes(BaseDetailType[detailType])) {
    return detailToField[BaseDetailType[detailType]];
  } else {
    return null;
  }
}

export function matchValues(matchTo: string): (AbstractControl) => ValidationErrors | null {
  return (control: AbstractControl): ValidationErrors | null => {
    return !!control.parent &&
    !!control.parent.value &&
    control.value === control.parent.controls[matchTo].value
      ? null
      : {isMatching: false};
  };
}

export class FormField {
  public appearance = 'standard';
  public color = 'accent';
  public hintText: string;
  public lines: string[] = [];
  public selectionOptions: SelectionOption[] = []

  makeCore = false;
  entityCore: EntityCoreProtocol;
  formGroup: FormGroup;

  validators = [];

  enumeration

  private _disabled = false;
  linkText: string;
  link: string;

  get disabled(): boolean {
    return this._disabled
  }
  set disabled(value: boolean) {
    this._disabled = value
    if (value) {
      this.formControl.disable()
    } else {
      this.formControl.enable()
    }
  }

  private _editDisabledType: BaseDetailType
  get editDisabledType(): BaseDetailType {
    if (!this._editDisabledType) {
      return getDetailTypeFromFormField(this.type)
    }
    return this._editDisabledType
  }
  set editDisabledType(value: BaseDetailType) {
    this._editDisabledType = value
  }

  private _selectedListEntry: ListEntry
  get selectedListEntry(): ListEntry {
    return this._selectedListEntry
  }

  set selectedListEntry(value: ListEntry) {
    if (value) {
      this._selectedListEntry = value
      this.value = value.entity.uid
      this.displayValue = value.lineHeading[0]
    } else {
      this._selectedListEntry = null
      this.displayValue = null
    }
  }

  get value() {
    return this.formGroup.value[this.identifierString]
  }

  set value(value) {
    this.formGroup.patchValue({
      [this.identifierString]: value
    })
  }

  displayValue: string

  get isEntity() {
    return this.entityType !== null
  }

  private _formControl: FormControl
  get formControl(): FormControl {
    if (!this._formControl) {
      const defaultValue = this.hasDefault ? this.defaultValue : null

      if (this.isRequired) {
        this.validators.push(
          Validators.required
        )
      }

      if (this.type == FormFieldType.boolean) {
        this._formControl = new FormControl(
          {
            value: defaultValue,
            disabled: this.disabled
          },
          this.validators.length > 0 ? this.validators : null
        )
      } else {
        this._formControl = new FormControl(
          defaultValue ? defaultValue : null,
          this.validators.length > 0 ? this.validators : null
        )
      }
    }
    return this._formControl
  }

  constructor(
    public identifierString: string,
    public displayText: string,
    public entityType: EntityType,
    public type: FormFieldType = FormFieldType.text,
    public isRequired: boolean = true,
    public hasDefault: boolean = false,
    public defaultValue = null,
    public dependents: EntityType[] = [],
    public inputType = 'text'
  ) {
    this.displayValue = null
  }

  initCore(activeUserService, httpClient) {
    this.displayValue = null
    if (this.makeCore) {
      this.entityCore = SPECTER_SPEC.makeCore(activeUserService, httpClient, this.entityType)
      if (this.type == FormFieldType.autoCompleteList) {
        this.entityCore.searchFormField = true
        this.entityCore.limit = 4
      }
    }
  }

  resetEnumerationOptions() {
    const enumeration: { [key: string]: any } = this.enumeration
    this.selectionOptions = []
    for (let num = 0; num < Object.values(enumeration).length; num++) {
      const value = Object.values(enumeration)[num]
      this.selectionOptions.push(
        new SelectionOption(value, value)
      )
    }
  }

  resetSelectionOptions() {
    if (this.isRequired) {
      this.selectionOptions = []
    } else {
      this.selectionOptions = [
        new SelectionOption('None', null)
      ]
    }
  }

  getLabel(entityViewSpec: EntityViewSpecProtocol) {
    if (!this.entityCore) {
      return this.displayText
    } else {
      const heading = getHeading(
        entityViewSpec.parentEntityViewSpec ? entityViewSpec.parentEntityViewSpec.entityCore : null,
        this.entityCore
      )
      return heading ? heading : this.displayText
    }
  }

  reset() {
    this._formControl = null;
    this.selectedListEntry = null;
  }
}
