<form [formGroup]="form">

  <h3 *ngIf="!detail.editEnabled"
      [ngClass]="{'warn': detail.warn}">
    {{detail.name}}: {{detail.value}}
  </h3>

  <mat-form-field
    *ngIf="detail.editEnabled"
    appearance="{{detail.editableFormField.appearance}}"
    color="{{detail.editableFormField.color}}">
    <mat-label>{{detail.editableFormField.getLabel(parentView.entityViewSpec)}}</mat-label>
    <mat-select matInput [id]="detail.editableFormField.identifierString"
                [formControlName]="detail.editableFormField.identifierString"
                (selectionChange)="onSelectionChange()">
      <mat-option *ngFor="let option of detail.editableFormField.selectionOptions"
                  value="{{option.value}}">{{option.displayName}}</mat-option>
    </mat-select>
  </mat-form-field>

</form>
