import { Type } from 'class-transformer';
import { SORespPrice } from '../price/so-resp-price';
import { SORespInvoice } from '../invoice/so-resp-invoice';
import {ObjectiveProtocol} from '../../../engine/protocols/objective-protocol';
import {EntityType} from "../../entity-types";


export class SORespInvoiceLine implements ObjectiveProtocol {

  entityType: EntityType = EntityType.invoiceLine;
  uid: string;
  amount: number;
  quantity: number;
  @Type(() => Date)
  dateCreated: Date;
  description: string = '';
  @Type(() => SORespPrice)
  price: SORespPrice;
  @Type(() => SORespInvoice)
  invoice: SORespInvoice;
}