import {SORespOrganization} from '../../core/organization/so-resp-organization';
import {OrganizationCore} from './organization.core';
import {ListEntry} from '../../../engine/shared/list-entry';
import {EntityAttributesForDisplay} from '../../../engine/shared/entity-attributes-for-display';
import {DetailEntry, DetailFactory, DetailFactoryProtocol, EditableType} from '../../../engine/shared/detail-entry';
import {ActiveUserService} from '../../../engine/shared/active-user/active-user.service';
import {BaseDetailType} from "../../../engine/shared/base-detail";
import {EntityViewSpecProtocol} from "../../../engine/shared/entity-view/entity-view-spec-protocol";
import {getHeading} from "../../../engine/shared/display-option-utilities";
import {putOrganizationFormFieldOptions} from './put-organization-form-fields';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {SOGetSubscription} from '../../core/subscription/so-get-subscription';
import {SOGetOrganizationInvite} from '../../core/organization-invite/so-get-organization-invite';
import {SOGetRoleGrant} from '../../core/role-grant/so-get-role-grant';
import {SOGetItemImport} from '../../core/item-import/so-get-item-import';
import {SOGetItem} from '../../core/item/so-get-item';
import {SOGetLocation} from '../../core/location/so-get-location';
import {SOGetTemloca} from '../../core/temloca/so-get-temloca';
import {SOGetOrder} from '../../core/order/so-get-order';
import {SOGetPickup} from '../../core/pickup/so-get-pickup';
import {SOGetCustomField} from '../../core/custom-field/so-get-custom-field';
import {SOGetLine} from '../../core/line/so-get-line';
import {SOGetDispatch} from '../../core/dispatch/so-get-dispatch';
import {SOGetTemfield} from '../../core/temfield/so-get-temfield';
import {SOGetMedia} from '../../core/media/so-get-media';
import {SOGetTag} from '../../core/tag/so-get-tag';
import {SOGetTemaga} from '../../core/temaga/so-get-temaga';
import {SOGetDistributor} from '../../core/distributor/so-get-distributor';
import {SOGetTemdisa} from '../../core/temdisa/so-get-temdisa';
import {SOGetBulkitem} from '../../core/bulkitem/so-get-bulkitem';
import {SOGetItemImportLine} from '../../core/item-import-line/so-get-item-import-line';
import {SOGetIngest} from '../../core/ingest/so-get-ingest';
import {SOGetAttachment} from '../../core/attachment/so-get-attachment';
import {DisplayVectorStringFactory} from "../../../engine/config/display-vector";
import {displayOptions} from "../../display-options";
import {ListSpecType} from "../../../engine/shared/entity-view/list-spec";
import {SPECTER_SPEC} from "../../specter-spec";
import {getCore} from "../../../engine/shared/entity-view/get-core";

export class OrganizationDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespOrganization = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(displayOptions);
    let heading;

    const attributes = []

    const nameDetail = new DetailEntry(
        'Name',
        entity.name,
        'name',
        BaseDetailType.text,
        null,
        EditableType.always,
        putOrganizationFormFieldOptions.name
    )
    attributes.push(nameDetail)

    const identifierDetail = new DetailEntry(
        'Identifier',
        entity.identifier,
        'identifier',
        BaseDetailType.text,
        null,
        EditableType.always,
        putOrganizationFormFieldOptions.identifier
    )
    attributes.push(identifierDetail)

    const dateCreatedDetail = new DetailEntry(
        'Date created',
        activeUserService.time.niceDate(entity.dateCreated),
        'dateCreated',
        BaseDetailType.text,
        null,
        EditableType.never,
    )
    attributes.push(dateCreatedDetail)

    const exRefPaymentsLinkDetail = new DetailEntry(
        'Manage billing and payments',
        entity.exRefPaymentsLink,
        'exRefPaymentsLink',
        BaseDetailType.link,
        null,
        EditableType.never,
    )
    attributes.push(exRefPaymentsLinkDetail)


    const subscriptionsCore = getCore(SPECTER_SPEC, activeUserService, listEntry.entityCore.httpClient, 'SubscriptionCore');
    subscriptionsCore.soGet = SOGetSubscription.construct({
      organization: new SOGetOrganization(entity.uid),
    });
    heading = getHeading(entityViewSpec.entityCore, subscriptionsCore)
    subscriptionsCore.title = heading ? heading : subscriptionsCore.title
    subscriptionsCore.listTitle = 'Subscriptions'

    subscriptionsCore.configureForDisplay(entityViewSpec)

    const subscriptionsDetail = new DetailEntry(
      heading ? heading : subscriptionsCore.listTitle,
      null,
      'subscriptions',
      BaseDetailType.embeddedList,
      subscriptionsCore,
      EditableType.never
    )
    attributes.push(subscriptionsDetail);

    const invitesCore = getCore(SPECTER_SPEC, activeUserService, listEntry.entityCore.httpClient, 'OrganizationInviteCore');
    invitesCore.soGet = SOGetOrganizationInvite.construct({
      organization: new SOGetOrganization(entity.uid),
    });
    heading = getHeading(entityViewSpec.entityCore, invitesCore)
    invitesCore.title = heading ? heading : invitesCore.title
    invitesCore.listTitle = 'Invites'

    invitesCore.configureForDisplay(entityViewSpec)

    const invitesDetail = new DetailEntry(
      heading ? heading : invitesCore.listTitle,
      null,
      'invites',
      BaseDetailType.embeddedList,
      invitesCore,
      EditableType.never
    )
    attributes.push(invitesDetail);

    const roleGrantsCore = getCore(SPECTER_SPEC, activeUserService, listEntry.entityCore.httpClient, 'RoleGrantCore');
    roleGrantsCore.soGet = SOGetRoleGrant.construct({
      organization: new SOGetOrganization(entity.uid),
    });
    heading = getHeading(entityViewSpec.entityCore, roleGrantsCore)
    roleGrantsCore.title = heading ? heading : roleGrantsCore.title
    roleGrantsCore.listTitle = 'Role grants'

    roleGrantsCore.configureForDisplay(entityViewSpec)

    const roleGrantsDetail = new DetailEntry(
      heading ? heading : roleGrantsCore.listTitle,
      null,
      'roleGrants',
      BaseDetailType.embeddedList,
      roleGrantsCore,
      EditableType.never
    )
    attributes.push(roleGrantsDetail);

    const itemImportsCore = getCore(SPECTER_SPEC, activeUserService, listEntry.entityCore.httpClient, 'ItemImportCore');
    itemImportsCore.soGet = SOGetItemImport.construct({
      organization: new SOGetOrganization(entity.uid),
    });
    heading = getHeading(entityViewSpec.entityCore, itemImportsCore)
    itemImportsCore.title = heading ? heading : itemImportsCore.title
    itemImportsCore.listTitle = 'Item imports'

    itemImportsCore.configureForDisplay(entityViewSpec)

    const itemImportsDetail = new DetailEntry(
      heading ? heading : itemImportsCore.listTitle,
      null,
      'itemImports',
      BaseDetailType.embeddedList,
      itemImportsCore,
      EditableType.never
    )
    attributes.push(itemImportsDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}