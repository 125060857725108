import {FormField, FormFieldType, SelectionOption} from '../../../engine/shared/form-field';
import {EntityType} from "../../entity-types";


const quantityField = new FormField(
  'quantity',
  'Quantity',
  null,
  FormFieldType.text,
  true,
)
const itemField = new FormField(
  'item',
  'Item',
  EntityType.item,
  FormFieldType.autoCompleteList,
  true,
)
const orderField = new FormField(
  'order',
  'Order',
  EntityType.order,
  FormFieldType.autoCompleteList,
  true,
)


export const postLineFormFieldOptions: {[key: string]: FormField} = {
  quantity: quantityField,
  item: itemField,
  order: orderField,
};