import {SORespDefaultCustomField} from '../../core/default-custom-field/so-resp-default-custom-field';
import {DefaultCustomFieldCore} from './default-custom-field.core';
import { ListEntry, ListEntryFactory, ListEntryFactoryProtocol} from '../../../engine/shared/list-entry';
import {EntityCoreProtocol} from "../../../engine/shared/entity-view/entity-core-protocol";


export class DefaultCustomFieldListEntryFactory  extends ListEntryFactory implements ListEntryFactoryProtocol {

  makeListEntry(parentCoreViewSpec, entityCore: EntityCoreProtocol, entity: SORespDefaultCustomField, section: number = 0): ListEntry {
    const resp = super.makeListEntry(parentCoreViewSpec, entityCore, entity, section)
    return resp
  }
}