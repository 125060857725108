import { Type } from 'class-transformer';
import {ObjectiveProtocol} from '../../../engine/protocols/objective-protocol';
import {EntityType} from "../../entity-types";


export class SORespDefaultCustomField implements ObjectiveProtocol {

  entityType: EntityType = EntityType.defaultCustomField;
  uid: string;
  name: string;
}