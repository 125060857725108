import {SORespOrganizationInvite} from '../../core/organization-invite/so-resp-organization-invite';
import {OrganizationInviteCore} from './organization-invite.core';
import { ListEntry, ListEntryFactory, ListEntryFactoryProtocol} from '../../../engine/shared/list-entry';
import {EntityCoreProtocol} from "../../../engine/shared/entity-view/entity-core-protocol";


export class OrganizationInviteListEntryFactory  extends ListEntryFactory implements ListEntryFactoryProtocol {

  makeListEntry(parentCoreViewSpec, entityCore: EntityCoreProtocol, entity: SORespOrganizationInvite, section: number = 0): ListEntry {
    const resp = super.makeListEntry(parentCoreViewSpec, entityCore, entity, section)
    return resp
  }
}