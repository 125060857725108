import {ActiveUserService} from '../../../engine/shared/active-user/active-user.service';
import {HttpClient} from '@angular/common/http';
import {EOConstraintService} from '../../core/constraint/eo-constraint';
import {ConstraintListEntryFactory} from './constraint-list-entry-factory';
import {ConstraintDetailFactory} from './constraint-detail-factory';
import {SOGetConstraint} from '../../core/constraint/so-get-constraint';
import {RAPutConstraint} from './ra-put-constraint';
import {RAPostConstraint} from './ra-post-constraint';
import {RADeleteConstraint} from './ra-delete-constraint';
import {AbstractEntityCore} from "../../../engine/shared/entity-view/abstract-entity-core";
import {EntityCoreProtocol} from "../../../engine/shared/entity-view/entity-core-protocol";
import {EntityType} from '../../entity-types';
import {SPECTER_SPEC} from "../../specter-spec";

export class ConstraintCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = EntityType.constraint;
  title = 'Constraint';
  listEntryFactory = new ConstraintListEntryFactory();
  detailFactory = new ConstraintDetailFactory();

  entityService: EOConstraintService;
  soGetClass = SOGetConstraint;
  soGet: SOGetConstraint;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOConstraintService
    );
    this.adapters = [
      new RAPostConstraint(this),
      new RAPutConstraint(this),
      new RADeleteConstraint(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetConstraint();
    this.soGet.queryOptions.limit = this.limit;
  }


}