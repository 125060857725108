import {DispatchCore} from "../../../entities/bv-dispatch/dispatch.core";
import {SPECTER_SPEC} from "../../../specter-spec";
import {SOGetPickup} from "../../../core/pickup/so-get-pickup";
import {SORespPickup} from "../../../core/pickup/so-resp-pickup";

export class DispatchMultiSelect extends DispatchCore {

  parentPickup: SORespPickup;

  checkCheckbox(entity, checked: boolean) {
    console.log('check check')
    const request = this.putHelper.RequestClass.construct({
      'uid': entity.uid,
      'pickup': checked ? new SOGetPickup(this.parentPickup.uid) : new SOGetPickup(null, null, null, null, 'Remove'),
    })
    this.entityService.put(request).subscribe(resp => {
      if (resp) {
        const customProcessor = SPECTER_SPEC.putEntityCompleteProcessors.find(obj => obj.entityType == this.type)
        if (customProcessor) {
          customProcessor.putEntityComplete(resp, this.delegate)
        }
      }
    })
  }
}
