import {ActiveUserService} from '../../../engine/shared/active-user/active-user.service';
import {HttpClient} from '@angular/common/http';
import {EOSesMessageService} from '../../core/ses-message/eo-ses-message';
import {SesMessageListEntryFactory} from './ses-message-list-entry-factory';
import {SesMessageDetailFactory} from './ses-message-detail-factory';
import {SOGetSesMessage} from '../../core/ses-message/so-get-ses-message';
import {RAPutSesMessage} from './ra-put-ses-message';
import {RAPostSesMessage} from './ra-post-ses-message';
import {RADeleteSesMessage} from './ra-delete-ses-message';
import {AbstractEntityCore} from "../../../engine/shared/entity-view/abstract-entity-core";
import {EntityCoreProtocol} from "../../../engine/shared/entity-view/entity-core-protocol";
import {EntityType} from '../../entity-types';
import {SPECTER_SPEC} from "../../specter-spec";

export class SesMessageCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = EntityType.sesMessage;
  title = 'Ses message';
  listEntryFactory = new SesMessageListEntryFactory();
  detailFactory = new SesMessageDetailFactory();

  entityService: EOSesMessageService;
  soGetClass = SOGetSesMessage;
  soGet: SOGetSesMessage;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOSesMessageService
    );
    this.adapters = [
      new RAPostSesMessage(this),
      new RAPutSesMessage(this),
      new RADeleteSesMessage(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetSesMessage();
    this.soGet.queryOptions.limit = this.limit;
  }


}