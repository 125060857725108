import {EntityType} from "../../../entity-types";
import {PutEntityCompleteProtocol} from "../../../../engine/protocols/put-entity-complete-protocol";
import {SORespDispatch} from "../../../core/dispatch/so-resp-dispatch";
import {MessagePromptProtocol} from "../../../../engine/protocols/message-prompt-protocol";


export class PutDispatchComplete implements PutEntityCompleteProtocol {

  entityType = EntityType.dispatch;

  putEntityComplete(entity: SORespDispatch, view: MessagePromptProtocol) {

    console.log('put dispatch complete')

    if (entity.line.item.quantity === 0) {
      view.promptMessage('Item quantity 0! Remember to remove listings from distributors.', 3)
    }
  }
}
