import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {SvListComponent} from "../sv-list/sv-list.component";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {MatAutocomplete, MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {MatChipInputEvent} from "@angular/material/chips";
import {ListEntry} from "../../../shared/list-entry";
import {AutoCompleteSpec} from "../../../shared/entity-view/auto-complete-spec";
import {FormControl} from "@angular/forms";
import {FormViewProtocol} from "../../../protocols/form-view-protocol";
import {EntityViewSpecProtocol} from "../../../shared/entity-view/entity-view-spec-protocol";
import {FormField} from "../../../shared/form-field";
import {SvNavigatorComponent} from "../../structure/sv-navigator/sv-navigator.component";
import {DetailEntry} from "../../../shared/detail-entry";

@Component({
  selector: 'app-sv-auto-complete',
  templateUrl: './sv-auto-complete.component.html',
  styleUrls: ['./sv-auto-complete.component.scss']
})
export class SvAutoCompleteComponent extends SvListComponent implements FormViewProtocol {

  selectedEntity: any;
  formInitialized: boolean;

  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  chipCtrl = new FormControl()
  chips: ListEntry[] = [];

  multiSelect = false
  showCreateNew = true

  heading = '';

  clear = true;
  lastSearchStr = '';
  defaultPlaceHolderText = 'Search';
  placeholderText = this.defaultPlaceHolderText

  isPost = false;

  @ViewChild('chipInput') chipInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  private postingNewItem = false;
  private createNew = false;
  private firstEnter= true;

  get appearance() {
    return this.entityViewSpec.compact ? 'legacy' : 'standard'
  }

  @Input()
  set entityViewSpec(entityViewSpec: AutoCompleteSpec) {
    if (!this._entityViewSpec) {
      this._entityViewSpec = entityViewSpec
      this.pulledList = !entityViewSpec.entityCore.prefetchAutoComplete;
      super.entityViewSpec = entityViewSpec;
      this.heading = entityViewSpec.entityCore.title;
    }
  }

  get entityViewSpec(): AutoCompleteSpec {
    return this._entityViewSpec
  }

  listFetched(entities, entityViewSpec, hasMore) {
    console.log('list fetched in auto complete')
    super.listFetched(entities, entityViewSpec, hasMore)
    // this.showCreateNew = entities.findIndex(obj => obj.name.toLowerCase() === this.chipCtrl.value) == -1;
  }

  createNewTrue() {
    console.log('create new true')
    this.createNew = true;
  }


  add(event: MatChipInputEvent): void {
    // const input = event.input;
    // const value = event.value;
    // if (input) {
    //   input.value = '';
    // }
    // this.chipCtrl.setValue(null);
  }

  remove(listEntry: ListEntry): void {
    const index = this.chips.indexOf(listEntry);

    if (index >= 0) {
      this.chips.splice(index, 1);
    }

    if (this.chips.length == 0) {
      this.placeholderText = this.defaultPlaceHolderText
      this.entityViewSpec.formField.formControl.setValue(null)
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (this.createNew && event.option.value === '') {
      this.startPost(null)
      return
    } else if (this.createNew && !this.postingNewItem) {
      console.log('posting new entity from autocomplete')

      this.postingNewItem = true
      this.loading = true
      this.entityViewSpec.entityCore.postHelper.delegate = this
      this.loading = true
      this.entityViewSpec.entityCore.postHelper.prepareRequestWithQuickCreate(event.option.value)
      this.entityViewSpec.entityCore.postHelper.post(this.entityViewSpec.entityCore.postHelper.request)

    } else if (!this.createNew) {
      if (this.multiSelect) {
        this.chips.push(event.option.value);
      } else {
        this.setChip(event.option.value)
      }
      // this.chipInput.nativeElement.value = '';
      // this.chipCtrl.setValue(null);

      this.resetSearch()
    }
  }

  keyUp(event: KeyboardEvent) {

    if (event.code == 'Backspace' && this.chips.length > 0 && (this.chipCtrl.value == '' || !this.chipCtrl.value)) {
      this.chips.pop()
      this.chipCtrl.setValue(null);
      this.firstEnter = false
    } else if ((event.keyCode >= 48 && event.keyCode <= 57) || (event.keyCode >= 65 && event.keyCode <= 90) || event.keyCode == 8 || event.keyCode == 127) {
      this.firstEnter = false
      if (!this.multiSelect) {
        this.chips = []
        this.entityViewSpec.formField.formControl.setValue(null)
      }
      this.executeSearch()
    } else if (
      event.code == 'Enter' && this.chips.length > 0
      && this.entityViewSpec.formField.formGroup.valid
      && !this.firstEnter
    ) {
      this.firstEnter = true;
    } else if (
      event.code == 'Enter' && this.chips.length > 0
      && this.entityViewSpec.formField.formGroup.valid
      && this.firstEnter
    ) {
      this.entityViewSpec.parentEntityViewSpec.entityCore.postHelper.submitForm();
    } else {
      this.firstEnter = false
    }
  }

  setChip(listEntry: ListEntry) {
    console.log('Selecting chip from list in autocomplete')
    this.postingNewItem = false;
    this.chips = [listEntry];
    this.entityViewSpec.formField.formControl.setValue(listEntry.entity.uid)
    this.chipCtrl.setValue(listEntry.lineHeading[0])
    this.entityViewSpec.entityCore.entities = null;

    if (this.entityViewSpec.chipListAdd) {
      console.log('about to submit new chip')
      this.resetSearch();
      this.loading = true;
      this.chipCtrl.setValue(null);
      this.entityViewSpec.parentEntityViewSpec.entityCore.postHelper.submitForm()
    }
  }

  private resetSearch() {
    this.entityViewSpec.listBody.reset();
    this.entityViewSpec.entityCore.entities = null;
    this.entityViewSpec.entityCore.searchForm.patchValue({'search': ''})
  }

  executeSearch() {
    if (this.clear) {
      this.loading = true;
      this.lastSearchStr = this.chipCtrl.value
      this.entityViewSpec.entityCore.search(this.lastSearchStr, this.entityViewSpec)
      this.clear = false
      setTimeout(() => {
        this.clear = true
        if (this.lastSearchStr !== this.chipCtrl.value) {
          this.executeSearch()
        }
      }, 800)
    }
  }

  submitComplete(listEntry: any, showNew: any) {
    console.log('submit complete in autocomplete')
    this.loading = false;
    this.createNew = false;
    this.setChip(listEntry)
  }

  onSelectionChange(detail: DetailEntry, data: any) {
    throw new Error("Method not implemented.");
  }

  clickSubmitFormButton() {
    throw new Error("Method not implemented.");
  }

  submitError(message: any): void {
    throw new Error("Method not implemented.");
  }

  cancel() {
    throw new Error("Method not implemented.");
  }

  deleteComplete() {
    throw new Error("Method not implemented.");
  }

  clickViewSelectionFromPushedSelectionList(navigator: SvNavigatorComponent, currentEntityViewSpec: EntityViewSpecProtocol, formField: FormField): void {
    throw new Error("Method not implemented.");
  }

  clickViewPushedSelectionList(navigator: SvNavigatorComponent, currentEntityViewSpec: EntityViewSpecProtocol, formField: FormField): void {
    throw new Error("Method not implemented.");
  }

  clickPushDetail(detailEntry: DetailEntry) {
    throw new Error("Method not implemented.");
  }

  clickPushList(detailEntry: DetailEntry) {
    throw new Error("Method not implemented.");
  }

  presentUserWithMessage(message: string, duration: number) {
    this.snackBar.open(message);
  }
}
