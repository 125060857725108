import {SORespItemImport} from '../../core/item-import/so-resp-item-import';
import {ItemImportCore} from './item-import.core';
import {ListEntry} from '../../../engine/shared/list-entry';
import {EntityAttributesForDisplay} from '../../../engine/shared/entity-attributes-for-display';
import {DetailEntry, DetailFactory, DetailFactoryProtocol, EditableType} from '../../../engine/shared/detail-entry';
import {ActiveUserService} from '../../../engine/shared/active-user/active-user.service';
import {BaseDetailType} from "../../../engine/shared/base-detail";
import {EntityViewSpecProtocol} from "../../../engine/shared/entity-view/entity-view-spec-protocol";
import {getHeading} from "../../../engine/shared/display-option-utilities";
import {putItemImportFormFieldOptions} from './put-item-import-form-fields';
import {SOGetItemImport} from '../../core/item-import/so-get-item-import';
import {SOGetItemImportLine} from '../../core/item-import-line/so-get-item-import-line';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {DisplayVectorStringFactory} from "../../../engine/config/display-vector";
import {displayOptions} from "../../display-options";
import {ListSpecType} from "../../../engine/shared/entity-view/list-spec";
import {SPECTER_SPEC} from "../../specter-spec";
import {getCore} from "../../../engine/shared/entity-view/get-core";

export class ItemImportDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespItemImport = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(displayOptions);
    let heading;

    const attributes = []

    const dateCreatedDetail = new DetailEntry(
        'Date created',
        activeUserService.time.niceDate(entity.dateCreated),
        'dateCreated',
        BaseDetailType.text,
        null,
        EditableType.never,
    )
    attributes.push(dateCreatedDetail)


    const linesCore = getCore(SPECTER_SPEC, activeUserService, listEntry.entityCore.httpClient, 'ItemImportLineCore');
    linesCore.soGet = SOGetItemImportLine.construct({
      itemImport: new SOGetItemImport(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, linesCore)
    linesCore.title = heading ? heading : linesCore.title
    linesCore.listTitle = 'Lines'

    linesCore.configureForDisplay(entityViewSpec)

    const linesDetail = new DetailEntry(
      heading ? heading : linesCore.listTitle,
      null,
      'lines',
      BaseDetailType.embeddedList,
      linesCore,
      EditableType.never
    )
    attributes.push(linesDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}