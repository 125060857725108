import {DetailEntry} from "./detail-entry";
import {FormGroup} from "@angular/forms";
import {FormViewProtocol} from "../protocols/form-view-protocol";

export class AttributeViewSpec {
  compact = false;
  chipListAdd = false;

  constructor(
    public detailEntry: DetailEntry,
    public from: FormGroup,
    public parent: FormViewProtocol
  ) {
  }

}
