import { Type } from 'class-transformer';
import {ENSesMessageType} from '../../enums';
import {ObjectiveProtocol} from '../../../engine/protocols/objective-protocol';
import {EntityType} from "../../entity-types";


export class SORespSesMessage implements ObjectiveProtocol {

  entityType: EntityType = EntityType.sesMessage;
  uid: string;
  data: string;
  @Type(() => Date)
  dateCreated: Date;
  messageType: ENSesMessageType;
}