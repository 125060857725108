<div class="main-container" fxLayout="column">
  <app-sv-banner *ngIf="!banner.disabled" [banner]="banner"></app-sv-banner>
  <app-sv-nav-bar *ngIf="showTopBar" [appName]="appName" [parent]="this"></app-sv-nav-bar>
  <mat-sidenav-container autosize>
    <mat-sidenav #sidenav
                 [class.drawer]="!activeUserService.isHandset"
                 [class.drawer-mobile]="activeUserService.isHandset"
                 mode="side"
                 opened="{{this.drawerOpen}}"
                 [disableClose]="activeUserService.userLoaded && !activeUserService.isHandset">

      <app-sv-sidenav [parent]="this"></app-sv-sidenav>
    </mat-sidenav>
    <mat-sidenav-content fxLayout="column" [class.mobile]="activeUserService.isHandset">
      <div fxFlex="auto" class="main-container">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
