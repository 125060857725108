import { Type } from 'class-transformer';
import { SORespUser } from '../user/so-resp-user';
import { MMCredential } from '../../../engine/multiverse/mm-credential';
import {ObjectiveProtocol} from '../../../engine/protocols/objective-protocol';
import {EntityType} from "../../entity-types";


export class SORespCertificate implements ObjectiveProtocol {

  entityType: EntityType = EntityType.certificate;
  uid: string;
  @Type(() => SORespUser)
  user: SORespUser;
  @Type(() => Date)
  dateCreated: Date;
  credential: MMCredential = null;
}