import {SORespMedia} from '../../core/media/so-resp-media';
import {MediaCore} from './media.core';
import {ListEntry} from '../../../engine/shared/list-entry';
import {EntityAttributesForDisplay} from '../../../engine/shared/entity-attributes-for-display';
import {DetailEntry, DetailFactory, DetailFactoryProtocol, EditableType} from '../../../engine/shared/detail-entry';
import {ActiveUserService} from '../../../engine/shared/active-user/active-user.service';
import {BaseDetailType} from "../../../engine/shared/base-detail";
import {EntityViewSpecProtocol} from "../../../engine/shared/entity-view/entity-view-spec-protocol";
import {getHeading} from "../../../engine/shared/display-option-utilities";
import {putMediaFormFieldOptions} from './put-media-form-fields';
import {SOGetMedia} from '../../core/media/so-get-media';
import {SOGetAttachment} from '../../core/attachment/so-get-attachment';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {DisplayVectorStringFactory} from "../../../engine/config/display-vector";
import {displayOptions} from "../../display-options";
import {ListSpecType} from "../../../engine/shared/entity-view/list-spec";
import {SPECTER_SPEC} from "../../specter-spec";
import {getCore} from "../../../engine/shared/entity-view/get-core";

export class MediaDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespMedia = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(displayOptions);
    let heading;

    const attributes = []

    const linksDetail = new DetailEntry(
        'Links',
        entity.links,
        'links',
        BaseDetailType.file,
        null,
        EditableType.never,
    )
    attributes.push(linksDetail)

    const nameDetail = new DetailEntry(
        'Name',
        entity.name,
        'name',
        BaseDetailType.text,
        null,
        EditableType.always,
        putMediaFormFieldOptions.name
    )
    attributes.push(nameDetail)

    const descriptionDetail = new DetailEntry(
        'Description',
        entity.description,
        'description',
        BaseDetailType.text,
        null,
        EditableType.always,
        putMediaFormFieldOptions.description
    )
    attributes.push(descriptionDetail)

    const sizeDetail = new DetailEntry(
        'Size',
        entity.size,
        'size',
        BaseDetailType.text,
        null,
        EditableType.never,
    )
    attributes.push(sizeDetail)

    const dateCreatedDetail = new DetailEntry(
        'Date created',
        activeUserService.time.niceDate(entity.dateCreated),
        'dateCreated',
        BaseDetailType.text,
        null,
        EditableType.never,
    )
    attributes.push(dateCreatedDetail)


    const attachmentsCore = getCore(SPECTER_SPEC, activeUserService, listEntry.entityCore.httpClient, 'AttachmentCore');
    attachmentsCore.soGet = SOGetAttachment.construct({
      media: new SOGetMedia(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, attachmentsCore)
    attachmentsCore.title = heading ? heading : attachmentsCore.title
    attachmentsCore.listTitle = 'Items'
    attachmentsCore.listSpecType = ListSpecType.thumbList

    attachmentsCore.configureForDisplay(entityViewSpec)

    const attachmentsDetail = new DetailEntry(
      heading ? heading : attachmentsCore.listTitle,
      null,
      'attachments',
      BaseDetailType.embeddedList,
      attachmentsCore,
      EditableType.never
    )
    attributes.push(attachmentsDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}