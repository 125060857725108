<div class="fill-container">
  <div class="center-box-outer">
    <div class="center-box-inner" fxLayout="column" fxLayoutAlign="start center">
      <img src="assets/logos/logo_256.png" class="logo">
      <h1>Welcome to {{title}}</h1>
      <h3>{{summary}}</h3>
      <br>
      <button mat-stroked-button routerLink="login">Login</button>
      <button mat-stroked-button routerLink="register">Register</button>
      <br>
      <br>
      <br>
      <p>&copy; {{year }} - {{company}}</p>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <a routerLink="terms">Terms and conditions</a>
        <div>|</div>
        <a routerLink="privacy">Privacy policy</a>
      </div>
    </div>
  </div>
</div>
