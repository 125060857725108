import {ActiveUserService} from '../../../engine/shared/active-user/active-user.service';
import {HttpClient} from '@angular/common/http';
import {EOTagService} from '../../core/tag/eo-tag';
import {TagListEntryFactory} from './tag-list-entry-factory';
import {TagDetailFactory} from './tag-detail-factory';
import {SOGetTag} from '../../core/tag/so-get-tag';
import {RAPutTag} from './ra-put-tag';
import {RAPostTag} from './ra-post-tag';
import {RADeleteTag} from './ra-delete-tag';
import {AbstractEntityCore} from "../../../engine/shared/entity-view/abstract-entity-core";
import {EntityCoreProtocol} from "../../../engine/shared/entity-view/entity-core-protocol";
import {EntityType} from '../../entity-types';
import {SPECTER_SPEC} from "../../specter-spec";
import {FormControl, FormGroup} from "@angular/forms";
import {SOGetOrganization} from '../../core/organization/so-get-organization'

export class TagCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = EntityType.tag;
  title = 'Tag';
  listEntryFactory = new TagListEntryFactory();
  detailFactory = new TagDetailFactory();

  entityService: EOTagService;
  soGetClass = SOGetTag;
  soGet: SOGetTag;

  searchEnabled = true;

  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOTagService
    );
    this.adapters = [
      new RAPostTag(this),
      new RAPutTag(this),
      new RADeleteTag(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetTag();
    this.soGet.organization = new SOGetOrganization(
      this.activeUserService.getScope('organization').uid
    );
    this.soGet.queryOptions.limit = this.limit;
  }


}