import { Type } from 'class-transformer';
import { SORespRole } from '../role/so-resp-role';
import { SORespView } from '../view/so-resp-view';
import {ObjectiveProtocol} from '../../../engine/protocols/objective-protocol';
import {EntityType} from "../../entity-types";


export class SORespViewGrant implements ObjectiveProtocol {

  entityType: EntityType = EntityType.viewGrant;
  uid: string;
  @Type(() => SORespRole)
  role: SORespRole;
  @Type(() => SORespView)
  view: SORespView;
}