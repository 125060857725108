import { Type } from 'class-transformer';
import { SORespItem } from '../item/so-resp-item';
import { SORespLocation } from '../location/so-resp-location';
import {ObjectiveProtocol} from '../../../engine/protocols/objective-protocol';
import {EntityType} from "../../entity-types";


export class SORespTemloca implements ObjectiveProtocol {

  entityType: EntityType = EntityType.temloca;
  uid: string;
  quantity: number;
  @Type(() => SORespItem)
  item: SORespItem;
  @Type(() => SORespLocation)
  location: SORespLocation;
}