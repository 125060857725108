import { SOGetPrice } from '../price/so-get-price';
import { SOGetOrganization } from '../organization/so-get-organization';
import {MMQueryOptions} from '../../../engine/multiverse/mm-query-options';
import {QueryProtocol} from "../../../engine/protocols/query-protocol";


export class SOGetSubscription implements QueryProtocol {

  constructor(
    public uid: string = null,
    public price: SOGetPrice = null,
    public organization: SOGetOrganization = null,
    public exRef: string = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetSubscription(
      'uid' in params ? params.uid : null,
      'price' in params ? params.price : null,
      'organization' in params ? params.organization : null,
      'exRef' in params ? params.exRef : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
