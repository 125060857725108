import {FormField, FormFieldType, SelectionOption} from '../../../engine/shared/form-field';
import {EntityType} from "../../entity-types";


const titleField = new FormField(
  'title',
  'Title',
  null,
  FormFieldType.text,
  false,
)
const numberField = new FormField(
  'number',
  'Number',
  null,
  FormFieldType.text,
  false,
)


export const putSectionFormFieldOptions: {[key: string]: FormField} = {
  title: titleField,
  number: numberField,
};