import { Type } from 'class-transformer';
import { SORespUser } from '../user/so-resp-user';
import { SORespRole } from '../role/so-resp-role';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ENRoleGrantStatus} from '../../enums';
import {ObjectiveProtocol} from '../../../engine/protocols/objective-protocol';
import {EntityType} from "../../entity-types";


export class SORespRoleGrant implements ObjectiveProtocol {

  entityType: EntityType = EntityType.roleGrant;
  uid: string;
  @Type(() => Date)
  dateCreated: Date;
  isDefault: boolean = false;
  status: ENRoleGrantStatus;
  @Type(() => SORespUser)
  user: SORespUser;
  @Type(() => SORespRole)
  role: SORespRole;
  @Type(() => SORespOrganization)
  organization: SORespOrganization = null;
}