import {FormField, FormFieldType, SelectionOption} from '../../../engine/shared/form-field';
import {EntityType} from "../../entity-types";
import {ENPaymentIntentStatus} from '../../enums';


const amountField = new FormField(
  'amount',
  'Amount',
  null,
  FormFieldType.text,
  true,
)
const statusField = new FormField(
  'status',
  'Status',
  null,
  FormFieldType.enumeration,
  true,
)
statusField.enumeration = ENPaymentIntentStatus
const invoiceField = new FormField(
  'invoice',
  'Invoice',
  EntityType.invoice,
  FormFieldType.pushedSelectionList,
  true,
)


export const postPaymentIntentFormFieldOptions: {[key: string]: FormField} = {
  amount: amountField,
  status: statusField,
  invoice: invoiceField,
};