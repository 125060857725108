import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';

import { SOPostIdentity } from './so-post-identity';
import { SOPutIdentity } from './so-put-identity';
import { SOGetIdentity } from './so-get-identity';
import { SODeleteIdentity } from './so-delete-identity';
import {SORespIdentity} from './so-resp-identity';
import {SOPluralIdentity} from './so-plural-identity';

import {MMEntityService} from '../../../engine/multiverse/mm-entity-service';
import {EntityCoreProtocol} from '../../../engine/shared/entity-view/entity-core-protocol';
import {MMEntityServiceBase} from '../../../engine/multiverse/mm-entity-service-base';
import {HttpHeaders} from "@angular/common/http";


export class EOIdentityService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'identity',
      entityCore
    );
  }

  /** GET Identity by id. Will 404 if id not found */
  get(request: SOGetIdentity): Observable<SOPluralIdentity> {
    return this.httpClient.get<SOPluralIdentity>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralIdentity, resp) }),
      tap(_ => this.log(`fetched Identity`)),
      catchError(this.handleError<SOPluralIdentity>(`get Identity`))
    );
  }

  /** POST: add a new Identity to the server */
  post(request: SOPostIdentity): Observable<SORespIdentity> {
    return this.httpClient.post<SORespIdentity>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespIdentity, resp) }),
      tap((response: SORespIdentity) => this.log(`added Identity w/ id=${response.uid}`)),
      catchError(this.handleError<SORespIdentity>('Identity post'))
    );
  }

  /** PUT: update Identity  */
  put(request: SOPutIdentity): Observable<SORespIdentity> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespIdentity>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespIdentity, resp) }),
      tap((response: SORespIdentity) => this.log(`edited Identity w/ uid=${uid}`)),
      catchError(this.handleError<SORespIdentity>('Identity put'))
    );
  }

  /** DELETE: delete the Identity from the server */
  delete(request: SODeleteIdentity): Observable<SORespIdentity> {
    return this.httpClient.delete<SORespIdentity>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Identity uid=${request.uid}`)),
      catchError(this.handleError<SORespIdentity>('delete Identity'))
    );
  }
}
