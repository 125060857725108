import {ActiveUserService} from '../../../engine/shared/active-user/active-user.service';
import {HttpClient} from '@angular/common/http';
import {EOCertificateService} from '../../core/certificate/eo-certificate';
import {CertificateListEntryFactory} from './certificate-list-entry-factory';
import {CertificateDetailFactory} from './certificate-detail-factory';
import {SOGetCertificate} from '../../core/certificate/so-get-certificate';
import {RAPutCertificate} from './ra-put-certificate';
import {RAPostCertificate} from './ra-post-certificate';
import {RADeleteCertificate} from './ra-delete-certificate';
import {AbstractEntityCore} from "../../../engine/shared/entity-view/abstract-entity-core";
import {EntityCoreProtocol} from "../../../engine/shared/entity-view/entity-core-protocol";
import {EntityType} from '../../entity-types';
import {SPECTER_SPEC} from "../../specter-spec";

export class CertificateCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = EntityType.certificate;
  title = 'Certificate';
  listEntryFactory = new CertificateListEntryFactory();
  detailFactory = new CertificateDetailFactory();

  entityService: EOCertificateService;
  soGetClass = SOGetCertificate;
  soGet: SOGetCertificate;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOCertificateService
    );
    this.adapters = [
      new RAPostCertificate(this),
      new RAPutCertificate(this),
      new RADeleteCertificate(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetCertificate();
    this.soGet.queryOptions.limit = this.limit;
  }


}