import {ListEntry} from './list-entry';
import {ListSpec} from "./entity-view/list-spec";
import {EntityViewSpecProtocol} from "./entity-view/entity-view-spec-protocol";

export class Section {
  startDate: Date;
  endDate: Date;

  constructor(
    public index: number,
    public heading: string = '',
    public subLines: string[] = null,
    public trailingHeading: string = '',
    public listEntries: ListEntry[] = [],
    public showItemDivider = true
  ) {
  }
}


export class ListBody {

  isEmpty = true;

  get coreWhenUniformList() {
    return this.sections[0].listEntries[0].entityCore;
  }

  constructor(
    public emptyListMessage: string = 'No items at this time',
    public listIcon: string = null,
    public sections: Section[] = [new Section(0, null)],
    public titleHeading: string = null,
    public titleSybLines: string[] = null,
    public trailingTitleHeading: string = null
  ) {
  }

  addEntry(listEntry: ListEntry) {
    // this.coreWhenUniformList.entities.push(listEntry.entity)
    this.isEmpty = false;

    if (listEntry.icon) {
      this.listIcon = listEntry.icon;
    }

    const index = this.sections.findIndex(p => p.index === listEntry.section);
    if (index > -1) {
      this.sections[index].listEntries.push(listEntry);
    } else {
      const newSection = new Section(listEntry.section);
      newSection.index = listEntry.section;
      this.sections.push(newSection);
      newSection.listEntries.push(listEntry);
    }
  }

  splice(listEntry: ListEntry, updatedEntry: ListEntry = null) {
    console.log('splicing list entry');
    let index;
    for (const section of this.sections) {
      index = section.listEntries.findIndex(obj => obj.entity.uid === listEntry.entity.uid);
      // console.log(index)
      if (index !== -1) {
        console.log(listEntry);
        section.listEntries.splice(index, 1, updatedEntry);
        // section.listEntries.splice(index, 1);
        // section.listEntries.splice(0, 0, updatedEntry);
      }
    }
  }

  reset() {
    this.sections = [new Section(0, null)];
  }

  resetListBodyFromEntities(entities, entityViewSpec: EntityViewSpecProtocol) {
    this.reset();
    for (const entity of entities) {
      this.addEntry(
        entityViewSpec.entityCore.makeListEntry(
          entityViewSpec && entityViewSpec.parentEntityViewSpec ? entityViewSpec.parentEntityViewSpec : null,
          entityViewSpec.entityCore, entity, 0)
      );
    }
  }

  addEntitiesToListBody(entityViewSpec: EntityViewSpecProtocol, newEntities) {
    for (const entity of newEntities) {
      this.addEntry(
        entityViewSpec.entityCore.makeListEntry(
          entityViewSpec.parentEntityViewSpec ? entityViewSpec.parentEntityViewSpec : null,
          entityViewSpec.entityCore, entity, 0)
      );
    }
  }
}
