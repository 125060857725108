<form [formGroup]="form">
  <mat-button-toggle-group #toggleGroup [multiple]="multiple"
                           *ngIf="detail.editEnabled && detail.editableFormField"
                           [formControlName]="detail.editableFormField.identifierString">
    <mat-button-toggle *ngFor="let button of toggleButtons"
                       [id]="button.identifier"
                       [checked]="button.value"
                       (click)="clickMenuItem(button)">
      {{button.displayText}}
    </mat-button-toggle>
  </mat-button-toggle-group>
  <h3 *ngIf="!detail.editEnabled || !detail.editableFormField"
      [ngClass]="{'warn': detail.warn}">
    {{detail.name}}: {{detail.value}}
  </h3>
</form>
