<div *ngIf="!parent.entityViewSpec.listBody.isEmpty && parent.entityViewSpec.entityCore.listAsTable" fxLayout="column">
  <div *ngFor="let section of parent.entityViewSpec.listBody.sections" class="section-header">
    <div *ngIf="section.heading" fxLayout="column" fxLayoutAlign="space-between start">
      <div fxLayout="column">
        <h2>{{section.heading}}</h2>
        <h3 *ngFor="let line of section.subLines">{{line}}</h3>
      </div>
      <div fxLayout="column" fxLayoutAlign="start">
        <h2 *ngIf="section.trailingHeading">{{section.trailingHeading}}</h2>
      </div>
    </div>
    <div fxLayout="row">
      <div *ngFor="let listEntry of section.listEntries" [ngClass]="parent.border(listEntry)" class="vert-col">
        <div (click)="parent.selectListEntry(listEntry)">
          <div fxLayout="column">
            <div mat-line fxLayout="column" fxLayoutAlign="space-between start">
              <div>{{listEntry.lineHeading}}</div>
            </div>
            <div mat-line *ngFor="let line of listEntry.lines" style="color: {{line.color}}">{{line.text}}</div>
            <div *ngIf="listEntry.trailingText">{{listEntry.trailingText}}</div>
            <div *ngIf="!listEntry.trailingText && listEntry.trailingNumber !== null"
                 style="color: {{listEntry.trailingColor}}">{{listEntry.trailingNumber}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
