import {Component, Input, OnInit} from '@angular/core';
import {AbstractAttributeView} from "../../../shared/abstract-attribute-view";
import {AttributeViewSpec} from "../../../shared/attribute-view-spec";

@Component({
  selector: 'app-sv-toggle-attribute-view',
  templateUrl: './sv-toggle-attribute-view.component.html',
  styleUrls: ['./sv-toggle-attribute-view.component.scss']
})
export class SvToggleAttributeViewComponent extends AbstractAttributeView {

  get checked() {
    return !!this.detail.value
  }
}
