import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';

import { SOPostDefaultCustomField } from './so-post-default-custom-field';
import { SOGetDefaultCustomField } from './so-get-default-custom-field';
import { SOPutDefaultCustomField } from './so-put-default-custom-field';
import { SODeleteDefaultCustomField } from './so-delete-default-custom-field';
import {SORespDefaultCustomField} from './so-resp-default-custom-field';
import {SOPluralDefaultCustomField} from './so-plural-default-custom-field';

import {MMEntityService} from '../../../engine/multiverse/mm-entity-service';
import {EntityCoreProtocol} from '../../../engine/shared/entity-view/entity-core-protocol';
import {MMEntityServiceBase} from '../../../engine/multiverse/mm-entity-service-base';
import {HttpHeaders} from "@angular/common/http";


export class EODefaultCustomFieldService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'default_custom_field',
      entityCore
    );
  }

  /** GET DefaultCustomField by id. Will 404 if id not found */
  get(request: SOGetDefaultCustomField): Observable<SOPluralDefaultCustomField> {
    return this.httpClient.get<SOPluralDefaultCustomField>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralDefaultCustomField, resp) }),
      tap(_ => this.log(`fetched DefaultCustomField`)),
      catchError(this.handleError<SOPluralDefaultCustomField>(`get DefaultCustomField`))
    );
  }

  /** POST: add a new DefaultCustomField to the server */
  post(request: SOPostDefaultCustomField): Observable<SORespDefaultCustomField> {
    return this.httpClient.post<SORespDefaultCustomField>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespDefaultCustomField, resp) }),
      tap((response: SORespDefaultCustomField) => this.log(`added DefaultCustomField w/ id=${response.uid}`)),
      catchError(this.handleError<SORespDefaultCustomField>('DefaultCustomField post'))
    );
  }

  /** PUT: update DefaultCustomField  */
  put(request: SOPutDefaultCustomField): Observable<SORespDefaultCustomField> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespDefaultCustomField>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespDefaultCustomField, resp) }),
      tap((response: SORespDefaultCustomField) => this.log(`edited DefaultCustomField w/ uid=${uid}`)),
      catchError(this.handleError<SORespDefaultCustomField>('DefaultCustomField put'))
    );
  }

  /** DELETE: delete the DefaultCustomField from the server */
  delete(request: SODeleteDefaultCustomField): Observable<SORespDefaultCustomField> {
    return this.httpClient.delete<SORespDefaultCustomField>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted DefaultCustomField uid=${request.uid}`)),
      catchError(this.handleError<SORespDefaultCustomField>('delete DefaultCustomField'))
    );
  }
}
