import { Type } from 'class-transformer';
import { SORespLine } from '../line/so-resp-line';
import { SORespTemloca } from '../temloca/so-resp-temloca';
import { SORespPickup } from '../pickup/so-resp-pickup';
import {ObjectiveProtocol} from '../../../engine/protocols/objective-protocol';
import {EntityType} from "../../entity-types";


export class SORespDispatch implements ObjectiveProtocol {

  entityType: EntityType = EntityType.dispatch;
  uid: string;
  complete: boolean = false;
  isAllocation: boolean = false;
  quantity: number;
  @Type(() => SORespLine)
  line: SORespLine = null;
  @Type(() => SORespTemloca)
  temloca: SORespTemloca;
  notes: string = null;
  @Type(() => SORespPickup)
  pickup: SORespPickup = null;
  @Type(() => Date)
  dateCreated: Date;
  @Type(() => Date)
  dateUpdated: Date;
}