import { Component, OnInit } from '@angular/core';
import {AbstractAttributeView} from "../../../shared/abstract-attribute-view";

@Component({
  selector: 'app-sv-datetime-attribute-view',
  templateUrl: './sv-datetime-attribute-view.component.html',
  styleUrls: ['./sv-datetime-attribute-view.component.scss']
})
export class SvDatetimeAttributeViewComponent extends AbstractAttributeView {

}
