

export class SOPutSection {

  constructor(
    public uid: string,
    public title: string = null,
    public number: number = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutSection(
      'uid' in params ? params.uid : null,
      'title' in params ? params.title : null,
      'number' in params ? params.number : null,
    );
  }
}
