import {ItemCore} from "../../../entities/bv-item/item.core";
import {MenuItem, MenuItemType} from "../../../../engine/protocols/header-protocol";
import {BulkitemCore} from "../../../entities/bv-bulkitem/bulkitem.core";
import {SOPostBulkitem} from "../../../core/bulkitem/so-post-bulkitem";
import {SOGetOrganization} from "../../../core/organization/so-get-organization";
import {ENActive} from "../../../enums";
import {EntityViewSpecProtocol} from "../../../../engine/shared/entity-view/entity-view-spec-protocol";
import {ExcelService} from "../../../../engine/shared/excel-service";
import {Button, ButtonPressProtocol, HeaderSpec} from "../../../../engine/shared/header-spec";
import {PostSpec} from "../../../../engine/shared/entity-view/post-spec";
import {SPECTER_SPEC} from "../../../specter-spec";
import {ItemImportCore} from "../../../entities/bv-item-import/item-import.core";
import {SOPostItemImport} from "../../../core/item-import/so-post-item-import";


export class ItemProcessor extends ItemCore implements ButtonPressProtocol {

  duplicate = false;

  showMenu = true;

  active = new MenuItem('active', 'Active', this, MenuItemType.multiselect, true)
  archived = new MenuItem('archived', 'Archived', this, MenuItemType.multiselect, false)

  timeAscending = new MenuItem('timeAscending', 'Time ascending', this, MenuItemType.multiselect, false)
  timeDescending = new MenuItem('timeDescending', 'Time descending', this, MenuItemType.multiselect, true)

  configureForDisplay(entityViewSpec: EntityViewSpecProtocol) {

    this.menuItems = [
      new MenuItem('upload', 'Import', this, MenuItemType.excel),
      new MenuItem('template', 'Download template', this, MenuItemType.excelTemplate, '/assets/templates/item_upload_template.xls'),
      this.active,
      this.archived,
      this.timeDescending,
      this.timeAscending
    ]

    super.configureForDisplay(entityViewSpec);

    this.listHeaderSpec.showFilter = true;
    this.soGet.status = [ENActive.active];
  }

  makeHeaderSpecForEntityAttributesView(entityViewSpec: EntityViewSpecProtocol): HeaderSpec {
    const spec = super.makeHeaderSpecForEntityAttributesView(entityViewSpec);
    const duplicate = new Button('duplicate', 'Duplicate', 'control_point_duplicate')
    duplicate.buttonPressHandler = this;
    spec.buttons.push(
      duplicate
    )
    return spec
  }

  menuClick(item: MenuItem, entityViewSpec: EntityViewSpecProtocol) {
    console.log(item)
    if (item.identifier === this.active.identifier && !item.value) {
      this.soGet.status = [ENActive.active]
      this.archived.value = false;
      this.active.value = true;
      this.resetViewAndFetchList(entityViewSpec);
    } else if (item.identifier === this.archived.identifier && !item.value) {
      this.soGet.status = [ENActive.archived]
      this.archived.value = true;
      this.active.value = false;
      this.resetViewAndFetchList(entityViewSpec);
    } else if (item.identifier === this.timeAscending.identifier && !item.value) {
      this.soGet.queryOptions.descending = false;
      this.descending = false;
      this.timeDescending.value = false;
      this.resetViewAndFetchList(entityViewSpec);
    } else if (item.identifier === this.timeDescending.identifier && !item.value) {
      this.soGet.queryOptions.descending = true;
      this.descending = true;
      this.timeDescending.value = true;
      this.resetViewAndFetchList(entityViewSpec);
    } else if (item.identifier === 'template') {
      this.exportFilename = 'binin_import_template'
      const excelService = new ExcelService(this.httpClient)
      excelService.exportDataToTemplate(item.value, this)

    }
  }

  processMenuItem(menuItem: MenuItem, data) {
    menuItem.header.parent.loading = true;
    if (menuItem.identifier === 'upload') {
      const itemImportCore = new ItemImportCore(this.activeUserService, this.httpClient)
      itemImportCore.delegate = this.delegate
      itemImportCore.entityService.post(
        new SOPostItemImport(
          {data: data},
          new SOGetOrganization(this.activeUserService.activeOrganization.uid)
        )
      ).subscribe(resp => {
        menuItem.header.parent.loading = false;
        if (resp) {
          menuItem.header.parent.showMessage("Import successful")
          menuItem.header.router.navigateByUrl('/', {skipLocationChange: true}).then(() =>
            menuItem.header.router.navigate(['item'])
          );
        }
      })
    }
  }

  buttonPress(button: Button): void {
    if (button.identifier === 'duplicate') {
      const entityCore = SPECTER_SPEC.makeCore(this.activeUserService, this.httpClient, this.type);
      const entity = button.header.parent.entityViewSpec.listEntry.entity

      entityCore.postHelper.patchFormDisplayValues = {
        name: entity.name,
        description: entity.description,
        salePrice: entity.salePrice,
        cost: entity.cost,
      }

      button.header.parent.entityViewSpec.navigator.entityViewSpecs.pop();
      button.header.parent.entityViewSpec.navigator.addEntityViewSpec(
        new PostSpec(
          entityCore,
          button.header.parent.entityViewSpec.parentEntityViewSpec,
          button.header.parent.entityViewSpec.navigator,
        )
      )


    }
  }
}
