import {SbNavItem} from '../engine/shared/sb-nav-item/sb-nav-item';
import {EntityType} from "./entity-types";

export const navItems = {
  item: new SbNavItem('item', EntityType.item, '/item', 'View your items', false),
  order: new SbNavItem('order', EntityType.order, '/order', 'View your orders', false),
  pickup: new SbNavItem('pickup', EntityType.pickup, '/pickup', 'View your pickups', false),
  location: new SbNavItem('location', EntityType.location, '/location', 'View your locations', false),
  customField: new SbNavItem('customField', EntityType.customField, '/custom_field', 'View your custom_fields', false),
  media: new SbNavItem('media', EntityType.media, '/media', 'View your medias', false),
  tag: new SbNavItem('tag', EntityType.tag, '/tag', 'View your tags', false),
  distributor: new SbNavItem('distributor', EntityType.distributor, '/distributor', 'View your distributors', false),
  defaultCustomField: new SbNavItem('defaultCustomField', EntityType.defaultCustomField, '/default_custom_field', 'View your default_custom_fields', false),
  organization: new SbNavItem('organization', EntityType.organization, '/organization', 'View your organizations', false),
  product: new SbNavItem('product', EntityType.product, '/product', 'View your products', false),
  constraint: new SbNavItem('constraint', EntityType.constraint, '/constraint', 'View your constraints', false),
  appliedConstraint: new SbNavItem('appliedConstraint', EntityType.appliedConstraint, '/applied_constraint', 'View your applied_constraints', false),
  role: new SbNavItem('role', EntityType.role, '/role', 'View your roles', false),
  view: new SbNavItem('view', EntityType.view, '/view', 'View your views', false),
  sesMessage: new SbNavItem('sesMessage', EntityType.sesMessage, '/ses_message', 'View your ses_messages', false),
};