import {Component, Input, OnInit} from '@angular/core';
import {SvListComponent} from "../../entity/sv-list/sv-list.component";
import {ListEntry} from "../../../shared/list-entry";

@Component({
  selector: 'app-sv-check-list',
  templateUrl: './sv-check-list.component.html',
  styleUrls: ['./sv-check-list.component.scss']
})
export class SvCheckListComponent implements OnInit {

  @Input()
  parent: SvListComponent

  constructor() { }

  ngOnInit(): void {
  }

  checkCheckBox(listEntry: ListEntry, checked: boolean) {
    listEntry.checked = checked
    listEntry.entityCore.checkCheckbox(listEntry.entity, checked)
  }

}
