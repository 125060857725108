import {DisplaySeparator} from "../engine/config/display-separator";
import {EntityType} from "./entity-types";
import {
  DisplayVector,
  DisplayVectorBundle,
  DisplayVectorComponent,
  DisplayVectorEntity,
  DisplayVectorKey,
  DisplayVectorText
} from "../engine/config/display-vector";
import {baseDisplayOptions} from "../engine/base-display-options";


export const displayOptions = []

for (const option of baseDisplayOptions) {
  displayOptions.push(option)
}
displayOptions.push(
  new DisplayVectorBundle(
    EntityType.item,
    [
      new DisplayVector(
        null,
        null,
        [
          new DisplayVectorComponent(
            [
              new DisplayVectorKey('quantity'),
            ],
            DisplaySeparator.comma,
          )
        ],
        null,
        true
      )
    ]
  ),
  new DisplayVectorBundle(
    EntityType.temloca,
    [
      new DisplayVector(
        null,
        null,
        [
          new DisplayVectorComponent(
            [
              new DisplayVectorKey('quantity'),
            ],
            DisplaySeparator.comma,
          )
        ],
        null,
        true
      ),
      new DisplayVector(
        EntityType.location,
        new DisplayVectorComponent(
          [
            new DisplayVectorEntity(EntityType.item),
            new DisplayVectorText('x'),
            new DisplayVectorKey('quantity'),
          ]
        )
      ),
      new DisplayVector(
        EntityType.item,
        new DisplayVectorComponent(
          [
            new DisplayVectorEntity(EntityType.location),
            new DisplayVectorText('x'),
            new DisplayVectorKey('quantity'),
          ]
        )
      ),
      new DisplayVector(
        EntityType.line,
        new DisplayVectorComponent(
          [
            new DisplayVectorEntity(EntityType.item),
            new DisplayVectorText('-'),
            new DisplayVectorEntity(EntityType.location),
          ]
        ),
        [
          new DisplayVectorComponent(
            [
              new DisplayVectorEntity(EntityType.item),
            ]
          ),
          new DisplayVectorComponent(
            [
              new DisplayVectorKey('quantity'),
            ],
            DisplaySeparator.comma
          )
        ],
        null,
        true
      ),
      new DisplayVector(
        EntityType.pickup,
        new DisplayVectorComponent(
          [
            new DisplayVectorEntity(EntityType.location),
          ]
        ),
      ),
      new DisplayVector(
        EntityType.dispatch,
        new DisplayVectorComponent(
          [
            new DisplayVectorEntity(EntityType.location),
            new DisplayVectorEntity(EntityType.item, null, ''),
            new DisplayVectorKey('quantity', null, ''),
          ],
          DisplaySeparator.dash,
        ),
        [],
        null,
        false,
        new DisplayVectorKey('Source location')
      )
    ]
  ),
  new DisplayVectorBundle(
    EntityType.temaga,
    [
      new DisplayVector(
        EntityType.item,
        new DisplayVectorComponent(
          [
            new DisplayVectorEntity(EntityType.tag),
          ]
        )
      ),
      new DisplayVector(
        EntityType.tag,
        new DisplayVectorComponent(
          [
            new DisplayVectorEntity(EntityType.item),
          ]
        )
      )
    ]
  ),
  new DisplayVectorBundle(
    EntityType.temdisa,
    [
      new DisplayVector(
        EntityType.item,
        new DisplayVectorComponent(
          [
            new DisplayVectorEntity(EntityType.distributor),
          ]
        )
      ),
      new DisplayVector(
        EntityType.distributor,
        new DisplayVectorComponent(
          [
            new DisplayVectorEntity(EntityType.item),
          ]
        )
      )
    ]
  ),
  new DisplayVectorBundle(
    EntityType.temfield,
    [
      new DisplayVector(
        EntityType.customField,
        new DisplayVectorComponent(
          [
            new DisplayVectorEntity(EntityType.item),
          ]
        )
      ),
      new DisplayVector(
        EntityType.item,
        new DisplayVectorComponent(
          [
            new DisplayVectorEntity(EntityType.customField),
            new DisplayVectorKey('value'),
          ]
        )
      )
    ]
  ),
  new DisplayVectorBundle(
    EntityType.roleGrant,
    [
      new DisplayVector(
        EntityType.organization,
        new DisplayVectorComponent(
          [
            new DisplayVectorEntity(EntityType.user),
          ]
        )
      ),
      new DisplayVector(
        EntityType.role,
        new DisplayVectorComponent(
          [
            new DisplayVectorEntity(EntityType.user),
          ]
        )
      ),
      new DisplayVector(
        EntityType.user,
        new DisplayVectorComponent(
          [
            new DisplayVectorEntity(EntityType.organization),
          ]
        )
      )
    ]
  ),
  new DisplayVectorBundle(
    EntityType.line,
    [
      new DisplayVector(
        null,
        null,
        [
          new DisplayVectorComponent(
            [
              new DisplayVectorKey('quantity'),
            ]
          )
        ]
      ),
      new DisplayVector(
        EntityType.order,
        new DisplayVectorComponent(
          [
            new DisplayVectorKey('quantity'),
            new DisplayVectorEntity(EntityType.item),
          ],
          DisplaySeparator.x
        ),
        [
          new DisplayVectorComponent(
            [
              new DisplayVectorKey('dispatchStatus'),
            ]
          )
        ],
        null,
        false,
        new DisplayVectorKey('Line item')
      ),
      new DisplayVector(
        EntityType.item,
        new DisplayVectorComponent(
          [
            new DisplayVectorEntity(EntityType.order),
          ]
        ),
      ),
      new DisplayVector(
        EntityType.dispatch,
        new DisplayVectorComponent(
          [
            new DisplayVectorEntity(EntityType.order),
          ]
        ),
        [],
        null,
        false,
        new DisplayVectorKey('Order')
      ),
      new DisplayVector(
        EntityType.pickup,
        new DisplayVectorComponent(
          [
            new DisplayVectorEntity(EntityType.item),
            new DisplayVectorText('-'),
            new DisplayVectorText('Order'),
            new DisplayVectorEntity(EntityType.order),
            new DisplayVectorKey('notes', 'line notes', null, true),
          ]
        ),
        [],
        null,
        true,
      )
    ]
  ),
  new DisplayVectorBundle(
    EntityType.dispatch,
    [
      new DisplayVector(
        EntityType.item,
        new DisplayVectorComponent(
          [
            new DisplayVectorEntity(EntityType.location),
          ]
        )
      ),
      new DisplayVector(
        EntityType.line,
        new DisplayVectorComponent(
          [
            new DisplayVectorKey('quantity'),
            new DisplayVectorEntity(EntityType.temloca, EntityType.line),
            new DisplayVectorEntity(EntityType.pickup),
          ],
          DisplaySeparator.comma,
          false
        ),
      ),
      new DisplayVector(
        EntityType.pickup,
        new DisplayVectorComponent(
          [
            new DisplayVectorEntity(EntityType.temloca, EntityType.pickup),
            new DisplayVectorKey('quantity'),
            new DisplayVectorEntity(EntityType.line, EntityType.pickup),
            new DisplayVectorKey('notes', 'dispatch notes', null, true),
          ],
          DisplaySeparator.dash,
          false
        ),
      ),
      new DisplayVector(
        EntityType.dispatch,
        new DisplayVectorComponent(
          [
            new DisplayVectorKey('quantity'),
            new DisplayVectorEntity(EntityType.temloca, EntityType.line),
            new DisplayVectorEntity(EntityType.line, EntityType.pickup),
          ],
          DisplaySeparator.dash,
          false
        ),
      ),
      new DisplayVector(
        EntityType.temloca,
        new DisplayVectorComponent(
          [
            new DisplayVectorKey('quantity'),
            new DisplayVectorEntity(EntityType.line, EntityType.pickup),
          ],
          DisplaySeparator.dash,
          false
        ),
      )
    ]
  ),
  new DisplayVectorBundle(
    EntityType.pickup,
    [
      new DisplayVector(
        null,
        new DisplayVectorComponent(
          [
            new DisplayVectorKey('ref'),
          ]
        ),
        [
          new DisplayVectorComponent(
            [
              new DisplayVectorKey('status'),
            ],
            DisplaySeparator.comma,
          )
        ],
        null,
        true
      )
    ]
  ),
  new DisplayVectorBundle(
    EntityType.order,
    [
      new DisplayVector(
        null,
        new DisplayVectorComponent(
          [
            new DisplayVectorKey('name'),
          ]
        ),
        [
          new DisplayVectorComponent(
            [
              new DisplayVectorKey('ref'),
            ]
          ),
          new DisplayVectorComponent(
            [
              new DisplayVectorKey('dispatchStatus'),
            ]
          )
        ],
        null,
        true
      )
    ]
  ),
  new DisplayVectorBundle(
    EntityType.viewGrant,
    [
      new DisplayVector(
        EntityType.role,
        new DisplayVectorComponent(
          [
            new DisplayVectorEntity(EntityType.view),
          ]
        )
      )
    ]
  ),
  new DisplayVectorBundle(
    EntityType.roleGrant,
    [
      new DisplayVector(
        EntityType.role,
        new DisplayVectorComponent(
          [
            new DisplayVectorEntity(EntityType.user),
          ]
        )
      ),
      new DisplayVector(
        EntityType.organization,
        new DisplayVectorComponent(
          [
            new DisplayVectorEntity(EntityType.user),
          ]
        )
      ),
    ]
  ),
  new DisplayVectorBundle(
    EntityType.user,
    [
      new DisplayVector(
        null,
        new DisplayVectorComponent(
          [
            new DisplayVectorKey('username'),
            new DisplayVectorKey('giveName'),
            new DisplayVectorKey('surname'),
          ]
        )
      )
    ]
  ),
  new DisplayVectorBundle(
    EntityType.attachment,
    [
      new DisplayVector(
        EntityType.media,
        new DisplayVectorComponent(
          [
            new DisplayVectorEntity(EntityType.item),
          ]
        )
      )
    ]
  ),
)
