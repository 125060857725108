import {SpecterAppSpec} from "../specter-app-spec";
import {EntityCoreProtocol} from "./entity-core-protocol";

export function getCore(specter_spec: SpecterAppSpec, activeUserService, httpClient, identifier): EntityCoreProtocol {
  let coreProcessor
  if (Object.keys(specter_spec.customEntityProcessors).includes(identifier)) {
    coreProcessor = specter_spec.customEntityProcessors[identifier]
  } else {
    coreProcessor = specter_spec.entityCoreNameMap[identifier]
  }
  return new coreProcessor(activeUserService, httpClient);
}
