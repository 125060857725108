import {ItemProcessor} from "./custom/entities/item/item-processor";
import {SpecterAppSpec} from "../engine/shared/specter-app-spec";
import {EntityType} from "./entity-types";
import {entityCoreMap} from "./entity-core-map";
import {PutDispatchComplete} from "./custom/entities/dispatch/put-dispatch-complete";
import {DispatchProcessor} from "./custom/entities/dispatch/dispatch-processor";
import {UserProcessor} from "../engine/views/pre-built/entities/user/user-processor";
import {BaseOrganizationProcessor} from "../engine/views/pre-built/entities/organization/base-organization-processor";
import {OrderProcessor} from "./custom/entities/order/order-processor";
import {PickupProcessor} from "./custom/entities/pickup/pickup-processor";
import {AttachmentProcessor} from "./custom/entities/attachment/attachment-processor";
import {MediaProcessor} from "./custom/entities/media/media-processor";
import {TemfieldProcessor} from "./custom/entities/temfield/temfield-processor";
import {PostSubscriptionComplete} from "./custom/entities/subscription/post-subscription-complete";
import {SubscriptionProcessor} from "./custom/entities/subscription/subscription-processor";
import {PriceProcessor} from "./custom/entities/price/price-processor";
import {BaseOrganizationInviteProcessor} from "../engine/views/pre-built/entities/organization-invite/base-organization-invite-processor";
import {LineProcessor} from "./custom/entities/line/line-processor";
import {DistributorProcessor} from "./custom/entities/distributor/distributor-processor";
import {ItemImportProcessor} from "./custom/entities/item-import/item-import-processor";
import {ItemImportLineProcessor} from "./custom/entities/item-import-line/item-import-line-processor";
import {RoleGrantProcessor} from "./custom/entities/role-grant/role-grant-processor";
import {RoleProcessor} from "./custom/entities/role/role-processor";
import {TemlocaProcessor} from "./custom/entities/temloca/temloca-processor";


export const SPECTER_SPEC = new SpecterAppSpec(
  'item',
  'Binin',
  entityCoreMap
)

SPECTER_SPEC.summary = 'The inventory app for resellers by resellers.'
SPECTER_SPEC.company = 'Bin Inventory LLC.'

SPECTER_SPEC.customEntityProcessors = {
  OrganizationInviteCore: BaseOrganizationInviteProcessor,
  OrganizationCore: BaseOrganizationProcessor,
  PriceCore: PriceProcessor,
  SubscriptionCore: SubscriptionProcessor,
  UserCore: UserProcessor,
  RoleCore: RoleProcessor,
  RoleGrantCore: RoleGrantProcessor,
  MediaCore: MediaProcessor,
  AttachmentCore: AttachmentProcessor,
  ItemCore: ItemProcessor,
  DispatchCore: DispatchProcessor,
  OrderCore: OrderProcessor,
  PickupCore: PickupProcessor,
  TemfieldCore: TemfieldProcessor,
  LineCore: LineProcessor,
  DistributorCore: DistributorProcessor,
  ItemImportCore: ItemImportProcessor,
  ItemImportLineCore: ItemImportLineProcessor,
  TemlocaCore: TemlocaProcessor
}

SPECTER_SPEC.chainPost = {
  'line': EntityType.dispatch
}

SPECTER_SPEC.postPrepareRequestProcessors = [
  // new PostMediaPrepareRequest()
]

SPECTER_SPEC.postListItemCompleteProcessors = [
  // new PostMediaComplete(),
  new PostSubscriptionComplete()
]

SPECTER_SPEC.putEntityCompleteProcessors = [
  new PutDispatchComplete()
]

SPECTER_SPEC.mediaEntityTypes.push(
  EntityType.media
)

SPECTER_SPEC.help = true;
