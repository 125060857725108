import { SOGetItem } from '../item/so-get-item';
import { SOGetTag } from '../tag/so-get-tag';


export class SOPutTemaga {

  constructor(
    public uid: string,
    public item: SOGetItem = null,
    public tag: SOGetTag = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutTemaga(
      'uid' in params ? params.uid : null,
      'item' in params ? params.item : null,
      'tag' in params ? params.tag : null,
    );
  }
}
