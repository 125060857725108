import { SOGetLine } from '../line/so-get-line';
import { SOGetTemloca } from '../temloca/so-get-temloca';
import { SOGetPickup } from '../pickup/so-get-pickup';
import { SOGetOrganization } from '../organization/so-get-organization';
import {MMQueryOptions} from '../../../engine/multiverse/mm-query-options';
import {QueryProtocol} from "../../../engine/protocols/query-protocol";


export class SOGetDispatch implements QueryProtocol {

  constructor(
    public uid: string = null,
    public isAllocation: boolean = null,
    public line: SOGetLine = null,
    public temloca: SOGetTemloca = null,
    public pickup: SOGetPickup = null,
    public organization: SOGetOrganization = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetDispatch(
      'uid' in params ? params.uid : null,
      'isAllocation' in params ? params.isAllocation : null,
      'line' in params ? params.line : null,
      'temloca' in params ? params.temloca : null,
      'pickup' in params ? params.pickup : null,
      'organization' in params ? params.organization : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
