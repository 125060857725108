import {SOPostOrder} from '../../core/order/so-post-order';
import {OrderCore} from './order.core';
import {SOGetOrder} from '../../core/order/so-get-order';
import {FormField, SelectionOption} from '../../../engine/shared/form-field';
import {postOrderFormFieldOptions} from './post-order-form-fields';
import {AdapterProtocol} from '../../../engine/shared/entity-construction/adapter-protocol';
import {BasePost} from '../../../engine/shared/entity-construction/base-post';
import {DistributorCore} from '../bv-distributor/distributor.core';
import {SOGetDistributor} from '../../core/distributor/so-get-distributor';
import {OrganizationCore} from '../bv-organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';

export class RAPostOrder extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postOrderFormFieldOptions);

  RequestClass = SOPostOrder

  constructor(
    public entityCore: OrderCore
  ) {
    super();
    if (postOrderFormFieldOptions.distributor) {
      postOrderFormFieldOptions.distributor.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('ref'),
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier(
        'distributor',
        SOGetDistributor,
      ),
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
      this.getFormValueFromIdentifier('notes'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}