import {SOPostBulkitem} from '../../core/bulkitem/so-post-bulkitem';
import {BulkitemCore} from './bulkitem.core';
import {SOGetBulkitem} from '../../core/bulkitem/so-get-bulkitem';
import {FormField, SelectionOption} from '../../../engine/shared/form-field';
import {postBulkitemFormFieldOptions} from './post-bulkitem-form-fields';
import {AdapterProtocol} from '../../../engine/shared/entity-construction/adapter-protocol';
import {BasePost} from '../../../engine/shared/entity-construction/base-post';
import {OrganizationCore} from '../bv-organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';

export class RAPostBulkitem extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postBulkitemFormFieldOptions);

  RequestClass = SOPostBulkitem

  constructor(
    public entityCore: BulkitemCore
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('data'),
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}