import { Component, OnInit } from '@angular/core';
import {AbstractAttributeView} from "../../../shared/abstract-attribute-view";

@Component({
  selector: 'app-sv-embedded-entity-attributes-attribute-view',
  templateUrl: './sv-embedded-entity-attributes-attribute-view.component.html',
  styleUrls: ['./sv-embedded-entity-attributes-attribute-view.component.scss']
})
export class SvEmbeddedEntityAttributesAttributeViewComponent extends AbstractAttributeView {

}
