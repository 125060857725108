import { Type } from 'class-transformer';
import { SORespMedia } from '../media/so-resp-media';
import { SORespItem } from '../item/so-resp-item';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ObjectiveProtocol} from '../../../engine/protocols/objective-protocol';
import {EntityType} from "../../entity-types";


export class SORespAttachment implements ObjectiveProtocol {

  entityType: EntityType = EntityType.attachment;
  uid: string;
  @Type(() => SORespMedia)
  media: SORespMedia;
  @Type(() => SORespItem)
  item: SORespItem;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;
}