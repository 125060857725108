import {ActiveUserService} from '../../../engine/shared/active-user/active-user.service';
import {HttpClient} from '@angular/common/http';
import {EOOrganizationInviteService} from '../../core/organization-invite/eo-organization-invite';
import {OrganizationInviteListEntryFactory} from './organization-invite-list-entry-factory';
import {OrganizationInviteDetailFactory} from './organization-invite-detail-factory';
import {SOGetOrganizationInvite} from '../../core/organization-invite/so-get-organization-invite';
import {RAPutOrganizationInvite} from './ra-put-organization-invite';
import {RAPostOrganizationInvite} from './ra-post-organization-invite';
import {RADeleteOrganizationInvite} from './ra-delete-organization-invite';
import {AbstractEntityCore} from "../../../engine/shared/entity-view/abstract-entity-core";
import {EntityCoreProtocol} from "../../../engine/shared/entity-view/entity-core-protocol";
import {EntityType} from '../../entity-types';
import {SPECTER_SPEC} from "../../specter-spec";

export class OrganizationInviteCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = EntityType.organizationInvite;
  title = 'Organization invite';
  listEntryFactory = new OrganizationInviteListEntryFactory();
  detailFactory = new OrganizationInviteDetailFactory();

  entityService: EOOrganizationInviteService;
  soGetClass = SOGetOrganizationInvite;
  soGet: SOGetOrganizationInvite;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOOrganizationInviteService
    );
    this.adapters = [
      new RAPostOrganizationInvite(this),
      new RAPutOrganizationInvite(this),
      new RADeleteOrganizationInvite(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetOrganizationInvite();
    this.soGet.queryOptions.limit = this.limit;
  }


}