import { Type } from 'class-transformer';
import { SORespUser } from '../user/so-resp-user';
import { SORespRole } from '../role/so-resp-role';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ENInviteStatus} from '../../enums';
import {ObjectiveProtocol} from '../../../engine/protocols/objective-protocol';
import {EntityType} from "../../entity-types";


export class SORespOrganizationInvite implements ObjectiveProtocol {

  entityType: EntityType = EntityType.organizationInvite;
  uid: string;
  @Type(() => Date)
  dateCreated: Date;
  email: string;
  status: ENInviteStatus = ENInviteStatus.sent;
  @Type(() => SORespUser)
  user: SORespUser = null;
  @Type(() => SORespRole)
  role: SORespRole;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;
}