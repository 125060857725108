import {FormField, FormFieldType, SelectionOption} from '../../../engine/shared/form-field';
import {EntityType} from "../../entity-types";
import {ENTokenStatus} from '../../enums';


const statusField = new FormField(
  'status',
  'Status',
  null,
  FormFieldType.enumeration,
  false,
)
statusField.enumeration = ENTokenStatus


export const putTokenFormFieldOptions: {[key: string]: FormField} = {
  status: statusField,
};