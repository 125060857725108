import { Type } from 'class-transformer';
import { SORespItem } from '../item/so-resp-item';
import { SORespTag } from '../tag/so-resp-tag';
import {ObjectiveProtocol} from '../../../engine/protocols/objective-protocol';
import {EntityType} from "../../entity-types";


export class SORespTemaga implements ObjectiveProtocol {

  entityType: EntityType = EntityType.temaga;
  uid: string;
  @Type(() => SORespItem)
  item: SORespItem;
  @Type(() => SORespTag)
  tag: SORespTag;
}