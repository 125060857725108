import {EntityViewSpecProtocol} from "./entity-view/entity-view-spec-protocol";
import {EntityType} from "../../app/entity-types";
import {displayOptions} from "../../app/display-options";
import {EntityCoreProtocol} from "./entity-view/entity-core-protocol";
import {DisplayVectorStringFactory} from "../config/display-vector";


export function getHeading(approachedFromCore: EntityCoreProtocol, viewingCore: EntityCoreProtocol) {

  let heading

  const bundle = displayOptions.find(obj => obj.entityType == viewingCore.type)
  if (bundle) {
    const headingOption = bundle.makeSectionHeading(
      approachedFromCore ? approachedFromCore.type : null
    );
    if (headingOption) {
      heading = headingOption
    }
  }

  return heading ?  stringSentenceCase(heading) : null
}

export function getListEntryHeading(parentEntityViewSpec: EntityViewSpecProtocol, entityCore: EntityCoreProtocol, entity) {

  const stringFactory = new DisplayVectorStringFactory(
    displayOptions,
    entity,
    parentEntityViewSpec && parentEntityViewSpec.entityCore? parentEntityViewSpec.entityCore.type : null
  )
  return stringFactory.primaryString

}


export function getListEntrySublines(parentEntityViewSpec: EntityViewSpecProtocol, entityCore: EntityCoreProtocol, entity) {

  const stringFactory = new DisplayVectorStringFactory(
    displayOptions,
    entity,
    parentEntityViewSpec && parentEntityViewSpec.entityCore? parentEntityViewSpec.entityCore.type : null
  )
  return stringFactory.secondaryStrings
}


export function stringSentenceCase(str) {
  return str.replace(/\.\s+([a-z])[^\.]|^(\s*[a-z])[^\.]/g, s => s.replace(/([a-z])/, s => s.toUpperCase()))
}
