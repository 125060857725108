import {SOPutCustomField} from '../../core/custom-field/so-put-custom-field';
import {CustomFieldCore} from './custom-field.core';
import {SOGetCustomField} from '../../core/custom-field/so-get-custom-field';
import {FormField, SelectionOption} from '../../../engine/shared/form-field';
import {putCustomFieldFormFieldOptions} from './put-custom-field-form-fields';
import {AdapterProtocol} from '../../../engine/shared/entity-construction/adapter-protocol';
import {BasePut} from '../../../engine/shared/entity-construction/base-put';

export class RAPutCustomField extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putCustomFieldFormFieldOptions);

  RequestClass = SOPutCustomField

  constructor(
    public entityCore: CustomFieldCore
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier('autoApply'),
      this.getFormValueFromIdentifier('active'),
    )
  }

  prepareRequestWithQuickCreate(value) {
    this.prepareRequest();
    this.request.name = value
  }

  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}