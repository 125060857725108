import {MMQueryOptions} from '../../../engine/multiverse/mm-query-options';


export class SODeleteQuestion {

  constructor(
    public uid: string,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
}
