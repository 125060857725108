import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';

import { SOPostSection } from './so-post-section';
import { SOGetSection } from './so-get-section';
import { SOPutSection } from './so-put-section';
import { SODeleteSection } from './so-delete-section';
import {SORespSection} from './so-resp-section';
import {SOPluralSection} from './so-plural-section';

import {MMEntityService} from '../../../engine/multiverse/mm-entity-service';
import {EntityCoreProtocol} from '../../../engine/shared/entity-view/entity-core-protocol';
import {MMEntityServiceBase} from '../../../engine/multiverse/mm-entity-service-base';
import {HttpHeaders} from "@angular/common/http";


export class EOSectionService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'section',
      entityCore
    );
  }

  /** GET Section by id. Will 404 if id not found */
  get(request: SOGetSection): Observable<SOPluralSection> {
    return this.httpClient.get<SOPluralSection>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralSection, resp) }),
      tap(_ => this.log(`fetched Section`)),
      catchError(this.handleError<SOPluralSection>(`get Section`))
    );
  }

  /** POST: add a new Section to the server */
  post(request: SOPostSection): Observable<SORespSection> {
    return this.httpClient.post<SORespSection>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespSection, resp) }),
      tap((response: SORespSection) => this.log(`added Section w/ id=${response.uid}`)),
      catchError(this.handleError<SORespSection>('Section post'))
    );
  }

  /** PUT: update Section  */
  put(request: SOPutSection): Observable<SORespSection> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespSection>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespSection, resp) }),
      tap((response: SORespSection) => this.log(`edited Section w/ uid=${uid}`)),
      catchError(this.handleError<SORespSection>('Section put'))
    );
  }

  /** DELETE: delete the Section from the server */
  delete(request: SODeleteSection): Observable<SORespSection> {
    return this.httpClient.delete<SORespSection>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Section uid=${request.uid}`)),
      catchError(this.handleError<SORespSection>('delete Section'))
    );
  }
}
