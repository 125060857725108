import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';

import { SOPostPrice } from './so-post-price';
import { SOGetPrice } from './so-get-price';
import { SOPutPrice } from './so-put-price';
import { SODeletePrice } from './so-delete-price';
import {SORespPrice} from './so-resp-price';
import {SOPluralPrice} from './so-plural-price';

import {MMEntityService} from '../../../engine/multiverse/mm-entity-service';
import {EntityCoreProtocol} from '../../../engine/shared/entity-view/entity-core-protocol';
import {MMEntityServiceBase} from '../../../engine/multiverse/mm-entity-service-base';
import {HttpHeaders} from "@angular/common/http";


export class EOPriceService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'price',
      entityCore
    );
  }

  /** GET Price by id. Will 404 if id not found */
  get(request: SOGetPrice): Observable<SOPluralPrice> {
    return this.httpClient.get<SOPluralPrice>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralPrice, resp) }),
      tap(_ => this.log(`fetched Price`)),
      catchError(this.handleError<SOPluralPrice>(`get Price`))
    );
  }

  /** POST: add a new Price to the server */
  post(request: SOPostPrice): Observable<SORespPrice> {
    return this.httpClient.post<SORespPrice>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespPrice, resp) }),
      tap((response: SORespPrice) => this.log(`added Price w/ id=${response.uid}`)),
      catchError(this.handleError<SORespPrice>('Price post'))
    );
  }

  /** PUT: update Price  */
  put(request: SOPutPrice): Observable<SORespPrice> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespPrice>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespPrice, resp) }),
      tap((response: SORespPrice) => this.log(`edited Price w/ uid=${uid}`)),
      catchError(this.handleError<SORespPrice>('Price put'))
    );
  }

  /** DELETE: delete the Price from the server */
  delete(request: SODeletePrice): Observable<SORespPrice> {
    return this.httpClient.delete<SORespPrice>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Price uid=${request.uid}`)),
      catchError(this.handleError<SORespPrice>('delete Price'))
    );
  }
}
