<form [formGroup]="form">

  <h3 *ngIf="!detail.editEnabled"
      [ngClass]="{'warn': detail.warn}">
    {{detail.name}}: {{detail.value}}
  </h3>

<!--  <mat-form-field *ngIf="detail.editEnabled"-->
<!--                  appearance="{{detail.editableFormField.appearance}}"-->
<!--                  color="{{detail.editableFormField.color}}">-->
<!--    <mat-label>{{detail.editableFormField.getLabel(parentView.entityViewSpec)}}</mat-label>-->
<!--    <input matInput [ngxMatDatetimePicker]="picker2"-->
<!--           [formControlName]="detail.editableFormField.identifierString"-->
<!--           (dateChange)="onSelectionChange()">-->
<!--    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>-->
<!--    <ngx-mat-datetime-picker #picker2-->
<!--                             (change)="onSelectionChange()"></ngx-mat-datetime-picker>-->
<!--  </mat-form-field>-->

</form>
