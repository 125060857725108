import {SOPostOrganization} from '../../core/organization/so-post-organization';
import {OrganizationCore} from './organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {FormField, SelectionOption} from '../../../engine/shared/form-field';
import {postOrganizationFormFieldOptions} from './post-organization-form-fields';
import {AdapterProtocol} from '../../../engine/shared/entity-construction/adapter-protocol';
import {BasePost} from '../../../engine/shared/entity-construction/base-post';

export class RAPostOrganization extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postOrganizationFormFieldOptions);

  RequestClass = SOPostOrganization

  constructor(
    public entityCore: OrganizationCore
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier('identifier'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}