import {FormField, FormFieldType, SelectionOption} from '../../../engine/shared/form-field';
import {EntityType} from "../../entity-types";


const refField = new FormField(
  'ref',
  'Ref',
  null,
  FormFieldType.text,
  true,
)


export const postPickupFormFieldOptions: {[key: string]: FormField} = {
  ref: refField,
};