import {Component, Input, OnInit} from '@angular/core';
import {SvListComponent} from "../../entity/sv-list/sv-list.component";
import {ListEntry} from "../../../shared/list-entry";

@Component({
  selector: 'app-sv-chip-list',
  templateUrl: './sv-chip-list.component.html',
  styleUrls: ['./sv-chip-list.component.scss']
})
export class SvChipListComponent {

  @Input()
  set parent(value: SvListComponent) {
    this._parent = value
  }
  get parent(): SvListComponent {
    return  this._parent
  }
  _parent: SvListComponent

  constructor() { }

  makeEmbeddedPostSpec() {
    const spec = this.parent.entityViewSpec.makeEmbeddedPostSpec()
    spec.narrow = true;
    spec.showSubmitButton = false;
    spec.chipListAdd = true;
    return spec;
  }

  getHeading(listEntry: ListEntry) {
    return listEntry && listEntry.lineHeading.length > 0 ? listEntry.lineHeading[0] : null
  }

}
