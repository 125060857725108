import {SelectionOption} from './form-field';
import {LaunchButton, MenuItem} from "../protocols/header-protocol";
import {FormControl, FormGroup} from "@angular/forms";
import {EntityViewSpecProtocol} from "./entity-view/entity-view-spec-protocol";
import {SvHeaderComponent} from "../views/structure/sv-header/sv-header.component";


export interface ButtonPressProtocol {

  buttonPress(button: Button): void;
}

export class Button {

  show: boolean = false;
  disabled: boolean = false;
  buttonPressHandler: ButtonPressProtocol;
  header: SvHeaderComponent;
  private _icon: string;
  get icon() {
    return this._icon;
  }

  constructor(
    public identifier: string,
    public displayText: string,
    public displayIcon: string = null,
    public options: SelectionOption = null,
    public toggleIcon: string = null
  ) {
    this._icon = this.displayIcon;
  }

  click() {
    if (this.icon == this.displayIcon) {
      this._icon = this.toggleIcon
    } else {
      this._icon = this.displayIcon
    }
    this.buttonPressHandler.buttonPress(this)
  }
}

export class HeaderSpec {

  searchEnabled: boolean = false;
  searchForm: FormGroup = new FormGroup({'search': new FormControl()});
  showSearchIcon: boolean = false;

  showBack: boolean = true;
  showCrumbs: boolean = false;

  showMenu: boolean = false;
  showDelete: boolean = true;
  deleteEnabled: boolean = false;
  menuItems: MenuItem[] = [];

  saveButton = new Button('save', 'Save', 'save')
  editButton = new Button('edit', 'Edit', 'create', null, 'edit_off')

  searchText: string = null

  buttons: Button[] = []
  showFilter = false;
  filterOpenInitial = false;
  showDetailsButtonEnabled = false;

  constructor(
    public heading: string = '',
    public showCloseButton: boolean = false,
    public showPostButton = false,
  ) {
  }
}
