import {SORespPickup} from '../../core/pickup/so-resp-pickup';
import {PickupCore} from './pickup.core';
import {ListEntry} from '../../../engine/shared/list-entry';
import {EntityAttributesForDisplay} from '../../../engine/shared/entity-attributes-for-display';
import {DetailEntry, DetailFactory, DetailFactoryProtocol, EditableType} from '../../../engine/shared/detail-entry';
import {ActiveUserService} from '../../../engine/shared/active-user/active-user.service';
import {BaseDetailType} from "../../../engine/shared/base-detail";
import {EntityViewSpecProtocol} from "../../../engine/shared/entity-view/entity-view-spec-protocol";
import {getHeading} from "../../../engine/shared/display-option-utilities";
import {putPickupFormFieldOptions} from './put-pickup-form-fields';
import {SOGetPickup} from '../../core/pickup/so-get-pickup';
import {SOGetDispatch} from '../../core/dispatch/so-get-dispatch';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {DisplayVectorStringFactory} from "../../../engine/config/display-vector";
import {displayOptions} from "../../display-options";
import {ListSpecType} from "../../../engine/shared/entity-view/list-spec";
import {SPECTER_SPEC} from "../../specter-spec";
import {getCore} from "../../../engine/shared/entity-view/get-core";

export class PickupDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespPickup = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(displayOptions);
    let heading;

    const attributes = []

    const refDetail = new DetailEntry(
        'Ref',
        entity.ref,
        'ref',
        BaseDetailType.text,
        null,
        EditableType.always,
        putPickupFormFieldOptions.ref
    )
    attributes.push(refDetail)

    const statusDetail = new DetailEntry(
        'Status',
        entity.status,
        'status',
        BaseDetailType.toggleGroup,
        null,
        EditableType.always,
        putPickupFormFieldOptions.status
    )
    attributes.push(statusDetail)

    const dateCreatedDetail = new DetailEntry(
        'Date created',
        activeUserService.time.niceDate(entity.dateCreated),
        'dateCreated',
        BaseDetailType.text,
        null,
        EditableType.never,
    )
    attributes.push(dateCreatedDetail)


    const dispatchesCore = getCore(SPECTER_SPEC, activeUserService, listEntry.entityCore.httpClient, 'DispatchCore');
    dispatchesCore.soGet = SOGetDispatch.construct({
      pickup: new SOGetPickup(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, dispatchesCore)
    dispatchesCore.title = heading ? heading : dispatchesCore.title
    dispatchesCore.listTitle = 'Dispatches'
    dispatchesCore.showAdd = false;

    dispatchesCore.configureForDisplay(entityViewSpec)

    const dispatchesDetail = new DetailEntry(
      heading ? heading : dispatchesCore.listTitle,
      null,
      'dispatches',
      BaseDetailType.embeddedList,
      dispatchesCore,
      EditableType.never
    )
    attributes.push(dispatchesDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}