import { Type } from 'class-transformer';
import { SORespProduct } from '../product/so-resp-product';
import {ENInterval} from '../../enums';
import {ObjectiveProtocol} from '../../../engine/protocols/objective-protocol';
import {EntityType} from "../../entity-types";


export class SORespPrice implements ObjectiveProtocol {

  entityType: EntityType = EntityType.price;
  uid: string;
  name: string;
  sku: string;
  trailDays: number = null;
  @Type(() => Date)
  dateUpdated: Date;
  @Type(() => Date)
  dateCreated: Date;
  exRef: string = null;
  amount: number;
  interval: ENInterval;
  active: boolean = true;
  @Type(() => SORespProduct)
  product: SORespProduct;
}