import {SOPutTemfield} from '../../core/temfield/so-put-temfield';
import {TemfieldCore} from './temfield.core';
import {SOGetTemfield} from '../../core/temfield/so-get-temfield';
import {FormField, SelectionOption} from '../../../engine/shared/form-field';
import {putTemfieldFormFieldOptions} from './put-temfield-form-fields';
import {AdapterProtocol} from '../../../engine/shared/entity-construction/adapter-protocol';
import {BasePut} from '../../../engine/shared/entity-construction/base-put';

export class RAPutTemfield extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putTemfieldFormFieldOptions);

  RequestClass = SOPutTemfield

  constructor(
    public entityCore: TemfieldCore
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('value'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}