import { SOGetItem } from '../item/so-get-item';
import { SOGetDistributor } from '../distributor/so-get-distributor';


export class SOPutTemdisa {

  constructor(
    public uid: string,
    public item: SOGetItem = null,
    public distributor: SOGetDistributor = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutTemdisa(
      'uid' in params ? params.uid : null,
      'item' in params ? params.item : null,
      'distributor' in params ? params.distributor : null,
    );
  }
}
