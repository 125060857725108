import {SORespItem} from '../../core/item/so-resp-item';
import {ItemCore} from './item.core';
import {ListEntry} from '../../../engine/shared/list-entry';
import {EntityAttributesForDisplay} from '../../../engine/shared/entity-attributes-for-display';
import {DetailEntry, DetailFactory, DetailFactoryProtocol, EditableType} from '../../../engine/shared/detail-entry';
import {ActiveUserService} from '../../../engine/shared/active-user/active-user.service';
import {BaseDetailType} from "../../../engine/shared/base-detail";
import {EntityViewSpecProtocol} from "../../../engine/shared/entity-view/entity-view-spec-protocol";
import {getHeading} from "../../../engine/shared/display-option-utilities";
import {putItemFormFieldOptions} from './put-item-form-fields';
import {SOGetItem} from '../../core/item/so-get-item';
import {SOGetTemaga} from '../../core/temaga/so-get-temaga';
import {SOGetTemdisa} from '../../core/temdisa/so-get-temdisa';
import {SOGetTemloca} from '../../core/temloca/so-get-temloca';
import {SOGetTemfield} from '../../core/temfield/so-get-temfield';
import {SOGetAttachment} from '../../core/attachment/so-get-attachment';
import {SOGetLine} from '../../core/line/so-get-line';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {DisplayVectorStringFactory} from "../../../engine/config/display-vector";
import {displayOptions} from "../../display-options";
import {ListSpecType} from "../../../engine/shared/entity-view/list-spec";
import {SPECTER_SPEC} from "../../specter-spec";
import {getCore} from "../../../engine/shared/entity-view/get-core";

export class ItemDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespItem = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(displayOptions);
    let heading;

    const attributes = []

    const nameDetail = new DetailEntry(
        'Name',
        entity.name,
        'name',
        BaseDetailType.text,
        null,
        EditableType.always,
        putItemFormFieldOptions.name
    )
    attributes.push(nameDetail)

    const descriptionDetail = new DetailEntry(
        'Description',
        entity.description,
        'description',
        BaseDetailType.text,
        null,
        EditableType.always,
        putItemFormFieldOptions.description
    )
    attributes.push(descriptionDetail)

    const statusDetail = new DetailEntry(
        'Status',
        entity.status,
        'status',
        BaseDetailType.toggleGroup,
        null,
        EditableType.always,
        putItemFormFieldOptions.status
    )
    attributes.push(statusDetail)

    const quantityDetail = new DetailEntry(
        'Quantity',
        entity.quantity,
        'quantity',
        BaseDetailType.text,
        null,
        EditableType.never,
    )
    attributes.push(quantityDetail)

    const dateCreatedDetail = new DetailEntry(
        'Date created',
        activeUserService.time.niceDate(entity.dateCreated),
        'dateCreated',
        BaseDetailType.text,
        null,
        EditableType.never,
    )
    dateCreatedDetail.showAsSecondaryDetail = true;
    attributes.push(dateCreatedDetail)

    const dateUpdatedDetail = new DetailEntry(
        'Date updated',
        activeUserService.time.niceDate(entity.dateUpdated),
        'dateUpdated',
        BaseDetailType.text,
        null,
        EditableType.never,
    )
    dateUpdatedDetail.showAsSecondaryDetail = true;
    attributes.push(dateUpdatedDetail)

    const barCodeDetail = new DetailEntry(
        'Barcode or reference #',
        entity.barCode,
        'barCode',
        BaseDetailType.text,
        null,
        EditableType.always,
        putItemFormFieldOptions.barCode
    )
    attributes.push(barCodeDetail)

    const retailPriceDetail = new DetailEntry(
        'Sale price',
        entity.retailPrice,
        'retailPrice',
        BaseDetailType.text,
        null,
        EditableType.always,
        putItemFormFieldOptions.retailPrice
    )
    attributes.push(retailPriceDetail)

    const costDetail = new DetailEntry(
        'Cost',
        entity.cost,
        'cost',
        BaseDetailType.text,
        null,
        EditableType.always,
        putItemFormFieldOptions.cost
    )
    attributes.push(costDetail)


    const tagsCore = getCore(SPECTER_SPEC, activeUserService, listEntry.entityCore.httpClient, 'TemagaCore');
    tagsCore.soGet = SOGetTemaga.construct({
      item: new SOGetItem(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, tagsCore)
    tagsCore.title = heading ? heading : tagsCore.title
    tagsCore.listTitle = 'Tags'
    tagsCore.listSpecType = ListSpecType.chipList

    tagsCore.configureForDisplay(entityViewSpec)

    const tagsDetail = new DetailEntry(
      heading ? heading : tagsCore.listTitle,
      null,
      'tags',
      BaseDetailType.embeddedList,
      tagsCore,
      EditableType.never
    )
    attributes.push(tagsDetail);

    const distributorsCore = getCore(SPECTER_SPEC, activeUserService, listEntry.entityCore.httpClient, 'TemdisaCore');
    distributorsCore.soGet = SOGetTemdisa.construct({
      item: new SOGetItem(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, distributorsCore)
    distributorsCore.title = heading ? heading : distributorsCore.title
    distributorsCore.listTitle = 'Seller platforms'
    distributorsCore.listSpecType = ListSpecType.chipList

    distributorsCore.configureForDisplay(entityViewSpec)

    const distributorsDetail = new DetailEntry(
      heading ? heading : distributorsCore.listTitle,
      null,
      'distributors',
      BaseDetailType.embeddedList,
      distributorsCore,
      EditableType.never
    )
    attributes.push(distributorsDetail);

    const locationsCore = getCore(SPECTER_SPEC, activeUserService, listEntry.entityCore.httpClient, 'TemlocaCore');
    locationsCore.soGet = SOGetTemloca.construct({
      item: new SOGetItem(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, locationsCore)
    locationsCore.title = heading ? heading : locationsCore.title
    locationsCore.listTitle = 'Item locations'
    locationsCore.listSpecType = ListSpecType.chipList

    locationsCore.configureForDisplay(entityViewSpec)

    const locationsDetail = new DetailEntry(
      heading ? heading : locationsCore.listTitle,
      null,
      'locations',
      BaseDetailType.embeddedList,
      locationsCore,
      EditableType.never
    )
    attributes.push(locationsDetail);

    const customFieldsCore = getCore(SPECTER_SPEC, activeUserService, listEntry.entityCore.httpClient, 'TemfieldCore');
    customFieldsCore.soGet = SOGetTemfield.construct({
      item: new SOGetItem(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, customFieldsCore)
    customFieldsCore.title = heading ? heading : customFieldsCore.title
    customFieldsCore.listTitle = 'Custom fields'

    customFieldsCore.configureForDisplay(entityViewSpec)

    const customFieldsDetail = new DetailEntry(
      heading ? heading : customFieldsCore.listTitle,
      null,
      'customFields',
      BaseDetailType.embeddedList,
      customFieldsCore,
      EditableType.never
    )
    attributes.push(customFieldsDetail);

    const attachmentsCore = getCore(SPECTER_SPEC, activeUserService, listEntry.entityCore.httpClient, 'AttachmentCore');
    attachmentsCore.soGet = SOGetAttachment.construct({
      item: new SOGetItem(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, attachmentsCore)
    attachmentsCore.title = heading ? heading : attachmentsCore.title
    attachmentsCore.listTitle = 'Images'
    attachmentsCore.listSpecType = ListSpecType.thumbList

    attachmentsCore.configureForDisplay(entityViewSpec)

    const attachmentsDetail = new DetailEntry(
      heading ? heading : attachmentsCore.listTitle,
      null,
      'attachments',
      BaseDetailType.embeddedList,
      attachmentsCore,
      EditableType.never
    )
    attributes.push(attachmentsDetail);

    const linesCore = getCore(SPECTER_SPEC, activeUserService, listEntry.entityCore.httpClient, 'LineCore');
    linesCore.soGet = SOGetLine.construct({
      item: new SOGetItem(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, linesCore)
    linesCore.title = heading ? heading : linesCore.title
    linesCore.listTitle = 'Lines'

    linesCore.configureForDisplay(entityViewSpec)

    const linesDetail = new DetailEntry(
      heading ? heading : linesCore.listTitle,
      null,
      'lines',
      BaseDetailType.pushedList,
      linesCore,
      EditableType.never
    )
    attributes.push(linesDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}