

export class SOPutCustomField {

  constructor(
    public uid: string,
    public name: string = null,
    public autoApply: boolean = null,
    public active: boolean = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutCustomField(
      'uid' in params ? params.uid : null,
      'name' in params ? params.name : null,
      'autoApply' in params ? params.autoApply : null,
      'active' in params ? params.active : null,
    );
  }
}
