import {AbstractAdapter} from './abstract-adapter';
import {AdapterType} from './adapter-type';
import {FormField, SelectionOption} from "../form-field";
import {DisplayVectorStringFactory} from "../../config/display-vector";
import {displayOptions} from "../../../app/display-options";
import {SPECTER_SPEC} from "../../../app/specter-spec";
import {AdapterProtocol} from "./adapter-protocol";
import {BlobUploadCompleteProtocol} from "../../protocols/blob-upload-complete-protocol";
import {BlobStorageOptions} from "../blob-storage-options";
import {catchError} from "rxjs/operators";


export class BasePost extends AbstractAdapter implements AdapterProtocol {

  adapterType = AdapterType.post;

  submitForm() {
    console.log('Submitting form')

    const customProcessor = SPECTER_SPEC.postPrepareRequestProcessors.find(obj => obj.entityType === this.entityCore.type)

    if (!this.request && !customProcessor) {
      this.prepareRequest()
    } else if (customProcessor) {
      customProcessor.prepareRequest(this)
    }
    this.post(this.request);
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    const factory = new DisplayVectorStringFactory(
      displayOptions,
      entity,
      this.delegate.entityViewSpec.entityCore && this.delegate.entityViewSpec.entityCore ? this.delegate.entityViewSpec.entityCore.type : null
    )
    return new SelectionOption(factory.combinedString, entity.uid)
  }

  setParentEntityViewSpec() {
    super.setParentEntityViewSpec();
    if (
      this.delegate.entityViewSpec
      && this.delegate.entityViewSpec.parentEntityViewSpec
      && this.delegate.entityViewSpec.parentEntityViewSpec.parentEntityViewSpec
      && this.adapterType.valueOf() === AdapterType.post.valueOf()
    ) {
      this.parentEntityViewSpec = this.delegate.entityViewSpec.parentEntityViewSpec.parentEntityViewSpec;
    }
  }

  post(request, section: number = 0) {
    console.log('making post')
    this.requestInProgress = true;
    if (this.entityCore && this.entityCore.isMedia) {
      this.entityCore.entityService.postFile(
        request,
        this.file
      ).subscribe(resp => {
          this.finishRequest(resp, section);
        }
      );
    } else {
      this.entityCore.entityService.post(
        request
      ).subscribe(resp => {
          this.finishRequest(resp, section);
        }
      );
    }
  }

  private finishRequest(resp, section: number) {
    console.log('post request finished')
    this.requestInProgress = false;
    this.request = null;
    if (resp) {
      this.errorMessage = null;
      this.resetForm();
      const parentViewSpec = this.delegate.entityViewSpec && this.delegate.entityViewSpec.parentEntityViewSpec && this.delegate.entityViewSpec.parentEntityViewSpec.parentEntityViewSpec ? this.delegate.entityViewSpec.parentEntityViewSpec.parentEntityViewSpec : null;
      this.updateParent(resp);
      this.entityCore.postCompletePreListEntryReturn(resp, parentViewSpec);
      const listEntry = this.entityCore.makeListEntry(
        parentViewSpec,
        this.entityCore,
        resp,
        section
      );
      this.delegate.submitComplete(
        listEntry,
        false
      );
    }
  }
}
