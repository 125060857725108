import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {OAuthModule, OAuthStorage} from 'angular-oauth2-oidc';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatButtonModule} from '@angular/material/button';
import {SvSidenavComponent} from '../engine/views/structure/sv-sidenav/sv-sidenav.component';
import {RouterModule} from '@angular/router';
import {AppRoutingModule} from './app-routing.module';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatListModule} from '@angular/material/list';
import {SvNavBarComponent} from '../engine/views/structure/sv-nav-bar/sv-nav-bar.component';
import {MatCardModule} from '@angular/material/card';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AuthInterceptor} from './auth.interceptor';
import {MatSelectModule} from '@angular/material/select';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatMenuModule} from '@angular/material/menu';
import {MatTabsModule} from '@angular/material/tabs';
import {MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule} from '@angular/material/snack-bar';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatNativeDateModule} from '@angular/material/core';
import {MatStepperModule} from '@angular/material/stepper';
import {SvNavigatorComponent} from '../engine/views/structure/sv-navigator/sv-navigator.component';
import {SvListComponent} from '../engine/views/entity/sv-list/sv-list.component';
import {SvHeaderComponent} from '../engine/views/structure/sv-header/sv-header.component';
import {SvEntityViewComponent} from '../engine/views/entity/sv-entity-view/sv-entity-view.component';
import {SvPostComponent} from '../engine/views/entity/sv-post/sv-post.component';
import {ExcelService} from '../engine/shared/excel-service';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {SvDayComponent} from '../engine/views/sv-day/sv-day.component';
import {SvCalendarComponent} from '../engine/views/sv-calendar/sv-calendar.component';
import {SvEventComponent} from '../engine/views/sv-event/sv-event.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {SvContainerComponent} from "../engine/views/structure/sv-container/sv-container.component";
import {SvRegistrationComponent} from "../engine/views/pre-built/sv-registration/sv-registration.component";
import {SvLoginComponent} from "../engine/views/pre-built/sv-login/sv-login.component";
import {SvFacadeComponent} from '../engine/views/pre-built/sv-facade/sv-facade.component';
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {SvAutoCompleteComponent} from "../engine/views/entity/sv-auto-complete/sv-auto-complete.component";
import {MatChipsModule} from "@angular/material/chips";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import 'reflect-metadata';
import { SvUploadButtonComponent } from '../engine/views/structure/sv-upload-button/sv-upload-button.component';
import { SvSelectionListComponent } from '../engine/views/lists/sv-selection-list/sv-selection-list.component';
import { SvCheckListComponent } from '../engine/views/lists/sv-check-list/sv-check-list.component';
import { SvSimpleListComponent } from '../engine/views/lists/sv-simple-list/sv-simple-list.component';
import { SvColumnListComponent } from '../engine/views/lists/sv-column-list/sv-column-list.component';
import { SvAttributeViewComponent } from '../engine/views/attributes/sv-attribute-view/sv-attribute-view.component';
import { SvEntityAttributesViewComponent } from '../engine/views/entity/sv-entity-attributes-view/sv-entity-attributes-view.component';
import { SvTextAttributeViewComponent } from '../engine/views/attributes/sv-text-attribute-view/sv-text-attribute-view.component';
import { SvTextareaAttributeViewComponent } from '../engine/views/attributes/sv-textarea-attribute-view/sv-textarea-attribute-view.component';
import { SvMarkdownAttributeViewComponent } from '../engine/views/attributes/sv-markdown-attribute-view/sv-markdown-attribute-view.component';
import { SvSelectionAttributeViewComponent } from '../engine/views/attributes/sv-selection-attribute-view/sv-selection-attribute-view.component';
import { SvToggleAttributeViewComponent } from '../engine/views/attributes/sv-toggle-attribute-view/sv-toggle-attribute-view.component';
import { SvCheckboxAttributeViewComponent } from '../engine/views/attributes/sv-checkbox-attribute-view/sv-checkbox-attribute-view.component';
import { SvEmbeddedEntityListAttributeViewComponent } from '../engine/views/attributes/sv-embedded-entity-list-attribute-view/sv-embedded-entity-list-attribute-view.component';
import { SvPushedEntityListAttributeViewComponent } from '../engine/views/attributes/sv-pushed-entity-list-attribute-view/sv-pushed-entity-list-attribute-view.component';
import { SvPushedEntityAttributesAttributeViewComponent } from '../engine/views/attributes/sv-pushed-entity-attributes-attribute-view/sv-pushed-entity-attributes-attribute-view.component';
import { SvEmbeddedEntityAttributesAttributeViewComponent } from '../engine/views/attributes/sv-embedded-entity-attributes-attribute-view/sv-embedded-entity-attributes-attribute-view.component';
import { SvDatetimeAttributeViewComponent } from '../engine/views/attributes/sv-datetime-attribute-view/sv-datetime-attribute-view.component';
import { SvDateAttributeViewComponent } from '../engine/views/attributes/sv-date-attribute-view/sv-date-attribute-view.component';
import { SvLinkAttributeViewComponent } from '../engine/views/attributes/sv-link-attribute-view/sv-link-attribute-view.component';
import { SvFileAttributeViewComponent } from '../engine/views/attributes/sv-file-attribute-view/sv-file-attribute-view.component';
import { SvSignatureAttributeViewComponent } from '../engine/views/attributes/sv-signature-attribute-view/sv-signature-attribute-view.component';
import {SvChipListAttributeViewComponent} from "../engine/views/attributes/sv-chip-list-attribute-view/sv-chip-list-attribute-view.component";
import {SvChipListComponent} from "../engine/views/lists/sv-chip-list/sv-chip-list.component";
import {SvThumbListComponent} from "../engine/views/lists/sv-thumb-list/sv-thumb-list.component";
import {SvToggleGroupAttributeViewComponent} from "../engine/views/attributes/sv-toggle-group-attribute-view/sv-toggle-group-attribute-view.component";
import { SvTermsComponent } from '../engine/views/pre-built/sv-terms/sv-terms.component';
import { SvPrivacyComponent } from '../engine/views/pre-built/sv-privacy/sv-privacy.component';
import {MatTooltipModule} from "@angular/material/tooltip";
import {SvEditableListComponent} from "../engine/views/lists/sv-editable-list/sv-editable-list.component";
import {SvCardListComponent} from "../engine/views/lists/sv-card-list/sv-card-list.component";
import {SvEmailVerificationComponent} from "../engine/views/pre-built/sv-email-verification/sv-email-verification.component";
import {SvPasswordResetComponent} from "../engine/views/pre-built/sv-password-reset/sv-password-reset.component";
import {SvBannerComponent} from "../engine/views/structure/sv-banner/sv-banner.component";


@NgModule({
    imports: [
        BrowserModule,
        AppRoutingModule,
        OAuthModule.forRoot(),
        HttpClientModule,
        BrowserAnimationsModule,
        MatToolbarModule,
        MatIconModule,
        MatSidenavModule,
        MatButtonModule,
        RouterModule,
        FlexLayoutModule,
        MatListModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatMenuModule,
        MatTabsModule,
        MatSnackBarModule,
        MatCheckboxModule,
        MatNativeDateModule,
        MatStepperModule,
        MatButtonToggleModule,
        MatDatepickerModule,
        MatSlideToggleModule,
        MatChipsModule,
        MatAutocompleteModule,
        MatTooltipModule
    ],
  declarations: [
    AppComponent,
    SvSidenavComponent,
    SvNavBarComponent,
    SvHeaderComponent,
    SvNavigatorComponent,
    SvListComponent,
    SvEntityViewComponent,
    SvPostComponent,
    SvCalendarComponent,
    SvDayComponent,
    SvEventComponent,
    SvContainerComponent,
    SvRegistrationComponent,
    SvLoginComponent,
    SvFacadeComponent,
    SvAutoCompleteComponent,
    SvUploadButtonComponent,
    SvSelectionListComponent,
    SvCheckListComponent,
    SvSimpleListComponent,
    SvColumnListComponent,
    SvAttributeViewComponent,
    SvEntityAttributesViewComponent,
    SvTextAttributeViewComponent,
    SvTextareaAttributeViewComponent,
    SvMarkdownAttributeViewComponent,
    SvSelectionAttributeViewComponent,
    SvToggleAttributeViewComponent,
    SvToggleGroupAttributeViewComponent,
    SvCheckboxAttributeViewComponent,
    SvEmbeddedEntityListAttributeViewComponent,
    SvPushedEntityListAttributeViewComponent,
    SvPushedEntityAttributesAttributeViewComponent,
    SvEmbeddedEntityAttributesAttributeViewComponent,
    SvDatetimeAttributeViewComponent,
    SvDateAttributeViewComponent,
    SvLinkAttributeViewComponent,
    SvFileAttributeViewComponent,
    SvSignatureAttributeViewComponent,
    SvChipListAttributeViewComponent,
    SvChipListComponent,
    SvThumbListComponent,
    SvTermsComponent,
    SvPrivacyComponent,
    SvEditableListComponent,
    SvCardListComponent,
    SvEmailVerificationComponent,
    SvPasswordResetComponent,
    SvBannerComponent,
  ],
  providers: [
    // { provide: OAuthStorage, useValue: sessionStorage }
    {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 2500}},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    ExcelService
    // { provide: CdkStepper }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
