export class SideNavItem {

  constructor(
    public displayText: string,
    public data: any,
    public subText: string = null
  ) {
  }

}
