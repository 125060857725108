import {ActiveUserService} from '../../../engine/shared/active-user/active-user.service';
import {HttpClient} from '@angular/common/http';
import {EOItemImportLineService} from '../../core/item-import-line/eo-item-import-line';
import {ItemImportLineListEntryFactory} from './item-import-line-list-entry-factory';
import {ItemImportLineDetailFactory} from './item-import-line-detail-factory';
import {SOGetItemImportLine} from '../../core/item-import-line/so-get-item-import-line';
import {RAPutItemImportLine} from './ra-put-item-import-line';
import {RAPostItemImportLine} from './ra-post-item-import-line';
import {RADeleteItemImportLine} from './ra-delete-item-import-line';
import {AbstractEntityCore} from "../../../engine/shared/entity-view/abstract-entity-core";
import {EntityCoreProtocol} from "../../../engine/shared/entity-view/entity-core-protocol";
import {EntityType} from '../../entity-types';
import {SPECTER_SPEC} from "../../specter-spec";
import {SOGetOrganization} from '../../core/organization/so-get-organization'

export class ItemImportLineCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = EntityType.itemImportLine;
  title = 'Item import line';
  listEntryFactory = new ItemImportLineListEntryFactory();
  detailFactory = new ItemImportLineDetailFactory();

  entityService: EOItemImportLineService;
  soGetClass = SOGetItemImportLine;
  soGet: SOGetItemImportLine;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOItemImportLineService
    );
    this.adapters = [
      new RAPostItemImportLine(this),
      new RAPutItemImportLine(this),
      new RADeleteItemImportLine(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetItemImportLine();
    this.soGet.organization = new SOGetOrganization(
      this.activeUserService.getScope('organization').uid
    );
    this.soGet.queryOptions.limit = this.limit;
  }


}