import {Component, Input, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {DetailEntry} from '../../../shared/detail-entry';
import {FormFieldType} from '../../../shared/form-field';
import {BaseDetailType} from '../../../shared/base-detail';
import {SvEntityAttributesViewComponent} from "../../entity/sv-entity-attributes-view/sv-entity-attributes-view.component";
import {AttributeViewSpec} from "../../../shared/attribute-view-spec";
import {FormGroup} from "@angular/forms";
import {FormViewProtocol} from "../../../protocols/form-view-protocol";
import {AttributeViewType} from "../../../shared/attribute-view-type";
import {SPECTER_SPEC} from "../../../../app/specter-spec";

@Component({
  selector: 'app-sv-attribute-view',
  templateUrl: './sv-attribute-view.component.html',
  styleUrls: ['./sv-attribute-view.component.scss']
})
export class SvAttributeViewComponent {

  @ViewChild('container', {read: ViewContainerRef}) container: ViewContainerRef;

  FormFieldType = FormFieldType;
  BaseDetailType = BaseDetailType;

  AttributeViewType =AttributeViewType;

  private _attributeViewSpec: AttributeViewSpec
  @Input()
  set attributeViewSpec(value: AttributeViewSpec) {
    this._attributeViewSpec = value
    this.detail = value.detailEntry
    // console.log(this.detail)
    this.parent = value.parent
    this.form = value.from
  }
  get attributeViewSpec(): AttributeViewSpec {
    return this._attributeViewSpec
  }
  detail: DetailEntry;
  form: FormGroup;
  parent: FormViewProtocol;

  constructor() { }

  getShowView(viewType: AttributeViewType) {
    if (this.detail.editEnabled && this.detail.editableFormField) {
      return SPECTER_SPEC.formFieldTypeToViewTypeMap[FormFieldType[this.detail.editableFormField.type]] == viewType
    } else {
      return SPECTER_SPEC.detailTypeToViewTypeMap[BaseDetailType[this.detail.detailType]] == viewType
    }
  }
}
