import {ActiveUserService} from '../../../engine/shared/active-user/active-user.service';
import {HttpClient} from '@angular/common/http';
import {EORoleService} from '../../core/role/eo-role';
import {RoleListEntryFactory} from './role-list-entry-factory';
import {RoleDetailFactory} from './role-detail-factory';
import {SOGetRole} from '../../core/role/so-get-role';
import {RAPutRole} from './ra-put-role';
import {RAPostRole} from './ra-post-role';
import {RADeleteRole} from './ra-delete-role';
import {AbstractEntityCore} from "../../../engine/shared/entity-view/abstract-entity-core";
import {EntityCoreProtocol} from "../../../engine/shared/entity-view/entity-core-protocol";
import {EntityType} from '../../entity-types';
import {SPECTER_SPEC} from "../../specter-spec";

export class RoleCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = EntityType.role;
  title = 'Role';
  listEntryFactory = new RoleListEntryFactory();
  detailFactory = new RoleDetailFactory();

  entityService: EORoleService;
  soGetClass = SOGetRole;
  soGet: SOGetRole;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EORoleService
    );
    this.adapters = [
      new RAPostRole(this),
      new RAPutRole(this),
      new RADeleteRole(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetRole();
    this.soGet.queryOptions.limit = this.limit;
  }


}