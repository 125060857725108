import { Type } from 'class-transformer';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ENProgress} from '../../enums';
import {ObjectiveProtocol} from '../../../engine/protocols/objective-protocol';
import {EntityType} from "../../entity-types";


export class SORespPickup implements ObjectiveProtocol {

  entityType: EntityType = EntityType.pickup;
  uid: string;
  ref: string;
  status: ENProgress = ENProgress.pending;
  @Type(() => Date)
  dateCreated: Date;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;
}