import {AdapterType} from './adapter-type';
import {ObjectiveProtocol} from "../../protocols/objective-protocol";
import {DeleteCompleteProtocol} from "../../protocols/delete-complete-protocol";
import {EntityCoreProtocol} from "../entity-view/entity-core-protocol";
import {DeleteEntityProtocol} from "./delete-entity-protocol";

export class BaseDelete implements DeleteEntityProtocol {

  delegate: DeleteCompleteProtocol
  adapterType = AdapterType.delete;
  isDefault = true;

  deleteClass;
  requestInProgress = false;

  constructor(
    public entityCore: EntityCoreProtocol
  ) {

  }

  delete(entity: ObjectiveProtocol) {
    this.requestInProgress = true
    this.entityCore.entityService.delete(new this.deleteClass(entity.uid)).subscribe(resp => {
      console.log('delete request complete')
      this.requestInProgress = false
      if (resp) {
        this.delegate.deleteComplete();
      }
    });
  }

  requestError(request, message) {
    this.delegate.submitError(message)
  }
}
