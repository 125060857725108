import {SOPostItemImportLine} from '../../core/item-import-line/so-post-item-import-line';
import {ItemImportLineCore} from './item-import-line.core';
import {SOGetItemImportLine} from '../../core/item-import-line/so-get-item-import-line';
import {FormField, SelectionOption} from '../../../engine/shared/form-field';
import {postItemImportLineFormFieldOptions} from './post-item-import-line-form-fields';
import {AdapterProtocol} from '../../../engine/shared/entity-construction/adapter-protocol';
import {BasePost} from '../../../engine/shared/entity-construction/base-post';
import {ItemImportCore} from '../bv-item-import/item-import.core';
import {SOGetItemImport} from '../../core/item-import/so-get-item-import';
import {DispatchCore} from '../bv-dispatch/dispatch.core';
import {SOGetDispatch} from '../../core/dispatch/so-get-dispatch';
import {OrganizationCore} from '../bv-organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';

export class RAPostItemImportLine extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postItemImportLineFormFieldOptions);

  RequestClass = SOPostItemImportLine

  constructor(
    public entityCore: ItemImportLineCore
  ) {
    super();
    if (postItemImportLineFormFieldOptions.itemImport) {
      postItemImportLineFormFieldOptions.itemImport.makeCore = true;
    }
    if (postItemImportLineFormFieldOptions.dispatch) {
      postItemImportLineFormFieldOptions.dispatch.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('data'),
      this.getFormValueFromIdentifier(
        'itemImport',
        SOGetItemImport,
      ),
      this.getFormValueFromIdentifier(
        'dispatch',
        SOGetDispatch,
      ),
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}