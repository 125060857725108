<div fxLayout="row" fxLayoutAlign="start center" style="width: 100%; height: 100%">
  <h3>
    {{parent.headerSpec.heading}}:
  </h3>
  <mat-chip-list *ngIf="parent.entityViewSpec">
    <div *ngFor="let listEntry of parent.entityViewSpec.listBody.sections[0].listEntries">
      <mat-chip *ngIf="listEntry" (click)="parent.selectListEntry(listEntry)">
        {{getHeading(listEntry)}}
        <mat-icon matChipRemove (click)="parent.remove(listEntry)"
                  *ngIf="parent.entityViewSpec.entityCore.showAdd">
          cancel
        </mat-icon>
      </mat-chip>
    </div>
  </mat-chip-list>
  <app-sv-entity-view class="quick-add"
                      *ngIf="parent.entityViewSpec.entityCore.showQuickAdd && parent.entityViewSpec.entityCore.showAdd"
                      [entityViewSpec]="makeEmbeddedPostSpec()"
                      [parentRouteView]="parent.parentEntityViewComponent.parentRouteView"></app-sv-entity-view>

</div>
