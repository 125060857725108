import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';

import { SOPostProduct } from './so-post-product';
import { SOGetProduct } from './so-get-product';
import { SOPutProduct } from './so-put-product';
import { SODeleteProduct } from './so-delete-product';
import {SORespProduct} from './so-resp-product';
import {SOPluralProduct} from './so-plural-product';

import {MMEntityService} from '../../../engine/multiverse/mm-entity-service';
import {EntityCoreProtocol} from '../../../engine/shared/entity-view/entity-core-protocol';
import {MMEntityServiceBase} from '../../../engine/multiverse/mm-entity-service-base';
import {HttpHeaders} from "@angular/common/http";


export class EOProductService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'product',
      entityCore
    );
  }

  /** GET Product by id. Will 404 if id not found */
  get(request: SOGetProduct): Observable<SOPluralProduct> {
    return this.httpClient.get<SOPluralProduct>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralProduct, resp) }),
      tap(_ => this.log(`fetched Product`)),
      catchError(this.handleError<SOPluralProduct>(`get Product`))
    );
  }

  /** POST: add a new Product to the server */
  post(request: SOPostProduct): Observable<SORespProduct> {
    return this.httpClient.post<SORespProduct>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespProduct, resp) }),
      tap((response: SORespProduct) => this.log(`added Product w/ id=${response.uid}`)),
      catchError(this.handleError<SORespProduct>('Product post'))
    );
  }

  /** PUT: update Product  */
  put(request: SOPutProduct): Observable<SORespProduct> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespProduct>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespProduct, resp) }),
      tap((response: SORespProduct) => this.log(`edited Product w/ uid=${uid}`)),
      catchError(this.handleError<SORespProduct>('Product put'))
    );
  }

  /** DELETE: delete the Product from the server */
  delete(request: SODeleteProduct): Observable<SORespProduct> {
    return this.httpClient.delete<SORespProduct>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Product uid=${request.uid}`)),
      catchError(this.handleError<SORespProduct>('delete Product'))
    );
  }
}
