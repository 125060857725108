import { Type } from 'class-transformer';
import { SORespInvoice } from '../invoice/so-resp-invoice';
import {ENPaymentIntentStatus} from '../../enums';
import {ObjectiveProtocol} from '../../../engine/protocols/objective-protocol';
import {EntityType} from "../../entity-types";


export class SORespPaymentIntent implements ObjectiveProtocol {

  entityType: EntityType = EntityType.paymentIntent;
  uid: string;
  amount: number;
  @Type(() => Date)
  dateCreated: Date;
  status: ENPaymentIntentStatus;
  @Type(() => SORespInvoice)
  invoice: SORespInvoice;
}