import { SOGetItem } from '../item/so-get-item';
import { SOGetLocation } from '../location/so-get-location';
import { SOGetOrganization } from '../organization/so-get-organization';
import {MMQueryOptions} from '../../../engine/multiverse/mm-query-options';
import {QueryProtocol} from "../../../engine/protocols/query-protocol";


export class SOGetTemloca implements QueryProtocol {

  constructor(
    public uid: string = null,
    public item: SOGetItem = null,
    public location: SOGetLocation = null,
    public organization: SOGetOrganization = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetTemloca(
      'uid' in params ? params.uid : null,
      'item' in params ? params.item : null,
      'location' in params ? params.location : null,
      'organization' in params ? params.organization : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
