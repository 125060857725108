<div class="fill-container">
  <div class="center-box-outer">
    <mat-progress-bar *ngIf="loading && !userCreated" mode="indeterminate" color="primary"></mat-progress-bar>
    <div *ngIf="!loading || userCreated" class="progress-bar"></div>
    <div class="center-box-inner" fxLayout="column">
      <h1>Register</h1>
      <form class="main-form" *ngIf="!userCreated" [formGroup]="userCore.postHelper.form" fxLayout="column" (ngSubmit)="clickSubmitFormButton()">
        <app-sv-attribute-view *ngFor="let formField of userCore.postHelper.formFields"
                               class="field" [attributeViewSpec]="makeAttributeViewSpec(formField)">
        </app-sv-attribute-view>
        <button mat-stroked-button type="submit"
                color="accent" [disabled]="!userCore.postHelper.form.valid || userCore.postHelper.submitDisabled">Submit</button>
      </form>
      <div *ngIf="userCreated" class="body" fxLayout="column" fxLayoutAlign="start center">
        <mat-progress-spinner mode="indeterminate" color="primary" [diameter]="50" [strokeWidth]="3"></mat-progress-spinner>
        <h5 class="progress-message">{{progressMessage}}</h5>
      </div>
      <button mat-stroked-button *ngIf="!loading" class="cancel-button" (click)="cancel()" color="primary">Cancel</button>
      <h6 *ngIf="errorMessage" class="body">{{errorMessage}}</h6>
    </div>
  </div>
</div>
