import {SORespPaymentIntent} from '../../core/payment-intent/so-resp-payment-intent';
import {PaymentIntentCore} from './payment-intent.core';
import {ListEntry} from '../../../engine/shared/list-entry';
import {EntityAttributesForDisplay} from '../../../engine/shared/entity-attributes-for-display';
import {DetailEntry, DetailFactory, DetailFactoryProtocol, EditableType} from '../../../engine/shared/detail-entry';
import {ActiveUserService} from '../../../engine/shared/active-user/active-user.service';
import {BaseDetailType} from "../../../engine/shared/base-detail";
import {EntityViewSpecProtocol} from "../../../engine/shared/entity-view/entity-view-spec-protocol";
import {getHeading} from "../../../engine/shared/display-option-utilities";
import {putPaymentIntentFormFieldOptions} from './put-payment-intent-form-fields';
import {SOGetPaymentIntent} from '../../core/payment-intent/so-get-payment-intent';
import {SOGetInvoice} from '../../core/invoice/so-get-invoice';
import {DisplayVectorStringFactory} from "../../../engine/config/display-vector";
import {displayOptions} from "../../display-options";
import {ListSpecType} from "../../../engine/shared/entity-view/list-spec";
import {SPECTER_SPEC} from "../../specter-spec";
import {getCore} from "../../../engine/shared/entity-view/get-core";

export class PaymentIntentDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespPaymentIntent = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(displayOptions);
    let heading;

    const attributes = []

    const amountDetail = new DetailEntry(
        'Amount',
        entity.amount,
        'amount',
        BaseDetailType.text,
        null,
        EditableType.always,
        putPaymentIntentFormFieldOptions.amount
    )
    attributes.push(amountDetail)

    const dateCreatedDetail = new DetailEntry(
        'Date created',
        activeUserService.time.niceDate(entity.dateCreated),
        'dateCreated',
        BaseDetailType.text,
        null,
        EditableType.never,
    )
    attributes.push(dateCreatedDetail)

    const statusDetail = new DetailEntry(
        'Status',
        entity.status,
        'status',
        BaseDetailType.toggleGroup,
        null,
        EditableType.always,
        putPaymentIntentFormFieldOptions.status
    )
    attributes.push(statusDetail)


    const invoiceCore = getCore(SPECTER_SPEC, activeUserService, listEntry.entityCore.httpClient, 'InvoiceCore');
    invoiceCore.soGet = SOGetInvoice.construct({
    });
    heading = getHeading(entityViewSpec.entityCore, invoiceCore)
    invoiceCore.title = heading ? heading : invoiceCore.title
    invoiceCore.listTitle = 'Invoice'

    if (entity.invoice) {
        displayStringFactory.reconfigure(entity.invoice, entityViewSpec.entityCore.type)
    }
    invoiceCore.configureForDisplay(entityViewSpec)

    const invoiceDetail = new DetailEntry(
      heading ? heading : invoiceCore.listTitle,
      entity.invoice ? displayStringFactory.primaryString : null,
      'invoice',
      BaseDetailType.pushedDetail,
      invoiceCore,
      EditableType.always,
      putPaymentIntentFormFieldOptions.invoice
    )
    if (entity.invoice) {
      invoiceDetail.cachedListEntry = invoiceCore.makeListEntry(entityViewSpec, invoiceCore, entity.invoice, 0)
    }
    invoiceDetail.singleFieldSelect = true
    attributes.push(invoiceDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}