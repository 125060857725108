import {SORespOrder} from '../../core/order/so-resp-order';
import {OrderCore} from './order.core';
import { ListEntry, ListEntryFactory, ListEntryFactoryProtocol} from '../../../engine/shared/list-entry';
import {EntityCoreProtocol} from "../../../engine/shared/entity-view/entity-core-protocol";


export class OrderListEntryFactory  extends ListEntryFactory implements ListEntryFactoryProtocol {

  makeListEntry(parentCoreViewSpec, entityCore: EntityCoreProtocol, entity: SORespOrder, section: number = 0): ListEntry {
    const resp = super.makeListEntry(parentCoreViewSpec, entityCore, entity, section)
    return resp
  }
}