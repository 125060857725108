import { SOGetItem } from '../item/so-get-item';
import { SOGetLocation } from '../location/so-get-location';
import { SOGetOrganization } from '../organization/so-get-organization';


export class SOPostTemloca {

  constructor(
    public quantity: number,
    public item: SOGetItem,
    public location: SOGetLocation,
    public organization: SOGetOrganization,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostTemloca(
      'quantity' in params ? params.quantity : null,
      'item' in params ? params.item : null,
      'location' in params ? params.location : null,
      'organization' in params ? params.organization : null,
    );
  }
}
