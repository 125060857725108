import {ActiveUserService} from '../active-user/active-user.service';
import {HttpClient} from '@angular/common/http';
import {MMEntityService} from '../../multiverse/mm-entity-service';
import {SvListComponent} from '../../views/entity/sv-list/sv-list.component';
import {LaunchButton, MenuItem, MenuItemType} from '../../protocols/header-protocol';
import {SvNavigatorComponent} from '../../views/structure/sv-navigator/sv-navigator.component';
import {DetailEntry, DetailFactory} from '../detail-entry';
import {FormControl, FormGroup} from '@angular/forms';
import {AdapterProtocol} from '../entity-construction/adapter-protocol';
import {EntityType} from '../../../app/entity-types';
import {EntityCoreProtocol} from './entity-core-protocol';
import {ListSpec, ListSpecType} from './list-spec';
import {AttributesSpec} from './detail-spec';
import {EntityViewSpecProtocol} from './entity-view-spec-protocol';
import {DeleteEntityProtocol} from '../entity-construction/delete-entity-protocol';
import {AdapterType} from '../entity-construction/adapter-type';
import {ListEntry, ListEntryFactory} from "../list-entry";
import {QueryProtocol} from "../../protocols/query-protocol";
import {FormViewProtocol} from "../../protocols/form-view-protocol";
import {Button, HeaderSpec} from "../header-spec";
import {EntityAttributesForDisplay} from "../entity-attributes-for-display";
import {SvEntityAttributesViewComponent} from "../../views/entity/sv-entity-attributes-view/sv-entity-attributes-view.component";
import {ObjectiveProtocol} from "../../protocols/objective-protocol";
import {DeleteCompleteProtocol} from "../../protocols/delete-complete-protocol";
import {PostSpec} from "./post-spec";
import {AttributesLayout} from "../attributes-layout";
import {AttributeViewSpec} from "../attribute-view-spec";


export class AbstractEntityCore implements EntityCoreProtocol {

  title: string;
  listTitle: string;
  detailTitle: string;
  navTitle: string;
  onlyShowOne = false;
  asyncCore = false;

  entityUid: string;

  type: EntityType;
  delegate: SvListComponent;
  entityAttributesViewDelegate: SvEntityAttributesViewComponent;
  entities: any[];

  attributesLayout: AttributesLayout;
  hideAttributeLabels = false;

  prefetchAutoComplete = false;

  entityService: MMEntityService;
  soGetClass
  postHelper: AdapterProtocol;
  putHelper: AdapterProtocol;
  deleteHelper: DeleteEntityProtocol;

  detailFactory = new DetailFactory()
  listEntryFactory = new ListEntryFactory()

  requiresConfirm = false;

  showHeader = true;
  listHeaderSpec: HeaderSpec;
  menuItems: MenuItem[] = [];
  showMenu = false;
  showMore = true;
  showAdd = true;
  showSave = false;

  newIsSelectionFromList = false;
  newSelectionCore: EntityCoreProtocol = null;

  showSearchIcon = false;

  ascendingTimeSortKey: string;
  descendingTimeSortKey: string;

  descending;
  sortKey: string;

  selectedEntity;

  listAsTable = false;
  showTwoColumns = false;
  quickAddNarrow = false;
  showQuickAdd = false;

  soGet: QueryProtocol;

  limit = 30;
  offset = 0;

  exportFilename: string;

  searchFormField = false;

  adapters = [];

  isScope = false;

  listSpecType = ListSpecType.selection

  searchEnabled = false;
  lastQuery
  searchForm: FormGroup = new FormGroup(
    {
      search: new FormControl()
    }
  );

  ingestEnabled = false;
  checklistDisabled = false;
  listItemDeleteDisabled = true;

  showDelete = true;
  autoSave = true;

  isMedia = false;

  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
    entityServiceClass
  ) {
    this.entityService = new entityServiceClass(this);
  }


  finalInit() {

  }

  setDefaultAdapters() {
    this.postHelper = this.adapters.find(obj => obj.adapterType === AdapterType.post && obj.isDefault === true);
    this.putHelper = this.adapters.find(obj => obj.adapterType === AdapterType.put && obj.isDefault === true);
    this.deleteHelper = this.adapters.find(obj => obj.adapterType === AdapterType.delete && obj.isDefault === true);
  }

  resetSoGet() {

  }

  initListHeaderSpec() {

    let title = this.title
    if (!this.listTitle) {
      const lastLetter = this.title[this.title.length - 1]
      let newTitle = ''
      if (['y', 'h'].includes(lastLetter)) {
        for (let num = 0; num <= this.title.length - 1; num++) {
          if (num !== this.title.length - 1) {
            newTitle = newTitle + this.title[num]
          }
        }
        title = `${newTitle}${lastLetter === 'y' ? 'ie' : 'he'}`
      }
    }

    this.listHeaderSpec = new HeaderSpec(
      this.listTitle ? this.listTitle : title + 's',
    );
    this.listHeaderSpec.searchForm = this.searchForm;
    this.listHeaderSpec.showPostButton = this.showAdd;
    this.listHeaderSpec.showDelete = false;
    this.listHeaderSpec.saveButton.show = false;
    this.listHeaderSpec.showMenu = this.showMenu;

    if (this.ingestEnabled) {
      this.listHeaderSpec.showMenu = true;
      if (this.menuItems.findIndex(obj => obj.identifier === 'ingest') == -1) {
        this.menuItems.push(
          new MenuItem('ingest', 'Import', this, MenuItemType.excel),
        )
      }
    }

    this.listHeaderSpec.menuItems = this.menuItems;
  }

  search(query, entityViewSpec) {
    if (this.lastQuery !== query) {
      this.entities = null
      this.soGet.predicate = `search::${query}`
      this.fetchList(entityViewSpec);
    } else if (query === '' || !query) {
      this.resetSoGet()
      this.entities = null
      this.fetchList(entityViewSpec);
    }
    this.lastQuery = query
  }

  prepareStartingEntityViewSpec(avRoute: SvNavigatorComponent) {

    const startingSpec = this.startingEntityViewSpec(avRoute)
    avRoute.setInitialEntityViewSpec(
      startingSpec
    )

    if (this.entityUid) {
      this.entityService.get(new this.soGetClass(this.entityUid)).subscribe(resp => {
        if (resp && resp.objects.length > 0) {
          avRoute.addEntityViewSpec(
            new AttributesSpec(
              this.listEntryFactory.makeListEntry(startingSpec, this, resp.objects[0], 0),
              startingSpec,
              null,
              avRoute,
            )
          )
        }
      })
    }
  }

  startingEntityViewSpec(avRoute: SvNavigatorComponent) {
    return new ListSpec(
      this,
      null,
      null,
      avRoute
    );
  }

  getEntityViewSpecFromList(listEntry, sourceListSpec, parentRouteView) {
    return new AttributesSpec(
      listEntry,
      sourceListSpec,
      null,
      parentRouteView
    );
  }

  startTask(delegate, entityViewSpec: EntityViewSpecProtocol, identifier): EntityCoreProtocol {
    return null;
  }

  menuClick(item: MenuItem, entityViewSpec: EntityViewSpecProtocol) {
    if (item.identifier === 'start') {
      this.soGet.queryOptions.datetimeStart = item.value;
      this.resetViewAndFetchList(entityViewSpec)
    } else if (item.identifier === 'end') {
      this.soGet.queryOptions.datetimeEnd = item.value;
      this.resetViewAndFetchList(entityViewSpec)
    }
  }

  processMenuItem(menuItem: MenuItem, data) {
  }

  requestError(request, message) {
    console.log('Core request error');
    console.log(message)
    if (message.startsWith('UNAUTHORIZED')) {
      this.activeUserService.logout();
    }

    if (this.postHelper && this.postHelper.requestInProgress) {
      this.postHelper.requestError(request, message);
    }
    if (this.putHelper && this.putHelper.requestInProgress) {
      console.log('Put request error');
      this.putHelper.requestError(request, message);
    }
    if (this.deleteHelper && this.deleteHelper.requestInProgress) {
      console.log('Put request error');
      this.deleteHelper.requestError(request, message);
    }
    if (this.delegate) {
      this.delegate.submitError(message);
    }
    if (this.entityAttributesViewDelegate) {
      this.entityAttributesViewDelegate.submitError(message);
    }
  }

  resetViewAndFetchList(entityViewSpec: EntityViewSpecProtocol) {
    this.entities = null;
    entityViewSpec.listBody.reset();
    this.fetchList(entityViewSpec);
  }

  fetchList(entityViewSpec: EntityViewSpecProtocol) {
    console.log(`fetching list for - ${this.title}`);
    console.log(this.soGet)
    if (this.entities == null) {
      this.entities = [];
      this.offset = 0;
      this.delegate.loading = true;
      this.entityService.get(this.soGet).subscribe(resp => {
        if (resp) {
          console.log(resp);
          this.entities = resp.objects;
          this.sortEntities();

          for (const entity of resp.objects) {
            if (entity.start) {
              entity.start = new Date(entity.start);
            }
            if (entity.end) {
              entity.end = new Date(entity.end);
            }
          }

          this.offset = resp.nextOffset;
          this.delegate.listFetched(this.entities, entityViewSpec, this.hasMore());
        }
      });
    } else {
      this.delegate.listFetched(this.entities, entityViewSpec, false);
    }
  }

  sortEntities() {
    if (this.ascendingTimeSortKey) {
      this.entities.sort((a, b) => a[
        this.ascendingTimeSortKey
        ] < b[this.ascendingTimeSortKey] ? -1 : a[this.ascendingTimeSortKey] > b[this.ascendingTimeSortKey] ? 1 : 0);
    } else if (this.descendingTimeSortKey) {
      this.entities.sort((a, b) => a[
        this.descendingTimeSortKey
        ] > b[this.descendingTimeSortKey] ? -1 : a[this.descendingTimeSortKey] > b[this.descendingTimeSortKey] ? 1 : 0);
    }
  }

  hasMore() {
    return this.showMore && this.offset !== null && this.entities.length > 0 && this.offset !== 0;
  }

  fetchMore() {
    console.log(this.offset + this.limit);
    this.soGet.queryOptions.offset = this.offset;
    this.soGet.queryOptions.limit = this.offset + this.limit;
    this.entityService.get(this.soGet).subscribe(resp => {
      for (const entity of resp.objects) {
        this.entities.push(entity);
      }
      this.sortEntities();
      console.log(resp);
      console.log(resp.nextOffset);
      this.offset = resp.nextOffset;
      console.log(this.hasMore());
      this.delegate.moreFetched(resp.objects, this.hasMore());
    });
  }

  entitiesForExcel() {
    return this.entities;
  }

  fileUploadComplete(detail: DetailEntry, file, parentView): void {

  }

  checkCheckbox(adapter, checked: boolean) {
  };

  configureForDisplay(entityViewSpec: EntityViewSpecProtocol) {
    this.initListHeaderSpec();
  }

  makeEntityAttributesForDisplay(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry): EntityAttributesForDisplay {
    const resp = this.detailFactory.makeDetail(entityViewSpec, entityViewSpec.listEntry, this.activeUserService);
    for (const detail of resp.attributes) {
      if (
        entityViewSpec.parentEntityViewSpec
        && entityViewSpec.parentEntityViewSpec.parentEntityViewSpec
        && detail.entityCore
        && detail.entityCore.type == entityViewSpec.parentEntityViewSpec.parentEntityViewSpec.entityCore.type) {
        resp.attributes.splice(
          resp.attributes.findIndex(obj => obj.identifier === detail.identifier),
          1
        )
      }
    }
    return resp
  }

  makeHeaderSpecForEntityAttributesView(entityViewSpec: EntityViewSpecProtocol): HeaderSpec {
    const headerSpec = new HeaderSpec(
      entityViewSpec.listEntry.entityCore.detailTitle ? entityViewSpec.listEntry.entityCore.detailTitle : (
        entityViewSpec.listEntry.entityCore.title + ' details'
      ),
      entityViewSpec.showClose
    );
    headerSpec.showDetailsButtonEnabled = true
    if (entityViewSpec.navigator.entityViewSpecs.length === 1) {
      headerSpec.showCloseButton = false;
    }
    headerSpec.showBack = entityViewSpec.index > 0;

    if (this.activeUserService.canDelete(entityViewSpec.entityCore.type)) {
      headerSpec.showDelete = true;
      headerSpec.deleteEnabled = true;
    }

    headerSpec.showDelete = this.showDelete;
    headerSpec.editButton.show = true;

    return headerSpec;
  }

  setEditingEnabled(entityViewSpec: EntityViewSpecProtocol, parentView: SvEntityAttributesViewComponent) {
    if (parentView.navigator.editEnabled) {
      parentView.enableEditing();
    } else {
      parentView.disableEditing();
    }
  }

  finalOverrides(entityViewSpec: EntityViewSpecProtocol, parentView: SvEntityAttributesViewComponent) {
  }

  makeListEntry(parentEntityViewSpec, entityCore: EntityCoreProtocol, entity, section: number = 0): ListEntry {
    return this.listEntryFactory.makeListEntry(parentEntityViewSpec, entityCore, entity, section)
  }

  handleParentChange(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry): void {

  }

  postCompletePreListEntryReturn(newEntity: ObjectiveProtocol, parentEntityViewSpec: EntityViewSpecProtocol): void {

  };

  putCompletePreListEntryReturn(updatedEntity: ObjectiveProtocol, parentEntityViewSpec: EntityViewSpecProtocol): void {

  };

  startRemoveListEntry(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, view: DeleteCompleteProtocol): void {
    this.deleteHelper.delete(listEntry.entity)
  }

  startPost(parentEntityViewSpec: EntityViewSpecProtocol) {
    parentEntityViewSpec.navigator.addEntityViewSpec(
      new PostSpec(
        this,
        parentEntityViewSpec,
        parentEntityViewSpec.navigator
      ),
    );
  }

  removePostFields(fieldIdentifiers: string[]) {
    for (const identifier of fieldIdentifiers) {
      const dex = this.postHelper.formFieldOptions.findIndex(obj => obj.identifierString === identifier)
      if (dex >= 0) {
        this.postHelper.formFieldOptions.splice(dex, 1)
      }
    }
  }
}
