import {EntityType} from "../../../app/entity-types";

export class SbNavItem {

  constructor(
    public id: string,
    public entityType: EntityType,
    public routerLink: string = '',
    public description: string = '',
    public main: boolean = true,
    public edit: boolean = false,
    public routerState = null
  ) {
  }
}
