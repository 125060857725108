import {ListSpec} from "./list-spec";
import {EntityViewSpecType} from "./entity-view-spec-type";
import {EntityCoreProtocol} from "./entity-core-protocol";
import {EntityViewSpecProtocol} from "./entity-view-spec-protocol";
import {SvNavigatorComponent} from "../../views/structure/sv-navigator/sv-navigator.component";
import {DetailEntry} from "../detail-entry";
import {FormField} from "../form-field";

export function autoCompleteSpecFactory(
  formField: FormField,
  parentEntityViewSpec: EntityViewSpecProtocol = null,
  parentPanelEntityViewSpec: EntityViewSpecProtocol,
  navigator: SvNavigatorComponent,
  showClose = false,
  detailEntry: DetailEntry = null,
) {
  const spec = new AutoCompleteSpec(
    formField.entityCore,
    parentEntityViewSpec,
    parentPanelEntityViewSpec,
    navigator,
    showClose,
    detailEntry
  )
  spec.formField = formField;
  return spec

}

export class AutoCompleteSpec extends ListSpec {
  type = EntityViewSpecType.autoComplete

}
