import {EntityViewSpecProtocol} from "../../../../engine/shared/entity-view/entity-view-spec-protocol";
import {AttachmentCore} from "../../../entities/bv-attachment/attachment.core";
import {PostSpec} from "../../../../engine/shared/entity-view/post-spec";
import {MediaCore} from "../../../entities/bv-media/media.core";
import {EntityCoreProtocol} from "../../../../engine/shared/entity-view/entity-core-protocol";
import {ListEntry} from "../../../../engine/shared/list-entry";
import {SORespAttachment} from "../../../core/attachment/so-resp-attachment";
import {MediaDef, SOMediaSpec} from "../../../../engine/multiverse/so-media-spec";
import {EntityType} from "../../../entity-types";
import {EntityAttributesForDisplay} from "../../../../engine/shared/entity-attributes-for-display";
import {ListSpecType} from "../../../../engine/shared/entity-view/list-spec";

export class AttachmentProcessor extends AttachmentCore {

  configureForDisplay(entityViewSpec: EntityViewSpecProtocol) {
    super.configureForDisplay(entityViewSpec);
    if (entityViewSpec.entityCore.type == EntityType.media) {
      this.listSpecType = ListSpecType.selection
    } else if (entityViewSpec.parentEntityViewSpec.entityCore.type == EntityType.item) {

    }
  }

  makeListEntry(parentEntityViewSpec, entityCore: EntityCoreProtocol, entity: SORespAttachment, section: number = 0): ListEntry {
    const resp = super.makeListEntry(parentEntityViewSpec, entityCore, entity, section);
    let spec: SOMediaSpec
    spec = entity.media.links.find(obj => obj.definition == MediaDef.px200)
    if (spec == null) {
      spec = entity.media.links.find(obj => obj.definition == MediaDef.px400)
    }
    if (spec == null) {
      spec = entity.media.links.find(obj => obj.definition == MediaDef.px800)
    }
    if (spec == null) {
      spec = entity.media.links.find(obj => obj.definition == MediaDef.pxOrig)
    }
    resp.mediaLink = spec != null ? spec.url : ''
    return resp
  }
}
