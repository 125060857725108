import {SORespView} from '../../core/view/so-resp-view';
import {ViewCore} from './view.core';
import {ListEntry} from '../../../engine/shared/list-entry';
import {EntityAttributesForDisplay} from '../../../engine/shared/entity-attributes-for-display';
import {DetailEntry, DetailFactory, DetailFactoryProtocol, EditableType} from '../../../engine/shared/detail-entry';
import {ActiveUserService} from '../../../engine/shared/active-user/active-user.service';
import {BaseDetailType} from "../../../engine/shared/base-detail";
import {EntityViewSpecProtocol} from "../../../engine/shared/entity-view/entity-view-spec-protocol";
import {getHeading} from "../../../engine/shared/display-option-utilities";
import {putViewFormFieldOptions} from './put-view-form-fields';
import {SOGetView} from '../../core/view/so-get-view';
import {SOGetViewGrant} from '../../core/view-grant/so-get-view-grant';
import {DisplayVectorStringFactory} from "../../../engine/config/display-vector";
import {displayOptions} from "../../display-options";
import {ListSpecType} from "../../../engine/shared/entity-view/list-spec";
import {SPECTER_SPEC} from "../../specter-spec";
import {getCore} from "../../../engine/shared/entity-view/get-core";

export class ViewDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespView = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(displayOptions);
    let heading;

    const attributes = []

    const nameDetail = new DetailEntry(
        'Name',
        entity.name,
        'name',
        BaseDetailType.text,
        null,
        EditableType.always,
        putViewFormFieldOptions.name
    )
    attributes.push(nameDetail)

    const identifierDetail = new DetailEntry(
        'Identifier',
        entity.identifier,
        'identifier',
        BaseDetailType.text,
        null,
        EditableType.always,
        putViewFormFieldOptions.identifier
    )
    attributes.push(identifierDetail)

    const typeDetail = new DetailEntry(
        'Type',
        entity.type,
        'type',
        BaseDetailType.toggleGroup,
        null,
        EditableType.always,
        putViewFormFieldOptions.type
    )
    attributes.push(typeDetail)


    const viewGrantsCore = getCore(SPECTER_SPEC, activeUserService, listEntry.entityCore.httpClient, 'ViewGrantCore');
    viewGrantsCore.soGet = SOGetViewGrant.construct({
      view: new SOGetView(entity.uid),
    });
    heading = getHeading(entityViewSpec.entityCore, viewGrantsCore)
    viewGrantsCore.title = heading ? heading : viewGrantsCore.title
    viewGrantsCore.listTitle = 'View grants'

    viewGrantsCore.configureForDisplay(entityViewSpec)

    const viewGrantsDetail = new DetailEntry(
      heading ? heading : viewGrantsCore.listTitle,
      null,
      'viewGrants',
      BaseDetailType.embeddedList,
      viewGrantsCore,
      EditableType.never
    )
    attributes.push(viewGrantsDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}