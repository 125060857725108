<div>
  <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutAlign="start center" class="header-title">
      <button
        mat-icon-button
        *ngIf="showBackButton"
        (click)="parent.closeDetailView(null)">
        <mat-icon>
          arrow_back_ios
        </mat-icon>
      </button>
      <h2 class="header-title-text">
        {{headerSpec.heading}}
      </h2>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center">

      <button
        mat-icon-button
        *ngFor="let button of headerSpec.buttons"
        (click)="button.buttonPressHandler.buttonPress(button)">
        <mat-icon>
          {{button.icon}}
        </mat-icon>
      </button>

      <div *ngIf="headerSpec.showFilter">
        <button mat-icon-button (click)="filterPress()">
          <mat-icon>
            sort
          </mat-icon>
        </button>
      </div>
      <button
        mat-icon-button
        *ngIf="headerSpec.showDetailsButtonEnabled"
        (click)="parent.showDetails()">
        <mat-icon>
          info
        </mat-icon>
      </button>
      <button
        mat-icon-button
        *ngIf="headerSpec.showDelete"
        [disabled]="!headerSpec.deleteEnabled"
        (click)="parent.clickDeleteButton()">
        <mat-icon>
          delete_forever
        </mat-icon>
      </button>
      <button
        mat-icon-button
        *ngIf="headerSpec.saveButton.show"
        [disabled]="saveDisabled"
        (click)="headerSpec.saveButton.click()">
        <mat-icon>
          save
        </mat-icon>
      </button>
      <button
        mat-icon-button
        *ngIf="headerSpec.editButton.show"
        [disabled]="headerSpec.editButton.disabled"
        (click)="headerSpec.editButton.click()">
        <mat-icon>
          {{headerSpec.editButton.icon}}
        </mat-icon>
      </button>
      <button
        mat-icon-button
        *ngIf="headerSpec.showSearchIcon"
        (click)="parent.clickSearchIcon(null)">
        <mat-icon>
          search
        </mat-icon>
      </button>
      <button
        mat-icon-button
        *ngIf="headerSpec.showPostButton"
        (click)="startPost()">
        <mat-icon>
          add
        </mat-icon>
      </button>
      <div *ngIf="headerSpec.showMenu">
        <button mat-icon-button (click)="filterPress()" [matMenuTriggerFor]="menu">
          <mat-icon>
            more_vert
          </mat-icon>
        </button>
        <!--        <mat-menu #menu="matMenu">-->
        <!--          <button mat-menu-item>-->
        <!--            <mat-icon>dialpad</mat-icon>-->
        <!--            <span>Redial</span>-->
        <!--          </button>-->
        <!--          <button mat-menu-item disabled>-->
        <!--            <mat-icon>voicemail</mat-icon>-->
        <!--            <span>Check voice mail</span>-->
        <!--          </button>-->
        <!--          <button mat-menu-item>-->
        <!--            <mat-icon>notifications_off</mat-icon>-->
        <!--            <span>Disable alerts</span>-->
        <!--          </button>-->
        <!--        </mat-menu>-->
      </div>
      <button
        mat-icon-button
        *ngIf="showCloseButton && showBackButton"
        (click)="parent.closeDetailView(null)">
        <mat-icon>
          close
        </mat-icon>
      </button>
    </div>
  </mat-toolbar>
  <div *ngIf="searchBar" class="search-box">

    <form fxLayout="row"
          fxLayoutAlign="start center"
          [formGroup]="headerSpec.searchForm"
          (ngSubmit)="submitSearchForm()"
          (keyup.enter)="submitSearchForm()">

      <mat-form-field appearance="fill" color="accent" (keyup)="submitSearchForm()">
        <mat-label for="search">Search</mat-label>
        <input matInput type="text" id="search" name="search" formControlName="search">
      </mat-form-field>
    </form>

  </div>
  <div *ngIf="showFilterOptions" class="filter-options" fxLayout="column">
    <div fxLayout="row" *ngIf="toggleButtons.length > 0">
      <mat-button-toggle-group>
        <mat-button-toggle *ngFor="let menuItem of toggleButtons"
                           [checked]="menuItem.value"
                           (click)="clickMenuItem(menuItem)">
          {{menuItem.displayText}}
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>
  <mat-menu #menu>
    <div mat-menu-item *ngFor="let menuItem of dropDownMenuItems">
      <app-sv-upload-button *ngIf="menuItem.menuItemType == MenuItemType.excel"
                            [uploadButtonInput]="makeUploadButtonInput(menuItem)"></app-sv-upload-button>
      <button *ngIf="menuItem.menuItemType == MenuItemType.excelTemplate" mat-stroked-button (click)="clickMenuItem(menuItem)">
        {{menuItem.displayText}}
      </button>
    </div>
  </mat-menu>
</div>
