<form [formGroup]="form" class="container" fxLayout="row">

  <mat-form-field *ngIf="detail.editEnabled"
                  appearance="{{detail.editableFormField.appearance}}"
                  color="{{detail.editableFormField.color}}"
                  class="editor">
    <mat-label>{{detail.editableFormField.getLabel(parentView.entityViewSpec)}}</mat-label>
    <textarea matInput
              [id]="detail.editableFormField.identifierString"
              [name]="detail.editableFormField.identifierString"
              [formControlName]="detail.editableFormField.identifierString"
              (change)="onSelectionChange()"
              class="editor">
            </textarea>
  </mat-form-field>

<!--  <markdown class="markdown">-->
<!--    {{detail.value}}-->
<!--  </markdown>-->
</form>
