import {Component, Input, OnInit} from '@angular/core';
import {SvListComponent} from "../../entity/sv-list/sv-list.component";

@Component({
  selector: 'app-sv-column-list',
  templateUrl: './sv-column-list.component.html',
  styleUrls: ['./sv-column-list.component.scss']
})
export class SvColumnListComponent implements OnInit {

  @Input()
  parent: SvListComponent

  constructor() { }

  ngOnInit(): void {
  }

}
