import { Type } from 'class-transformer';
import { SORespRoleGrant } from '../role-grant/so-resp-role-grant';
import {ENTokenStatus} from '../../enums';
import {ObjectiveProtocol} from '../../../engine/protocols/objective-protocol';
import {EntityType} from "../../entity-types";


export class SORespToken implements ObjectiveProtocol {

  entityType: EntityType = EntityType.token;
  uid: string;
  @Type(() => Date)
  dateCreated: Date;
  status: ENTokenStatus = null;
  @Type(() => SORespRoleGrant)
  roleGrant: SORespRoleGrant;
}