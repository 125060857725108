import { Type } from 'class-transformer';
import {ObjectiveProtocol} from '../../../engine/protocols/objective-protocol';
import {EntityType} from "../../entity-types";


export class SORespSection implements ObjectiveProtocol {

  entityType: EntityType = EntityType.section;
  uid: string;
  title: string;
  number: number;
}