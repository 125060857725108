import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import {ActiveUserService} from "../active-user/active-user.service";
import {SPECTER_SPEC} from "../../../app/specter-spec";

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private activeUserService: ActiveUserService
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    if (this.activeUserService.userLoaded) {
      return true
    }
    if (SPECTER_SPEC.unprotectedPaths.includes(route.routeConfig.path)) {
      return true
    }

    const queryParams = this.getQueryParams();
    this.router.navigate([''], { queryParams });

    return false;
  }

  getQueryParams() {
    const urlparts = window.location.href.split('?');
    if (urlparts.length > 1) {
      const params = urlparts[1].split('&');
      const newParams = {};
      for (const param of params) {
        const kv = param.split('=');
        newParams[kv[0]] = kv[1];
      }
      return newParams;
    }
    return null;
  }
}
