import {Injectable} from '@angular/core';
import {SPECTER_SPEC} from "../../../app/specter-spec";
import {BlobStorageOptions} from "../blob-storage-options";
import {HttpClient} from "@angular/common/http";
import {BlobUploadCompleteProtocol} from "../../protocols/blob-upload-complete-protocol";
import {catchError, map, tap} from "rxjs/operators";
import {Observable, of} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  private uploadQueue = {};
  private cache = {};

  constructor(
    private httpClient: HttpClient
  ) {
  }

  addToQueue(identifier, file: File) {
    this.uploadQueue[identifier + file.name] = file
  }

  getMedia(identifier, filename) {
    return this.uploadQueue[identifier + filename]
  }

  finishUpload(identifier, filename, link: string, finishProcessor: BlobUploadCompleteProtocol, newIdentifier) {

    // if (SPECTER_SPEC.blobStorage == BlobStorageOptions.aws) {
      const params = JSON.parse(link)
      let formData = new FormData();
      for (const key of Object.keys(params.fields)) {
        formData.append(key, params.fields[key])
      }
      formData.append('file', this.uploadQueue[identifier + filename]);
      this.httpClient.post(
        params.url, formData
      ).pipe(
        catchError(this.handleError('Media post to blob'))
      ).subscribe(resp => {
        finishProcessor.blobUploadComplete(this.uploadQueue[identifier + filename])
        this.uploadQueue[newIdentifier + filename] = this.uploadQueue[identifier + filename]
        delete this.uploadQueue[identifier + filename]
      });
    // }
  }

  handleError<T>(request, result?: T) {
    return (error: any): Observable<T> => {

      console.error(error); // log to console instead
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
