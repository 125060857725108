<div fxLayout="row" fxLayoutAlign="space-between">
  <div fxLayout="column">
    <h3>{{detail.name}}: {{displayValue}}</h3>
    <div *ngIf="selectedMedia">
      <img *ngIf="imageUrl != null" [src]="imageUrl" alt="image" style="width: 200px">
    </div>
  </div>
  <div>
    <button mat-stroked-button
            *ngIf="!parentView.isPost"
            (click)="parentView.clickPushList(detail)"
            color="accent">
        VIEW
    </button>
    <button mat-stroked-button
            *ngIf="parentView.isPost"
            (click)="parentView.clickPushList(detail)"
            color="accent">
        SELECT
    </button>
  </div>
</div>
