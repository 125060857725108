<div fxLayout="column" class="list-detail">
  <div fxLayout="row" class="list-detail-content">
    <app-sv-list *ngIf="isList" class="sv-list" #list [entityViewSpec]="entityViewSpec"
                 [parentEntityViewComponent]="this"></app-sv-list>
    <app-sv-entity-attributes-view *ngIf="isDetail" class="list-detail-list" [entityViewSpec]="entityViewSpec"
                                   [parentEntityViewComponent]="this"></app-sv-entity-attributes-view>
    <app-sv-post *ngIf="isPost" class="sv-list" [entityViewSpec]="entityViewSpec"
                 [parentEntityViewComponent]="this"></app-sv-post>
    <app-sv-calendar *ngIf="isCalendar" class="list-detail-detail" [entityViewSpec]="entityViewSpec"
                     [parentEntityViewComponent]="this"></app-sv-calendar>
    <app-sv-auto-complete *ngIf="isAutoComplete" class="sv-list" [entityViewSpec]="entityViewSpec"
                          [parentEntityViewComponent]="this"></app-sv-auto-complete>
  </div>
</div>
