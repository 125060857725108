import {SORespView} from '../../core/view/so-resp-view';
import {ViewCore} from './view.core';
import { ListEntry, ListEntryFactory, ListEntryFactoryProtocol} from '../../../engine/shared/list-entry';
import {EntityCoreProtocol} from "../../../engine/shared/entity-view/entity-core-protocol";


export class ViewListEntryFactory  extends ListEntryFactory implements ListEntryFactoryProtocol {

  makeListEntry(parentCoreViewSpec, entityCore: EntityCoreProtocol, entity: SORespView, section: number = 0): ListEntry {
    const resp = super.makeListEntry(parentCoreViewSpec, entityCore, entity, section)
    return resp
  }
}