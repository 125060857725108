import { SOGetUser } from '../user/so-get-user';
import { SOGetRole } from '../role/so-get-role';
import { SOGetOrganization } from '../organization/so-get-organization';
import {ENRoleGrantStatus} from '../../enums';
import {MMQueryOptions} from '../../../engine/multiverse/mm-query-options';
import {QueryProtocol} from "../../../engine/protocols/query-protocol";


export class SOGetRoleGrant implements QueryProtocol {

  constructor(
    public uid: string = null,
    public isDefault: boolean = null,
    public status: ENRoleGrantStatus[] = null,
    public user: SOGetUser = null,
    public role: SOGetRole = null,
    public organization: SOGetOrganization = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetRoleGrant(
      'uid' in params ? params.uid : null,
      'isDefault' in params ? params.isDefault : null,
      'status' in params ? params.status : null,
      'user' in params ? params.user : null,
      'role' in params ? params.role : null,
      'organization' in params ? params.organization : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
