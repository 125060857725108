import {FormField, FormFieldType, SelectionOption} from '../../../engine/shared/form-field';
import {EntityType} from "../../entity-types";


const roleField = new FormField(
  'role',
  'Role',
  EntityType.role,
  FormFieldType.pushedSelectionList,
  true,
)
const viewField = new FormField(
  'view',
  'View',
  EntityType.view,
  FormFieldType.pushedSelectionList,
  true,
)


export const postViewGrantFormFieldOptions: {[key: string]: FormField} = {
  role: roleField,
  view: viewField,
};