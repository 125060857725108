import { Type } from 'class-transformer';
import { SORespItem } from '../item/so-resp-item';
import { SORespDistributor } from '../distributor/so-resp-distributor';
import {ObjectiveProtocol} from '../../../engine/protocols/objective-protocol';
import {EntityType} from "../../entity-types";


export class SORespTemdisa implements ObjectiveProtocol {

  entityType: EntityType = EntityType.temdisa;
  uid: string;
  @Type(() => SORespItem)
  item: SORespItem;
  @Type(() => SORespDistributor)
  distributor: SORespDistributor;
}