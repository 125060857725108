import {SOPutDistributor} from '../../core/distributor/so-put-distributor';
import {DistributorCore} from './distributor.core';
import {SOGetDistributor} from '../../core/distributor/so-get-distributor';
import {FormField, SelectionOption} from '../../../engine/shared/form-field';
import {putDistributorFormFieldOptions} from './put-distributor-form-fields';
import {AdapterProtocol} from '../../../engine/shared/entity-construction/adapter-protocol';
import {BasePut} from '../../../engine/shared/entity-construction/base-put';

export class RAPutDistributor extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putDistributorFormFieldOptions);

  RequestClass = SOPutDistributor

  constructor(
    public entityCore: DistributorCore
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('name'),
    )
  }

  prepareRequestWithQuickCreate(value) {
    this.prepareRequest();
    this.request.name = value
  }

  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}