import {SOPostAttachment} from '../../core/attachment/so-post-attachment';
import {AttachmentCore} from './attachment.core';
import {SOGetAttachment} from '../../core/attachment/so-get-attachment';
import {FormField, SelectionOption} from '../../../engine/shared/form-field';
import {postAttachmentFormFieldOptions} from './post-attachment-form-fields';
import {AdapterProtocol} from '../../../engine/shared/entity-construction/adapter-protocol';
import {BasePost} from '../../../engine/shared/entity-construction/base-post';
import {MediaCore} from '../bv-media/media.core';
import {SOGetMedia} from '../../core/media/so-get-media';
import {ItemCore} from '../bv-item/item.core';
import {SOGetItem} from '../../core/item/so-get-item';
import {OrganizationCore} from '../bv-organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';

export class RAPostAttachment extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postAttachmentFormFieldOptions);

  RequestClass = SOPostAttachment

  constructor(
    public entityCore: AttachmentCore
  ) {
    super();
    if (postAttachmentFormFieldOptions.media) {
      postAttachmentFormFieldOptions.media.makeCore = true;
    }
    if (postAttachmentFormFieldOptions.item) {
      postAttachmentFormFieldOptions.item.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier(
        'media',
        SOGetMedia,
      ),
      this.getFormValueFromIdentifier(
        'item',
        SOGetItem,
      ),
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}