import {FormField, FormFieldType, SelectionOption} from '../../../engine/shared/form-field';
import {EntityType} from "../../entity-types";


const quantityField = new FormField(
  'quantity',
  'Quantity',
  null,
  FormFieldType.text,
  false,
)
const unitSalePriceField = new FormField(
  'unitSalePrice',
  'Unit sale price',
  null,
  FormFieldType.text,
  false,
)
const unitCostField = new FormField(
  'unitCost',
  'Unit cost',
  null,
  FormFieldType.text,
  false,
)
const notesField = new FormField(
  'notes',
  'Notes',
  null,
  FormFieldType.text,
  false,
)


export const putLineFormFieldOptions: {[key: string]: FormField} = {
  quantity: quantityField,
  unitSalePrice: unitSalePriceField,
  unitCost: unitCostField,
  notes: notesField,
};