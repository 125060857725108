import {FormField, FormFieldType, SelectionOption} from '../../../engine/shared/form-field';
import {EntityType} from "../../entity-types";


const nameField = new FormField(
  'name',
  'Name',
  null,
  FormFieldType.text,
  true,
)


export const postDefaultCustomFieldFormFieldOptions: {[key: string]: FormField} = {
  name: nameField,
};