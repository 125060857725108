import {ActiveUserService} from '../../../engine/shared/active-user/active-user.service';
import {HttpClient} from '@angular/common/http';
import {EOTokenService} from '../../core/token/eo-token';
import {TokenListEntryFactory} from './token-list-entry-factory';
import {TokenDetailFactory} from './token-detail-factory';
import {SOGetToken} from '../../core/token/so-get-token';
import {RAPutToken} from './ra-put-token';
import {RAPostToken} from './ra-post-token';
import {RADeleteToken} from './ra-delete-token';
import {AbstractEntityCore} from "../../../engine/shared/entity-view/abstract-entity-core";
import {EntityCoreProtocol} from "../../../engine/shared/entity-view/entity-core-protocol";
import {EntityType} from '../../entity-types';
import {SPECTER_SPEC} from "../../specter-spec";

export class TokenCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = EntityType.token;
  title = 'Token';
  listEntryFactory = new TokenListEntryFactory();
  detailFactory = new TokenDetailFactory();

  entityService: EOTokenService;
  soGetClass = SOGetToken;
  soGet: SOGetToken;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOTokenService
    );
    this.adapters = [
      new RAPostToken(this),
      new RAPutToken(this),
      new RADeleteToken(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetToken();
    this.soGet.queryOptions.limit = this.limit;
  }


}