import {EntityCoreProtocol} from "../../../../engine/shared/entity-view/entity-core-protocol";
import {ListEntry} from "../../../../engine/shared/list-entry";
import {EntityViewSpecProtocol} from "../../../../engine/shared/entity-view/entity-view-spec-protocol";
import {ItemImportCore} from "../../../entities/bv-item-import/item-import.core";
import {SORespItemImport} from "../../../core/item-import/so-resp-item-import";
import {SOGetItemImport} from "../../../core/item-import/so-get-item-import";
import {SOGetOrganization} from "../../../core/organization/so-get-organization";

export class ItemImportProcessor extends ItemImportCore {

  showAdd = false;
  descending = true;
  descendingTimeSortKey = 'dateCreated'
  showDelete = false;

  makeListEntry(
    parentEntityViewSpec: EntityViewSpecProtocol,
    entityCore: EntityCoreProtocol,
    entity: SORespItemImport,
    section: number = 0
  ): ListEntry {

    return new ListEntry(
      entityCore,
      entity,
      [this.activeUserService.time.niceShortDateAndTime(entity.dateCreated)],
      [],
      ``,
      section
    );
  }

  resetSoGet() {
    this.soGet = new SOGetItemImport();
    this.soGet.organization = new SOGetOrganization(
      this.activeUserService.getScope('organization') ? this.activeUserService.getScope('organization').uid : null
    );
    this.soGet.queryOptions.limit = this.limit;
  }

}
