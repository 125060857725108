import {SOPostSection} from '../../core/section/so-post-section';
import {SectionCore} from './section.core';
import {SOGetSection} from '../../core/section/so-get-section';
import {FormField, SelectionOption} from '../../../engine/shared/form-field';
import {postSectionFormFieldOptions} from './post-section-form-fields';
import {AdapterProtocol} from '../../../engine/shared/entity-construction/adapter-protocol';
import {BasePost} from '../../../engine/shared/entity-construction/base-post';

export class RAPostSection extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postSectionFormFieldOptions);

  RequestClass = SOPostSection

  constructor(
    public entityCore: SectionCore
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('title'),
      this.getFormValueFromIdentifier('number'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}