import {Component, Input, OnInit} from '@angular/core';
import {AbstractAttributeView} from "../../../shared/abstract-attribute-view";
import {EntityViewSpecType} from "../../../shared/entity-view/entity-view-spec-type";

@Component({
  selector: 'app-sv-text-attribute-view',
  templateUrl: './sv-text-attribute-view.component.html',
  styleUrls: ['./sv-text-attribute-view.component.scss']
})
export class SvTextAttributeViewComponent extends AbstractAttributeView {
  showPassword = false;

  lastTypeTime: Date;
  private lastSavedValue: string;
  get hasChanges() {
    return this.lastSavedValue != this.form.value[this.detail.identifier]
  };

  attributeSpecSet() {
    this.lastSavedValue = this.detail.value
  }

  keyup(event: KeyboardEvent) {
    this.lastTypeTime = new Date();
    if (
      event && event.code === 'Enter'
      && this.form.valid
      && this.parentView.isPost
    ) {
      this.parentView.clickSubmitFormButton();
    } else if (
      this.parentView.entityViewSpec
      && this.parentView.entityViewSpec.type !== EntityViewSpecType.post
      && this.parentView.entityViewSpec.entityCore.autoSave
    ) {
      setTimeout(() => {
        if (
          new Date().getTime() - this.lastTypeTime.getTime() > 1000
          && this.hasChanges
        ) {
          this.lastSavedValue = this.form.value[this.detail.identifier]
          this.parentView.clickSubmitFormButton();
        }
      }, 1050)
    }
  }
}
