import {SORespPaymentIntent} from '../../core/payment-intent/so-resp-payment-intent';
import {PaymentIntentCore} from './payment-intent.core';
import { ListEntry, ListEntryFactory, ListEntryFactoryProtocol} from '../../../engine/shared/list-entry';
import {EntityCoreProtocol} from "../../../engine/shared/entity-view/entity-core-protocol";


export class PaymentIntentListEntryFactory  extends ListEntryFactory implements ListEntryFactoryProtocol {

  makeListEntry(parentCoreViewSpec, entityCore: EntityCoreProtocol, entity: SORespPaymentIntent, section: number = 0): ListEntry {
    const resp = super.makeListEntry(parentCoreViewSpec, entityCore, entity, section)
    return resp
  }
}