import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';
import {SPECTER_SPEC} from "../../specter-spec";

import { SOPostMedia } from './so-post-media';
import { SOGetMedia } from './so-get-media';
import { SOPutMedia } from './so-put-media';
import { SODeleteMedia } from './so-delete-media';
import {SORespMedia} from './so-resp-media';
import {SOPluralMedia} from './so-plural-media';

import {MMEntityService} from '../../../engine/multiverse/mm-entity-service';
import {EntityCoreProtocol} from '../../../engine/shared/entity-view/entity-core-protocol';
import {MMEntityServiceBase} from '../../../engine/multiverse/mm-entity-service-base';
import {HttpHeaders} from "@angular/common/http";


export class EOMediaService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'media',
      entityCore
    );
  }

  /** GET Media by id. Will 404 if id not found */
  get(request: SOGetMedia): Observable<SOPluralMedia> {
    return this.httpClient.get<SOPluralMedia>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralMedia, resp) }),
      tap(_ => this.log(`fetched Media`)),
      catchError(this.handleError<SOPluralMedia>(`get Media`))
    );
  }

  /** POST: add a new Media to the server */
  post(request: SOPostMedia): Observable<SORespMedia> {
    return this.httpClient.post<SORespMedia>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespMedia, resp) }),
      tap((response: SORespMedia) => this.log(`added Media w/ id=${response.uid}`)),
      catchError(this.handleError<SORespMedia>('Media post'))
    );
  }

  /** PUT: update Media  */
  put(request: SOPutMedia): Observable<SORespMedia> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespMedia>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespMedia, resp) }),
      tap((response: SORespMedia) => this.log(`edited Media w/ uid=${uid}`)),
      catchError(this.handleError<SORespMedia>('Media put'))
    );
  }

  /** DELETE: delete the Media from the server */
  delete(request: SODeleteMedia): Observable<SORespMedia> {
    return this.httpClient.delete<SORespMedia>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Media uid=${request.uid}`)),
      catchError(this.handleError<SORespMedia>('delete Media'))
    );
  }

/** POST: add a new File to the server */
  postFile(request: SOPostMedia, file: File): Observable<SORespMedia> {

    let formData = new FormData();
    formData.append('request', JSON.stringify(classToPlain(request)))
    formData.append('file', file);

    const options = this.httpOptions
    options.headers = new HttpHeaders({
      'X-Sid': SPECTER_SPEC.specterId
    })

    return this.httpClient.post<SORespMedia>(this.coreUrl + this.route, formData, options).pipe(
      map(resp => { return plainToClass(SORespMedia, resp) }),
      tap((response: SORespMedia) => this.log(`added Media w/ id=${response.uid}`)),
      catchError(this.handleError<SORespMedia>('Media post'))
    );
  }
}
