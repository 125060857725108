<div *ngFor="let section of parent.entityViewSpec.listBody.sections" class="section-header">
  <!--  <mat-divider></mat-divider>-->
  <div *ngIf="section.heading" fxLayout="row" fxLayoutAlign="space-between start">
    <div fxLayout="column">
      <h2>{{section.heading}}</h2>
      <h3 *ngFor="let line of section.subLines">{{line}}</h3>
    </div>
    <div fxLayout="column" fxLayoutAlign="start">
      <h2 *ngIf="section.trailingHeading">{{section.trailingHeading}}</h2>
    </div>
  </div>
  <div fxLayout="column" class="checklist">
    <div class="checklist-item" fxLayout="row" fxLayoutAlign="start center"
         *ngFor="let listEntry of section.listEntries">
      <div *ngIf="listEntry" fxLayout="row" fxLayoutAlign="start center" fxFlex="grow">
        <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex="grow">
            <app-sv-entity-view [entityViewSpec]="getEntityViewSpec(listEntry)" [parentRouteView]="parent.entityViewSpec.navigator"></app-sv-entity-view>
<!--          <div class="row-details"-->
<!--               fxLayout="row"-->
<!--               fxLayoutAlign="start center">-->
<!--          </div>-->
          <button mat-icon-button
                  *ngIf="!listEntry.entityCore.listItemDeleteDisabled && !listEntry.checked"
                  (click)="parent.remove(listEntry)">
            <mat-icon>
              highlight_off
            </mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
