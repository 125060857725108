import {FormField, FormFieldType, SelectionOption} from '../../../engine/shared/form-field';
import {EntityType} from "../../entity-types";


const quantityField = new FormField(
  'quantity',
  'Quantity',
  null,
  FormFieldType.text,
  false,
)


export const putAppliedConstraintFormFieldOptions: {[key: string]: FormField} = {
  quantity: quantityField,
};