import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';

import { SOPostQuestion } from './so-post-question';
import { SOGetQuestion } from './so-get-question';
import { SOPutQuestion } from './so-put-question';
import { SODeleteQuestion } from './so-delete-question';
import {SORespQuestion} from './so-resp-question';
import {SOPluralQuestion} from './so-plural-question';

import {MMEntityService} from '../../../engine/multiverse/mm-entity-service';
import {EntityCoreProtocol} from '../../../engine/shared/entity-view/entity-core-protocol';
import {MMEntityServiceBase} from '../../../engine/multiverse/mm-entity-service-base';
import {HttpHeaders} from "@angular/common/http";


export class EOQuestionService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'question',
      entityCore
    );
  }

  /** GET Question by id. Will 404 if id not found */
  get(request: SOGetQuestion): Observable<SOPluralQuestion> {
    return this.httpClient.get<SOPluralQuestion>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralQuestion, resp) }),
      tap(_ => this.log(`fetched Question`)),
      catchError(this.handleError<SOPluralQuestion>(`get Question`))
    );
  }

  /** POST: add a new Question to the server */
  post(request: SOPostQuestion): Observable<SORespQuestion> {
    return this.httpClient.post<SORespQuestion>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespQuestion, resp) }),
      tap((response: SORespQuestion) => this.log(`added Question w/ id=${response.uid}`)),
      catchError(this.handleError<SORespQuestion>('Question post'))
    );
  }

  /** PUT: update Question  */
  put(request: SOPutQuestion): Observable<SORespQuestion> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespQuestion>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespQuestion, resp) }),
      tap((response: SORespQuestion) => this.log(`edited Question w/ uid=${uid}`)),
      catchError(this.handleError<SORespQuestion>('Question put'))
    );
  }

  /** DELETE: delete the Question from the server */
  delete(request: SODeleteQuestion): Observable<SORespQuestion> {
    return this.httpClient.delete<SORespQuestion>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Question uid=${request.uid}`)),
      catchError(this.handleError<SORespQuestion>('delete Question'))
    );
  }
}
