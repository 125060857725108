import {FormField, FormFieldType, SelectionOption} from '../../../engine/shared/form-field';
import {EntityType} from "../../entity-types";


const nameField = new FormField(
  'name',
  'Name',
  null,
  FormFieldType.text,
  true,
)
const descriptionField = new FormField(
  'description',
  'Description',
  null,
  FormFieldType.text,
  false,
)
const barCodeField = new FormField(
  'barCode',
  'Barcode or reference #',
  null,
  FormFieldType.text,
  false,
)
const retailPriceField = new FormField(
  'retailPrice',
  'Sale price',
  null,
  FormFieldType.text,
  false,
)
const costField = new FormField(
  'cost',
  'Cost',
  null,
  FormFieldType.text,
  false,
)


export const postItemFormFieldOptions: {[key: string]: FormField} = {
  name: nameField,
  description: descriptionField,
  barCode: barCodeField,
  retailPrice: retailPriceField,
  cost: costField,
};