import {SORespOrder} from '../../core/order/so-resp-order';
import {OrderCore} from './order.core';
import {ListEntry} from '../../../engine/shared/list-entry';
import {EntityAttributesForDisplay} from '../../../engine/shared/entity-attributes-for-display';
import {DetailEntry, DetailFactory, DetailFactoryProtocol, EditableType} from '../../../engine/shared/detail-entry';
import {ActiveUserService} from '../../../engine/shared/active-user/active-user.service';
import {BaseDetailType} from "../../../engine/shared/base-detail";
import {EntityViewSpecProtocol} from "../../../engine/shared/entity-view/entity-view-spec-protocol";
import {getHeading} from "../../../engine/shared/display-option-utilities";
import {putOrderFormFieldOptions} from './put-order-form-fields';
import {SOGetOrder} from '../../core/order/so-get-order';
import {SOGetDistributor} from '../../core/distributor/so-get-distributor';
import {SOGetLine} from '../../core/line/so-get-line';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {DisplayVectorStringFactory} from "../../../engine/config/display-vector";
import {displayOptions} from "../../display-options";
import {ListSpecType} from "../../../engine/shared/entity-view/list-spec";
import {SPECTER_SPEC} from "../../specter-spec";
import {getCore} from "../../../engine/shared/entity-view/get-core";

export class OrderDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespOrder = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(displayOptions);
    let heading;

    const attributes = []

    const refDetail = new DetailEntry(
        'Order id',
        entity.ref,
        'ref',
        BaseDetailType.text,
        null,
        EditableType.always,
        putOrderFormFieldOptions.ref
    )
    attributes.push(refDetail)

    const nameDetail = new DetailEntry(
        'Name',
        entity.name,
        'name',
        BaseDetailType.text,
        null,
        EditableType.always,
        putOrderFormFieldOptions.name
    )
    attributes.push(nameDetail)

    const dispatchStatusDetail = new DetailEntry(
        'Dispatch status',
        entity.dispatchStatus,
        'dispatchStatus',
        BaseDetailType.toggleGroup,
        null,
        EditableType.always,
        putOrderFormFieldOptions.dispatchStatus
    )
    attributes.push(dispatchStatusDetail)

    const dateCreatedDetail = new DetailEntry(
        'Date created',
        activeUserService.time.niceDate(entity.dateCreated),
        'dateCreated',
        BaseDetailType.text,
        null,
        EditableType.never,
    )
    attributes.push(dateCreatedDetail)

    const salePriceDetail = new DetailEntry(
        'Sale price',
        entity.salePrice,
        'salePrice',
        BaseDetailType.text,
        null,
        EditableType.never,
    )
    attributes.push(salePriceDetail)

    const costDetail = new DetailEntry(
        'Cost',
        entity.cost,
        'cost',
        BaseDetailType.text,
        null,
        EditableType.never,
    )
    attributes.push(costDetail)

    const netDetail = new DetailEntry(
        'Net',
        entity.net,
        'net',
        BaseDetailType.text,
        null,
        EditableType.never,
    )
    attributes.push(netDetail)

    const notesDetail = new DetailEntry(
        'Notes',
        entity.notes,
        'notes',
        BaseDetailType.text,
        null,
        EditableType.always,
        putOrderFormFieldOptions.notes
    )
    attributes.push(notesDetail)


    const distributorCore = getCore(SPECTER_SPEC, activeUserService, listEntry.entityCore.httpClient, 'DistributorCore');
    distributorCore.soGet = SOGetDistributor.construct({
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, distributorCore)
    distributorCore.title = heading ? heading : distributorCore.title
    distributorCore.listTitle = 'Distributor'

    if (entity.distributor) {
        displayStringFactory.reconfigure(entity.distributor, entityViewSpec.entityCore.type)
    }
    distributorCore.configureForDisplay(entityViewSpec)

    const distributorDetail = new DetailEntry(
      heading ? heading : distributorCore.listTitle,
      entity.distributor ? displayStringFactory.primaryString : null,
      'distributor',
      BaseDetailType.pushedDetail,
      distributorCore,
      EditableType.always,
      putOrderFormFieldOptions.distributor
    )
    if (entity.distributor) {
      distributorDetail.cachedListEntry = distributorCore.makeListEntry(entityViewSpec, distributorCore, entity.distributor, 0)
    }
    distributorDetail.singleFieldSelect = true
    attributes.push(distributorDetail);

    const linesCore = getCore(SPECTER_SPEC, activeUserService, listEntry.entityCore.httpClient, 'LineCore');
    linesCore.soGet = SOGetLine.construct({
      order: new SOGetOrder(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, linesCore)
    linesCore.title = heading ? heading : linesCore.title
    linesCore.listTitle = 'Line items'

    linesCore.configureForDisplay(entityViewSpec)

    const linesDetail = new DetailEntry(
      heading ? heading : linesCore.listTitle,
      null,
      'lines',
      BaseDetailType.embeddedList,
      linesCore,
      EditableType.never
    )
    attributes.push(linesDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}