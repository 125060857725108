import {ListBody} from '../list-body';
import {ListEntry} from '../list-entry';
import {EntityViewSpecType} from './entity-view-spec-type';
import {EntityType} from '../../../app/entity-types';
import {FormField} from "../form-field";
import {EntityViewSpecProtocol} from "./entity-view-spec-protocol";
import {SvNavigatorComponent} from "../../views/structure/sv-navigator/sv-navigator.component";
import {DetailEntry} from "../detail-entry";
import {EntityCoreProtocol} from "./entity-core-protocol";
import {AttributeViewSpec} from "../attribute-view-spec";

export abstract class EntityViewSpec implements EntityViewSpecProtocol {

  type: EntityViewSpecType

  listBody: ListBody;
  listEntry: ListEntry;

  embedded = false;

  hasMods = false;

  formInitialized: boolean = false

  formField: FormField;

  childrenEntityViewSpecs: EntityViewSpecProtocol[] = [];
  detailEntry: DetailEntry;
  _entityCore: EntityCoreProtocol
  initialized = false;

  childrenEntityAttributeViewSpecs: AttributeViewSpec[] = []

  get entityCore(): EntityCoreProtocol {
    return this._entityCore
  };
  private _index: number;
  get index(): number {
    return this._index
  };
  navigator: SvNavigatorComponent;
  parentPanelEntityViewSpec: EntityViewSpecProtocol;
  showClose: boolean;

  get parentEntityViewSpec(): EntityViewSpecProtocol {
    return this._parentEntityViewSpec
  }
  set parentEntityViewSpec(value: EntityViewSpecProtocol) {
    this._parentEntityViewSpec = value;
  };
  private _parentEntityViewSpec: EntityViewSpecProtocol;


  protected constructor(
  ) {
  }

  addEntryToList(listEntry: ListEntry, identifier = null) {
    console.log(`Not implemented on ${EntityType[this.type]}`);
  }

  updateEntryInList(existingListEntry: ListEntry, updatedListEntry: ListEntry) {
    console.log(`Not implemented on ${EntityType[this.type]}`);
  }

  removeEntryFromList(existingListEntry: ListEntry, updatedListEntry: ListEntry) {
    console.log(`Not implemented on ${this.type.toString()}`);
  }
}
