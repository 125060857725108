import {Component} from '@angular/core';
import {ActiveUserService} from "../engine/shared/active-user/active-user.service";
import {UserLoaded} from "../engine/protocols/int-user-loaded";
import 'reflect-metadata';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements UserLoaded {

  id = 'appComponent';

  constructor(
    public activeUserService: ActiveUserService,
  ) {
    this.activeUserService.registerUserLoadedView(this)
  }

  userLoaded() {
  }

  userUnloaded() {
  }
}
