import {SOPostTemdisa} from '../../core/temdisa/so-post-temdisa';
import {TemdisaCore} from './temdisa.core';
import {SOGetTemdisa} from '../../core/temdisa/so-get-temdisa';
import {FormField, SelectionOption} from '../../../engine/shared/form-field';
import {postTemdisaFormFieldOptions} from './post-temdisa-form-fields';
import {AdapterProtocol} from '../../../engine/shared/entity-construction/adapter-protocol';
import {BasePost} from '../../../engine/shared/entity-construction/base-post';
import {ItemCore} from '../bv-item/item.core';
import {SOGetItem} from '../../core/item/so-get-item';
import {DistributorCore} from '../bv-distributor/distributor.core';
import {SOGetDistributor} from '../../core/distributor/so-get-distributor';
import {OrganizationCore} from '../bv-organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';

export class RAPostTemdisa extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postTemdisaFormFieldOptions);

  RequestClass = SOPostTemdisa

  constructor(
    public entityCore: TemdisaCore
  ) {
    super();
    if (postTemdisaFormFieldOptions.item) {
      postTemdisaFormFieldOptions.item.makeCore = true;
    }
    if (postTemdisaFormFieldOptions.distributor) {
      postTemdisaFormFieldOptions.distributor.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier(
        'item',
        SOGetItem,
      ),
      this.getFormValueFromIdentifier(
        'distributor',
        SOGetDistributor,
      ),
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}