import {SOPutLocation} from '../../core/location/so-put-location';
import {LocationCore} from './location.core';
import {SOGetLocation} from '../../core/location/so-get-location';
import {FormField, SelectionOption} from '../../../engine/shared/form-field';
import {putLocationFormFieldOptions} from './put-location-form-fields';
import {AdapterProtocol} from '../../../engine/shared/entity-construction/adapter-protocol';
import {BasePut} from '../../../engine/shared/entity-construction/base-put';

export class RAPutLocation extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putLocationFormFieldOptions);

  RequestClass = SOPutLocation

  constructor(
    public entityCore: LocationCore
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier('description'),
    )
  }

  prepareRequestWithQuickCreate(value) {
    this.prepareRequest();
    this.request.name = value
  }

  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}