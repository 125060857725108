import {FormField, FormFieldType, SelectionOption} from '../../../engine/shared/form-field';
import {EntityType} from "../../entity-types";


const valueField = new FormField(
  'value',
  'Value',
  null,
  FormFieldType.text,
  false,
)


export const putTemfieldFormFieldOptions: {[key: string]: FormField} = {
  value: valueField,
};