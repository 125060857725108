import {FormField, FormFieldType, SelectionOption} from '../../../engine/shared/form-field';
import {EntityType} from "../../entity-types";


const customFieldField = new FormField(
  'customField',
  'Custom field',
  EntityType.customField,
  FormFieldType.autoCompleteList,
  true,
)
const valueField = new FormField(
  'value',
  'Value',
  null,
  FormFieldType.text,
  true,
)
const itemField = new FormField(
  'item',
  'Item',
  EntityType.item,
  FormFieldType.autoCompleteList,
  true,
)


export const postTemfieldFormFieldOptions: {[key: string]: FormField} = {
  customField: customFieldField,
  value: valueField,
  item: itemField,
};