import {FormField, FormFieldType, SelectionOption} from '../../../engine/shared/form-field';
import {EntityType} from "../../entity-types";


const temlocaField = new FormField(
  'temloca',
  'Temloca',
  null,
  FormFieldType.text,
  true,
)
const loginStyleField = new FormField(
  'loginStyle',
  'Login style',
  null,
  FormFieldType.text,
  true,
)


export const postHelloFormFieldOptions: {[key: string]: FormField} = {
  temloca: temlocaField,
  loginStyle: loginStyleField,
};