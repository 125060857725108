import { SOGetOrganization } from '../organization/so-get-organization';


export class SOPostItem {

  constructor(
    public name: string,
    public organization: SOGetOrganization,
    public description: string = null,
    public barCode: string = null,
    public retailPrice: number = null,
    public cost: number = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostItem(
      'name' in params ? params.name : null,
      'description' in params ? params.description : null,
      'barCode' in params ? params.barCode : null,
      'retailPrice' in params ? params.retailPrice : null,
      'cost' in params ? params.cost : null,
      'organization' in params ? params.organization : null,
    );
  }
}
