import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';

import { SOPutIngest } from './so-put-ingest';
import { SOGetIngest } from './so-get-ingest';
import { SOPostIngest } from './so-post-ingest';
import {SORespIngest} from './so-resp-ingest';
import {SOPluralIngest} from './so-plural-ingest';

import {MMEntityService} from '../../../engine/multiverse/mm-entity-service';
import {EntityCoreProtocol} from '../../../engine/shared/entity-view/entity-core-protocol';
import {MMEntityServiceBase} from '../../../engine/multiverse/mm-entity-service-base';
import {HttpHeaders} from "@angular/common/http";


export class EOIngestService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'ingest',
      entityCore
    );
  }

  /** GET Ingest by id. Will 404 if id not found */
  get(request: SOGetIngest): Observable<SOPluralIngest> {
    return this.httpClient.get<SOPluralIngest>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralIngest, resp) }),
      tap(_ => this.log(`fetched Ingest`)),
      catchError(this.handleError<SOPluralIngest>(`get Ingest`))
    );
  }

  /** POST: add a new Ingest to the server */
  post(request: SOPostIngest): Observable<SORespIngest> {
    return this.httpClient.post<SORespIngest>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespIngest, resp) }),
      tap((response: SORespIngest) => this.log(`added Ingest w/ id=${response.uid}`)),
      catchError(this.handleError<SORespIngest>('Ingest post'))
    );
  }

  /** PUT: update Ingest  */
  put(request: SOPutIngest): Observable<SORespIngest> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespIngest>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespIngest, resp) }),
      tap((response: SORespIngest) => this.log(`edited Ingest w/ uid=${uid}`)),
      catchError(this.handleError<SORespIngest>('Ingest put'))
    );
  }

  delete(request: any): string {
    return 'NOT IMPLEMENTED';
  }
}
