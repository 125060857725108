import {SOPostUser} from '../../core/user/so-post-user';
import {UserCore} from './user.core';
import {SOGetUser} from '../../core/user/so-get-user';
import {FormField, SelectionOption} from '../../../engine/shared/form-field';
import {postUserFormFieldOptions} from './post-user-form-fields';
import {AdapterProtocol} from '../../../engine/shared/entity-construction/adapter-protocol';
import {BasePost} from '../../../engine/shared/entity-construction/base-post';

export class RAPostUser extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postUserFormFieldOptions);

  RequestClass = SOPostUser

  constructor(
    public entityCore: UserCore
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('username'),
      this.getFormValueFromIdentifier('password'),
      this.getFormValueFromIdentifier('givenName'),
      this.getFormValueFromIdentifier('surname'),
      this.getFormValueFromIdentifier('email'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}