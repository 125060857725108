
export enum BaseDetailType {
  text,
  embeddedList,
  pushedList,
  embeddedDetail,
  pushedDetail,
  link,
  file,
  bigText,
  signature,
  checkitem,
  header,
  button,
  submitButton,
  fetchField,
  checklistDownload,
  loadWeight,
  excelExport,
  warning,
  markdown,
  selection,
  date,
  datetime,
  toggle,
  chipList,
  toggleGroup,
}
