import {ActiveUserService} from '../../../engine/shared/active-user/active-user.service';
import {HttpClient} from '@angular/common/http';
import {EOSectionService} from '../../core/section/eo-section';
import {SectionListEntryFactory} from './section-list-entry-factory';
import {SectionDetailFactory} from './section-detail-factory';
import {SOGetSection} from '../../core/section/so-get-section';
import {RAPutSection} from './ra-put-section';
import {RAPostSection} from './ra-post-section';
import {RADeleteSection} from './ra-delete-section';
import {AbstractEntityCore} from "../../../engine/shared/entity-view/abstract-entity-core";
import {EntityCoreProtocol} from "../../../engine/shared/entity-view/entity-core-protocol";
import {EntityType} from '../../entity-types';
import {SPECTER_SPEC} from "../../specter-spec";

export class SectionCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = EntityType.section;
  title = 'Frequently asked question';
  listEntryFactory = new SectionListEntryFactory();
  detailFactory = new SectionDetailFactory();

  entityService: EOSectionService;
  soGetClass = SOGetSection;
  soGet: SOGetSection;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOSectionService
    );
    this.adapters = [
      new RAPostSection(this),
      new RAPutSection(this),
      new RADeleteSection(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetSection();
    this.soGet.queryOptions.limit = this.limit;
  }


}