import {FormField, FormFieldType, SelectionOption} from '../../../engine/shared/form-field';
import {EntityType} from "../../entity-types";
import {ENProgress} from '../../enums';


const refField = new FormField(
  'ref',
  'Ref',
  null,
  FormFieldType.text,
  false,
)
const statusField = new FormField(
  'status',
  'Status',
  null,
  FormFieldType.enumeration,
  false,
  true,
  ENProgress.pending
)
statusField.enumeration = ENProgress


export const putPickupFormFieldOptions: {[key: string]: FormField} = {
  ref: refField,
  status: statusField,
};