export enum EntityType {
  item = 'item',
  order = 'order',
  pickup = 'pickup',
  location = 'location',
  customField = 'customField',
  line = 'line',
  dispatch = 'dispatch',
  temloca = 'temloca',
  temfield = 'temfield',
  media = 'media',
  tag = 'tag',
  temaga = 'temaga',
  distributor = 'distributor',
  temdisa = 'temdisa',
  defaultCustomField = 'defaultCustomField',
  itemImport = 'itemImport',
  itemImportLine = 'itemImportLine',
  bulkitem = 'bulkitem',
  attachment = 'attachment',
  organization = 'organization',
  organizationInvite = 'organizationInvite',
  subscription = 'subscription',
  product = 'product',
  price = 'price',
  invoice = 'invoice',
  invoiceLine = 'invoiceLine',
  paymentIntent = 'paymentIntent',
  constraint = 'constraint',
  appliedConstraint = 'appliedConstraint',
  user = 'user',
  certificate = 'certificate',
  identity = 'identity',
  token = 'token',
  roleGrant = 'roleGrant',
  role = 'role',
  ingest = 'ingest',
  hello = 'hello',
  view = 'view',
  viewGrant = 'viewGrant',
  section = 'section',
  question = 'question',
  sesMessage = 'sesMessage',
}