import {ItemCore} from './entities/bv-item/item.core';
import {OrderCore} from './entities/bv-order/order.core';
import {PickupCore} from './entities/bv-pickup/pickup.core';
import {LocationCore} from './entities/bv-location/location.core';
import {CustomFieldCore} from './entities/bv-custom-field/custom-field.core';
import {LineCore} from './entities/bv-line/line.core';
import {DispatchCore} from './entities/bv-dispatch/dispatch.core';
import {TemlocaCore} from './entities/bv-temloca/temloca.core';
import {TemfieldCore} from './entities/bv-temfield/temfield.core';
import {MediaCore} from './entities/bv-media/media.core';
import {TagCore} from './entities/bv-tag/tag.core';
import {TemagaCore} from './entities/bv-temaga/temaga.core';
import {DistributorCore} from './entities/bv-distributor/distributor.core';
import {TemdisaCore} from './entities/bv-temdisa/temdisa.core';
import {DefaultCustomFieldCore} from './entities/bv-default-custom-field/default-custom-field.core';
import {ItemImportCore} from './entities/bv-item-import/item-import.core';
import {ItemImportLineCore} from './entities/bv-item-import-line/item-import-line.core';
import {BulkitemCore} from './entities/bv-bulkitem/bulkitem.core';
import {AttachmentCore} from './entities/bv-attachment/attachment.core';
import {OrganizationCore} from './entities/bv-organization/organization.core';
import {OrganizationInviteCore} from './entities/bv-organization-invite/organization-invite.core';
import {SubscriptionCore} from './entities/bv-subscription/subscription.core';
import {ProductCore} from './entities/bv-product/product.core';
import {PriceCore} from './entities/bv-price/price.core';
import {InvoiceCore} from './entities/bv-invoice/invoice.core';
import {InvoiceLineCore} from './entities/bv-invoice-line/invoice-line.core';
import {PaymentIntentCore} from './entities/bv-payment-intent/payment-intent.core';
import {ConstraintCore} from './entities/bv-constraint/constraint.core';
import {AppliedConstraintCore} from './entities/bv-applied-constraint/applied-constraint.core';
import {UserCore} from './entities/bv-user/user.core';
import {CertificateCore} from './entities/bv-certificate/certificate.core';
import {IdentityCore} from './entities/bv-identity/identity.core';
import {TokenCore} from './entities/bv-token/token.core';
import {RoleGrantCore} from './entities/bv-role-grant/role-grant.core';
import {RoleCore} from './entities/bv-role/role.core';
import {IngestCore} from './entities/bv-ingest/ingest.core';
import {HelloCore} from './entities/bv-hello/hello.core';
import {ViewCore} from './entities/bv-view/view.core';
import {ViewGrantCore} from './entities/bv-view-grant/view-grant.core';
import {SectionCore} from './entities/bv-section/section.core';
import {QuestionCore} from './entities/bv-question/question.core';
import {SesMessageCore} from './entities/bv-ses-message/ses-message.core';


export const entityCoreMap = {
  item: ItemCore,
  order: OrderCore,
  pickup: PickupCore,
  location: LocationCore,
  customField: CustomFieldCore,
  line: LineCore,
  dispatch: DispatchCore,
  temloca: TemlocaCore,
  temfield: TemfieldCore,
  media: MediaCore,
  tag: TagCore,
  temaga: TemagaCore,
  distributor: DistributorCore,
  temdisa: TemdisaCore,
  defaultCustomField: DefaultCustomFieldCore,
  itemImport: ItemImportCore,
  itemImportLine: ItemImportLineCore,
  bulkitem: BulkitemCore,
  attachment: AttachmentCore,
  organization: OrganizationCore,
  organizationInvite: OrganizationInviteCore,
  subscription: SubscriptionCore,
  product: ProductCore,
  price: PriceCore,
  invoice: InvoiceCore,
  invoiceLine: InvoiceLineCore,
  paymentIntent: PaymentIntentCore,
  constraint: ConstraintCore,
  appliedConstraint: AppliedConstraintCore,
  user: UserCore,
  certificate: CertificateCore,
  identity: IdentityCore,
  token: TokenCore,
  roleGrant: RoleGrantCore,
  role: RoleCore,
  ingest: IngestCore,
  hello: HelloCore,
  view: ViewCore,
  viewGrant: ViewGrantCore,
  section: SectionCore,
  question: QuestionCore,
  sesMessage: SesMessageCore,
};

export const entityCoreNameMap = {
  ItemCore: ItemCore,
  OrderCore: OrderCore,
  PickupCore: PickupCore,
  LocationCore: LocationCore,
  CustomFieldCore: CustomFieldCore,
  LineCore: LineCore,
  DispatchCore: DispatchCore,
  TemlocaCore: TemlocaCore,
  TemfieldCore: TemfieldCore,
  MediaCore: MediaCore,
  TagCore: TagCore,
  TemagaCore: TemagaCore,
  DistributorCore: DistributorCore,
  TemdisaCore: TemdisaCore,
  DefaultCustomFieldCore: DefaultCustomFieldCore,
  ItemImportCore: ItemImportCore,
  ItemImportLineCore: ItemImportLineCore,
  BulkitemCore: BulkitemCore,
  AttachmentCore: AttachmentCore,
  OrganizationCore: OrganizationCore,
  OrganizationInviteCore: OrganizationInviteCore,
  SubscriptionCore: SubscriptionCore,
  ProductCore: ProductCore,
  PriceCore: PriceCore,
  InvoiceCore: InvoiceCore,
  InvoiceLineCore: InvoiceLineCore,
  PaymentIntentCore: PaymentIntentCore,
  ConstraintCore: ConstraintCore,
  AppliedConstraintCore: AppliedConstraintCore,
  UserCore: UserCore,
  CertificateCore: CertificateCore,
  IdentityCore: IdentityCore,
  TokenCore: TokenCore,
  RoleGrantCore: RoleGrantCore,
  RoleCore: RoleCore,
  IngestCore: IngestCore,
  HelloCore: HelloCore,
  ViewCore: ViewCore,
  ViewGrantCore: ViewGrantCore,
  SectionCore: SectionCore,
  QuestionCore: QuestionCore,
  SesMessageCore: SesMessageCore,
};