

export class SOPutTemfield {

  constructor(
    public uid: string,
    public value: string = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutTemfield(
      'uid' in params ? params.uid : null,
      'value' in params ? params.value : null,
    );
  }
}
