import {Component, ElementRef, ViewChild} from '@angular/core';
import {AbstractAttributeView} from "../../../shared/abstract-attribute-view";
import {HttpClient} from "@angular/common/http";
import {tap} from "rxjs/operators";
import {FileUploadService} from "../../../shared/file-upload/file-upload.service";
import {ENMediaTypePrimary} from "../../../../app/enums";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {MEDIA_DEF_DISPLAY_NAME, MediaDef, SOMediaSpec} from "../../../multiverse/so-media-spec";

@Component({
  selector: 'app-sv-file-attribute-view',
  templateUrl: './sv-file-attribute-view.component.html',
  styleUrls: ['./sv-file-attribute-view.component.scss']
})
export class SvFileAttributeViewComponent extends AbstractAttributeView {

  fetchingFile = false;
  private cachedFile: SafeUrl;

  downloadLinks = []

  downloadImage

  _imageUrl
  get imageUrl() {
    if (this._imageUrl != null) {
      return this._imageUrl
    }
    if (this.cachedFile != null) {
      return this.cachedFile
    }

    let spec: SOMediaSpec = null
    if (this.detail.value != null) {
      spec = this.detail.value.find(obj => obj.definition == MediaDef.px800);
      if (spec == null) {
        spec = this.detail.value.find(obj => obj.definition == MediaDef.pxOrig)
      }
    }
    return spec != null ? spec.url : null
  }

  hasFile = false;
  // tslint:disable-next-line:variable-name
  _showDownload = false;
  anchorHref: string;
  anchorDownload: string;

  @ViewChild('anchor') anchor: ElementRef<HTMLAnchorElement>;

  get isImage() {
    return this.parentEntity && this.parentEntity.mediaTypePrimary === ENMediaTypePrimary.img
  }

  get showDownload() {

    return this.hasFile ? true : this._showDownload;
  }

  set showDownload(value) {
    this._showDownload = value;
  }

  get showUpload() {
    return this.detail.editEnabled;
  }

  constructor(
    public httpClient: HttpClient,
    private fileUploadService: FileUploadService,
    private sanitizer: DomSanitizer
  ) {
    super()
  }

  attributeSpecSet() {
    super.attributeSpecSet()
    if (this.detail.value != null) {

      for (const option of this.detail.value) {
        if (option.definition == MediaDef.pxOrig) {

        this.downloadLinks.push(
          {
            displayName: `Download ${MEDIA_DEF_DISPLAY_NAME[option.definition.valueOf()]}`,
            url: option.url,
            fileName: `${this.parentView.entityViewSpec.listEntry.entity.uid}-${option.definition.valueOf()}.jpg`
          }
        )
        }
      }
    }

    // if (this.parentView.entityViewSpec.listEntry) {
    //   const file = this.fileUploadService.getMedia(this.parentView.entityViewSpec.parentEntityViewSpec.parentEntityViewSpec.listEntry.entity.uid, this.parentView.entityViewSpec.listEntry.entity.name)
    //   if (file) {
    //     this.detail.value = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file));
    //   }
    // }
  }


  uploadFile($event) {
    console.log('uploading file');

    this.cachedFile = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL($event.target.files[0]));
    // this.imageUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL($event.target.files[0]));

    this.form.patchValue({
      [this.detail.editableFormField.identifierString]: $event.target.files[0].name
    })

    this.parentView.entityViewSpec.entityCore.postHelper.file = $event.target.files[0]

    // this.fileUploadService.addToQueue(this.parentEntity.uid, $event.target.files[0])

  }

  getFile(fileUrl: string) {
    this.fetchingFile = true;
    return this.httpClient.get(fileUrl, {responseType: 'blob'})
      .pipe(
        tap( // Log the result or error
          data => this.log(fileUrl, data),
          error => this.logError(fileUrl, error)
        )
      );
  }

  log(filename, data) {
    this.fetchingFile = false;

  }

  logError(filename, error) {
    this.fetchingFile = false;

  }


  downloadFile(fileUrl) {

    this.httpClient.get(fileUrl, {responseType: 'blob'})
      .pipe(
        tap( // Log the result or error
          data => this.log(fileUrl, data),
          error => this.logError(fileUrl, error)
        )
      ).subscribe( resp => {
      // let file = new Blob([resp], );
      let file = new File([resp], "filename.jpg", { type: 'image/jpeg' })
      let fileURL = URL.createObjectURL(file);

      this.anchor.nativeElement.download = `${this.parentView.entityViewSpec.listEntry.entity.uid}.jpg`
      this.anchor.nativeElement.href = fileURL
      this.anchor.nativeElement.click()

    })
  }
}

