import {ActiveUserService} from '../../../engine/shared/active-user/active-user.service';
import {HttpClient} from '@angular/common/http';
import {EOIdentityService} from '../../core/identity/eo-identity';
import {IdentityListEntryFactory} from './identity-list-entry-factory';
import {IdentityDetailFactory} from './identity-detail-factory';
import {SOGetIdentity} from '../../core/identity/so-get-identity';
import {RAPutIdentity} from './ra-put-identity';
import {RAPostIdentity} from './ra-post-identity';
import {RADeleteIdentity} from './ra-delete-identity';
import {AbstractEntityCore} from "../../../engine/shared/entity-view/abstract-entity-core";
import {EntityCoreProtocol} from "../../../engine/shared/entity-view/entity-core-protocol";
import {EntityType} from '../../entity-types';
import {SPECTER_SPEC} from "../../specter-spec";

export class IdentityCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = EntityType.identity;
  title = 'Identity';
  listEntryFactory = new IdentityListEntryFactory();
  detailFactory = new IdentityDetailFactory();

  entityService: EOIdentityService;
  soGetClass = SOGetIdentity;
  soGet: SOGetIdentity;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOIdentityService
    );
    this.adapters = [
      new RAPostIdentity(this),
      new RAPutIdentity(this),
      new RADeleteIdentity(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetIdentity();
    this.soGet.queryOptions.limit = this.limit;
  }


}