import { SOGetItem } from '../item/so-get-item';
import { SOGetTag } from '../tag/so-get-tag';
import { SOGetOrganization } from '../organization/so-get-organization';
import {MMQueryOptions} from '../../../engine/multiverse/mm-query-options';
import {QueryProtocol} from "../../../engine/protocols/query-protocol";


export class SOGetTemaga implements QueryProtocol {

  constructor(
    public uid: string = null,
    public item: SOGetItem = null,
    public tag: SOGetTag = null,
    public organization: SOGetOrganization = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetTemaga(
      'uid' in params ? params.uid : null,
      'item' in params ? params.item : null,
      'tag' in params ? params.tag : null,
      'organization' in params ? params.organization : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
