import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';

import { SOPostDispatch } from './so-post-dispatch';
import { SOGetDispatch } from './so-get-dispatch';
import { SOPutDispatch } from './so-put-dispatch';
import { SODeleteDispatch } from './so-delete-dispatch';
import {SORespDispatch} from './so-resp-dispatch';
import {SOPluralDispatch} from './so-plural-dispatch';

import {MMEntityService} from '../../../engine/multiverse/mm-entity-service';
import {EntityCoreProtocol} from '../../../engine/shared/entity-view/entity-core-protocol';
import {MMEntityServiceBase} from '../../../engine/multiverse/mm-entity-service-base';
import {HttpHeaders} from "@angular/common/http";


export class EODispatchService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'dispatch',
      entityCore
    );
  }

  /** GET Dispatch by id. Will 404 if id not found */
  get(request: SOGetDispatch): Observable<SOPluralDispatch> {
    return this.httpClient.get<SOPluralDispatch>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralDispatch, resp) }),
      tap(_ => this.log(`fetched Dispatch`)),
      catchError(this.handleError<SOPluralDispatch>(`get Dispatch`))
    );
  }

  /** POST: add a new Dispatch to the server */
  post(request: SOPostDispatch): Observable<SORespDispatch> {
    return this.httpClient.post<SORespDispatch>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespDispatch, resp) }),
      tap((response: SORespDispatch) => this.log(`added Dispatch w/ id=${response.uid}`)),
      catchError(this.handleError<SORespDispatch>('Dispatch post'))
    );
  }

  /** PUT: update Dispatch  */
  put(request: SOPutDispatch): Observable<SORespDispatch> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespDispatch>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespDispatch, resp) }),
      tap((response: SORespDispatch) => this.log(`edited Dispatch w/ uid=${uid}`)),
      catchError(this.handleError<SORespDispatch>('Dispatch put'))
    );
  }

  /** DELETE: delete the Dispatch from the server */
  delete(request: SODeleteDispatch): Observable<SORespDispatch> {
    return this.httpClient.delete<SORespDispatch>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Dispatch uid=${request.uid}`)),
      catchError(this.handleError<SORespDispatch>('delete Dispatch'))
    );
  }
}
