import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';

import { SOPostAttachment } from './so-post-attachment';
import { SOGetAttachment } from './so-get-attachment';
import { SOPutAttachment } from './so-put-attachment';
import { SODeleteAttachment } from './so-delete-attachment';
import {SORespAttachment} from './so-resp-attachment';
import {SOPluralAttachment} from './so-plural-attachment';

import {MMEntityService} from '../../../engine/multiverse/mm-entity-service';
import {EntityCoreProtocol} from '../../../engine/shared/entity-view/entity-core-protocol';
import {MMEntityServiceBase} from '../../../engine/multiverse/mm-entity-service-base';
import {HttpHeaders} from "@angular/common/http";


export class EOAttachmentService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'attachment',
      entityCore
    );
  }

  /** GET Attachment by id. Will 404 if id not found */
  get(request: SOGetAttachment): Observable<SOPluralAttachment> {
    return this.httpClient.get<SOPluralAttachment>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralAttachment, resp) }),
      tap(_ => this.log(`fetched Attachment`)),
      catchError(this.handleError<SOPluralAttachment>(`get Attachment`))
    );
  }

  /** POST: add a new Attachment to the server */
  post(request: SOPostAttachment): Observable<SORespAttachment> {
    return this.httpClient.post<SORespAttachment>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespAttachment, resp) }),
      tap((response: SORespAttachment) => this.log(`added Attachment w/ id=${response.uid}`)),
      catchError(this.handleError<SORespAttachment>('Attachment post'))
    );
  }

  /** PUT: update Attachment  */
  put(request: SOPutAttachment): Observable<SORespAttachment> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespAttachment>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespAttachment, resp) }),
      tap((response: SORespAttachment) => this.log(`edited Attachment w/ uid=${uid}`)),
      catchError(this.handleError<SORespAttachment>('Attachment put'))
    );
  }

  /** DELETE: delete the Attachment from the server */
  delete(request: SODeleteAttachment): Observable<SORespAttachment> {
    return this.httpClient.delete<SORespAttachment>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Attachment uid=${request.uid}`)),
      catchError(this.handleError<SORespAttachment>('delete Attachment'))
    );
  }
}
