import { Type } from 'class-transformer';
import {ENViewType} from '../../enums';
import {ObjectiveProtocol} from '../../../engine/protocols/objective-protocol';
import {EntityType} from "../../entity-types";


export class SORespView implements ObjectiveProtocol {

  entityType: EntityType = EntityType.view;
  uid: string;
  name: string;
  identifier: string;
  type: ENViewType = null;
}