import {FormField, FormFieldType, SelectionOption} from '../../../engine/shared/form-field';
import {EntityType} from "../../entity-types";


const completeField = new FormField(
  'complete',
  'Complete',
  null,
  FormFieldType.boolean,
  false,
  true,
  false
)
const notesField = new FormField(
  'notes',
  'Notes',
  null,
  FormFieldType.text,
  false,
)
const pickupField = new FormField(
  'pickup',
  'Pickup',
  EntityType.pickup,
  FormFieldType.autoCompleteList,
  false,
)


export const putDispatchFormFieldOptions: {[key: string]: FormField} = {
  complete: completeField,
  notes: notesField,
  pickup: pickupField,
};