import {SOPostRoleGrant} from '../../core/role-grant/so-post-role-grant';
import {RoleGrantCore} from './role-grant.core';
import {SOGetRoleGrant} from '../../core/role-grant/so-get-role-grant';
import {FormField, SelectionOption} from '../../../engine/shared/form-field';
import {postRoleGrantFormFieldOptions} from './post-role-grant-form-fields';
import {AdapterProtocol} from '../../../engine/shared/entity-construction/adapter-protocol';
import {BasePost} from '../../../engine/shared/entity-construction/base-post';
import {UserCore} from '../bv-user/user.core';
import {SOGetUser} from '../../core/user/so-get-user';
import {RoleCore} from '../bv-role/role.core';
import {SOGetRole} from '../../core/role/so-get-role';
import {OrganizationCore} from '../bv-organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';

export class RAPostRoleGrant extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postRoleGrantFormFieldOptions);

  RequestClass = SOPostRoleGrant

  constructor(
    public entityCore: RoleGrantCore
  ) {
    super();
    if (postRoleGrantFormFieldOptions.user) {
      postRoleGrantFormFieldOptions.user.makeCore = true;
    }
    if (postRoleGrantFormFieldOptions.role) {
      postRoleGrantFormFieldOptions.role.makeCore = true;
    }
    if (postRoleGrantFormFieldOptions.organization) {
      postRoleGrantFormFieldOptions.organization.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier(
        'user',
        SOGetUser,
      ),
      this.getFormValueFromIdentifier(
        'role',
        SOGetRole,
      ),
      this.getFormValueFromIdentifier('isDefault'),
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}