import { SOGetItemImport } from '../item-import/so-get-item-import';
import { SOGetOrganization } from '../organization/so-get-organization';
import {MMQueryOptions} from '../../../engine/multiverse/mm-query-options';
import {QueryProtocol} from "../../../engine/protocols/query-protocol";


export class SOGetItemImportLine implements QueryProtocol {

  constructor(
    public uid: string = null,
    public itemImport: SOGetItemImport = null,
    public organization: SOGetOrganization = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetItemImportLine(
      'uid' in params ? params.uid : null,
      'itemImport' in params ? params.itemImport : null,
      'organization' in params ? params.organization : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
