<input hidden type="file" accept=".pdf,.jpg,.png,.jpeg,.xls,.xlsx" #uploader (change)="uploadFile($event)"/>
<a #anchor hidden></a>
<div fxLayout="column" fxLayoutAlign="start stretch">
  <img *ngIf="imageUrl != null" [src]="imageUrl" alt="image">
  <div *ngFor="let option of downloadLinks">
<!--    <a [href]="option.url" [download]="option.fileName">{{option.displayName}}</a>-->
    <button mat-stroked-button (click)="downloadFile(option.url)" >{{option.displayName}}</button>
  </div>
<!--  <img *ngIf="imageUrl" [src]="imageUrl" alt="{{detail.name}}">-->
  <a mat-stroked-button *ngIf="showDownload"
     href="{{detail.value}}" target="_blank" rel="noopener noreferrer">
    Download {{detail.name}}
  </a>
  <mat-progress-bar *ngIf="fetchingFile" mode="indeterminate" color="primary"></mat-progress-bar>
  <div *ngIf="!fetchingFile" class="progress-bar"></div>
  <button mat-stroked-button *ngIf="showUpload" (click)="uploader.click()">
    Upload {{detail.name}}
  </button>
</div>

<form hidden [formGroup]="form">
  <mat-form-field
    *ngIf="detail.editEnabled"
    appearance="{{detail.editableFormField.appearance}}"
    color="{{detail.editableFormField.color}}">
    <mat-label>{{detail.editableFormField.getLabel(parentView.entityViewSpec)}}</mat-label>
    <input matInput
           [type]="detail.editableFormField.inputType"
           [id]="detail.editableFormField.identifierString"
           [name]="detail.editableFormField.identifierString"
           [formControlName]="detail.editableFormField.identifierString"
           (keyup)="onSelectionChange()">
  </mat-form-field>

</form>
