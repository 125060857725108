import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';

import { SOPostPaymentIntent } from './so-post-payment-intent';
import { SOGetPaymentIntent } from './so-get-payment-intent';
import { SOPutPaymentIntent } from './so-put-payment-intent';
import { SODeletePaymentIntent } from './so-delete-payment-intent';
import {SORespPaymentIntent} from './so-resp-payment-intent';
import {SOPluralPaymentIntent} from './so-plural-payment-intent';

import {MMEntityService} from '../../../engine/multiverse/mm-entity-service';
import {EntityCoreProtocol} from '../../../engine/shared/entity-view/entity-core-protocol';
import {MMEntityServiceBase} from '../../../engine/multiverse/mm-entity-service-base';
import {HttpHeaders} from "@angular/common/http";


export class EOPaymentIntentService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'payment_intent',
      entityCore
    );
  }

  /** GET PaymentIntent by id. Will 404 if id not found */
  get(request: SOGetPaymentIntent): Observable<SOPluralPaymentIntent> {
    return this.httpClient.get<SOPluralPaymentIntent>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralPaymentIntent, resp) }),
      tap(_ => this.log(`fetched PaymentIntent`)),
      catchError(this.handleError<SOPluralPaymentIntent>(`get PaymentIntent`))
    );
  }

  /** POST: add a new PaymentIntent to the server */
  post(request: SOPostPaymentIntent): Observable<SORespPaymentIntent> {
    return this.httpClient.post<SORespPaymentIntent>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespPaymentIntent, resp) }),
      tap((response: SORespPaymentIntent) => this.log(`added PaymentIntent w/ id=${response.uid}`)),
      catchError(this.handleError<SORespPaymentIntent>('PaymentIntent post'))
    );
  }

  /** PUT: update PaymentIntent  */
  put(request: SOPutPaymentIntent): Observable<SORespPaymentIntent> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespPaymentIntent>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespPaymentIntent, resp) }),
      tap((response: SORespPaymentIntent) => this.log(`edited PaymentIntent w/ uid=${uid}`)),
      catchError(this.handleError<SORespPaymentIntent>('PaymentIntent put'))
    );
  }

  /** DELETE: delete the PaymentIntent from the server */
  delete(request: SODeletePaymentIntent): Observable<SORespPaymentIntent> {
    return this.httpClient.delete<SORespPaymentIntent>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted PaymentIntent uid=${request.uid}`)),
      catchError(this.handleError<SORespPaymentIntent>('delete PaymentIntent'))
    );
  }
}
