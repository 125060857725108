import {ActiveUserService} from '../../../engine/shared/active-user/active-user.service';
import {HttpClient} from '@angular/common/http';
import {EOTemlocaService} from '../../core/temloca/eo-temloca';
import {TemlocaListEntryFactory} from './temloca-list-entry-factory';
import {TemlocaDetailFactory} from './temloca-detail-factory';
import {SOGetTemloca} from '../../core/temloca/so-get-temloca';
import {RAPutTemloca} from './ra-put-temloca';
import {RAPostTemloca} from './ra-post-temloca';
import {RADeleteTemloca} from './ra-delete-temloca';
import {AbstractEntityCore} from "../../../engine/shared/entity-view/abstract-entity-core";
import {EntityCoreProtocol} from "../../../engine/shared/entity-view/entity-core-protocol";
import {EntityType} from '../../entity-types';
import {SPECTER_SPEC} from "../../specter-spec";
import {SOGetOrganization} from '../../core/organization/so-get-organization'

export class TemlocaCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = EntityType.temloca;
  title = 'Item locations';
  listEntryFactory = new TemlocaListEntryFactory();
  detailFactory = new TemlocaDetailFactory();

  entityService: EOTemlocaService;
  soGetClass = SOGetTemloca;
  soGet: SOGetTemloca;

  showQuickAdd = true;

  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOTemlocaService
    );
    this.adapters = [
      new RAPostTemloca(this),
      new RAPutTemloca(this),
      new RADeleteTemloca(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetTemloca();
    this.soGet.organization = new SOGetOrganization(
      this.activeUserService.getScope('organization').uid
    );
    this.soGet.queryOptions.limit = this.limit;
  }


}