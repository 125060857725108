import {SORespCertificate} from '../../core/certificate/so-resp-certificate';
import {CertificateCore} from './certificate.core';
import {ListEntry} from '../../../engine/shared/list-entry';
import {EntityAttributesForDisplay} from '../../../engine/shared/entity-attributes-for-display';
import {DetailEntry, DetailFactory, DetailFactoryProtocol, EditableType} from '../../../engine/shared/detail-entry';
import {ActiveUserService} from '../../../engine/shared/active-user/active-user.service';
import {BaseDetailType} from "../../../engine/shared/base-detail";
import {EntityViewSpecProtocol} from "../../../engine/shared/entity-view/entity-view-spec-protocol";
import {getHeading} from "../../../engine/shared/display-option-utilities";
import {putCertificateFormFieldOptions} from './put-certificate-form-fields';
import {SOGetCertificate} from '../../core/certificate/so-get-certificate';
import {SOGetUser} from '../../core/user/so-get-user';
import {SOGetToken} from '../../core/token/so-get-token';
import {DisplayVectorStringFactory} from "../../../engine/config/display-vector";
import {displayOptions} from "../../display-options";
import {ListSpecType} from "../../../engine/shared/entity-view/list-spec";
import {SPECTER_SPEC} from "../../specter-spec";
import {getCore} from "../../../engine/shared/entity-view/get-core";

export class CertificateDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespCertificate = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(displayOptions);
    let heading;

    const attributes = []

    const dateCreatedDetail = new DetailEntry(
        'Date created',
        activeUserService.time.niceDate(entity.dateCreated),
        'dateCreated',
        BaseDetailType.text,
        null,
        EditableType.never,
    )
    attributes.push(dateCreatedDetail)

    const credentialDetail = new DetailEntry(
        'Credential',
        entity.credential,
        'credential',
        BaseDetailType.text,
        null,
        EditableType.always,
        putCertificateFormFieldOptions.credential
    )
    attributes.push(credentialDetail)


    const userCore = getCore(SPECTER_SPEC, activeUserService, listEntry.entityCore.httpClient, 'UserCore');
    userCore.soGet = SOGetUser.construct({
    });
    heading = getHeading(entityViewSpec.entityCore, userCore)
    userCore.title = heading ? heading : userCore.title
    userCore.listTitle = 'User'

    if (entity.user) {
        displayStringFactory.reconfigure(entity.user, entityViewSpec.entityCore.type)
    }
    userCore.configureForDisplay(entityViewSpec)

    const userDetail = new DetailEntry(
      heading ? heading : userCore.listTitle,
      entity.user ? displayStringFactory.primaryString : null,
      'user',
      BaseDetailType.pushedDetail,
      userCore,
      EditableType.always,
      putCertificateFormFieldOptions.user
    )
    if (entity.user) {
      userDetail.cachedListEntry = userCore.makeListEntry(entityViewSpec, userCore, entity.user, 0)
    }
    userDetail.singleFieldSelect = true
    attributes.push(userDetail);

    const tokensCore = getCore(SPECTER_SPEC, activeUserService, listEntry.entityCore.httpClient, 'TokenCore');
    tokensCore.soGet = SOGetToken.construct({
    });
    heading = getHeading(entityViewSpec.entityCore, tokensCore)
    tokensCore.title = heading ? heading : tokensCore.title
    tokensCore.listTitle = 'Tokens'

    tokensCore.configureForDisplay(entityViewSpec)

    const tokensDetail = new DetailEntry(
      heading ? heading : tokensCore.listTitle,
      null,
      'tokens',
      BaseDetailType.embeddedList,
      tokensCore,
      EditableType.always,
      putCertificateFormFieldOptions.tokens
    )
    attributes.push(tokensDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}