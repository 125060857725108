import {ENSesMessageType} from '../../enums';
import {MMQueryOptions} from '../../../engine/multiverse/mm-query-options';
import {QueryProtocol} from "../../../engine/protocols/query-protocol";


export class SOGetSesMessage implements QueryProtocol {

  constructor(
    public uid: string = null,
    public messageType: ENSesMessageType[] = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetSesMessage(
      'uid' in params ? params.uid : null,
      'messageType' in params ? params.messageType : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
