import {SORespSesMessage} from '../../core/ses-message/so-resp-ses-message';
import {SesMessageCore} from './ses-message.core';
import {ListEntry} from '../../../engine/shared/list-entry';
import {EntityAttributesForDisplay} from '../../../engine/shared/entity-attributes-for-display';
import {DetailEntry, DetailFactory, DetailFactoryProtocol, EditableType} from '../../../engine/shared/detail-entry';
import {ActiveUserService} from '../../../engine/shared/active-user/active-user.service';
import {BaseDetailType} from "../../../engine/shared/base-detail";
import {EntityViewSpecProtocol} from "../../../engine/shared/entity-view/entity-view-spec-protocol";
import {getHeading} from "../../../engine/shared/display-option-utilities";
import {putSesMessageFormFieldOptions} from './put-ses-message-form-fields';
import {SOGetSesMessage} from '../../core/ses-message/so-get-ses-message';
import {DisplayVectorStringFactory} from "../../../engine/config/display-vector";
import {displayOptions} from "../../display-options";
import {ListSpecType} from "../../../engine/shared/entity-view/list-spec";
import {SPECTER_SPEC} from "../../specter-spec";
import {getCore} from "../../../engine/shared/entity-view/get-core";

export class SesMessageDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespSesMessage = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(displayOptions);
    let heading;

    const attributes = []

    const dataDetail = new DetailEntry(
        'Data',
        entity.data,
        'data',
        BaseDetailType.text,
        null,
        EditableType.always,
        putSesMessageFormFieldOptions.data
    )
    attributes.push(dataDetail)

    const dateCreatedDetail = new DetailEntry(
        'Date created',
        activeUserService.time.niceDate(entity.dateCreated),
        'dateCreated',
        BaseDetailType.text,
        null,
        EditableType.never,
    )
    attributes.push(dateCreatedDetail)

    const messageTypeDetail = new DetailEntry(
        'Message type',
        entity.messageType,
        'messageType',
        BaseDetailType.toggleGroup,
        null,
        EditableType.always,
        putSesMessageFormFieldOptions.messageType
    )
    attributes.push(messageTypeDetail)


    return new EntityAttributesForDisplay(attributes);

  }
}