import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';

import { SOPostRole } from './so-post-role';
import { SOGetRole } from './so-get-role';
import { SOPutRole } from './so-put-role';
import { SODeleteRole } from './so-delete-role';
import {SORespRole} from './so-resp-role';
import {SOPluralRole} from './so-plural-role';

import {MMEntityService} from '../../../engine/multiverse/mm-entity-service';
import {EntityCoreProtocol} from '../../../engine/shared/entity-view/entity-core-protocol';
import {MMEntityServiceBase} from '../../../engine/multiverse/mm-entity-service-base';
import {HttpHeaders} from "@angular/common/http";


export class EORoleService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'role',
      entityCore
    );
  }

  /** GET Role by id. Will 404 if id not found */
  get(request: SOGetRole): Observable<SOPluralRole> {
    return this.httpClient.get<SOPluralRole>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralRole, resp) }),
      tap(_ => this.log(`fetched Role`)),
      catchError(this.handleError<SOPluralRole>(`get Role`))
    );
  }

  /** POST: add a new Role to the server */
  post(request: SOPostRole): Observable<SORespRole> {
    return this.httpClient.post<SORespRole>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespRole, resp) }),
      tap((response: SORespRole) => this.log(`added Role w/ id=${response.uid}`)),
      catchError(this.handleError<SORespRole>('Role post'))
    );
  }

  /** PUT: update Role  */
  put(request: SOPutRole): Observable<SORespRole> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespRole>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespRole, resp) }),
      tap((response: SORespRole) => this.log(`edited Role w/ uid=${uid}`)),
      catchError(this.handleError<SORespRole>('Role put'))
    );
  }

  /** DELETE: delete the Role from the server */
  delete(request: SODeleteRole): Observable<SORespRole> {
    return this.httpClient.delete<SORespRole>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Role uid=${request.uid}`)),
      catchError(this.handleError<SORespRole>('delete Role'))
    );
  }
}
