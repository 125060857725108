import {EntityViewSpecProtocol} from "../../../../engine/shared/entity-view/entity-view-spec-protocol";
import {LineCore} from "../../../entities/bv-line/line.core";
import {EntityType} from "../../../entity-types";

export class LineProcessor extends LineCore {

  configureForDisplay(entityViewSpec: EntityViewSpecProtocol) {
    super.configureForDisplay(entityViewSpec);
    if (entityViewSpec.entityCore.type === EntityType.item) {
      this.showAdd = false;
      this.showQuickAdd = false;
      this.listHeaderSpec.showPostButton = false;
    }
  }
}
