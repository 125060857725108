import {AttributeViewType} from "./attribute-view-type";


export const formFieldTypeToViewTypeMap = {
  text: AttributeViewType.text,
  bigText: AttributeViewType.textarea,
  selection: AttributeViewType.selection,
  date: AttributeViewType.date,
  boolean: AttributeViewType.toggle,
  checkitem: AttributeViewType.checkbox,
  info: null,
  multiLineInfo: null,
  fetch: null,
  datetime: AttributeViewType.datetime,
  markdown: AttributeViewType.markdown,
  enumeration: AttributeViewType.toggleGroup,
  password: AttributeViewType.text,
  pushedSelectionList: AttributeViewType.pushedEntityList,
  autoCompleteList: AttributeViewType.pushedEntity,
  file: AttributeViewType.file,
}

export const detailTypeToViewTypeMap = {
  text: AttributeViewType.text,
  embeddedList: AttributeViewType.embeddedList,
  pushedList: AttributeViewType.pushedEntityList,
  embeddedDetail: AttributeViewType.embeddedEntity,
  pushedDetail: AttributeViewType.pushedEntity,
  link: AttributeViewType.link,
  file: AttributeViewType.file,
  bigText: AttributeViewType.textarea,
  signature: AttributeViewType.signature,
  checkitem: AttributeViewType.checkbox,
  header: null,
  button: null,
  submitButton: null,
  fetchField: null,
  checklistDownload: null,
  excelExport: null,
  warning: null,
  markdown: AttributeViewType.markdown,
  selection: AttributeViewType.selection,
  date: AttributeViewType.date,
  datetime: AttributeViewType.datetime,
  toggle: AttributeViewType.toggle,
  toggleGroup: AttributeViewType.toggleGroup,
}
