import {ActiveUserService} from '../../../engine/shared/active-user/active-user.service';
import {HttpClient} from '@angular/common/http';
import {EOLocationService} from '../../core/location/eo-location';
import {LocationListEntryFactory} from './location-list-entry-factory';
import {LocationDetailFactory} from './location-detail-factory';
import {SOGetLocation} from '../../core/location/so-get-location';
import {RAPutLocation} from './ra-put-location';
import {RAPostLocation} from './ra-post-location';
import {RADeleteLocation} from './ra-delete-location';
import {AbstractEntityCore} from "../../../engine/shared/entity-view/abstract-entity-core";
import {EntityCoreProtocol} from "../../../engine/shared/entity-view/entity-core-protocol";
import {EntityType} from '../../entity-types';
import {SPECTER_SPEC} from "../../specter-spec";
import {FormControl, FormGroup} from "@angular/forms";
import {SOGetOrganization} from '../../core/organization/so-get-organization'

export class LocationCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = EntityType.location;
  title = 'Location';
  listEntryFactory = new LocationListEntryFactory();
  detailFactory = new LocationDetailFactory();

  entityService: EOLocationService;
  soGetClass = SOGetLocation;
  soGet: SOGetLocation;

  searchEnabled = true;

  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOLocationService
    );
    this.adapters = [
      new RAPostLocation(this),
      new RAPutLocation(this),
      new RADeleteLocation(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetLocation();
    this.soGet.organization = new SOGetOrganization(
      this.activeUserService.getScope('organization').uid
    );
    this.soGet.queryOptions.limit = this.limit;
    this.soGet.queryOptions.sortKey = 'dateCreated';
    this.soGet.queryOptions.descending = true;
  }


}