import { Component, OnInit } from '@angular/core';
import {AbstractAttributeView} from "../../../shared/abstract-attribute-view";

@Component({
  selector: 'app-sv-embedded-entity-list-attribute-view',
  templateUrl: './sv-embedded-entity-list-attribute-view.component.html',
  styleUrls: ['./sv-embedded-entity-list-attribute-view.component.scss']
})
export class SvEmbeddedEntityListAttributeViewComponent extends AbstractAttributeView {

}
