import {SORespHello} from '../../core/hello/so-resp-hello';
import {HelloCore} from './hello.core';
import {ListEntry} from '../../../engine/shared/list-entry';
import {EntityAttributesForDisplay} from '../../../engine/shared/entity-attributes-for-display';
import {DetailEntry, DetailFactory, DetailFactoryProtocol, EditableType} from '../../../engine/shared/detail-entry';
import {ActiveUserService} from '../../../engine/shared/active-user/active-user.service';
import {BaseDetailType} from "../../../engine/shared/base-detail";
import {EntityViewSpecProtocol} from "../../../engine/shared/entity-view/entity-view-spec-protocol";
import {getHeading} from "../../../engine/shared/display-option-utilities";
import {putHelloFormFieldOptions} from './put-hello-form-fields';
import {SOGetHello} from '../../core/hello/so-get-hello';
import {DisplayVectorStringFactory} from "../../../engine/config/display-vector";
import {displayOptions} from "../../display-options";
import {ListSpecType} from "../../../engine/shared/entity-view/list-spec";
import {SPECTER_SPEC} from "../../specter-spec";
import {getCore} from "../../../engine/shared/entity-view/get-core";

export class HelloDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespHello = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(displayOptions);
    let heading;

    const attributes = []

    const temlocaDetail = new DetailEntry(
        'Temloca',
        entity.temloca,
        'temloca',
        BaseDetailType.text,
        null,
        EditableType.always,
        putHelloFormFieldOptions.temloca
    )
    attributes.push(temlocaDetail)

    const loginStyleDetail = new DetailEntry(
        'Login style',
        entity.loginStyle,
        'loginStyle',
        BaseDetailType.text,
        null,
        EditableType.always,
        putHelloFormFieldOptions.loginStyle
    )
    attributes.push(loginStyleDetail)


    return new EntityAttributesForDisplay(attributes);

  }
}