import { Type } from 'class-transformer';
import { SORespItem } from '../item/so-resp-item';
import { SORespOrder } from '../order/so-resp-order';
import {ENDispatchStatus} from '../../enums';
import {ObjectiveProtocol} from '../../../engine/protocols/objective-protocol';
import {EntityType} from "../../entity-types";


export class SORespLine implements ObjectiveProtocol {

  entityType: EntityType = EntityType.line;
  uid: string;
  dispatchStatus: ENDispatchStatus = ENDispatchStatus.needsDispatchAssignment;
  quantity: number;
  @Type(() => SORespItem)
  item: SORespItem;
  @Type(() => SORespOrder)
  order: SORespOrder;
  unitSalePrice: number = null;
  unitCost: number = null;
  notes: string = null;
}