import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';

import { SOPostTemfield } from './so-post-temfield';
import { SOGetTemfield } from './so-get-temfield';
import { SOPutTemfield } from './so-put-temfield';
import { SODeleteTemfield } from './so-delete-temfield';
import {SORespTemfield} from './so-resp-temfield';
import {SOPluralTemfield} from './so-plural-temfield';

import {MMEntityService} from '../../../engine/multiverse/mm-entity-service';
import {EntityCoreProtocol} from '../../../engine/shared/entity-view/entity-core-protocol';
import {MMEntityServiceBase} from '../../../engine/multiverse/mm-entity-service-base';
import {HttpHeaders} from "@angular/common/http";


export class EOTemfieldService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'temfield',
      entityCore
    );
  }

  /** GET Temfield by id. Will 404 if id not found */
  get(request: SOGetTemfield): Observable<SOPluralTemfield> {
    return this.httpClient.get<SOPluralTemfield>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralTemfield, resp) }),
      tap(_ => this.log(`fetched Temfield`)),
      catchError(this.handleError<SOPluralTemfield>(`get Temfield`))
    );
  }

  /** POST: add a new Temfield to the server */
  post(request: SOPostTemfield): Observable<SORespTemfield> {
    return this.httpClient.post<SORespTemfield>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespTemfield, resp) }),
      tap((response: SORespTemfield) => this.log(`added Temfield w/ id=${response.uid}`)),
      catchError(this.handleError<SORespTemfield>('Temfield post'))
    );
  }

  /** PUT: update Temfield  */
  put(request: SOPutTemfield): Observable<SORespTemfield> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespTemfield>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespTemfield, resp) }),
      tap((response: SORespTemfield) => this.log(`edited Temfield w/ uid=${uid}`)),
      catchError(this.handleError<SORespTemfield>('Temfield put'))
    );
  }

  /** DELETE: delete the Temfield from the server */
  delete(request: SODeleteTemfield): Observable<SORespTemfield> {
    return this.httpClient.delete<SORespTemfield>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Temfield uid=${request.uid}`)),
      catchError(this.handleError<SORespTemfield>('delete Temfield'))
    );
  }
}
