<div fxLayout="row" class="route">
  <mat-progress-bar *ngIf="loading" mode="indeterminate" color="primary"></mat-progress-bar>
  <app-sv-entity-view *ngFor="let entityViewSpec of visibleEntityViewSpecs"
                      [entityViewSpec]="entityViewSpec"
                      [parentRouteView]="this"
                      class="core"
                      style="width:{{width}}%">

  </app-sv-entity-view>
</div>
