import {UserCore} from "../../../../../app/entities/bv-user/user.core";
import {EntityViewSpecProtocol} from "../../../../shared/entity-view/entity-view-spec-protocol";
import {ListEntry} from "../../../../shared/list-entry";
import {EntityAttributesForDisplay} from "../../../../shared/entity-attributes-for-display";
import {DetailEntry, EditableType} from "../../../../shared/detail-entry";
import {BaseDetailType} from "../../../../shared/base-detail";
import {Button, ButtonPressProtocol, HeaderSpec} from "../../../../shared/header-spec";
import {RAPutUser} from "../../../../../app/entities/bv-user/ra-put-user";
import {FormField, FormFieldType, matchValues} from "../../../../shared/form-field";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {SvEntityAttributesViewComponent} from "../../../entity/sv-entity-attributes-view/sv-entity-attributes-view.component";
import {TokenCore} from "../../../../../app/entities/bv-token/token.core";
import {SOPostToken} from "../../../../../app/core/token/so-post-token";
import {SOGetUser} from "../../../../../app/core/user/so-get-user";
import {SOGetRoleGrant} from "../../../../../app/core/role-grant/so-get-role-grant";


class PutPassword extends RAPutUser {

  getFormGroup() {
    return new FormGroup({
      currentPassword: new FormControl(
        null,
        [
          Validators.required,
        ]
      ),
      password: new FormControl(
        null,
        [
          Validators.required,
        ]
      ),
      confirmedPassword: new FormControl(
        null,
        [
          Validators.required,
          matchValues('password'),
        ]
      ),
    });
  }

  postRequestSuccessfullyCompleteProcessing() {
    this.resetForm();
  }
}


export class PasswordProcessor extends UserCore implements ButtonPressProtocol {

  autoSave = false

  setDefaultAdapters() {
    super.setDefaultAdapters()
    this.putHelper = new PutPassword(this)
  }

  makeEntityAttributesForDisplay(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry): EntityAttributesForDisplay {

    this.selectedEntity = listEntry.entity;

    const currentPasswordField = new FormField(
      'currentPassword',
      'Current password',
      null,
      FormFieldType.password,
      true,
    )
    currentPasswordField.inputType = 'password'
    const currentPassword = new DetailEntry(
      'Current password',
      null,
      'currentPassword',
      BaseDetailType.text,
      null,
      EditableType.always,
      currentPasswordField
    )

    const newPasswordField = new FormField(
      'password',
      'New password',
      null,
      FormFieldType.password,
      true,
    )
    newPasswordField.inputType = 'password'
    const newPassword = new DetailEntry(
      'New password',
      null,
      'password',
      BaseDetailType.text,
      null,
      EditableType.always,
      newPasswordField
    )

    const confirmNewPasswordField = new FormField(
      'confirmedPassword',
      'Confirm new password',
      null,
      FormFieldType.password,
      true,
    )
    confirmNewPasswordField.inputType = 'password'
    const confirmNewPassword = new DetailEntry(
      'Confirm new password',
      null,
      'confirmedPassword',
      BaseDetailType.text,
      null,
      EditableType.always,
      confirmNewPasswordField
    )

    const submitDetail = new DetailEntry(
      'Submit',
      null,
      'submit',
      BaseDetailType.submitButton,
      null,
      EditableType.always,
    )
    submitDetail.button = new Button('submit', 'Submit')
    submitDetail.button.buttonPressHandler = this;

    this.putHelper.formFieldOptions = [
      currentPasswordField, newPasswordField, confirmNewPasswordField
    ]

    return new EntityAttributesForDisplay(
      [currentPassword, newPassword, confirmNewPassword, submitDetail]
    )
  }

  buttonPress(button: Button): void {
    if (button.identifier === 'submit') {
      this.entityAttributesViewDelegate.loading = true;
      console.log('submit button')
      const tokenCore = new TokenCore(this.activeUserService, this.httpClient)
      tokenCore.entityService.entityCore = this;
      tokenCore.entityService.post(
        new SOPostToken(
          null,
          new SOGetRoleGrant(
            null,
            null,
            null,
            new SOGetUser(null, this.selectedEntity.username, this.putHelper.form.value.currentPassword)
          )
        )
      ).subscribe(resp => {
        if (resp) {
          this.putHelper.submitForm();
        }
      })
    }
  }

  makeHeaderSpecForEntityAttributesView(entityViewSpec: EntityViewSpecProtocol): HeaderSpec {
    const spec = super.makeHeaderSpecForEntityAttributesView(entityViewSpec);
    spec.heading = 'Change password';
    spec.showDetailsButtonEnabled = false;
    spec.showDelete = false;
    spec.editButton.show = false;
    return spec
  }

  setEditingEnabled(entityViewSpec: EntityViewSpecProtocol, parentView: SvEntityAttributesViewComponent) {
    parentView.enableEditing();
  }

  finalOverrides(entityViewSpec: EntityViewSpecProtocol, parentView: SvEntityAttributesViewComponent) {
    parentView.headerSpec.saveButton.show = false;
    parentView.closeDetail = true;
    this.entityAttributesViewDelegate = parentView;
  }
}
