import {EntityCoreProtocol} from "../../../../engine/shared/entity-view/entity-core-protocol";
import {ListEntry} from "../../../../engine/shared/list-entry";
import {EntityViewSpecProtocol} from "../../../../engine/shared/entity-view/entity-view-spec-protocol";
import {ItemImportLineCore} from "../../../entities/bv-item-import-line/item-import-line.core";
import {SORespItemImportLine} from "../../../core/item-import-line/so-resp-item-import-line";

export class ItemImportLineProcessor extends ItemImportLineCore {

  showAdd = false
  showDelete = false;

  makeListEntry(
    parentEntityViewSpec: EntityViewSpecProtocol,
    entityCore: EntityCoreProtocol,
    entity: SORespItemImportLine,
    section: number = 0
  ): ListEntry {

    return new ListEntry(
      entityCore,
      entity,
      [entity.dispatch.temloca.item.name],
      [`${entity.dispatch.temloca.location.name} X ${entity.dispatch.quantity}`],
      this.activeUserService.time.niceShortDateAndTime(entity.dateCreated),
      section
    );
  }

}
