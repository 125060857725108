import {Component, Input} from '@angular/core';
import {ListEntry} from '../../shared/list-entry';
import {ActiveUserService} from '../../shared/active-user/active-user.service';
import {SvCalendarComponent} from '../sv-calendar/sv-calendar.component';
import {HttpClient} from '@angular/common/http';


@Component({
  selector: 'app-sv-event',
  templateUrl: './sv-event.component.html',
  styleUrls: ['./sv-event.component.css']
})
export class SvEventComponent {
  @Input()
  parent: SvCalendarComponent;

  @Input()
  listEntry: ListEntry;
  loading = false;


  constructor(
    public activeUserService: ActiveUserService,
    private httpClient: HttpClient
  ) {
  }

  reserve() {

  }

  cancel() {
  }
}
