import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';

import { SOPostOrganization } from './so-post-organization';
import { SOGetOrganization } from './so-get-organization';
import { SOPutOrganization } from './so-put-organization';
import { SODeleteOrganization } from './so-delete-organization';
import {SORespOrganization} from './so-resp-organization';
import {SOPluralOrganization} from './so-plural-organization';

import {MMEntityService} from '../../../engine/multiverse/mm-entity-service';
import {EntityCoreProtocol} from '../../../engine/shared/entity-view/entity-core-protocol';
import {MMEntityServiceBase} from '../../../engine/multiverse/mm-entity-service-base';
import {HttpHeaders} from "@angular/common/http";


export class EOOrganizationService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'organization',
      entityCore
    );
  }

  /** GET Organization by id. Will 404 if id not found */
  get(request: SOGetOrganization): Observable<SOPluralOrganization> {
    return this.httpClient.get<SOPluralOrganization>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralOrganization, resp) }),
      tap(_ => this.log(`fetched Organization`)),
      catchError(this.handleError<SOPluralOrganization>(`get Organization`))
    );
  }

  /** POST: add a new Organization to the server */
  post(request: SOPostOrganization): Observable<SORespOrganization> {
    return this.httpClient.post<SORespOrganization>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespOrganization, resp) }),
      tap((response: SORespOrganization) => this.log(`added Organization w/ id=${response.uid}`)),
      catchError(this.handleError<SORespOrganization>('Organization post'))
    );
  }

  /** PUT: update Organization  */
  put(request: SOPutOrganization): Observable<SORespOrganization> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespOrganization>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespOrganization, resp) }),
      tap((response: SORespOrganization) => this.log(`edited Organization w/ uid=${uid}`)),
      catchError(this.handleError<SORespOrganization>('Organization put'))
    );
  }

  /** DELETE: delete the Organization from the server */
  delete(request: SODeleteOrganization): Observable<SORespOrganization> {
    return this.httpClient.delete<SORespOrganization>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Organization uid=${request.uid}`)),
      catchError(this.handleError<SORespOrganization>('delete Organization'))
    );
  }
}
