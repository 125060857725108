import {EntityViewSpecProtocol} from "../../../../engine/shared/entity-view/entity-view-spec-protocol";
import {SubscriptionCore} from "../../../entities/bv-subscription/subscription.core";


export class SubscriptionProcessor extends SubscriptionCore {


  configureForDisplay(entityViewSpec: EntityViewSpecProtocol) {
    super.configureForDisplay(entityViewSpec);
    super.removePostFields(['isActive', 'exRef'])

    this.postHelper.forceOverrideFormValues = {
      isActive: false
    };
  }
}
