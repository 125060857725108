import {SORespTag} from '../../core/tag/so-resp-tag';
import {TagCore} from './tag.core';
import {ListEntry} from '../../../engine/shared/list-entry';
import {EntityAttributesForDisplay} from '../../../engine/shared/entity-attributes-for-display';
import {DetailEntry, DetailFactory, DetailFactoryProtocol, EditableType} from '../../../engine/shared/detail-entry';
import {ActiveUserService} from '../../../engine/shared/active-user/active-user.service';
import {BaseDetailType} from "../../../engine/shared/base-detail";
import {EntityViewSpecProtocol} from "../../../engine/shared/entity-view/entity-view-spec-protocol";
import {getHeading} from "../../../engine/shared/display-option-utilities";
import {putTagFormFieldOptions} from './put-tag-form-fields';
import {SOGetTag} from '../../core/tag/so-get-tag';
import {SOGetTemaga} from '../../core/temaga/so-get-temaga';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {DisplayVectorStringFactory} from "../../../engine/config/display-vector";
import {displayOptions} from "../../display-options";
import {ListSpecType} from "../../../engine/shared/entity-view/list-spec";
import {SPECTER_SPEC} from "../../specter-spec";
import {getCore} from "../../../engine/shared/entity-view/get-core";

export class TagDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespTag = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(displayOptions);
    let heading;

    const attributes = []

    const nameDetail = new DetailEntry(
        'Name',
        entity.name,
        'name',
        BaseDetailType.text,
        null,
        EditableType.always,
        putTagFormFieldOptions.name
    )
    attributes.push(nameDetail)


    const itemsCore = getCore(SPECTER_SPEC, activeUserService, listEntry.entityCore.httpClient, 'TemagaCore');
    itemsCore.soGet = SOGetTemaga.construct({
      tag: new SOGetTag(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, itemsCore)
    itemsCore.title = heading ? heading : itemsCore.title
    itemsCore.listTitle = 'Items'
    itemsCore.listSpecType = ListSpecType.chipList

    itemsCore.configureForDisplay(entityViewSpec)

    const itemsDetail = new DetailEntry(
      heading ? heading : itemsCore.listTitle,
      null,
      'items',
      BaseDetailType.embeddedList,
      itemsCore,
      EditableType.never
    )
    attributes.push(itemsDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}