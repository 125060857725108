import {ActiveUserService} from '../../../engine/shared/active-user/active-user.service';
import {HttpClient} from '@angular/common/http';
import {EOQuestionService} from '../../core/question/eo-question';
import {QuestionListEntryFactory} from './question-list-entry-factory';
import {QuestionDetailFactory} from './question-detail-factory';
import {SOGetQuestion} from '../../core/question/so-get-question';
import {RAPutQuestion} from './ra-put-question';
import {RAPostQuestion} from './ra-post-question';
import {RADeleteQuestion} from './ra-delete-question';
import {AbstractEntityCore} from "../../../engine/shared/entity-view/abstract-entity-core";
import {EntityCoreProtocol} from "../../../engine/shared/entity-view/entity-core-protocol";
import {EntityType} from '../../entity-types';
import {SPECTER_SPEC} from "../../specter-spec";

export class QuestionCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = EntityType.question;
  title = 'Question';
  listEntryFactory = new QuestionListEntryFactory();
  detailFactory = new QuestionDetailFactory();

  entityService: EOQuestionService;
  soGetClass = SOGetQuestion;
  soGet: SOGetQuestion;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOQuestionService
    );
    this.adapters = [
      new RAPostQuestion(this),
      new RAPutQuestion(this),
      new RADeleteQuestion(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetQuestion();
    this.soGet.queryOptions.limit = this.limit;
  }


}