import { Type } from 'class-transformer';
import { SORespUser } from '../user/so-resp-user';
import {ObjectiveProtocol} from '../../../engine/protocols/objective-protocol';
import {EntityType} from "../../entity-types";


export class SORespIdentity implements ObjectiveProtocol {

  entityType: EntityType = EntityType.identity;
  uid: string;
  provider: string;
  @Type(() => SORespUser)
  user: SORespUser;
}