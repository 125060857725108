import {Component, Input, OnInit} from '@angular/core';
import {SvListComponent} from "../../entity/sv-list/sv-list.component";
import {ListEntry} from "../../../shared/list-entry";
import {AttributesSpec} from "../../../shared/entity-view/detail-spec";
import {getCore} from "../../../shared/entity-view/get-core";
import {SPECTER_SPEC} from "../../../../app/specter-spec";

@Component({
  selector: 'app-sv-editable-list',
  templateUrl: './sv-editable-list.component.html',
  styleUrls: ['./sv-editable-list.component.scss']
})
export class SvEditableListComponent implements OnInit {

  @Input()
  parent: SvListComponent

  constructor() { }

  ngOnInit(): void {
  }

  getEntityViewSpec(listEntry: ListEntry) {

    const newCore = SPECTER_SPEC.makeCore(listEntry.entityCore.activeUserService, listEntry.entityCore.httpClient, listEntry.entityCore.type);

    const newListEntry = new ListEntry(newCore, listEntry.entity, listEntry.lineHeading)

    newListEntry.entityCore.showHeader = false;
    const spec = new AttributesSpec(
      newListEntry, this.parent.entityViewSpec, this.parent.entityViewSpec.parentPanelEntityViewSpec, this.parent.entityViewSpec.navigator
    )
    return spec
  }
}
