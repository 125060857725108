import {SORespQuestion} from '../../core/question/so-resp-question';
import {QuestionCore} from './question.core';
import {ListEntry} from '../../../engine/shared/list-entry';
import {EntityAttributesForDisplay} from '../../../engine/shared/entity-attributes-for-display';
import {DetailEntry, DetailFactory, DetailFactoryProtocol, EditableType} from '../../../engine/shared/detail-entry';
import {ActiveUserService} from '../../../engine/shared/active-user/active-user.service';
import {BaseDetailType} from "../../../engine/shared/base-detail";
import {EntityViewSpecProtocol} from "../../../engine/shared/entity-view/entity-view-spec-protocol";
import {getHeading} from "../../../engine/shared/display-option-utilities";
import {putQuestionFormFieldOptions} from './put-question-form-fields';
import {SOGetQuestion} from '../../core/question/so-get-question';
import {SOGetSection} from '../../core/section/so-get-section';
import {DisplayVectorStringFactory} from "../../../engine/config/display-vector";
import {displayOptions} from "../../display-options";
import {ListSpecType} from "../../../engine/shared/entity-view/list-spec";
import {SPECTER_SPEC} from "../../specter-spec";
import {getCore} from "../../../engine/shared/entity-view/get-core";

export class QuestionDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespQuestion = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(displayOptions);
    let heading;

    const attributes = []

    const questionDetail = new DetailEntry(
        'Question',
        entity.question,
        'question',
        BaseDetailType.text,
        null,
        EditableType.always,
        putQuestionFormFieldOptions.question
    )
    attributes.push(questionDetail)

    const answerDetail = new DetailEntry(
        'Answer',
        entity.answer,
        'answer',
        BaseDetailType.text,
        null,
        EditableType.always,
        putQuestionFormFieldOptions.answer
    )
    attributes.push(answerDetail)

    const numberDetail = new DetailEntry(
        'Number',
        entity.number,
        'number',
        BaseDetailType.text,
        null,
        EditableType.always,
        putQuestionFormFieldOptions.number
    )
    attributes.push(numberDetail)


    const sectionCore = getCore(SPECTER_SPEC, activeUserService, listEntry.entityCore.httpClient, 'SectionCore');
    sectionCore.soGet = SOGetSection.construct({
    });
    heading = getHeading(entityViewSpec.entityCore, sectionCore)
    sectionCore.title = heading ? heading : sectionCore.title
    sectionCore.listTitle = 'Section'

    if (entity.section) {
        displayStringFactory.reconfigure(entity.section, entityViewSpec.entityCore.type)
    }
    sectionCore.configureForDisplay(entityViewSpec)

    const sectionDetail = new DetailEntry(
      heading ? heading : sectionCore.listTitle,
      entity.section ? displayStringFactory.primaryString : null,
      'section',
      BaseDetailType.pushedDetail,
      sectionCore,
      EditableType.always,
      putQuestionFormFieldOptions.section
    )
    if (entity.section) {
      sectionDetail.cachedListEntry = sectionCore.makeListEntry(entityViewSpec, sectionCore, entity.section, 0)
    }
    sectionDetail.singleFieldSelect = true
    attributes.push(sectionDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}