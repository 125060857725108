import {ActiveUserService} from '../../../engine/shared/active-user/active-user.service';
import {HttpClient} from '@angular/common/http';
import {EOInvoiceService} from '../../core/invoice/eo-invoice';
import {InvoiceListEntryFactory} from './invoice-list-entry-factory';
import {InvoiceDetailFactory} from './invoice-detail-factory';
import {SOGetInvoice} from '../../core/invoice/so-get-invoice';
import {RAPutInvoice} from './ra-put-invoice';
import {RAPostInvoice} from './ra-post-invoice';
import {RADeleteInvoice} from './ra-delete-invoice';
import {AbstractEntityCore} from "../../../engine/shared/entity-view/abstract-entity-core";
import {EntityCoreProtocol} from "../../../engine/shared/entity-view/entity-core-protocol";
import {EntityType} from '../../entity-types';
import {SPECTER_SPEC} from "../../specter-spec";

export class InvoiceCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = EntityType.invoice;
  title = 'Invoice';
  listEntryFactory = new InvoiceListEntryFactory();
  detailFactory = new InvoiceDetailFactory();

  entityService: EOInvoiceService;
  soGetClass = SOGetInvoice;
  soGet: SOGetInvoice;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOInvoiceService
    );
    this.adapters = [
      new RAPostInvoice(this),
      new RAPutInvoice(this),
      new RADeleteInvoice(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetInvoice();
    this.soGet.queryOptions.limit = this.limit;
  }


}