import {MediaCore} from "../../../entities/bv-media/media.core";
import {ENMediaTypePrimary} from "../../../enums";
import {EntityCoreProtocol} from "../../../../engine/shared/entity-view/entity-core-protocol";
import {ListEntry} from "../../../../engine/shared/list-entry";
import {SORespMedia} from "../../../core/media/so-resp-media";
import {ListSpecType} from "../../../../engine/shared/entity-view/list-spec";
import {MediaDef, SOMediaSpec} from "../../../../engine/multiverse/so-media-spec";
import {FormField, FormFieldType} from "../../../../engine/shared/form-field";


const linkField = new FormField(
  'link',
  'Link',
  null,
  FormFieldType.file,
  true,
)

export const postMediaFormFieldOptions: {[key: string]: FormField} = {
  link: linkField,
};


export class MediaProcessor extends MediaCore {

  isMedia = true;
  listSpecType = ListSpecType.thumbList

  finalInit() {
    this.postHelper.formFieldOptions = Object.values(postMediaFormFieldOptions);
  }

  makeListEntry(parentEntityViewSpec, entityCore: EntityCoreProtocol, entity: SORespMedia, section: number = 0): ListEntry {
    const resp = super.makeListEntry(parentEntityViewSpec, entityCore, entity, section);
    let spec: SOMediaSpec
    spec = entity.links.find(obj => obj.definition == MediaDef.px200)
    if (spec == null) {
      spec = entity.links.find(obj => obj.definition == MediaDef.px400)
    }
    if (spec == null) {
      spec = entity.links.find(obj => obj.definition == MediaDef.px800)
    }
    if (spec == null) {
      spec = entity.links.find(obj => obj.definition == MediaDef.pxOrig)
    }
    resp.mediaLink = spec != null ? spec.url : ''
    return resp
  }

}
