import {SORespProduct} from '../../core/product/so-resp-product';
import {ProductCore} from './product.core';
import {ListEntry} from '../../../engine/shared/list-entry';
import {EntityAttributesForDisplay} from '../../../engine/shared/entity-attributes-for-display';
import {DetailEntry, DetailFactory, DetailFactoryProtocol, EditableType} from '../../../engine/shared/detail-entry';
import {ActiveUserService} from '../../../engine/shared/active-user/active-user.service';
import {BaseDetailType} from "../../../engine/shared/base-detail";
import {EntityViewSpecProtocol} from "../../../engine/shared/entity-view/entity-view-spec-protocol";
import {getHeading} from "../../../engine/shared/display-option-utilities";
import {putProductFormFieldOptions} from './put-product-form-fields';
import {SOGetProduct} from '../../core/product/so-get-product';
import {SOGetPrice} from '../../core/price/so-get-price';
import {SOGetAppliedConstraint} from '../../core/applied-constraint/so-get-applied-constraint';
import {DisplayVectorStringFactory} from "../../../engine/config/display-vector";
import {displayOptions} from "../../display-options";
import {ListSpecType} from "../../../engine/shared/entity-view/list-spec";
import {SPECTER_SPEC} from "../../specter-spec";
import {getCore} from "../../../engine/shared/entity-view/get-core";

export class ProductDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespProduct = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(displayOptions);
    let heading;

    const attributes = []

    const nameDetail = new DetailEntry(
        'Name',
        entity.name,
        'name',
        BaseDetailType.text,
        null,
        EditableType.always,
        putProductFormFieldOptions.name
    )
    attributes.push(nameDetail)

    const skuDetail = new DetailEntry(
        'Sku',
        entity.sku,
        'sku',
        BaseDetailType.text,
        null,
        EditableType.always,
        putProductFormFieldOptions.sku
    )
    attributes.push(skuDetail)

    const exRefDetail = new DetailEntry(
        'Ex ref',
        entity.exRef,
        'exRef',
        BaseDetailType.text,
        null,
        EditableType.always,
        putProductFormFieldOptions.exRef
    )
    attributes.push(exRefDetail)

    const dateCreatedDetail = new DetailEntry(
        'Date created',
        activeUserService.time.niceDate(entity.dateCreated),
        'dateCreated',
        BaseDetailType.text,
        null,
        EditableType.never,
    )
    attributes.push(dateCreatedDetail)

    const dateUpdatedDetail = new DetailEntry(
        'Date updated',
        activeUserService.time.niceDate(entity.dateUpdated),
        'dateUpdated',
        BaseDetailType.text,
        null,
        EditableType.never,
    )
    attributes.push(dateUpdatedDetail)

    const activeDetail = new DetailEntry(
        'Active',
        entity.active,
        'active',
        BaseDetailType.toggle,
        null,
        EditableType.always,
        putProductFormFieldOptions.active
    )
    attributes.push(activeDetail)


    const pricesCore = getCore(SPECTER_SPEC, activeUserService, listEntry.entityCore.httpClient, 'PriceCore');
    pricesCore.soGet = SOGetPrice.construct({
      product: new SOGetProduct(entity.uid),
    });
    heading = getHeading(entityViewSpec.entityCore, pricesCore)
    pricesCore.title = heading ? heading : pricesCore.title
    pricesCore.listTitle = 'Prices'

    pricesCore.configureForDisplay(entityViewSpec)

    const pricesDetail = new DetailEntry(
      heading ? heading : pricesCore.listTitle,
      null,
      'prices',
      BaseDetailType.embeddedList,
      pricesCore,
      EditableType.never
    )
    attributes.push(pricesDetail);

    const appliedConstraintsCore = getCore(SPECTER_SPEC, activeUserService, listEntry.entityCore.httpClient, 'AppliedConstraintCore');
    appliedConstraintsCore.soGet = SOGetAppliedConstraint.construct({
      product: new SOGetProduct(entity.uid),
    });
    heading = getHeading(entityViewSpec.entityCore, appliedConstraintsCore)
    appliedConstraintsCore.title = heading ? heading : appliedConstraintsCore.title
    appliedConstraintsCore.listTitle = 'Applied constraints'

    appliedConstraintsCore.configureForDisplay(entityViewSpec)

    const appliedConstraintsDetail = new DetailEntry(
      heading ? heading : appliedConstraintsCore.listTitle,
      null,
      'appliedConstraints',
      BaseDetailType.embeddedList,
      appliedConstraintsCore,
      EditableType.never
    )
    attributes.push(appliedConstraintsDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}