import { Type } from 'class-transformer';
import { SORespItemImport } from '../item-import/so-resp-item-import';
import { SORespDispatch } from '../dispatch/so-resp-dispatch';
import {ObjectiveProtocol} from '../../../engine/protocols/objective-protocol';
import {EntityType} from "../../entity-types";


export class SORespItemImportLine implements ObjectiveProtocol {

  entityType: EntityType = EntityType.itemImportLine;
  uid: string;
  @Type(() => Date)
  dateCreated: Date;
  @Type(() => SORespItemImport)
  itemImport: SORespItemImport;
  @Type(() => SORespDispatch)
  dispatch: SORespDispatch;
}