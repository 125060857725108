import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';

import { SOPostBulkitem } from './so-post-bulkitem';
import { SOGetBulkitem } from './so-get-bulkitem';
import { SOPutBulkitem } from './so-put-bulkitem';
import { SODeleteBulkitem } from './so-delete-bulkitem';
import {SORespBulkitem} from './so-resp-bulkitem';
import {SOPluralBulkitem} from './so-plural-bulkitem';

import {MMEntityService} from '../../../engine/multiverse/mm-entity-service';
import {EntityCoreProtocol} from '../../../engine/shared/entity-view/entity-core-protocol';
import {MMEntityServiceBase} from '../../../engine/multiverse/mm-entity-service-base';
import {HttpHeaders} from "@angular/common/http";


export class EOBulkitemService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'bulkitem',
      entityCore
    );
  }

  /** GET Bulkitem by id. Will 404 if id not found */
  get(request: SOGetBulkitem): Observable<SOPluralBulkitem> {
    return this.httpClient.get<SOPluralBulkitem>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralBulkitem, resp) }),
      tap(_ => this.log(`fetched Bulkitem`)),
      catchError(this.handleError<SOPluralBulkitem>(`get Bulkitem`))
    );
  }

  /** POST: add a new Bulkitem to the server */
  post(request: SOPostBulkitem): Observable<SORespBulkitem> {
    return this.httpClient.post<SORespBulkitem>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespBulkitem, resp) }),
      tap((response: SORespBulkitem) => this.log(`added Bulkitem w/ id=${response.uid}`)),
      catchError(this.handleError<SORespBulkitem>('Bulkitem post'))
    );
  }

  /** PUT: update Bulkitem  */
  put(request: SOPutBulkitem): Observable<SORespBulkitem> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespBulkitem>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespBulkitem, resp) }),
      tap((response: SORespBulkitem) => this.log(`edited Bulkitem w/ uid=${uid}`)),
      catchError(this.handleError<SORespBulkitem>('Bulkitem put'))
    );
  }

  /** DELETE: delete the Bulkitem from the server */
  delete(request: SODeleteBulkitem): Observable<SORespBulkitem> {
    return this.httpClient.delete<SORespBulkitem>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Bulkitem uid=${request.uid}`)),
      catchError(this.handleError<SORespBulkitem>('delete Bulkitem'))
    );
  }
}
