import {SORespDispatch} from '../../core/dispatch/so-resp-dispatch';
import {DispatchCore} from './dispatch.core';
import {ListEntry} from '../../../engine/shared/list-entry';
import {EntityAttributesForDisplay} from '../../../engine/shared/entity-attributes-for-display';
import {DetailEntry, DetailFactory, DetailFactoryProtocol, EditableType} from '../../../engine/shared/detail-entry';
import {ActiveUserService} from '../../../engine/shared/active-user/active-user.service';
import {BaseDetailType} from "../../../engine/shared/base-detail";
import {EntityViewSpecProtocol} from "../../../engine/shared/entity-view/entity-view-spec-protocol";
import {getHeading} from "../../../engine/shared/display-option-utilities";
import {putDispatchFormFieldOptions} from './put-dispatch-form-fields';
import {SOGetDispatch} from '../../core/dispatch/so-get-dispatch';
import {SOGetLine} from '../../core/line/so-get-line';
import {SOGetTemloca} from '../../core/temloca/so-get-temloca';
import {SOGetPickup} from '../../core/pickup/so-get-pickup';
import {SOGetItemImportLine} from '../../core/item-import-line/so-get-item-import-line';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {DisplayVectorStringFactory} from "../../../engine/config/display-vector";
import {displayOptions} from "../../display-options";
import {ListSpecType} from "../../../engine/shared/entity-view/list-spec";
import {SPECTER_SPEC} from "../../specter-spec";
import {getCore} from "../../../engine/shared/entity-view/get-core";

export class DispatchDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespDispatch = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(displayOptions);
    let heading;

    const attributes = []

    const completeDetail = new DetailEntry(
        'Complete',
        entity.complete,
        'complete',
        BaseDetailType.toggle,
        null,
        EditableType.always,
        putDispatchFormFieldOptions.complete
    )
    attributes.push(completeDetail)

    const isAllocationDetail = new DetailEntry(
        'Add/Subtract',
        entity.isAllocation,
        'isAllocation',
        BaseDetailType.toggle,
        null,
        EditableType.never,
    )
    attributes.push(isAllocationDetail)

    const quantityDetail = new DetailEntry(
        'Quantity',
        entity.quantity,
        'quantity',
        BaseDetailType.text,
        null,
        EditableType.never,
    )
    attributes.push(quantityDetail)

    const notesDetail = new DetailEntry(
        'Notes',
        entity.notes,
        'notes',
        BaseDetailType.text,
        null,
        EditableType.always,
        putDispatchFormFieldOptions.notes
    )
    attributes.push(notesDetail)

    const dateCreatedDetail = new DetailEntry(
        'Date created',
        activeUserService.time.niceDate(entity.dateCreated),
        'dateCreated',
        BaseDetailType.text,
        null,
        EditableType.never,
    )
    attributes.push(dateCreatedDetail)

    const dateUpdatedDetail = new DetailEntry(
        'Date updated',
        activeUserService.time.niceDate(entity.dateUpdated),
        'dateUpdated',
        BaseDetailType.text,
        null,
        EditableType.never,
    )
    attributes.push(dateUpdatedDetail)


    const lineCore = getCore(SPECTER_SPEC, activeUserService, listEntry.entityCore.httpClient, 'LineCore');
    lineCore.soGet = SOGetLine.construct({
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, lineCore)
    lineCore.title = heading ? heading : lineCore.title
    lineCore.listTitle = 'Line'

    if (entity.line) {
        displayStringFactory.reconfigure(entity.line, entityViewSpec.entityCore.type)
    }
    lineCore.configureForDisplay(entityViewSpec)

    const lineDetail = new DetailEntry(
      lineCore.title,
      entity.line ? displayStringFactory.primaryString : null,
      'line',
      BaseDetailType.pushedDetail,
      lineCore,
      EditableType.never
    )
    if (entity.line) {
      lineDetail.cachedListEntry = lineCore.makeListEntry(entityViewSpec, lineCore, entity.line, 0)
    }
    lineDetail.singleFieldSelect = true
    attributes.push(lineDetail);

    const temlocaCore = getCore(SPECTER_SPEC, activeUserService, listEntry.entityCore.httpClient, 'TemlocaCore');
    temlocaCore.soGet = SOGetTemloca.construct({
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, temlocaCore)
    temlocaCore.title = heading ? heading : temlocaCore.title
    temlocaCore.listTitle = 'Item location'
    temlocaCore.listSpecType = ListSpecType.chipList

    if (entity.temloca) {
        displayStringFactory.reconfigure(entity.temloca, entityViewSpec.entityCore.type)
    }
    temlocaCore.configureForDisplay(entityViewSpec)

    const temlocaDetail = new DetailEntry(
      heading ? heading : temlocaCore.listTitle,
      entity.temloca ? displayStringFactory.primaryString : null,
      'temloca',
      BaseDetailType.pushedDetail,
      temlocaCore,
      EditableType.never
    )
    if (entity.temloca) {
      temlocaDetail.cachedListEntry = temlocaCore.makeListEntry(entityViewSpec, temlocaCore, entity.temloca, 0)
    }
    temlocaDetail.singleFieldSelect = true
    attributes.push(temlocaDetail);

    const pickupCore = getCore(SPECTER_SPEC, activeUserService, listEntry.entityCore.httpClient, 'PickupCore');
    pickupCore.soGet = SOGetPickup.construct({
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, pickupCore)
    pickupCore.title = heading ? heading : pickupCore.title
    pickupCore.listTitle = 'Pickup'

    if (entity.pickup) {
        displayStringFactory.reconfigure(entity.pickup, entityViewSpec.entityCore.type)
    }
    pickupCore.configureForDisplay(entityViewSpec)

    const pickupDetail = new DetailEntry(
      heading ? heading : pickupCore.listTitle,
      entity.pickup ? displayStringFactory.primaryString : null,
      'pickup',
      BaseDetailType.pushedDetail,
      pickupCore,
      EditableType.always,
      putDispatchFormFieldOptions.pickup
    )
    if (entity.pickup) {
      pickupDetail.cachedListEntry = pickupCore.makeListEntry(entityViewSpec, pickupCore, entity.pickup, 0)
    }
    pickupDetail.singleFieldSelect = true
    attributes.push(pickupDetail);

    const itemImportLineCore = getCore(SPECTER_SPEC, activeUserService, listEntry.entityCore.httpClient, 'ItemImportLineCore');
    itemImportLineCore.soGet = SOGetItemImportLine.construct({
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, itemImportLineCore)
    itemImportLineCore.title = heading ? heading : itemImportLineCore.title
    itemImportLineCore.listTitle = 'Item import line'

    itemImportLineCore.configureForDisplay(entityViewSpec)

    const itemImportLineDetail = new DetailEntry(
      heading ? heading : itemImportLineCore.listTitle,
      null,
      'itemImportLine',
      BaseDetailType.pushedDetail,
      itemImportLineCore,
      EditableType.never
    )
    itemImportLineDetail.singleFieldSelect = true
    attributes.push(itemImportLineDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}