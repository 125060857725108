import {FormField, FormFieldType, SelectionOption} from '../../../engine/shared/form-field';
import {EntityType} from "../../entity-types";


const nameField = new FormField(
  'name',
  'Name',
  null,
  FormFieldType.text,
  true,
)
const descriptionField = new FormField(
  'description',
  'Description',
  null,
  FormFieldType.text,
  false,
)


export const postLocationFormFieldOptions: {[key: string]: FormField} = {
  name: nameField,
  description: descriptionField,
};