import { SOGetOrganization } from '../organization/so-get-organization';


export class SOPostTag {

  constructor(
    public name: string,
    public organization: SOGetOrganization,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostTag(
      'name' in params ? params.name : null,
      'organization' in params ? params.organization : null,
    );
  }
}
