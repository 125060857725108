<form [formGroup]="form">

  <mat-checkbox [checked]="detail.isChecked"
                [formControlName]="detail.editableFormField.identifierString"
                (change)="onSelectionChange()">
    {{detail.name}}
    <a *ngIf="detail.editableFormField.link"
       href="#/{{detail.editableFormField.link}}"
       target="_blank" rel="noopener noreferrer">{{detail.editableFormField.linkText}}</a>
  </mat-checkbox>
  <!--  <mat-checkbox *ngIf="!detail.editEnabled" [checked]="detail.isChecked"-->
  <!--                (change)="onSelectionChange()">{{detail.value}}</mat-checkbox>-->

  <!--  <mat-form-field-->
  <!--    *ngIf="detail.editEnabled"-->
  <!--    appearance="{{detail.editableFormField.appearance}}"-->
  <!--    color="{{detail.editableFormField.color}}">-->
  <!--    <mat-label>{{detail.editableFormField.getLabel(parentView.entityViewSpec)}}</mat-label>-->
  <!--    <input matInput-->
  <!--           [type]="detail.editableFormField.inputType"-->
  <!--           [id]="detail.editableFormField.identifierString"-->
  <!--           [name]="detail.editableFormField.identifierString"-->
  <!--           [formControlName]="detail.editableFormField.identifierString"-->
  <!--           (change)="onSelectionChange()">-->
  <!--  </mat-form-field>-->

</form>
