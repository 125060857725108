import {MenuItem, MenuItemType} from "../../../../engine/protocols/header-protocol";
import {ENProgress} from "../../../enums";
import {EntityViewSpecProtocol} from "../../../../engine/shared/entity-view/entity-view-spec-protocol";
import {PickupCore} from "../../../entities/bv-pickup/pickup.core";

export class PickupProcessor extends PickupCore {

  active = new MenuItem('active', 'Active', this, MenuItemType.multiselect, true)
  archived = new MenuItem('archived', 'Archived', this, MenuItemType.multiselect, false)

  configureForDisplay(entityViewSpec: EntityViewSpecProtocol) {

    this.menuItems = [
      this.active,
      this.archived,
    ]

    super.configureForDisplay(entityViewSpec);

    this.listHeaderSpec.showFilter = true;
    this.soGet.status = [ENProgress.pending, ENProgress.active, ENProgress.completed];
  }

  menuClick(item: MenuItem, entityViewSpec: EntityViewSpecProtocol) {
    console.log(item)
    if (item.identifier === this.active.identifier && !item.value) {
      this.soGet.status = [ENProgress.pending, ENProgress.active, ENProgress.completed];
      this.archived.value = false;
      this.active.value = true;
      this.resetViewAndFetchList(entityViewSpec);
    } else if (item.identifier === this.archived.identifier && !item.value) {
      this.soGet.status = [ENProgress.archived];
      this.archived.value = true;
      this.active.value = false;
      this.resetViewAndFetchList(entityViewSpec);
    }
  }
}
