import {ActiveUserService} from '../../../engine/shared/active-user/active-user.service';
import {HttpClient} from '@angular/common/http';
import {EOPriceService} from '../../core/price/eo-price';
import {PriceListEntryFactory} from './price-list-entry-factory';
import {PriceDetailFactory} from './price-detail-factory';
import {SOGetPrice} from '../../core/price/so-get-price';
import {RAPutPrice} from './ra-put-price';
import {RAPostPrice} from './ra-post-price';
import {RADeletePrice} from './ra-delete-price';
import {AbstractEntityCore} from "../../../engine/shared/entity-view/abstract-entity-core";
import {EntityCoreProtocol} from "../../../engine/shared/entity-view/entity-core-protocol";
import {EntityType} from '../../entity-types';
import {SPECTER_SPEC} from "../../specter-spec";

export class PriceCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = EntityType.price;
  title = 'Price';
  listEntryFactory = new PriceListEntryFactory();
  detailFactory = new PriceDetailFactory();

  entityService: EOPriceService;
  soGetClass = SOGetPrice;
  soGet: SOGetPrice;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOPriceService
    );
    this.adapters = [
      new RAPostPrice(this),
      new RAPutPrice(this),
      new RADeletePrice(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetPrice();
    this.soGet.queryOptions.limit = this.limit;
  }


}