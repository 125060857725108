import {Component, Input, OnInit} from '@angular/core';
import {SvListComponent} from "../../entity/sv-list/sv-list.component";

@Component({
  selector: 'app-sv-thumb-list',
  templateUrl: './sv-thumb-list.component.html',
  styleUrls: ['./sv-thumb-list.component.scss']
})
export class SvThumbListComponent implements OnInit {

  @Input()
  parent: SvListComponent

  constructor() { }

  ngOnInit(): void {
  }

}
