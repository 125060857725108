import { SOGetItem } from '../item/so-get-item';
import { SOGetOrder } from '../order/so-get-order';
import { SOGetOrganization } from '../organization/so-get-organization';
import {ENDispatchStatus} from '../../enums';
import {MMQueryOptions} from '../../../engine/multiverse/mm-query-options';
import {QueryProtocol} from "../../../engine/protocols/query-protocol";


export class SOGetLine implements QueryProtocol {

  constructor(
    public uid: string = null,
    public dispatchStatus: ENDispatchStatus[] = null,
    public item: SOGetItem = null,
    public order: SOGetOrder = null,
    public organization: SOGetOrganization = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetLine(
      'uid' in params ? params.uid : null,
      'dispatchStatus' in params ? params.dispatchStatus : null,
      'item' in params ? params.item : null,
      'order' in params ? params.order : null,
      'organization' in params ? params.organization : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
