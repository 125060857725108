import {MenuItem, MenuItemType} from "../../../../engine/protocols/header-protocol";
import {ENOrderStatus} from "../../../enums";
import {EntityViewSpecProtocol} from "../../../../engine/shared/entity-view/entity-view-spec-protocol";
import {OrderCore} from "../../../entities/bv-order/order.core";
import {EntityCoreProtocol} from "../../../../engine/shared/entity-view/entity-core-protocol";
import {ListEntry} from "../../../../engine/shared/list-entry";
import {SORespOrder} from "../../../core/order/so-resp-order";

export class OrderProcessor extends OrderCore {

  active = new MenuItem('active', 'Active', this, MenuItemType.multiselect, true)
  archived = new MenuItem('archived', 'Archived', this, MenuItemType.multiselect, false)

  configureForDisplay(entityViewSpec: EntityViewSpecProtocol) {

    this.menuItems = [
      this.active,
      this.archived,
    ]

    super.configureForDisplay(entityViewSpec);

    this.listHeaderSpec.showFilter = true;
    this.soGet.dispatchStatus = [ENOrderStatus.needsDispatchAssignment, ENOrderStatus.pendingDispatch, ENOrderStatus.dispatched];
  }

  menuClick(item: MenuItem, entityViewSpec: EntityViewSpecProtocol) {
    console.log(item)
    if (item.identifier === this.active.identifier && !item.value) {
      this.soGet.dispatchStatus = [ENOrderStatus.needsDispatchAssignment, ENOrderStatus.pendingDispatch, ENOrderStatus.dispatched];
      this.archived.value = false;
      this.active.value = true;
      this.resetViewAndFetchList(entityViewSpec);
    } else if (item.identifier === this.archived.identifier && !item.value) {
      this.soGet.dispatchStatus = [ENOrderStatus.archived];
      this.archived.value = true;
      this.active.value = false;
      this.resetViewAndFetchList(entityViewSpec);
    }
  }

  makeListEntry(parentEntityViewSpec, entityCore: EntityCoreProtocol, entity: SORespOrder, section: number = 0): ListEntry {
    const resp = super.makeListEntry(parentEntityViewSpec, entityCore, entity, section);
    resp.trailingText = this.activeUserService.time.niceShortDateAndTime(entity.dateCreated)
    return  resp
  }
}
