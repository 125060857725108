import {SOPutSesMessage} from '../../core/ses-message/so-put-ses-message';
import {SesMessageCore} from './ses-message.core';
import {SOGetSesMessage} from '../../core/ses-message/so-get-ses-message';
import {FormField, SelectionOption} from '../../../engine/shared/form-field';
import {putSesMessageFormFieldOptions} from './put-ses-message-form-fields';
import {AdapterProtocol} from '../../../engine/shared/entity-construction/adapter-protocol';
import {BasePut} from '../../../engine/shared/entity-construction/base-put';

export class RAPutSesMessage extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putSesMessageFormFieldOptions);

  RequestClass = SOPutSesMessage

  constructor(
    public entityCore: SesMessageCore
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('data'),
      this.getFormValueFromIdentifier('messageType'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}