import {FormField, FormFieldType, SelectionOption} from '../../../engine/shared/form-field';
import {EntityType} from "../../entity-types";


const questionField = new FormField(
  'question',
  'Question',
  null,
  FormFieldType.text,
  false,
)
const answerField = new FormField(
  'answer',
  'Answer',
  null,
  FormFieldType.text,
  false,
)
const numberField = new FormField(
  'number',
  'Number',
  null,
  FormFieldType.text,
  false,
)
const sectionField = new FormField(
  'section',
  'Section',
  EntityType.section,
  FormFieldType.pushedSelectionList,
  false,
)


export const putQuestionFormFieldOptions: {[key: string]: FormField} = {
  question: questionField,
  answer: answerField,
  number: numberField,
  section: sectionField,
};