<div class="container">
  <div class="outer">
    <div class="inner" fxLayout="column" fxLayoutAlign="start center">
      <img *ngIf="banner.showLogo" src="assets/logos/logo_256.png" class="logo">
      <p>{{banner.text}}</p>
      <button mat-stroked-button *ngIf="banner.showAction"
              (click)="banner.handleAction()">{{banner.actionText}}</button>
      <button mat-stroked-button *ngIf="banner.showLogout"
              (click)="banner.activeUserService.logout()">Logout</button>
    </div>
  </div>
</div>
