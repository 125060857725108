import {SOPutQuestion} from '../../core/question/so-put-question';
import {QuestionCore} from './question.core';
import {SOGetQuestion} from '../../core/question/so-get-question';
import {FormField, SelectionOption} from '../../../engine/shared/form-field';
import {putQuestionFormFieldOptions} from './put-question-form-fields';
import {AdapterProtocol} from '../../../engine/shared/entity-construction/adapter-protocol';
import {BasePut} from '../../../engine/shared/entity-construction/base-put';
import {SectionCore} from '../bv-section/section.core';
import {SOGetSection} from '../../core/section/so-get-section';

export class RAPutQuestion extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putQuestionFormFieldOptions);

  RequestClass = SOPutQuestion

  constructor(
    public entityCore: QuestionCore
  ) {
    super();
    if (putQuestionFormFieldOptions.section) {
      putQuestionFormFieldOptions.section.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('question'),
      this.getFormValueFromIdentifier('answer'),
      this.getFormValueFromIdentifier('number'),
      this.getFormValueFromIdentifier(
        'section',
        SOGetSection,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}