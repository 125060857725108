import { Type } from 'class-transformer';
import {ObjectiveProtocol} from '../../../engine/protocols/objective-protocol';
import {EntityType} from "../../entity-types";


export class SORespHello implements ObjectiveProtocol {

  entityType: EntityType = EntityType.hello;
  uid: string;
  temloca: string;
  loginStyle: number;
}