import { Type } from 'class-transformer';
import { SORespCustomField } from '../custom-field/so-resp-custom-field';
import { SORespItem } from '../item/so-resp-item';
import {ObjectiveProtocol} from '../../../engine/protocols/objective-protocol';
import {EntityType} from "../../entity-types";


export class SORespTemfield implements ObjectiveProtocol {

  entityType: EntityType = EntityType.temfield;
  uid: string;
  @Type(() => SORespCustomField)
  customField: SORespCustomField;
  value: string;
  @Type(() => SORespItem)
  item: SORespItem;
}