import {Component, OnInit, ViewChild} from '@angular/core';
import {SPECTER_SPEC} from "../../../../app/specter-spec";
import {MatSidenav} from "@angular/material/sidenav";
import {ActivatedRoute, Router} from "@angular/router";
import {Title} from "@angular/platform-browser";
import {environment} from "../../../../environments/environment";
import {ActiveUserService} from "../../../shared/active-user/active-user.service";
import {UserLoaded} from "../../../protocols/int-user-loaded";
import {BannerProtocol} from "../../../protocols/banner-protocol";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-sv-container',
  templateUrl: './sv-container.component.html',
  styleUrls: ['./sv-container.component.scss']
})
export class SvContainerComponent implements UserLoaded {

  id = 'container';

  title = SPECTER_SPEC.title;
  appName: string;
  drawerShouldBeOpened = false;

  @ViewChild('sidenav') sidenav: MatSidenav;

  drawerOpen = false;
  get banner(): BannerProtocol {
    return SPECTER_SPEC.topBanner
  }

  get showTopBar() {
    return this.activeUserService.userLoaded;
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient
  ) {
    activeUserService.registerUserLoadedView(this)
    const urlParts = window.location.href.split('?');
    this.setTitle(urlParts);
    this.setNavState();
    this.navigateToInitialPage(urlParts);
  }

  userLoaded() {
    this.drawerOpen = true;
    this.banner.activeUserService = this.activeUserService;
    this.banner.httpClient = this.httpClient;
  }

  userUnloaded() {
    this.drawerOpen = false;
    this.banner.disabled = true;
  }

  setNavState() {
    const drawerState = sessionStorage.getItem('drawerOpen');
    if (drawerState) {
      this.drawerShouldBeOpened = true;
    }
  }

  setTitle(urlParts) {
    this.appName = environment.pageTitle;
    this.titleService.setTitle(`${this.appName}`);
  }

  getQueryParams(queryString: string) {
    const newParams = {};
    for (const param of queryString.split('&')) {
      const kv = param.split('=');
      newParams[kv[0]] = kv[1];
    }
    return newParams;
  }

  navigateToInitialPage(urlParts) {

    const pathParts = urlParts[0].split('/#/');
    let root = SPECTER_SPEC.home;
    if (pathParts.length > 1 && pathParts[1] !== '') {
      root = pathParts[1]
    }
    this.activeUserService.initialRoot = root.split('/')[0];
    if (urlParts.length > 1) {
      const queryParams = this.getQueryParams(urlParts[1]);
      console.log(`[INFO]: Navigating to ${root} with the following query params:`);
      console.log(queryParams);
      this.router.navigate([root], {queryParams});
    } else {
      console.log(`[INFO]: Navigating to ${root}`);
      if (this.activeUserService.userLoaded && ['login', 'register'].includes(root)) {
        this.router.navigate([SPECTER_SPEC.home]);
      } else {
        this.router.navigate([root]);
      }
    }
  }
}
