import {Component, OnInit} from '@angular/core';
import {ActiveUserService} from '../../../shared/active-user/active-user.service';
import {ActivatedRoute} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {UserLoaded} from '../../../protocols/int-user-loaded';
import {EntityViewSpecProtocol} from '../../../shared/entity-view/entity-view-spec-protocol';
import {EntityCoreProtocol} from '../../../shared/entity-view/entity-core-protocol';
import {ListEntry} from "../../../shared/list-entry";
import {FormField} from "../../../shared/form-field";
import {SPECTER_SPEC} from "../../../../app/specter-spec";
import {getCore} from "../../../shared/entity-view/get-core";


@Component({
  selector: 'app-sv-navigator',
  templateUrl: './sv-navigator.component.html',
  styleUrls: ['./sv-navigator.component.css']
})
export class SvNavigatorComponent implements UserLoaded {

  id = 'navigator';

  entityViewSpecs: EntityViewSpecProtocol[] = [];
  editEnabled = false;

  get showBack() {
    return this.entityViewSpecs.length > 1;
  }

  showCrumbs = false;

  pushedSelectionListFormField: FormField
  loading = false;

  get visibleEntityViewSpecs() {
    const visibleViews = 1;
    const start = this.entityViewSpecs.length > visibleViews ? this.entityViewSpecs.length - visibleViews : 0;
    const end = this.entityViewSpecs.length;
    return this.entityViewSpecs.slice(start, end);
  }

  get width() {
    // return 100/this.entityViewSpecs.length
    return 100 / this.visibleEntityViewSpecs.length;
  }

  get lastEntityViewSpec() {
    return this.entityViewSpecs[this.entityViewSpecs.length - 1];
  }

  constructor(
    public activeUserService: ActiveUserService,
    private route: ActivatedRoute,
    private httpClient: HttpClient
  ) {
    this.activeUserService.registerUserLoadedView(this);
  }

  userLoaded() {
    console.log(this.route.routeConfig.data.identifier)
    const entityCore: EntityCoreProtocol = getCore(SPECTER_SPEC, this.activeUserService, this.httpClient, this.route.routeConfig.data.identifier);
    entityCore.entityUid = this.route.snapshot.paramMap.get('entityUid')
    entityCore.prepareStartingEntityViewSpec(this);
  }

  userUnloaded() {
  }

  addEntityViewSpec(entityViewSpec: EntityViewSpecProtocol, atIndex: number = null) {
    if (atIndex && atIndex <= this.entityViewSpecs.length - 1) {
      entityViewSpec.entityCore.configureForDisplay(
        this.entityViewSpecs.length - 2 < atIndex && this.entityViewSpecs.length - 2 >= 0 ? this.entityViewSpecs[atIndex - 1] : null
      )
      this.entityViewSpecs.splice(atIndex, this.entityViewSpecs.length);
    } else {
      entityViewSpec.entityCore.configureForDisplay(
        this.entityViewSpecs.length > 0 ? this.entityViewSpecs[this.entityViewSpecs.length - 1] : null
      )
      this.entityViewSpecs.push(entityViewSpec);
    }
  }

  updateEntityViewSpec(existingEntityViewSpecIndex: number, entityViewSpec: EntityViewSpecProtocol = null) {
    this.entityViewSpecs.splice(existingEntityViewSpecIndex, this.entityViewSpecs.length);
    if (entityViewSpec) {
      this.entityViewSpecs.push(entityViewSpec);
    }
  }

  selectionFromPushedList(listEntry: ListEntry) {
    this.pushedSelectionListFormField.selectedListEntry = listEntry
    this.pushedSelectionListFormField = null
    this.entityViewSpecs.pop()
  }

  setInitialEntityViewSpec(viewSpec: EntityViewSpecProtocol) {
    this.loading = false;
    this.addEntityViewSpec(viewSpec);
  }
}
