import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';

import { SOPostTemloca } from './so-post-temloca';
import { SOGetTemloca } from './so-get-temloca';
import { SOPutTemloca } from './so-put-temloca';
import { SODeleteTemloca } from './so-delete-temloca';
import {SORespTemloca} from './so-resp-temloca';
import {SOPluralTemloca} from './so-plural-temloca';

import {MMEntityService} from '../../../engine/multiverse/mm-entity-service';
import {EntityCoreProtocol} from '../../../engine/shared/entity-view/entity-core-protocol';
import {MMEntityServiceBase} from '../../../engine/multiverse/mm-entity-service-base';
import {HttpHeaders} from "@angular/common/http";


export class EOTemlocaService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'temloca',
      entityCore
    );
  }

  /** GET Temloca by id. Will 404 if id not found */
  get(request: SOGetTemloca): Observable<SOPluralTemloca> {
    return this.httpClient.get<SOPluralTemloca>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralTemloca, resp) }),
      tap(_ => this.log(`fetched Temloca`)),
      catchError(this.handleError<SOPluralTemloca>(`get Temloca`))
    );
  }

  /** POST: add a new Temloca to the server */
  post(request: SOPostTemloca): Observable<SORespTemloca> {
    return this.httpClient.post<SORespTemloca>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespTemloca, resp) }),
      tap((response: SORespTemloca) => this.log(`added Temloca w/ id=${response.uid}`)),
      catchError(this.handleError<SORespTemloca>('Temloca post'))
    );
  }

  /** PUT: update Temloca  */
  put(request: SOPutTemloca): Observable<SORespTemloca> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespTemloca>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespTemloca, resp) }),
      tap((response: SORespTemloca) => this.log(`edited Temloca w/ uid=${uid}`)),
      catchError(this.handleError<SORespTemloca>('Temloca put'))
    );
  }

  /** DELETE: delete the Temloca from the server */
  delete(request: SODeleteTemloca): Observable<SORespTemloca> {
    return this.httpClient.delete<SORespTemloca>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Temloca uid=${request.uid}`)),
      catchError(this.handleError<SORespTemloca>('delete Temloca'))
    );
  }
}
