import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';

import { SOPostOrder } from './so-post-order';
import { SOGetOrder } from './so-get-order';
import { SOPutOrder } from './so-put-order';
import { SODeleteOrder } from './so-delete-order';
import {SORespOrder} from './so-resp-order';
import {SOPluralOrder} from './so-plural-order';

import {MMEntityService} from '../../../engine/multiverse/mm-entity-service';
import {EntityCoreProtocol} from '../../../engine/shared/entity-view/entity-core-protocol';
import {MMEntityServiceBase} from '../../../engine/multiverse/mm-entity-service-base';
import {HttpHeaders} from "@angular/common/http";


export class EOOrderService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'order',
      entityCore
    );
  }

  /** GET Order by id. Will 404 if id not found */
  get(request: SOGetOrder): Observable<SOPluralOrder> {
    return this.httpClient.get<SOPluralOrder>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralOrder, resp) }),
      tap(_ => this.log(`fetched Order`)),
      catchError(this.handleError<SOPluralOrder>(`get Order`))
    );
  }

  /** POST: add a new Order to the server */
  post(request: SOPostOrder): Observable<SORespOrder> {
    return this.httpClient.post<SORespOrder>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespOrder, resp) }),
      tap((response: SORespOrder) => this.log(`added Order w/ id=${response.uid}`)),
      catchError(this.handleError<SORespOrder>('Order post'))
    );
  }

  /** PUT: update Order  */
  put(request: SOPutOrder): Observable<SORespOrder> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespOrder>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespOrder, resp) }),
      tap((response: SORespOrder) => this.log(`edited Order w/ uid=${uid}`)),
      catchError(this.handleError<SORespOrder>('Order put'))
    );
  }

  /** DELETE: delete the Order from the server */
  delete(request: SODeleteOrder): Observable<SORespOrder> {
    return this.httpClient.delete<SORespOrder>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Order uid=${request.uid}`)),
      catchError(this.handleError<SORespOrder>('delete Order'))
    );
  }
}
