import {DetailEntry} from '../detail-entry';
import {ListEntry} from '../list-entry';
import {SvNavigatorComponent} from '../../views/structure/sv-navigator/sv-navigator.component';
import {EntityViewSpecType} from './entity-view-spec-type';
import {EntityViewSpecProtocol} from './entity-view-spec-protocol';
import {EntityViewSpec} from './entity-view-spec';

export class AttributesSpec extends EntityViewSpec implements EntityViewSpecProtocol {

  detailEntry: DetailEntry;

  get index() {
    return this.navigator.entityViewSpecs.findIndex(obj => obj === this);
  }

  get entityCore() {
    return this.listEntry.entityCore;
  }

  type = EntityViewSpecType.detail

  constructor(
    public listEntry: ListEntry,
    private parentEntityViewSpecInput: EntityViewSpecProtocol = null,
    public parentPanelEntityViewSpec: EntityViewSpecProtocol,
    public navigator: SvNavigatorComponent,
    public showClose: boolean = true,
  ) {
    super()
    this.parentEntityViewSpec = parentEntityViewSpecInput;
    this.childrenEntityViewSpecs.push(parentEntityViewSpecInput);
  }
}
