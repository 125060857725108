import { SOGetOrganization } from '../organization/so-get-organization';
import {MMQueryOptions} from '../../../engine/multiverse/mm-query-options';
import {QueryProtocol} from "../../../engine/protocols/query-protocol";


export class SOGetLocation implements QueryProtocol {

  constructor(
    public uid: string = null,
    public name: string = null,
    public organization: SOGetOrganization = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetLocation(
      'uid' in params ? params.uid : null,
      'name' in params ? params.name : null,
      'organization' in params ? params.organization : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
