import {SORespSubscription} from '../../core/subscription/so-resp-subscription';
import {SubscriptionCore} from './subscription.core';
import {ListEntry} from '../../../engine/shared/list-entry';
import {EntityAttributesForDisplay} from '../../../engine/shared/entity-attributes-for-display';
import {DetailEntry, DetailFactory, DetailFactoryProtocol, EditableType} from '../../../engine/shared/detail-entry';
import {ActiveUserService} from '../../../engine/shared/active-user/active-user.service';
import {BaseDetailType} from "../../../engine/shared/base-detail";
import {EntityViewSpecProtocol} from "../../../engine/shared/entity-view/entity-view-spec-protocol";
import {getHeading} from "../../../engine/shared/display-option-utilities";
import {putSubscriptionFormFieldOptions} from './put-subscription-form-fields';
import {SOGetSubscription} from '../../core/subscription/so-get-subscription';
import {SOGetPrice} from '../../core/price/so-get-price';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {SOGetInvoice} from '../../core/invoice/so-get-invoice';
import {DisplayVectorStringFactory} from "../../../engine/config/display-vector";
import {displayOptions} from "../../display-options";
import {ListSpecType} from "../../../engine/shared/entity-view/list-spec";
import {SPECTER_SPEC} from "../../specter-spec";
import {getCore} from "../../../engine/shared/entity-view/get-core";

export class SubscriptionDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespSubscription = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(displayOptions);
    let heading;

    const attributes = []

    const dateCreatedDetail = new DetailEntry(
        'Date created',
        activeUserService.time.niceDate(entity.dateCreated),
        'dateCreated',
        BaseDetailType.text,
        null,
        EditableType.never,
    )
    attributes.push(dateCreatedDetail)

    const dateUpdatedDetail = new DetailEntry(
        'Date updated',
        activeUserService.time.niceDate(entity.dateUpdated),
        'dateUpdated',
        BaseDetailType.text,
        null,
        EditableType.never,
    )
    attributes.push(dateUpdatedDetail)

    const dateExpiresDetail = new DetailEntry(
        'Date expires',
        activeUserService.time.niceDate(entity.dateExpires),
        'dateExpires',
        BaseDetailType.text,
        null,
        EditableType.always,
        putSubscriptionFormFieldOptions.dateExpires
    )
    attributes.push(dateExpiresDetail)

    const exRefDetail = new DetailEntry(
        'Ex ref',
        entity.exRef,
        'exRef',
        BaseDetailType.text,
        null,
        EditableType.never,
    )
    attributes.push(exRefDetail)

    const statusDetail = new DetailEntry(
        'Status',
        entity.status,
        'status',
        BaseDetailType.toggleGroup,
        null,
        EditableType.always,
        putSubscriptionFormFieldOptions.status
    )
    attributes.push(statusDetail)


    const priceCore = getCore(SPECTER_SPEC, activeUserService, listEntry.entityCore.httpClient, 'PriceCore');
    priceCore.soGet = SOGetPrice.construct({
    });
    heading = getHeading(entityViewSpec.entityCore, priceCore)
    priceCore.title = heading ? heading : priceCore.title
    priceCore.listTitle = 'Price'

    if (entity.price) {
        displayStringFactory.reconfigure(entity.price, entityViewSpec.entityCore.type)
    }
    priceCore.configureForDisplay(entityViewSpec)

    const priceDetail = new DetailEntry(
      heading ? heading : priceCore.listTitle,
      entity.price ? displayStringFactory.primaryString : null,
      'price',
      BaseDetailType.pushedDetail,
      priceCore,
      EditableType.never
    )
    if (entity.price) {
      priceDetail.cachedListEntry = priceCore.makeListEntry(entityViewSpec, priceCore, entity.price, 0)
    }
    priceDetail.singleFieldSelect = true
    attributes.push(priceDetail);

    const organizationCore = getCore(SPECTER_SPEC, activeUserService, listEntry.entityCore.httpClient, 'OrganizationCore');
    organizationCore.soGet = SOGetOrganization.construct({
    });
    heading = getHeading(entityViewSpec.entityCore, organizationCore)
    organizationCore.title = heading ? heading : organizationCore.title
    organizationCore.listTitle = 'Organization'

    if (entity.organization) {
        displayStringFactory.reconfigure(entity.organization, entityViewSpec.entityCore.type)
    }
    organizationCore.configureForDisplay(entityViewSpec)

    const organizationDetail = new DetailEntry(
      heading ? heading : organizationCore.listTitle,
      entity.organization ? displayStringFactory.primaryString : null,
      'organization',
      BaseDetailType.pushedDetail,
      organizationCore,
      EditableType.never
    )
    if (entity.organization) {
      organizationDetail.cachedListEntry = organizationCore.makeListEntry(entityViewSpec, organizationCore, entity.organization, 0)
    }
    organizationDetail.singleFieldSelect = true
    attributes.push(organizationDetail);

    const invoicesCore = getCore(SPECTER_SPEC, activeUserService, listEntry.entityCore.httpClient, 'InvoiceCore');
    invoicesCore.soGet = SOGetInvoice.construct({
    });
    heading = getHeading(entityViewSpec.entityCore, invoicesCore)
    invoicesCore.title = heading ? heading : invoicesCore.title
    invoicesCore.listTitle = 'Invoices'

    invoicesCore.configureForDisplay(entityViewSpec)

    const invoicesDetail = new DetailEntry(
      heading ? heading : invoicesCore.listTitle,
      null,
      'invoices',
      BaseDetailType.embeddedList,
      invoicesCore,
      EditableType.never
    )
    attributes.push(invoicesDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}