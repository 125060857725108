import {ActiveUserService} from '../../../engine/shared/active-user/active-user.service';
import {HttpClient} from '@angular/common/http';
import {EOCustomFieldService} from '../../core/custom-field/eo-custom-field';
import {CustomFieldListEntryFactory} from './custom-field-list-entry-factory';
import {CustomFieldDetailFactory} from './custom-field-detail-factory';
import {SOGetCustomField} from '../../core/custom-field/so-get-custom-field';
import {RAPutCustomField} from './ra-put-custom-field';
import {RAPostCustomField} from './ra-post-custom-field';
import {RADeleteCustomField} from './ra-delete-custom-field';
import {AbstractEntityCore} from "../../../engine/shared/entity-view/abstract-entity-core";
import {EntityCoreProtocol} from "../../../engine/shared/entity-view/entity-core-protocol";
import {EntityType} from '../../entity-types';
import {SPECTER_SPEC} from "../../specter-spec";
import {FormControl, FormGroup} from "@angular/forms";
import {SOGetOrganization} from '../../core/organization/so-get-organization'

export class CustomFieldCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = EntityType.customField;
  title = 'Custom field';
  listEntryFactory = new CustomFieldListEntryFactory();
  detailFactory = new CustomFieldDetailFactory();

  entityService: EOCustomFieldService;
  soGetClass = SOGetCustomField;
  soGet: SOGetCustomField;

  searchEnabled = true;

  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOCustomFieldService
    );
    this.adapters = [
      new RAPostCustomField(this),
      new RAPutCustomField(this),
      new RADeleteCustomField(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetCustomField();
    this.soGet.organization = new SOGetOrganization(
      this.activeUserService.getScope('organization').uid
    );
    this.soGet.queryOptions.limit = this.limit;
  }


}