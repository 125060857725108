import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {HeaderProtocol, MenuItem, MenuItemType} from '../../../protocols/header-protocol';
import {FormControl, FormGroup} from '@angular/forms';
import {HeaderSpec} from "../../../shared/header-spec";
import {UploadButtonInput} from "../sv-upload-button/sv-upload-button.component";
import {Router} from "@angular/router";


@Component({
  selector: 'app-sv-header',
  templateUrl: './sv-header.component.html',
  styleUrls: ['./sv-header.component.css']
})
export class SvHeaderComponent implements OnInit {

  MenuItemType = MenuItemType;

  initialized = false;
  showFilterOptions = false;
  showBackButton = true;
  showCloseButton = true;

  @Input()
  set headerSpec(value: HeaderSpec) {
    if (!this.initialized) {
      this.initialize(value);
    }
  };

  get headerSpec(): HeaderSpec {
    return this._headerSpec
  }
  _headerSpec: HeaderSpec


  @Input()
  parent: HeaderProtocol;
  get saveDisabled(): boolean {
    if (!this.headerSpec.saveButton.disabled) {
      return !this.parent.entityViewSpec.entityCore.putHelper.form.valid
    } else {
      return this.headerSpec.saveButton.disabled
    }
  }

  get dropDownMenuItems(): MenuItem[] {
    const buttons = [];
    for (const item of this.headerSpec.menuItems) {
      if (item.menuItemType !== MenuItemType.multiselect) {
        buttons.push(item);
      }
    }
    return buttons;
  }

  get toggleButtons(): MenuItem[] {
    const buttons = [];
    for (const item of this.headerSpec.menuItems) {
      if (item.menuItemType === MenuItemType.multiselect) {
        buttons.push(item);
      }
    }
    return buttons;
  }

  get searchBar() {
    return this.headerSpec.searchEnabled;
  }

  constructor(
    public router: Router
  ) {
  }

  private initialize(value: HeaderSpec) {
    console.log(value)
    this.initialized = true;
    this._headerSpec = value;
    for (const button of value.buttons) {
      button.header = this
    }
    this.showBackButton = value.showBack
    this.showCloseButton = value.showCloseButton
    this.showFilterOptions = value.filterOpenInitial
  }

  ngOnInit(): void {
  }

  filterPress() {
    console.log('filter press')
    this.showFilterOptions = !this.showFilterOptions;
    console.log(this.showFilterOptions)
  }

  submitSearchForm() {
    this.parent.submitSearchForm(this.headerSpec.searchForm.value.search);
  }

  searchFieldKeyup() {
    this.parent.submitSearchForm(this.headerSpec.searchForm.value.search);
  }

  clickMenuItem(menuItem: MenuItem) {
    menuItem.header = this
    menuItem.click()
  }

  makeUploadButtonInput(menuItem: MenuItem) {
    menuItem.header = this;
    return new UploadButtonInput(menuItem, this)
  }

  startPost() {
    this.parent.entityViewSpec.entityCore.startPost(this.parent.entityViewSpec)
  }
}
