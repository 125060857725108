import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {AuthGuard} from '../engine/shared/auth/auth-guard.service';
import {SvLoginComponent} from "../engine/views/pre-built/sv-login/sv-login.component";
import {SvRegistrationComponent} from "../engine/views/pre-built/sv-registration/sv-registration.component";
import {SvEmailVerificationComponent} from "../engine/views/pre-built/sv-email-verification/sv-email-verification.component";
import {SvFacadeComponent} from "../engine/views/pre-built/sv-facade/sv-facade.component";
import {SvNavigatorComponent} from "../engine/views/structure/sv-navigator/sv-navigator.component";
import {SvTermsComponent} from "../engine/views/pre-built/sv-terms/sv-terms.component";
import {SvPrivacyComponent} from "../engine/views/pre-built/sv-privacy/sv-privacy.component";
import {SvPasswordResetComponent} from "../engine/views/pre-built/sv-password-reset/sv-password-reset.component";

import {ItemCore} from './entities/bv-item/item.core';
import {OrderCore} from './entities/bv-order/order.core';
import {PickupCore} from './entities/bv-pickup/pickup.core';
import {LocationCore} from './entities/bv-location/location.core';
import {CustomFieldCore} from './entities/bv-custom-field/custom-field.core';
import {LineCore} from './entities/bv-line/line.core';
import {DispatchCore} from './entities/bv-dispatch/dispatch.core';
import {TemlocaCore} from './entities/bv-temloca/temloca.core';
import {TemfieldCore} from './entities/bv-temfield/temfield.core';
import {MediaCore} from './entities/bv-media/media.core';
import {TagCore} from './entities/bv-tag/tag.core';
import {TemagaCore} from './entities/bv-temaga/temaga.core';
import {DistributorCore} from './entities/bv-distributor/distributor.core';
import {TemdisaCore} from './entities/bv-temdisa/temdisa.core';
import {DefaultCustomFieldCore} from './entities/bv-default-custom-field/default-custom-field.core';
import {ItemImportCore} from './entities/bv-item-import/item-import.core';
import {ItemImportLineCore} from './entities/bv-item-import-line/item-import-line.core';
import {BulkitemCore} from './entities/bv-bulkitem/bulkitem.core';
import {AttachmentCore} from './entities/bv-attachment/attachment.core';
import {OrganizationCore} from './entities/bv-organization/organization.core';
import {OrganizationInviteCore} from './entities/bv-organization-invite/organization-invite.core';
import {SubscriptionCore} from './entities/bv-subscription/subscription.core';
import {ProductCore} from './entities/bv-product/product.core';
import {PriceCore} from './entities/bv-price/price.core';
import {InvoiceCore} from './entities/bv-invoice/invoice.core';
import {InvoiceLineCore} from './entities/bv-invoice-line/invoice-line.core';
import {PaymentIntentCore} from './entities/bv-payment-intent/payment-intent.core';
import {ConstraintCore} from './entities/bv-constraint/constraint.core';
import {AppliedConstraintCore} from './entities/bv-applied-constraint/applied-constraint.core';
import {UserCore} from './entities/bv-user/user.core';
import {CertificateCore} from './entities/bv-certificate/certificate.core';
import {IdentityCore} from './entities/bv-identity/identity.core';
import {TokenCore} from './entities/bv-token/token.core';
import {RoleGrantCore} from './entities/bv-role-grant/role-grant.core';
import {RoleCore} from './entities/bv-role/role.core';
import {IngestCore} from './entities/bv-ingest/ingest.core';
import {HelloCore} from './entities/bv-hello/hello.core';
import {ViewCore} from './entities/bv-view/view.core';
import {ViewGrantCore} from './entities/bv-view-grant/view-grant.core';
import {SectionCore} from './entities/bv-section/section.core';
import {QuestionCore} from './entities/bv-question/question.core';
import {SesMessageCore} from './entities/bv-ses-message/ses-message.core';



const routes: Routes = [
  { path: 'item', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ItemCore, identifier: 'ItemCore'} },
  { path: 'item/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ItemCore, identifier: 'ItemCore'} },
  { path: 'order', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: OrderCore, identifier: 'OrderCore'} },
  { path: 'order/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: OrderCore, identifier: 'OrderCore'} },
  { path: 'pickup', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: PickupCore, identifier: 'PickupCore'} },
  { path: 'pickup/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: PickupCore, identifier: 'PickupCore'} },
  { path: 'location', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: LocationCore, identifier: 'LocationCore'} },
  { path: 'location/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: LocationCore, identifier: 'LocationCore'} },
  { path: 'custom_field', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: CustomFieldCore, identifier: 'CustomFieldCore'} },
  { path: 'custom_field/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: CustomFieldCore, identifier: 'CustomFieldCore'} },
  { path: 'line', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: LineCore, identifier: 'LineCore'} },
  { path: 'line/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: LineCore, identifier: 'LineCore'} },
  { path: 'dispatch', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: DispatchCore, identifier: 'DispatchCore'} },
  { path: 'dispatch/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: DispatchCore, identifier: 'DispatchCore'} },
  { path: 'temloca', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: TemlocaCore, identifier: 'TemlocaCore'} },
  { path: 'temloca/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: TemlocaCore, identifier: 'TemlocaCore'} },
  { path: 'temfield', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: TemfieldCore, identifier: 'TemfieldCore'} },
  { path: 'temfield/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: TemfieldCore, identifier: 'TemfieldCore'} },
  { path: 'media', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: MediaCore, identifier: 'MediaCore'} },
  { path: 'media/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: MediaCore, identifier: 'MediaCore'} },
  { path: 'tag', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: TagCore, identifier: 'TagCore'} },
  { path: 'tag/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: TagCore, identifier: 'TagCore'} },
  { path: 'temaga', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: TemagaCore, identifier: 'TemagaCore'} },
  { path: 'temaga/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: TemagaCore, identifier: 'TemagaCore'} },
  { path: 'distributor', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: DistributorCore, identifier: 'DistributorCore'} },
  { path: 'distributor/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: DistributorCore, identifier: 'DistributorCore'} },
  { path: 'temdisa', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: TemdisaCore, identifier: 'TemdisaCore'} },
  { path: 'temdisa/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: TemdisaCore, identifier: 'TemdisaCore'} },
  { path: 'default_custom_field', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: DefaultCustomFieldCore, identifier: 'DefaultCustomFieldCore'} },
  { path: 'default_custom_field/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: DefaultCustomFieldCore, identifier: 'DefaultCustomFieldCore'} },
  { path: 'item_import', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ItemImportCore, identifier: 'ItemImportCore'} },
  { path: 'item_import/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ItemImportCore, identifier: 'ItemImportCore'} },
  { path: 'item_import_line', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ItemImportLineCore, identifier: 'ItemImportLineCore'} },
  { path: 'item_import_line/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ItemImportLineCore, identifier: 'ItemImportLineCore'} },
  { path: 'bulkitem', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: BulkitemCore, identifier: 'BulkitemCore'} },
  { path: 'bulkitem/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: BulkitemCore, identifier: 'BulkitemCore'} },
  { path: 'attachment', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: AttachmentCore, identifier: 'AttachmentCore'} },
  { path: 'attachment/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: AttachmentCore, identifier: 'AttachmentCore'} },
  { path: 'organization', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: OrganizationCore, identifier: 'OrganizationCore'} },
  { path: 'organization/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: OrganizationCore, identifier: 'OrganizationCore'} },
  { path: 'organization_invite', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: OrganizationInviteCore, identifier: 'OrganizationInviteCore'} },
  { path: 'organization_invite/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: OrganizationInviteCore, identifier: 'OrganizationInviteCore'} },
  { path: 'subscription', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: SubscriptionCore, identifier: 'SubscriptionCore'} },
  { path: 'subscription/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: SubscriptionCore, identifier: 'SubscriptionCore'} },
  { path: 'product', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ProductCore, identifier: 'ProductCore'} },
  { path: 'product/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ProductCore, identifier: 'ProductCore'} },
  { path: 'price', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: PriceCore, identifier: 'PriceCore'} },
  { path: 'price/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: PriceCore, identifier: 'PriceCore'} },
  { path: 'invoice', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: InvoiceCore, identifier: 'InvoiceCore'} },
  { path: 'invoice/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: InvoiceCore, identifier: 'InvoiceCore'} },
  { path: 'invoice_line', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: InvoiceLineCore, identifier: 'InvoiceLineCore'} },
  { path: 'invoice_line/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: InvoiceLineCore, identifier: 'InvoiceLineCore'} },
  { path: 'payment_intent', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: PaymentIntentCore, identifier: 'PaymentIntentCore'} },
  { path: 'payment_intent/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: PaymentIntentCore, identifier: 'PaymentIntentCore'} },
  { path: 'constraint', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ConstraintCore, identifier: 'ConstraintCore'} },
  { path: 'constraint/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ConstraintCore, identifier: 'ConstraintCore'} },
  { path: 'applied_constraint', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: AppliedConstraintCore, identifier: 'AppliedConstraintCore'} },
  { path: 'applied_constraint/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: AppliedConstraintCore, identifier: 'AppliedConstraintCore'} },
  { path: 'user', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: UserCore, identifier: 'UserCore'} },
  { path: 'user/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: UserCore, identifier: 'UserCore'} },
  { path: 'certificate', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: CertificateCore, identifier: 'CertificateCore'} },
  { path: 'certificate/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: CertificateCore, identifier: 'CertificateCore'} },
  { path: 'identity', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: IdentityCore, identifier: 'IdentityCore'} },
  { path: 'identity/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: IdentityCore, identifier: 'IdentityCore'} },
  { path: 'token', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: TokenCore, identifier: 'TokenCore'} },
  { path: 'token/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: TokenCore, identifier: 'TokenCore'} },
  { path: 'role_grant', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: RoleGrantCore, identifier: 'RoleGrantCore'} },
  { path: 'role_grant/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: RoleGrantCore, identifier: 'RoleGrantCore'} },
  { path: 'role', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: RoleCore, identifier: 'RoleCore'} },
  { path: 'role/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: RoleCore, identifier: 'RoleCore'} },
  { path: 'ingest', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: IngestCore, identifier: 'IngestCore'} },
  { path: 'ingest/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: IngestCore, identifier: 'IngestCore'} },
  { path: 'hello', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: HelloCore, identifier: 'HelloCore'} },
  { path: 'hello/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: HelloCore, identifier: 'HelloCore'} },
  { path: 'view', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ViewCore, identifier: 'ViewCore'} },
  { path: 'view/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ViewCore, identifier: 'ViewCore'} },
  { path: 'view_grant', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ViewGrantCore, identifier: 'ViewGrantCore'} },
  { path: 'view_grant/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: ViewGrantCore, identifier: 'ViewGrantCore'} },
  { path: 'section', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: SectionCore, identifier: 'SectionCore'} },
  { path: 'section/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: SectionCore, identifier: 'SectionCore'} },
  { path: 'question', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: QuestionCore, identifier: 'QuestionCore'} },
  { path: 'question/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: QuestionCore, identifier: 'QuestionCore'} },
  { path: 'ses_message', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: SesMessageCore, identifier: 'SesMessageCore'} },
  { path: 'ses_message/:entityUid', component: SvNavigatorComponent, canActivate: [AuthGuard], data: {CoreClass: SesMessageCore, identifier: 'SesMessageCore'} },
];

routes.push(
  { path: 'login', component: SvLoginComponent},
  { path: 'register', component: SvRegistrationComponent},
  { path: 'register/:email', component: SvRegistrationComponent},
  { path: 'terms', component: SvTermsComponent},
  { path: 'privacy', component: SvPrivacyComponent},
  { path: 'verify-email/:token', component: SvEmailVerificationComponent},
  { path: 'reset-password', component: SvPasswordResetComponent},
  { path: 'reset-password/:token', component: SvPasswordResetComponent},
  { path: '', component: SvFacadeComponent},
)

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }