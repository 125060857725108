import {SORespTemloca} from '../../core/temloca/so-resp-temloca';
import {TemlocaCore} from './temloca.core';
import {ListEntry} from '../../../engine/shared/list-entry';
import {EntityAttributesForDisplay} from '../../../engine/shared/entity-attributes-for-display';
import {DetailEntry, DetailFactory, DetailFactoryProtocol, EditableType} from '../../../engine/shared/detail-entry';
import {ActiveUserService} from '../../../engine/shared/active-user/active-user.service';
import {BaseDetailType} from "../../../engine/shared/base-detail";
import {EntityViewSpecProtocol} from "../../../engine/shared/entity-view/entity-view-spec-protocol";
import {getHeading} from "../../../engine/shared/display-option-utilities";
import {putTemlocaFormFieldOptions} from './put-temloca-form-fields';
import {SOGetTemloca} from '../../core/temloca/so-get-temloca';
import {SOGetItem} from '../../core/item/so-get-item';
import {SOGetLocation} from '../../core/location/so-get-location';
import {SOGetDispatch} from '../../core/dispatch/so-get-dispatch';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {DisplayVectorStringFactory} from "../../../engine/config/display-vector";
import {displayOptions} from "../../display-options";
import {ListSpecType} from "../../../engine/shared/entity-view/list-spec";
import {SPECTER_SPEC} from "../../specter-spec";
import {getCore} from "../../../engine/shared/entity-view/get-core";

export class TemlocaDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespTemloca = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(displayOptions);
    let heading;

    const attributes = []

    const quantityDetail = new DetailEntry(
        'Quantity',
        entity.quantity,
        'quantity',
        BaseDetailType.text,
        null,
        EditableType.never,
    )
    attributes.push(quantityDetail)


    const itemCore = getCore(SPECTER_SPEC, activeUserService, listEntry.entityCore.httpClient, 'ItemCore');
    itemCore.soGet = SOGetItem.construct({
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, itemCore)
    itemCore.title = heading ? heading : itemCore.title
    itemCore.listTitle = 'Item'

    if (entity.item) {
        displayStringFactory.reconfigure(entity.item, entityViewSpec.entityCore.type)
    }
    itemCore.configureForDisplay(entityViewSpec)

    const itemDetail = new DetailEntry(
      heading ? heading : itemCore.listTitle,
      entity.item ? displayStringFactory.primaryString : null,
      'item',
      BaseDetailType.pushedDetail,
      itemCore,
      EditableType.never
    )
    if (entity.item) {
      itemDetail.cachedListEntry = itemCore.makeListEntry(entityViewSpec, itemCore, entity.item, 0)
    }
    itemDetail.singleFieldSelect = true
    attributes.push(itemDetail);

    const locationCore = getCore(SPECTER_SPEC, activeUserService, listEntry.entityCore.httpClient, 'LocationCore');
    locationCore.soGet = SOGetLocation.construct({
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, locationCore)
    locationCore.title = heading ? heading : locationCore.title
    locationCore.listTitle = 'Location'

    if (entity.location) {
        displayStringFactory.reconfigure(entity.location, entityViewSpec.entityCore.type)
    }
    locationCore.configureForDisplay(entityViewSpec)

    const locationDetail = new DetailEntry(
      heading ? heading : locationCore.listTitle,
      entity.location ? displayStringFactory.primaryString : null,
      'location',
      BaseDetailType.pushedDetail,
      locationCore,
      EditableType.never
    )
    if (entity.location) {
      locationDetail.cachedListEntry = locationCore.makeListEntry(entityViewSpec, locationCore, entity.location, 0)
    }
    locationDetail.singleFieldSelect = true
    attributes.push(locationDetail);

    const dispatchesCore = getCore(SPECTER_SPEC, activeUserService, listEntry.entityCore.httpClient, 'DispatchCore');
    dispatchesCore.soGet = SOGetDispatch.construct({
      temloca: new SOGetTemloca(entity.uid),
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, dispatchesCore)
    dispatchesCore.title = heading ? heading : dispatchesCore.title
    dispatchesCore.listTitle = 'Dispatches'

    dispatchesCore.configureForDisplay(entityViewSpec)

    const dispatchesDetail = new DetailEntry(
      heading ? heading : dispatchesCore.listTitle,
      null,
      'dispatches',
      BaseDetailType.embeddedList,
      dispatchesCore,
      EditableType.never
    )
    attributes.push(dispatchesDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}