import {ENProgress} from '../../enums';


export class SOPutPickup {

  constructor(
    public uid: string,
    public ref: string = null,
    public status: ENProgress = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutPickup(
      'uid' in params ? params.uid : null,
      'ref' in params ? params.ref : null,
      'status' in params ? params.status : null,
    );
  }
}
