import { SOGetItem } from '../item/so-get-item';
import { SOGetTag } from '../tag/so-get-tag';
import { SOGetOrganization } from '../organization/so-get-organization';


export class SOPostTemaga {

  constructor(
    public item: SOGetItem,
    public tag: SOGetTag,
    public organization: SOGetOrganization,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostTemaga(
      'item' in params ? params.item : null,
      'tag' in params ? params.tag : null,
      'organization' in params ? params.organization : null,
    );
  }
}
