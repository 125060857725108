import {FormField, FormFieldType, SelectionOption} from '../../../engine/shared/form-field';
import {EntityType} from "../../entity-types";


const entityNameField = new FormField(
  'entityName',
  'Entity name',
  null,
  FormFieldType.text,
  true,
)
const dataField = new FormField(
  'data',
  'Data',
  null,
  FormFieldType.text,
  true,
)
const organizationField = new FormField(
  'organization',
  'Organization',
  EntityType.organization,
  FormFieldType.pushedSelectionList,
  true,
)


export const postIngestFormFieldOptions: {[key: string]: FormField} = {
  entityName: entityNameField,
  data: dataField,
  organization: organizationField,
};