import { Type } from 'class-transformer';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ObjectiveProtocol} from '../../../engine/protocols/objective-protocol';
import {EntityType} from "../../entity-types";


export class SORespDistributor implements ObjectiveProtocol {

  entityType: EntityType = EntityType.distributor;
  uid: string;
  name: string;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;
}