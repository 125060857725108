import {ActiveUserService} from '../../../engine/shared/active-user/active-user.service';
import {HttpClient} from '@angular/common/http';
import {EOTemagaService} from '../../core/temaga/eo-temaga';
import {TemagaListEntryFactory} from './temaga-list-entry-factory';
import {TemagaDetailFactory} from './temaga-detail-factory';
import {SOGetTemaga} from '../../core/temaga/so-get-temaga';
import {RAPutTemaga} from './ra-put-temaga';
import {RAPostTemaga} from './ra-post-temaga';
import {RADeleteTemaga} from './ra-delete-temaga';
import {AbstractEntityCore} from "../../../engine/shared/entity-view/abstract-entity-core";
import {EntityCoreProtocol} from "../../../engine/shared/entity-view/entity-core-protocol";
import {EntityType} from '../../entity-types';
import {SPECTER_SPEC} from "../../specter-spec";
import {SOGetOrganization} from '../../core/organization/so-get-organization'

export class TemagaCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = EntityType.temaga;
  title = 'Temaga';
  listEntryFactory = new TemagaListEntryFactory();
  detailFactory = new TemagaDetailFactory();

  entityService: EOTemagaService;
  soGetClass = SOGetTemaga;
  soGet: SOGetTemaga;

  showQuickAdd = true;

  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOTemagaService
    );
    this.adapters = [
      new RAPostTemaga(this),
      new RAPutTemaga(this),
      new RADeleteTemaga(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetTemaga();
    this.soGet.organization = new SOGetOrganization(
      this.activeUserService.getScope('organization').uid
    );
    this.soGet.queryOptions.limit = this.limit;
  }


}