import { SOGetSection } from '../section/so-get-section';


export class SOPostQuestion {

  constructor(
    public question: string,
    public answer: string,
    public number: number,
    public section: SOGetSection,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostQuestion(
      'question' in params ? params.question : null,
      'answer' in params ? params.answer : null,
      'number' in params ? params.number : null,
      'section' in params ? params.section : null,
    );
  }
}
