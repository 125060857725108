<div class="fill-container">
  <div class="center-box-outer">
    <mat-progress-bar *ngIf="loading" class="progress-bar" mode="indeterminate" color="primary"></mat-progress-bar>
    <div *ngIf="!loading" class="progress-bar"></div>
    <div class="center-box-inner" fxLayout="column">
      <h1 *ngIf="success">Email verification success</h1>
      <h1 *ngIf="fail">{{failMessage}}</h1>
    </div>
  </div>
</div>
