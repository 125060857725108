import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';

import { SOPostItemImport } from './so-post-item-import';
import { SOGetItemImport } from './so-get-item-import';
import { SOPutItemImport } from './so-put-item-import';
import { SODeleteItemImport } from './so-delete-item-import';
import {SORespItemImport} from './so-resp-item-import';
import {SOPluralItemImport} from './so-plural-item-import';

import {MMEntityService} from '../../../engine/multiverse/mm-entity-service';
import {EntityCoreProtocol} from '../../../engine/shared/entity-view/entity-core-protocol';
import {MMEntityServiceBase} from '../../../engine/multiverse/mm-entity-service-base';
import {HttpHeaders} from "@angular/common/http";


export class EOItemImportService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'item_import',
      entityCore
    );
  }

  /** GET ItemImport by id. Will 404 if id not found */
  get(request: SOGetItemImport): Observable<SOPluralItemImport> {
    return this.httpClient.get<SOPluralItemImport>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralItemImport, resp) }),
      tap(_ => this.log(`fetched ItemImport`)),
      catchError(this.handleError<SOPluralItemImport>(`get ItemImport`))
    );
  }

  /** POST: add a new ItemImport to the server */
  post(request: SOPostItemImport): Observable<SORespItemImport> {
    return this.httpClient.post<SORespItemImport>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespItemImport, resp) }),
      tap((response: SORespItemImport) => this.log(`added ItemImport w/ id=${response.uid}`)),
      catchError(this.handleError<SORespItemImport>('ItemImport post'))
    );
  }

  /** PUT: update ItemImport  */
  put(request: SOPutItemImport): Observable<SORespItemImport> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespItemImport>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespItemImport, resp) }),
      tap((response: SORespItemImport) => this.log(`edited ItemImport w/ uid=${uid}`)),
      catchError(this.handleError<SORespItemImport>('ItemImport put'))
    );
  }

  /** DELETE: delete the ItemImport from the server */
  delete(request: SODeleteItemImport): Observable<SORespItemImport> {
    return this.httpClient.delete<SORespItemImport>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted ItemImport uid=${request.uid}`)),
      catchError(this.handleError<SORespItemImport>('delete ItemImport'))
    );
  }
}
