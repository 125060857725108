import {SvNavigatorComponent} from "../views/structure/sv-navigator/sv-navigator.component";
import {DetailEntry} from "./detail-entry";


export class FormViewBase {

  errorMessage: string

  _navigator: SvNavigatorComponent
  get navigator(): SvNavigatorComponent {
    return this._navigator
  }
  set navigator(value: SvNavigatorComponent) {
    this._navigator = value;
  }

  clickPushDetail(detailEntry: DetailEntry) {
    throw new Error("Method not implemented.");
  }
  clickPushList(detailEntry: DetailEntry) {
    throw new Error("Method not implemented.");
  }

  presentUserWithMessage(message: string, duration: number) {
    throw new Error("Method not implemented.");
  }
}
