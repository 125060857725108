import {SOPutTemaga} from '../../core/temaga/so-put-temaga';
import {TemagaCore} from './temaga.core';
import {SOGetTemaga} from '../../core/temaga/so-get-temaga';
import {FormField, SelectionOption} from '../../../engine/shared/form-field';
import {putTemagaFormFieldOptions} from './put-temaga-form-fields';
import {AdapterProtocol} from '../../../engine/shared/entity-construction/adapter-protocol';
import {BasePut} from '../../../engine/shared/entity-construction/base-put';
import {ItemCore} from '../bv-item/item.core';
import {SOGetItem} from '../../core/item/so-get-item';
import {TagCore} from '../bv-tag/tag.core';
import {SOGetTag} from '../../core/tag/so-get-tag';

export class RAPutTemaga extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putTemagaFormFieldOptions);

  RequestClass = SOPutTemaga

  constructor(
    public entityCore: TemagaCore
  ) {
    super();
    if (putTemagaFormFieldOptions.item) {
      putTemagaFormFieldOptions.item.makeCore = true;
    }
    if (putTemagaFormFieldOptions.tag) {
      putTemagaFormFieldOptions.tag.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier(
        'item',
        SOGetItem,
      ),
      this.getFormValueFromIdentifier(
        'tag',
        SOGetTag,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}