<div class="panel-container" fxLayout="column">
  <app-sv-header
    *ngIf="entityViewSpec.showHeader"
    [headerSpec]="headerSpec"
    [parent]="this"
  ></app-sv-header>
  <mat-progress-bar *ngIf="loading" class="progress-bar" mode="indeterminate" color="primary"></mat-progress-bar>
  <div *ngIf="!loading" class="progress-bar"></div>
  <div [ngClass]="{'content-body': !entityViewSpec.embedded, 'narrow-content-body': entityViewSpec.embedded}"
       fxLayout="column">

    <p class="error-message" *ngIf="errorMessage">{{errorMessage}}</p>

    <div class="panel-content" *ngIf="posting">

      <div *ngFor="let action of entityViewSpec.entityCore.postHelper.actions" fxLayout="column"
           fxLayoutAlign="stretch center" class="fetch-dcs">
        <p>{{action.displayText}}</p>
        <button mat-stroked-button *ngIf="!action.processing" (click)="action.startAction()"
                [disabled]="!action.enabled">{{action.startActionText}}</button>
        <mat-spinner color="primary" *ngIf="action.processing" diameter="45"></mat-spinner>
      </div>

      <form *ngIf="!entityViewSpec.embedded" class="main-form" [formGroup]="entityViewSpec.entityCore.postHelper.form"
            fxLayout="column" (ngSubmit)="clickSubmitFormButton()">
        <app-sv-attribute-view *ngFor="let attributeViewSpec of attributeViewSpecs"
                               class="field" [attributeViewSpec]="attributeViewSpec">
        </app-sv-attribute-view>
        <button *ngIf="entityViewSpec.showSubmitButton" mat-stroked-button type="submit"
                color="accent"
                [disabled]="!entityViewSpec.entityCore.postHelper.form.valid || entityViewSpec.entityCore.postHelper.submitDisabled || entityViewSpec.entityCore.postHelper.requestInProgress">
          Submit
        </button>
      </form>
      <button *ngIf="!entityViewSpec.embedded" mat-stroked-button class="cancel-button" (click)="cancel()" [disabled]="entityViewSpec.entityCore.postHelper.requestInProgress" color="primary">
              Cancel
      </button>

      <form *ngIf="entityViewSpec.embedded" class="main-form" [formGroup]="entityViewSpec.entityCore.postHelper.form"
            style="width: 100%"
            fxLayout="row" fxLayoutAlign="space-between center" (ngSubmit)="clickSubmitFormButton()">
        <div fxLayout="row" fxLayoutAlign="start center" fxFlex="grow">
          <app-sv-attribute-view *ngFor="let attributeViewSpec of attributeViewSpecs"
                                 fxFlex="grow"
                                 class="field-horizontal" [attributeViewSpec]="attributeViewSpec">
          </app-sv-attribute-view>
        </div>
        <button *ngIf="entityViewSpec.showSubmitButton" mat-stroked-button type="submit"
                fxFlex="noshrink"
                color="accent"
                [disabled]="!entityViewSpec.entityCore.postHelper.form.valid
                || entityViewSpec.entityCore.postHelper.submitDisabled
                || entityViewSpec.entityCore.postHelper.requestInProgress">
          Submit
        </button>
      </form>

    </div>
  </div>
</div>
