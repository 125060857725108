import {SOGetOrganization} from "../../../../../app/core/organization/so-get-organization";
import {OrganizationCore} from "../../../../../app/entities/bv-organization/organization.core";
import {SvNavigatorComponent} from "../../../structure/sv-navigator/sv-navigator.component";
import {AttributesSpec} from "../../../../shared/entity-view/detail-spec";
import {EntityViewSpecProtocol} from "../../../../shared/entity-view/entity-view-spec-protocol";
import {EntityAttributesForDisplay} from "../../../../shared/entity-attributes-for-display";
import {ListEntry} from "../../../../shared/list-entry";
import {DetailEntry, EditableType} from "../../../../shared/detail-entry";
import {getCore} from "../../../../shared/entity-view/get-core";
import {SPECTER_SPEC} from "../../../../../app/specter-spec";
import {SOGetPrice} from "../../../../../app/core/price/so-get-price";
import {getHeading} from "../../../../shared/display-option-utilities";
import {BaseDetailType} from "../../../../shared/base-detail";
import {ListSpecType} from "../../../../shared/entity-view/list-spec";
import {SORespOrganization} from "../../../../../app/core/organization/so-resp-organization";
import {Button, ButtonPressProtocol} from "../../../../shared/header-spec";
import {BulkitemCore} from "../../../../../app/entities/bv-bulkitem/bulkitem.core";
import {SOGetBulkitem} from "../../../../../app/core/bulkitem/so-get-bulkitem";
import {ExcelService} from "../../../../shared/excel-service";

export class BaseOrganizationProcessor extends OrganizationCore implements ButtonPressProtocol {

  navTitle = 'Organization';
  showDelete = false;


  makeEntityAttributesForDisplay(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry): EntityAttributesForDisplay {
    const attrs = super.makeEntityAttributesForDisplay(entityViewSpec, listEntry);
    const org: SORespOrganization = listEntry.entity;

    if (!org.exRefPaymentsLink) {
      this.addAvailablePricesForPurchase(listEntry, entityViewSpec, attrs);
    }

    const exportButtonDetail = new DetailEntry(
      'Export data',
      'export',
      'export',
      BaseDetailType.button,
    )
    exportButtonDetail.button = new Button('export', 'Export data')
    exportButtonDetail.button.buttonPressHandler = this
    attrs.attributes.splice(4, 0,
      exportButtonDetail
    )

    if (this.activeUserService.activeGrant.role.identifier !== 'sysadmin') {
      const exRefPaymentsLinkDex = attrs.attributes.findIndex(obj => obj.identifier === 'subscriptions')
      attrs.attributes.splice(exRefPaymentsLinkDex, 1)
    }

    return attrs
  }

  private addAvailablePricesForPurchase(listEntry: ListEntry, entityViewSpec: EntityViewSpecProtocol, attrs: EntityAttributesForDisplay) {
    const pricesCore = getCore(SPECTER_SPEC, this.activeUserService, listEntry.entityCore.httpClient, 'PriceCore');
    pricesCore.listSpecType = ListSpecType.cardList;


    pricesCore.soGet = new SOGetPrice();
    const heading = 'Pricing option';
    pricesCore.title = heading ? heading : pricesCore.title;
    pricesCore.listTitle = 'Pricing options';
    pricesCore.showAdd = false;

    pricesCore.configureForDisplay(entityViewSpec)

    const pricesDetail = new DetailEntry(
      heading ? heading : pricesCore.listTitle,
      null,
      'prices',
      BaseDetailType.embeddedList,
      pricesCore,
      EditableType.never
    )

    const exRefPaymentsLinkDex = attrs.attributes.findIndex(obj => obj.identifier === 'exRefPaymentsLink')
    attrs.attributes.splice(exRefPaymentsLinkDex, 1, pricesDetail)
  }

  prepareStartingEntityViewSpec(avRoute: SvNavigatorComponent) {
    if (this.activeUserService.activeGrant.role.identifier === 'owner') {
      avRoute.loading = true;
      this.entityService.get(new SOGetOrganization(this.activeUserService.activeOrganization.uid)).subscribe(resp => {
        if (resp) {
          this.selectedEntity = resp.objects[0];
          avRoute.setInitialEntityViewSpec(this.startingEntityViewSpec(avRoute))
        }
      })
    } else {
      super.prepareStartingEntityViewSpec(avRoute)
    }
  }

  startingEntityViewSpec(avRoute: SvNavigatorComponent) {

    if (this.activeUserService.activeGrant.role.identifier === 'owner') {
      return new AttributesSpec(
        this.makeListEntry(null, this, this.selectedEntity),
        null,
        null,
        avRoute
      )
    } else {
      return super.startingEntityViewSpec(avRoute);
    }
  }

  buttonPress(button: Button): void {

    console.log('exporting')
    if (button.identifier === 'export') {
      const bulkItemCore = new BulkitemCore(this.activeUserService, this.httpClient)
      bulkItemCore.entityService.get(
        new SOGetBulkitem(
          null,
          new SOGetOrganization(this.activeUserService.activeOrganization.uid),
          'export'
          )
      ).subscribe(resp => {
        if (resp && resp.objects.length === 1) {
          const excelService = new ExcelService(this.httpClient)
          excelService.exportJsonData('binin_data_export', resp.objects[0].data)
        }
      })
    }
  }
}
