import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {EntityCoreProtocol} from '../shared/entity-view/entity-core-protocol';
import {PluralProtocol} from "../protocols/plural-protocol";
import {SPECTER_SPEC} from "../../app/specter-spec";

export interface EntityServiceErrorHandler {
  processError(request, message);
}

export class MMEntityServiceBase {

  coreUrl = environment.coreApiUrl;

  get httpOptions() {
    return {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'X-Sid': SPECTER_SPEC.specterId
        }
      ),
      withCredentials: true
    };
  }

  httpClient: HttpClient;
  errorHandler: EntityServiceErrorHandler;

  constructor(
    public route: string,
    public entityCore: EntityCoreProtocol
  ) {
    this.httpClient = entityCore.httpClient;
  }

  makeGetUri(request) {
    let uri;
    if (request.uid) {
      uri = `${this.coreUrl}${this.route}/${request.uid}`;
    } else {
      uri = `${this.coreUrl}${this.route}`;
    }
    let queryString = '';
    for (let [key, value] of Object.entries(request)) {
      if (value !== null) {
        if (value.hasOwnProperty('uid')) {
          queryString = queryString + `${key}=${value['uid']}&`;
        } else {
          if (key === 'queryOptions') {

            const options = {};
            for (const [k, v] of Object.entries(request.queryOptions)) {
              if (v !== null) {
                options[k] = v;
              }
            }
            value = encodeURIComponent(JSON.stringify(options));
          }
          queryString = queryString + `${key}=${value}&`;
        }
      }
    }
    if (queryString) {
      uri = uri + `?${queryString}`;
    }
    return uri;
  }

  makeDeleteUri(request) {
    let uri;
    if (request.uid) {
      uri = `${this.coreUrl}${this.route}/${request.uid}`;
    } else {
      uri = `${this.coreUrl}${this.route}/None`;
    }
    return uri;
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param request - request that failed
   * @param result - optional value to return as the observable result
   */
  handleError<T>(request, result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      let message;
      if (error.error.Message !== null && error.error.Message !== undefined) {
        message = error.error.Message;
      } else if (error.error.msg !== null && error.error.msg !== undefined) {
        message = error.error.msg;
      } else {
        message = 'Cannot parse error message';
      }

      if (this.errorHandler) {
        this.errorHandler.processError(request, message)
      } else {
        this.entityCore.requestError(request, message);
      }

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a SORespGrantService message with the MessageService */
  log(message: string) {
    // this.messageService.add(`SORespGrantService: ${message}`);
  }

  postFile(request, file: File) {

  }
}
