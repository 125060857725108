import {Component, Input, OnInit} from '@angular/core';
import {CalendarDay} from '../../shared/calendar/calendar-day';
import {Section} from '../../shared/list-body';
import {FormControl} from '@angular/forms';
import {ListEntry} from '../../shared/list-entry';
import {isDstObserved} from '../../shared/display-time-helper';
import {CalendarSpec} from '../../shared/entity-view/calendar-spec';
import {SvListComponent} from "../entity/sv-list/sv-list.component";


function getIndexOfDate(date: Date, adjustmentDate: Date, gmtOffset) {
  return Math.floor(
    (date.getTime() - 4 * 3600000) / 1000 / 60 / 60 / 24
  ) - Math.floor(
    (adjustmentDate.getTime() - 4 * 3600000) / 1000 / 60 / 60 / 24
  );
}


@Component({
  selector: 'app-sv-calendar',
  templateUrl: './sv-calendar.component.html',
  styleUrls: ['./sv-calendar.component.css']
})
export class SvCalendarComponent extends SvListComponent {
  soak = false;
  sunday: Date;

  @Input()
  set entityViewSpec(entityViewSpec) {
    console.log('in cal spec');
    if (this.days.length === 0) {
      this.init();
    }
    entityViewSpec.entityCore.soGet.queryOptions.datetimeStart = this.days[0].date.setHours(this.days[0].date.getHours() - 2);
    entityViewSpec.entityCore.soGet.queryOptions.datetimeEnd = this.days[6].date;
    super.entityViewSpec = entityViewSpec;
  }

  date: FormControl;

  monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];

  days: CalendarDay[] = [];
  month: string;
  year: number;
  dayIndex = 0;

  entities: any[] = [];
  dayIndexDate: Date;

  timeChangeFall = false;
  timeChangeSpring = false;

  get showDownload() {
    return true;
  }

  get showUpload(): boolean {
    return true;
  }

  init() {
    console.log('initing calendar');
    this.setToday();
    this.date = new FormControl(this.dayIndexDate);
  }

  listFetched(entities, entityViewSpec: CalendarSpec, hasMore) {
    this._entityViewSpec = entityViewSpec;
    console.log('Fetching list');
    console.log(entities);
    this.hasMore = hasMore;
    entityViewSpec.listBody.reset()
    this.entities = entities;
    this.initialize(entityViewSpec)

  }

  selectListEntry(listEntry: ListEntry) {
    this.parentEntityViewComponent.selectListEntry(listEntry, this._entityViewSpec);
  }

  next() {
    // if (this.activeUserService.isHandset) {
    //   if (this.dayIndex < 6) {
    //     this.dayIndex = this.dayIndex + 1;
    //   } else {
    //     this.dayIndex = 0;
    //     this.nextWeek();
    //   }
    // } else {
    this.nextWeek();
    // }
  }

  back() {
    // if (this.activeUserService.isHandset) {
    //   if (this.dayIndex > 0) {
    //     this.dayIndex = this.dayIndex - 1;
    //   } else {
    //     this.dayIndex = 6;
    //     this.backWeek();
    //   }
    // } else {
    this.backWeek();
    // }
  }

  backWeek() {

    const startDst = isDstObserved(this.dayIndexDate);
    let dayIndexDate = this.dayIndexDate.getTime();

    this.dayIndexDate = new Date((dayIndexDate / 1000 + (-7 * 24 * 3600)) * 1000);

    const endDst = isDstObserved(this.dayIndexDate);

    const timeChangeFall = endDst === true && startDst === false;
    const timeChangeSpring = endDst === false && startDst === true;

    if (timeChangeFall) {
      dayIndexDate = this.dayIndexDate.getTime();
      this.dayIndexDate = new Date(dayIndexDate - 3600000);
    }

    if (timeChangeSpring) {
      dayIndexDate = this.dayIndexDate.getTime();
      this.dayIndexDate = new Date(dayIndexDate + 3600000);
    }

    this.timeChangeSpring = false;
    this.timeChangeFall = false;

    this.setDays();
    this.pullEvents();
  }

  nextWeek() {

    const startDst = isDstObserved(this.dayIndexDate);
    let dayIndexDate = this.dayIndexDate.getTime();

    this.dayIndexDate = new Date((dayIndexDate / 1000 + (7 * 24 * 3600)) * 1000);

    const endDst = isDstObserved(this.dayIndexDate);

    const timeChangeFall = endDst === true && startDst === false;
    const timeChangeSpring = endDst === false && startDst === true;

    if (timeChangeFall) {
      dayIndexDate = this.dayIndexDate.getTime();
      this.dayIndexDate = new Date(dayIndexDate - 3600000);
    }

    if (timeChangeSpring) {
      dayIndexDate = this.dayIndexDate.getTime();
      this.dayIndexDate = new Date(dayIndexDate + 3600000);
    }

    this.timeChangeSpring = false;
    this.timeChangeFall = false;

    this.setDays();
    this.pullEvents();
  }

  private pullEvents() {
    this._entityViewSpec.entityCore.soGet.queryOptions.datetimeStart = this.days[0].date;
    this._entityViewSpec.entityCore.soGet.queryOptions.datetimeEnd = this.days[6].date;
    // this._entityViewSpec.entityCore.delegate = this;
    // super.entityViewSpec = this._entityViewSpec;
    this._entityViewSpec.entityCore.entities = null;
    this._entityViewSpec.entityCore.fetchList(this._entityViewSpec);
  }

  pressTodayButton() {
    this.setToday();
    this.pullEvents();

  }

  setToday() {
    this.timeChangeSpring = false;
    this.timeChangeFall = false;
    this.getToday();
    this.setDays();
  }

  getToday() {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    // date.setUTCHours(0, 0, 0, 0);

    const today = date.getDate();
    const dayOfTheWeek = date.getDay();
    const newDate = date.setDate(today - dayOfTheWeek);
    const result = new Date(newDate);
    result.setUTCHours(0);
    const satDst = isDstObserved(result);

    const anotherDay = result.getDate();
    result.setDate(anotherDay - 1);

    const sunDst = isDstObserved(result);

    console.log(satDst);
    console.log(sunDst);

    this.timeChangeFall = satDst === false && sunDst === true;
    this.timeChangeSpring = satDst === true && sunDst === false;

    console.log(this.timeChangeFall);

    this.dayIndexDate = date;
    this.dayIndex = this.dayIndexDate.getUTCDay();


    console.log(`INFO: sunday is set to ${result} which is ${result.toLocaleString()} at the plant`);
    // console.log(result.toLocaleString());
    console.log(date);
    // console.log(date.toLocaleString())
    // console.log(date.toUTCString())
    // console.log(date.toLocaleTimeString())
    // console.log(date.getTimezoneOffset()/60)
    // console.log(stdTimezoneOffset(date)/60)
    // console.log(isDstObserved(date))

    // return result;
  }

  private setDays() {
    this.month = this.monthNames[this.dayIndexDate.getMonth()];
    this.year = this.dayIndexDate.getFullYear();
    this.days = [];

    let today = this.dayIndexDate.getTime();
    for (let num = 0; num <= 6; num++) {
      if (this.timeChangeFall && num === 1) {
        today = today + 3600000;
      }
      if (this.timeChangeSpring && num === 1) {
        today = today - 3600000;
      }
      const date = new Date((today / 1000 + (num * 24 * 3600)) * 1000);

      console.log(date);
      this.days.push(
        new CalendarDay(date)
      );
    }
  }

  get slotDuration() {
    // tslint:disable-next-line:max-line-length
    return 4;
  }

  private slotStart(date: Date, slotNumber: number) {
    const startDate = new Date(date);
    return startDate;
  }

  private slotEnd(startDate: Date) {
    const endDate = new Date(startDate);
    endDate.setHours(startDate.getHours() + this.slotDuration, 0, 0, 0);
    return endDate;
  }

  uploadFile($event) {
    console.log('Upload file selected');
    const data = $event.target.files[0];
    this.excelService.readFirstSheet(data, this);
  }

  readComplete(data) {

  }

  downloadSchedule() {

  }
}
