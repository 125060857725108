import { Type } from 'class-transformer';
import {ObjectiveProtocol} from '../../../engine/protocols/objective-protocol';
import {EntityType} from "../../entity-types";


export class SORespOrganization implements ObjectiveProtocol {

  entityType: EntityType = EntityType.organization;
  uid: string;
  name: string;
  identifier: string;
  @Type(() => Date)
  dateCreated: Date;
  exRefPaymentsLink: string = null;
  exRefPayment: string = null;
}
