import {Injectable} from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import {EntityCoreProtocol} from './entity-view/entity-core-protocol';
import {read} from 'xlsx';
import {ReadCompleteProtocol} from "../protocols/read-complete-protocol";
import * as Papa from 'papaparse'
import {ParseResult} from "papaparse";
import {HttpClient} from "@angular/common/http";

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable()
export class ExcelService {
  private vba: any;
  private wb: XLSX.WorkBook;

  constructor(
    private httpClient: HttpClient
  ) {
  }

  public exportCoreAsExcelFile(entityCore: EntityCoreProtocol, entities: {} = null): void {

    if (!entities) {
      entities = entityCore.entitiesForExcel();
    }

    let filename;
    if (entityCore && !entityCore.exportFilename) {
      filename = `${entityCore.title.replace(' ', '_')}_${new Date().toUTCString()}`;
    } else if (entityCore && entityCore.exportFilename) {
      filename = entityCore.exportFilename;
    } else {
      filename = 'app_export_';
    }
    this.exportJsonData(filename, entities)

  }

  exportJsonData(filename, data: {}) {

    const workbook = XLSX.utils.book_new();

    for (const sheet of Object.keys(data)) {
      XLSX.utils.book_append_sheet(
        workbook, XLSX.utils.json_to_sheet(
          data[sheet]
        ), sheet
      );
    }

    const excelBuffer: any = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});

    this.saveAsExcelFile(excelBuffer, filename + new Date().toUTCString(), EXCEL_EXTENSION);

  }

  private saveAsExcelFile(buffer: any, fileName: string, extension: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + extension);
  }

  readFirstSheet(data: any, delegate: ReadCompleteProtocol, options: XLSX.ParsingOptions = null) {

    const reader = new FileReader();
    reader.readAsArrayBuffer(data);
    reader.onload = (obj => {
      if (obj.type == 'csv') {

      } else {
        const wb: XLSX.WorkBook = read(reader.result, {type: 'buffer'});
        const ws: XLSX.WorkSheet = wb.Sheets[wb.SheetNames[0]];
        const result = XLSX.utils.sheet_to_json(ws);
        delegate.readComplete(result);
      }
    });
  }

  readCsv(data: any, delegate: ReadCompleteProtocol) {

    const reader = new FileReader();
    reader.readAsText(data);
    reader.onload = (_ => {
      Papa.parse(data, {
        complete: function(results: ParseResult<any>) {
          const headers = results.data[0]
          const resp = []
          results.data.splice(0, 1)
          for (const line of results.data) {
            const obj = {}
            for (let j = 0; j < headers.length; j++) {
              obj[headers[j]] = line[j];
            }
            resp.push(obj)
          }
          delegate.readComplete(resp);
        }
      })
    });
  }

  exportDataToTemplate(templateUrl: string, core: EntityCoreProtocol, sheets: {} = null, macro: boolean = false) {

    this.httpClient.get(templateUrl, {responseType: 'blob'}).subscribe(resp => {
      if (resp) {
        const reader = new FileReader();
        reader.readAsArrayBuffer(resp);
        reader.onload = (_ => {
          this.wb = read(reader.result, {type: 'buffer', bookVBA: macro});
          this.vba = this.wb.vbaraw;
          this.endFinishExcelExport(core, sheets);
        });
      }
    });

  }

  private endFinishExcelExport(core: EntityCoreProtocol, sheetsToEntities: {} = null) {

    if (!this.wb) {
      this.wb = XLSX.utils.book_new();
    }
    if (sheetsToEntities) {
      for (const sheet of Object.keys(sheetsToEntities)) {
        XLSX.utils.book_append_sheet(
          this.wb, XLSX.utils.json_to_sheet(
            sheetsToEntities[sheet]
          ), sheet
        );
      }
    }

    const excelBuffer: any = XLSX.write(this.wb, {bookType: 'xlsx', type: 'array'});

    let filename;
    if (core && !core.exportFilename) {
      filename = `${core.title.replace(' ', '_')}_${new Date().toUTCString()}`;
    } else if (core && core.exportFilename) {
      filename = core.exportFilename;
    } else {
      filename = 'gas_direct_export_' + new Date().toUTCString();
    }

    this.saveAsExcelFile(excelBuffer, filename, '.xlsx');
  }

}
