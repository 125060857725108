import { SOGetItemImport } from '../item-import/so-get-item-import';
import { SOGetDispatch } from '../dispatch/so-get-dispatch';
import { SOGetOrganization } from '../organization/so-get-organization';


export class SOPostItemImportLine {

  constructor(
    public data: any,
    public itemImport: SOGetItemImport,
    public dispatch: SOGetDispatch,
    public organization: SOGetOrganization,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostItemImportLine(
      'data' in params ? params.data : null,
      'itemImport' in params ? params.itemImport : null,
      'dispatch' in params ? params.dispatch : null,
      'organization' in params ? params.organization : null,
    );
  }
}
