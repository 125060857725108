import {SOPostTemaga} from '../../core/temaga/so-post-temaga';
import {TemagaCore} from './temaga.core';
import {SOGetTemaga} from '../../core/temaga/so-get-temaga';
import {FormField, SelectionOption} from '../../../engine/shared/form-field';
import {postTemagaFormFieldOptions} from './post-temaga-form-fields';
import {AdapterProtocol} from '../../../engine/shared/entity-construction/adapter-protocol';
import {BasePost} from '../../../engine/shared/entity-construction/base-post';
import {ItemCore} from '../bv-item/item.core';
import {SOGetItem} from '../../core/item/so-get-item';
import {TagCore} from '../bv-tag/tag.core';
import {SOGetTag} from '../../core/tag/so-get-tag';
import {OrganizationCore} from '../bv-organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';

export class RAPostTemaga extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postTemagaFormFieldOptions);

  RequestClass = SOPostTemaga

  constructor(
    public entityCore: TemagaCore
  ) {
    super();
    if (postTemagaFormFieldOptions.item) {
      postTemagaFormFieldOptions.item.makeCore = true;
    }
    if (postTemagaFormFieldOptions.tag) {
      postTemagaFormFieldOptions.tag.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier(
        'item',
        SOGetItem,
      ),
      this.getFormValueFromIdentifier(
        'tag',
        SOGetTag,
      ),
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}