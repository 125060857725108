import {EntityType} from "../app/entity-types";
import {
  DisplayVector,
  DisplayVectorBundle,
  DisplayVectorComponent,
  DisplayVectorEntity,
  DisplayVectorKey
} from "./config/display-vector";
import {DisplaySeparator} from "./config/display-separator";


export const baseDisplayOptions = [
  new DisplayVectorBundle(
    EntityType.user,
    [
      new DisplayVector(
        null,
        new DisplayVectorComponent(
          [
            new DisplayVectorKey('surname'),
            new DisplayVectorKey('givenName')
          ],
          DisplaySeparator.comma
        )
      )
    ]
  ),
  new DisplayVectorBundle(
    EntityType.roleGrant,
    [
      new DisplayVector(
        EntityType.user,
        new DisplayVectorComponent(
          [
            new DisplayVectorEntity(EntityType.organization),
            new DisplayVectorEntity(EntityType.role),
          ],
          DisplaySeparator.dash,
          false
        ),
      ),
      new DisplayVector(
        EntityType.organization,
        new DisplayVectorComponent(
          [
            new DisplayVectorEntity(EntityType.user),
            new DisplayVectorEntity(EntityType.role),
          ],
          DisplaySeparator.dash,
          false
        ),
      ),
    ]
  ),
  new DisplayVectorBundle(
    EntityType.organizationInvite,
    [
      new DisplayVector(
        EntityType.user,
        new DisplayVectorComponent(
          [
            new DisplayVectorEntity(EntityType.organization),
            new DisplayVectorEntity(EntityType.role),
            new DisplayVectorKey('status'),
          ],
          DisplaySeparator.dash,
          false
        ),
      ),
      new DisplayVector(
        EntityType.organization,
        new DisplayVectorComponent(
          [
            new DisplayVectorEntity(EntityType.role),
            new DisplayVectorKey('email'),
            new DisplayVectorEntity(EntityType.user),
            new DisplayVectorKey('status'),
          ],
          DisplaySeparator.dash,
          false
        ),
      ),
      new DisplayVector(
        null,
        new DisplayVectorComponent(
          [
            new DisplayVectorEntity(EntityType.organization),
            new DisplayVectorEntity(EntityType.role),
            new DisplayVectorKey('status'),
          ],
          DisplaySeparator.dash,
          false
        ),
      )
    ],
  ),
  new DisplayVectorBundle(
    EntityType.appliedConstraint,
    [
      new DisplayVector(
        EntityType.subscription,
        new DisplayVectorComponent(
          [
            new DisplayVectorEntity(EntityType.constraint),
            new DisplayVectorKey('quantity'),
          ],
          DisplaySeparator.dash,
          false
        ),
      ),
      new DisplayVector(
        EntityType.constraint,
        new DisplayVectorComponent(
          [
            new DisplayVectorEntity(EntityType.subscription),
            new DisplayVectorKey('quantity'),
          ],
          DisplaySeparator.dash,
          false
        ),
      )
    ],
  ),
]
