import { Type } from 'class-transformer';
import { SORespSection } from '../section/so-resp-section';
import {ObjectiveProtocol} from '../../../engine/protocols/objective-protocol';
import {EntityType} from "../../entity-types";


export class SORespQuestion implements ObjectiveProtocol {

  entityType: EntityType = EntityType.question;
  uid: string;
  question: string;
  answer: string;
  number: number;
  @Type(() => SORespSection)
  section: SORespSection;
}