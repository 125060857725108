<mat-toolbar fxLayout="row" fxLayoutAlign="space-between center">
  <div fxLayout="row" fxLayoutAlign="start center">
    <button *ngIf="activeUserService.userLoaded"
            mat-icon-button
            class="main-menu" (click)="parent.sidenav.toggle()" aria-label="Main menu button">
      <mat-icon>menu</mat-icon>
    </button>
<!--    <img class="logo" src="assets/images/logo.svg" alt=" logo" routerLink="/start">-->
    <h6 *ngIf="showAppName">{{appName}}</h6>
<!--    <div class="logo">-->
<!--      <img src="assets/images/gas-direct-small.png">-->
<!--    </div>-->

  </div>
  <div *ngIf="activeUserService.userLoaded" fxLayout="row" fxLayoutAlign="end center" >
    <button *ngIf="notificationRoute"
      mat-icon-button class="notification" routerLink="/{{notificationRoute}}">
      <mat-icon>notifications</mat-icon>
    </button>
    <button *ngIf="helpPath" mat-icon-button class="help" aria-label="Help" routerLink="/{{helpPath}}">
      <mat-icon>help</mat-icon>
    </button>
  </div>
</mat-toolbar>
