<div class="list-container" fxLayout="column" *ngIf="!detailEntry || detailEntry && detailEntry.listStyle == 'normal'">
  <app-sv-header
    *ngIf="showHeader"
    [headerSpec]="headerSpec"
    [parent]="this"
  ></app-sv-header>
  <mat-progress-bar *ngIf="loading" class="progress-bar" mode="indeterminate" color="primary"></mat-progress-bar>
  <div *ngIf="!loading" class="progress-bar"></div>
  <app-sv-entity-view *ngIf="entityViewSpec.entityCore.showQuickAdd && entityViewSpec.entityCore.showAdd && entityViewSpec.subType != ListSpecType.chipList"
                      [entityViewSpec]="entityViewSpec.makeEmbeddedPostSpec()"
                      [parentRouteView]="parentEntityViewComponent.parentRouteView"></app-sv-entity-view>
  <div *ngIf="entityViewSpec.listBody && entityViewSpec.listBody.isEmpty && entityViewSpec.subType != ListSpecType.chipList" class="base-list-empty">
    <h4>{{entityViewSpec.listBody.emptyListMessage}}</h4>
  </div>

  <app-sv-selection-list *ngIf="entityViewSpec.subType == ListSpecType.selection" [parent]="this"
                         class="list-body"></app-sv-selection-list>
  <app-sv-column-list *ngIf="entityViewSpec.subType == ListSpecType.column" [parent]="this"
                      class="list-body"></app-sv-column-list>
  <app-sv-simple-list *ngIf="entityViewSpec.subType == ListSpecType.simple" [parent]="this"
                      class="list-body"></app-sv-simple-list>
  <app-sv-check-list *ngIf="entityViewSpec.subType == ListSpecType.checklist" [parent]="this"
                     class="list-body"></app-sv-check-list>
  <app-sv-chip-list *ngIf="entityViewSpec.subType == ListSpecType.chipList" [parent]="this"
                     class="list-body"></app-sv-chip-list>
  <app-sv-thumb-list *ngIf="entityViewSpec.subType == ListSpecType.thumbList" [parent]="this"
                    class="list-body"></app-sv-thumb-list>
  <app-sv-editable-list *ngIf="entityViewSpec.subType == ListSpecType.editableList" [parent]="this"
                     class="list-body"></app-sv-editable-list>
  <app-sv-card-list *ngIf="entityViewSpec.subType == ListSpecType.cardList" [parent]="this"
                        class="list-body"></app-sv-card-list>

</div>
