import {Component, OnInit} from '@angular/core';
import {FormViewProtocol} from "../../../protocols/form-view-protocol";
import {EntityViewSpecProtocol} from "../../../shared/entity-view/entity-view-spec-protocol";
import {FormField, FormFieldType, getDetailTypeFromFormField, matchValues} from "../../../shared/form-field";
import {HttpClient} from "@angular/common/http";
import {ActiveUserService} from "../../../shared/active-user/active-user.service";
import {UserCore} from "../../../../app/entities/bv-user/user.core";
import {Router} from "@angular/router";
import {TokenCore} from "../../../../app/entities/bv-token/token.core";
import {SOPostToken} from "../../../../app/core/token/so-post-token";
import {SOGetUser} from "../../../../app/core/user/so-get-user";
import {FormViewBase} from "../../../shared/form-view-base";
import {OrganizationCore} from "../../../../app/entities/bv-organization/organization.core";
import {SOPostOrganization} from "../../../../app/core/organization/so-post-organization";
import {SOGetSubscription} from "../../../../app/core/subscription/so-get-subscription";
import {SORespToken} from "../../../../app/core/token/so-resp-token";
import {SOGetOrganization} from "../../../../app/core/organization/so-get-organization";
import {SOGetRole} from "../../../../app/core/role/so-get-role";
import {SORespOrganization} from "../../../../app/core/organization/so-resp-organization";
import {RoleGrantCore} from "../../../../app/entities/bv-role-grant/role-grant.core";
import {SOPostRoleGrant} from "../../../../app/core/role-grant/so-post-role-grant";
import {DetailEntry} from "../../../shared/detail-entry";
import {SvEntityViewComponent} from "../../entity/sv-entity-view/sv-entity-view.component";
import {AttributeViewSpec} from "../../../shared/attribute-view-spec";
import {FormControl, Validators} from "@angular/forms";
import {SOGetRoleGrant} from "../../../../app/core/role-grant/so-get-role-grant";


@Component({
  selector: 'app-sv-registration',
  templateUrl: './sv-registration.component.html',
  styleUrls: ['./sv-registration.component.scss']
})
export class SvRegistrationComponent extends FormViewBase implements FormViewProtocol {

  entityViewSpec: EntityViewSpecProtocol;
  parentEntityViewComponent: SvEntityViewComponent;
  loading: boolean;
  selectedEntity;
  parentEntity;

  userCore: UserCore
  tokenCore: TokenCore;

  errorMessage: string
  formInitialized = false

  username: string
  password: string
  token: SORespToken
  organization: SORespOrganization
  userCreated = false;
  progressMessage = '';

  isPost = true;

  usernameValid = true
  emailValid = true

  constructor(
    public httpClient: HttpClient,
    public activeUserService: ActiveUserService,
    public router: Router
  ) {
    super()
    this.userCore = new UserCore(activeUserService, httpClient)
    this.userCore.postHelper.delegate = this
    const confirmedPasswordField = new FormField(
      'confirmedPassword',
      'Confirm password',
      null,
      FormFieldType.password,
      true,
    )
    confirmedPasswordField.inputType = 'password'
    const passwordIndex = this.userCore.postHelper.formFields.findIndex(obj => obj.identifierString === 'password')
    this.userCore.postHelper.formFields.splice(passwordIndex + 1, 0, confirmedPasswordField)
    this.userCore.postHelper.form.addControl(
      'confirmedPassword',
      new FormControl(
        null,
        [
          Validators.required,
          matchValues('password'),
        ]
      )
    )

    const acceptTerms = new FormField(
      'acceptTerms',
      'Accept ',
      null,
      FormFieldType.checkitem,
      true,
    )
    acceptTerms.linkText = 'terms and conditions'
    acceptTerms.link = 'terms'
    this.userCore.postHelper.formFields.push(acceptTerms)
    this.userCore.postHelper.form.addControl(
      'acceptTerms',
      new FormControl(
        null,
        [
          Validators.required,
          Validators.requiredTrue,
        ]
      )
    )
  }

  clickSubmitFormButton() {
    console.log('Click submit')
    this.username = this.userCore.postHelper.form.value.username
    this.password = this.userCore.postHelper.form.value.password

    const usernameRegex = new RegExp(/^[a-z0-9_-]{3,20}$/)
    this.usernameValid = usernameRegex.test(this.userCore.postHelper.form.value.username);
    const emailRegex = new RegExp('(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])')
    this.emailValid = emailRegex.test(this.userCore.postHelper.form.value.email);

    if (this.emailValid && this.usernameValid) {
      this.errorMessage = null
      this.loading = true
      this.userCore.postHelper.submitDisabled = true
      this.userCore.postHelper.request = null;
      this.userCore.postHelper.submitForm();
    } else {
      if (!this.usernameValid) {
        this.errorMessage = "Username can only contain 0-9, a-z, _, -, and must be between 4-20 characters"
      }
      if (!this.emailValid) {
        this.errorMessage = "Email is not valid"
      }
    }
  }

  deleteComplete() {
  }

  onSelectionChange(detail: DetailEntry, data: any) {
    this.errorMessage = null
  }

  submitComplete(listEntry, showNew) {
    if (!this.tokenCore) {
      this.userCreated = true;
      this.progressMessage = 'Spooling the turboencabulator...'
      console.log('Registration: about to post token')
      this.tokenCore = new TokenCore(this.activeUserService, this.httpClient)
      this.tokenCore.postHelper.delegate = this
      this.tokenCore.postHelper.request = new SOPostToken(
        null,
        new SOGetRoleGrant(
          null,
          null,
          null,
          new SOGetUser(
            null,
            this.username,
            this.password
          )
        )
      )
      this.tokenCore.postHelper.submitForm();
    } else {
      this.progressMessage = 'Checking the integrity of the prefabulated amulite...'
      console.log('Registration: final steps')
      this.activeUserService.loginSuccess(listEntry.entity)
    }
  }

  submitError(message): void {
    console.log('registration error')
    this.userCore.postHelper.submitDisabled = false
    for (const field of this.userCore.postHelper.formFields) {
      field.disabled = false
    }
    this.loading = false
    this.errorMessage = message
  }

  cancel() {
    this.router.navigate([''])
  }

  makeAttributeViewSpec(formField: FormField) {
    const detail = new DetailEntry(
      formField.displayText,
      formField.defaultValue,
      formField.identifierString,
      getDetailTypeFromFormField(formField.type)
    )
    detail.editEnabled = true;
    detail.editableFormField = formField
    return new AttributeViewSpec(
      detail,
      this.userCore.postHelper.form,
      this
    )
  }
}
