import {Component, Input, OnInit} from '@angular/core';
import {SvListComponent} from "../../entity/sv-list/sv-list.component";
import {ListEntry} from "../../../shared/list-entry";

@Component({
  selector: 'app-sv-card-list',
  templateUrl: './sv-card-list.component.html',
  styleUrls: ['./sv-card-list.component.scss']
})
export class SvCardListComponent {

  @Input()
  set parent(value: SvListComponent) {
    this._parent = value
    this.layout = value.activeUserService.isHandset ? 'column' : 'row'
  }

  get parent(): SvListComponent {
    return this._parent
  }

  _parent: SvListComponent
  layout = 'row';

  constructor() {
  }

  getHeading(listEntry: ListEntry) {
    return listEntry && listEntry.lineHeading.length > 0 ? listEntry.lineHeading[0] : null
  }

  clickEntry(listEntry: ListEntry) {

    window.open(listEntry.link, "_blank");

  }
}
