<mat-sidenav-container fxFill autosize>
  <mat-sidenav #innerNav class="inner-nav" mode="side" fxFill>
    <mat-sidenav-container fxFill>
      <div class="main-nav" fxLayout="column" fxLayoutAlign="space-between stretch" fxFill>
        <div fxLayout="column">
          <mat-toolbar fxLayout="row" fxLayoutAlign="start center" fxFlex="noshrink">
            <button mat-icon-button (click)="innerNav.close()"
                    aria-label="Close main nav">
              <mat-icon>close</mat-icon>
            </button>
            Role
          </mat-toolbar>
          <mat-progress-bar *ngIf="innerNavLoading" mode="indeterminate" color="primary"></mat-progress-bar>
          <div *ngIf="!innerNavLoading" class="progress-bar"></div>
          <mat-nav-list class="sidenav-list">
            <mat-list-item *ngFor="let navItem of innerItems" (click)="innerNavItemSelect(navItem)">
              <div fxLayout="column">
                <div class="nav-item-primary-text">
                  {{navItem.displayText}}
                </div>
                <div class="nav-item-sub-text">
                  {{navItem.subText}}
                </div>
              </div>
            </mat-list-item>
          </mat-nav-list>
        </div>
        <div>
          <mat-divider></mat-divider>
          <mat-nav-list>
            <a mat-list-item *ngIf="showSettings" (click)="settingsClick()">
              Settings
            </a>
            <a mat-list-item (click)="logoutClick()">
              Logout
            </a>
          </mat-nav-list>
        </div>
      </div>
    </mat-sidenav-container>
  </mat-sidenav>

  <mat-sidenav-content class="main-nav" fxLayout="column" fxLayoutAlign="space-between" fxFill>
    <div fxLayout="column" class="main-nav-items">
      <mat-toolbar fxLayout="row" fxLayoutAlign="space-between center"
                   fxFlex="noshrink">
        <h2>
          Menu
        </h2>
      </mat-toolbar>

      <mat-nav-list class="sidenav-list">
        <a mat-list-item *ngFor="let navItem of navItems" (click)="navItemSelect(navItem)">
          <!--           routerLink="{{navItem.routerLink}}">-->
          {{getNavItemDisplayText(navItem)}}
        </a>
      </mat-nav-list>
    </div>
    <div class="bottom-buttons">
      <mat-divider></mat-divider>
      <mat-nav-list>
        <a mat-list-item (click)="showInnerNavItems()">
          <mat-icon>arrow_back_ios</mat-icon>
          <mat-icon>face</mat-icon>
          <span class="spacer"></span>
          {{activeUserService.user ? activeUserService.displayName : ''}}
        </a>
      </mat-nav-list>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

