

export class SOPutTag {

  constructor(
    public uid: string,
    public name: string = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutTag(
      'uid' in params ? params.uid : null,
      'name' in params ? params.name : null,
    );
  }
}
