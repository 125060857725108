import {PostListItemCompleteProtocol} from "../../../../engine/protocols/post-list-item-complete-protocol";
import {ListEntry} from "../../../../engine/shared/list-entry";
import {EntityType} from "../../../entity-types";
import {EntityViewSpecProtocol} from "../../../../engine/shared/entity-view/entity-view-spec-protocol";
import {SvEntityViewComponent} from "../../../../engine/views/entity/sv-entity-view/sv-entity-view.component";


export class PostSubscriptionComplete implements PostListItemCompleteProtocol {

  entityType = EntityType.subscription;

  postListItemComplete(listSpec: EntityViewSpecProtocol, listEntry: ListEntry, parent: SvEntityViewComponent) {
    window.location.href = listEntry.entity.exRef
  }
}
