import {FormField, FormFieldType, SelectionOption} from '../../../engine/shared/form-field';
import {EntityType} from "../../entity-types";


const refField = new FormField(
  'ref',
  'Order id',
  null,
  FormFieldType.text,
  true,
)
const nameField = new FormField(
  'name',
  'Name',
  null,
  FormFieldType.text,
  true,
)
const distributorField = new FormField(
  'distributor',
  'Distributor',
  EntityType.distributor,
  FormFieldType.autoCompleteList,
  true,
)
const notesField = new FormField(
  'notes',
  'Notes',
  null,
  FormFieldType.text,
  false,
)


export const postOrderFormFieldOptions: {[key: string]: FormField} = {
  ref: refField,
  name: nameField,
  distributor: distributorField,
  notes: notesField,
};