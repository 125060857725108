import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';

import { SOPostTemdisa } from './so-post-temdisa';
import { SOGetTemdisa } from './so-get-temdisa';
import { SOPutTemdisa } from './so-put-temdisa';
import { SODeleteTemdisa } from './so-delete-temdisa';
import {SORespTemdisa} from './so-resp-temdisa';
import {SOPluralTemdisa} from './so-plural-temdisa';

import {MMEntityService} from '../../../engine/multiverse/mm-entity-service';
import {EntityCoreProtocol} from '../../../engine/shared/entity-view/entity-core-protocol';
import {MMEntityServiceBase} from '../../../engine/multiverse/mm-entity-service-base';
import {HttpHeaders} from "@angular/common/http";


export class EOTemdisaService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'temdisa',
      entityCore
    );
  }

  /** GET Temdisa by id. Will 404 if id not found */
  get(request: SOGetTemdisa): Observable<SOPluralTemdisa> {
    return this.httpClient.get<SOPluralTemdisa>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralTemdisa, resp) }),
      tap(_ => this.log(`fetched Temdisa`)),
      catchError(this.handleError<SOPluralTemdisa>(`get Temdisa`))
    );
  }

  /** POST: add a new Temdisa to the server */
  post(request: SOPostTemdisa): Observable<SORespTemdisa> {
    return this.httpClient.post<SORespTemdisa>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespTemdisa, resp) }),
      tap((response: SORespTemdisa) => this.log(`added Temdisa w/ id=${response.uid}`)),
      catchError(this.handleError<SORespTemdisa>('Temdisa post'))
    );
  }

  /** PUT: update Temdisa  */
  put(request: SOPutTemdisa): Observable<SORespTemdisa> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespTemdisa>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespTemdisa, resp) }),
      tap((response: SORespTemdisa) => this.log(`edited Temdisa w/ uid=${uid}`)),
      catchError(this.handleError<SORespTemdisa>('Temdisa put'))
    );
  }

  /** DELETE: delete the Temdisa from the server */
  delete(request: SODeleteTemdisa): Observable<SORespTemdisa> {
    return this.httpClient.delete<SORespTemdisa>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Temdisa uid=${request.uid}`)),
      catchError(this.handleError<SORespTemdisa>('delete Temdisa'))
    );
  }
}
