import {SOPostSubscription} from '../../core/subscription/so-post-subscription';
import {SubscriptionCore} from './subscription.core';
import {SOGetSubscription} from '../../core/subscription/so-get-subscription';
import {FormField, SelectionOption} from '../../../engine/shared/form-field';
import {postSubscriptionFormFieldOptions} from './post-subscription-form-fields';
import {AdapterProtocol} from '../../../engine/shared/entity-construction/adapter-protocol';
import {BasePost} from '../../../engine/shared/entity-construction/base-post';
import {PriceCore} from '../bv-price/price.core';
import {SOGetPrice} from '../../core/price/so-get-price';
import {OrganizationCore} from '../bv-organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';

export class RAPostSubscription extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postSubscriptionFormFieldOptions);

  RequestClass = SOPostSubscription

  constructor(
    public entityCore: SubscriptionCore
  ) {
    super();
    if (postSubscriptionFormFieldOptions.price) {
      postSubscriptionFormFieldOptions.price.makeCore = true;
    }
    if (postSubscriptionFormFieldOptions.organization) {
      postSubscriptionFormFieldOptions.organization.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier(
        'price',
        SOGetPrice,
      ),
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
      ),
      this.getFormValueFromIdentifier('status'),
      this.getFormValueFromIdentifier('exRef'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}