import {FormField, FormFieldType, SelectionOption} from '../../../engine/shared/form-field';
import {EntityType} from "../../entity-types";


const userField = new FormField(
  'user',
  'User',
  EntityType.user,
  FormFieldType.pushedSelectionList,
  true,
)


export const postCertificateFormFieldOptions: {[key: string]: FormField} = {
  user: userField,
};