import { SOGetDistributor } from '../distributor/so-get-distributor';
import { SOGetOrganization } from '../organization/so-get-organization';


export class SOPostOrder {

  constructor(
    public ref: string,
    public name: string,
    public distributor: SOGetDistributor,
    public organization: SOGetOrganization,
    public notes: string = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostOrder(
      'ref' in params ? params.ref : null,
      'name' in params ? params.name : null,
      'distributor' in params ? params.distributor : null,
      'notes' in params ? params.notes : null,
      'organization' in params ? params.organization : null,
    );
  }
}
