import {SOPostCertificate} from '../../core/certificate/so-post-certificate';
import {CertificateCore} from './certificate.core';
import {SOGetCertificate} from '../../core/certificate/so-get-certificate';
import {FormField, SelectionOption} from '../../../engine/shared/form-field';
import {postCertificateFormFieldOptions} from './post-certificate-form-fields';
import {AdapterProtocol} from '../../../engine/shared/entity-construction/adapter-protocol';
import {BasePost} from '../../../engine/shared/entity-construction/base-post';
import {UserCore} from '../bv-user/user.core';
import {SOGetUser} from '../../core/user/so-get-user';

export class RAPostCertificate extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postCertificateFormFieldOptions);

  RequestClass = SOPostCertificate

  constructor(
    public entityCore: CertificateCore
  ) {
    super();
    if (postCertificateFormFieldOptions.user) {
      postCertificateFormFieldOptions.user.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier(
        'user',
        SOGetUser,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}