import {FormField, FormFieldType, SelectionOption} from '../../../engine/shared/form-field';
import {EntityType} from "../../entity-types";


const isAllocationField = new FormField(
  'isAllocation',
  'Add/Subtract',
  null,
  FormFieldType.boolean,
  true,
  true,
  false
)
const quantityField = new FormField(
  'quantity',
  'Quantity',
  null,
  FormFieldType.text,
  true,
)
const lineField = new FormField(
  'line',
  'Line',
  EntityType.line,
  FormFieldType.pushedSelectionList,
  false,
)
const temlocaField = new FormField(
  'temloca',
  'Item location',
  EntityType.temloca,
  FormFieldType.pushedSelectionList,
  true,
)
const notesField = new FormField(
  'notes',
  'Notes',
  null,
  FormFieldType.text,
  false,
)
const pickupField = new FormField(
  'pickup',
  'Pickup',
  EntityType.pickup,
  FormFieldType.autoCompleteList,
  false,
)


export const postDispatchFormFieldOptions: {[key: string]: FormField} = {
  isAllocation: isAllocationField,
  quantity: quantityField,
  line: lineField,
  temloca: temlocaField,
  notes: notesField,
  pickup: pickupField,
};