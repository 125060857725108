import {SORespInvoiceLine} from '../../core/invoice-line/so-resp-invoice-line';
import {InvoiceLineCore} from './invoice-line.core';
import {ListEntry} from '../../../engine/shared/list-entry';
import {EntityAttributesForDisplay} from '../../../engine/shared/entity-attributes-for-display';
import {DetailEntry, DetailFactory, DetailFactoryProtocol, EditableType} from '../../../engine/shared/detail-entry';
import {ActiveUserService} from '../../../engine/shared/active-user/active-user.service';
import {BaseDetailType} from "../../../engine/shared/base-detail";
import {EntityViewSpecProtocol} from "../../../engine/shared/entity-view/entity-view-spec-protocol";
import {getHeading} from "../../../engine/shared/display-option-utilities";
import {putInvoiceLineFormFieldOptions} from './put-invoice-line-form-fields';
import {SOGetInvoiceLine} from '../../core/invoice-line/so-get-invoice-line';
import {SOGetPrice} from '../../core/price/so-get-price';
import {SOGetInvoice} from '../../core/invoice/so-get-invoice';
import {DisplayVectorStringFactory} from "../../../engine/config/display-vector";
import {displayOptions} from "../../display-options";
import {ListSpecType} from "../../../engine/shared/entity-view/list-spec";
import {SPECTER_SPEC} from "../../specter-spec";
import {getCore} from "../../../engine/shared/entity-view/get-core";

export class InvoiceLineDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespInvoiceLine = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(displayOptions);
    let heading;

    const attributes = []

    const amountDetail = new DetailEntry(
        'Amount',
        entity.amount,
        'amount',
        BaseDetailType.text,
        null,
        EditableType.always,
        putInvoiceLineFormFieldOptions.amount
    )
    attributes.push(amountDetail)

    const quantityDetail = new DetailEntry(
        'Quantity',
        entity.quantity,
        'quantity',
        BaseDetailType.text,
        null,
        EditableType.always,
        putInvoiceLineFormFieldOptions.quantity
    )
    attributes.push(quantityDetail)

    const dateCreatedDetail = new DetailEntry(
        'Date created',
        activeUserService.time.niceDate(entity.dateCreated),
        'dateCreated',
        BaseDetailType.text,
        null,
        EditableType.never,
    )
    attributes.push(dateCreatedDetail)

    const descriptionDetail = new DetailEntry(
        'Description',
        entity.description,
        'description',
        BaseDetailType.text,
        null,
        EditableType.always,
        putInvoiceLineFormFieldOptions.description
    )
    attributes.push(descriptionDetail)


    const priceCore = getCore(SPECTER_SPEC, activeUserService, listEntry.entityCore.httpClient, 'PriceCore');
    priceCore.soGet = SOGetPrice.construct({
    });
    heading = getHeading(entityViewSpec.entityCore, priceCore)
    priceCore.title = heading ? heading : priceCore.title
    priceCore.listTitle = 'Price'

    if (entity.price) {
        displayStringFactory.reconfigure(entity.price, entityViewSpec.entityCore.type)
    }
    priceCore.configureForDisplay(entityViewSpec)

    const priceDetail = new DetailEntry(
      heading ? heading : priceCore.listTitle,
      entity.price ? displayStringFactory.primaryString : null,
      'price',
      BaseDetailType.pushedDetail,
      priceCore,
      EditableType.always,
      putInvoiceLineFormFieldOptions.price
    )
    if (entity.price) {
      priceDetail.cachedListEntry = priceCore.makeListEntry(entityViewSpec, priceCore, entity.price, 0)
    }
    priceDetail.singleFieldSelect = true
    attributes.push(priceDetail);

    const invoiceCore = getCore(SPECTER_SPEC, activeUserService, listEntry.entityCore.httpClient, 'InvoiceCore');
    invoiceCore.soGet = SOGetInvoice.construct({
    });
    heading = getHeading(entityViewSpec.entityCore, invoiceCore)
    invoiceCore.title = heading ? heading : invoiceCore.title
    invoiceCore.listTitle = 'Invoice'

    if (entity.invoice) {
        displayStringFactory.reconfigure(entity.invoice, entityViewSpec.entityCore.type)
    }
    invoiceCore.configureForDisplay(entityViewSpec)

    const invoiceDetail = new DetailEntry(
      heading ? heading : invoiceCore.listTitle,
      entity.invoice ? displayStringFactory.primaryString : null,
      'invoice',
      BaseDetailType.pushedDetail,
      invoiceCore,
      EditableType.always,
      putInvoiceLineFormFieldOptions.invoice
    )
    if (entity.invoice) {
      invoiceDetail.cachedListEntry = invoiceCore.makeListEntry(entityViewSpec, invoiceCore, entity.invoice, 0)
    }
    invoiceDetail.singleFieldSelect = true
    attributes.push(invoiceDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}