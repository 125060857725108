import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';

import { SOPostDistributor } from './so-post-distributor';
import { SOGetDistributor } from './so-get-distributor';
import { SOPutDistributor } from './so-put-distributor';
import { SODeleteDistributor } from './so-delete-distributor';
import {SORespDistributor} from './so-resp-distributor';
import {SOPluralDistributor} from './so-plural-distributor';

import {MMEntityService} from '../../../engine/multiverse/mm-entity-service';
import {EntityCoreProtocol} from '../../../engine/shared/entity-view/entity-core-protocol';
import {MMEntityServiceBase} from '../../../engine/multiverse/mm-entity-service-base';
import {HttpHeaders} from "@angular/common/http";


export class EODistributorService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'distributor',
      entityCore
    );
  }

  /** GET Distributor by id. Will 404 if id not found */
  get(request: SOGetDistributor): Observable<SOPluralDistributor> {
    return this.httpClient.get<SOPluralDistributor>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralDistributor, resp) }),
      tap(_ => this.log(`fetched Distributor`)),
      catchError(this.handleError<SOPluralDistributor>(`get Distributor`))
    );
  }

  /** POST: add a new Distributor to the server */
  post(request: SOPostDistributor): Observable<SORespDistributor> {
    return this.httpClient.post<SORespDistributor>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespDistributor, resp) }),
      tap((response: SORespDistributor) => this.log(`added Distributor w/ id=${response.uid}`)),
      catchError(this.handleError<SORespDistributor>('Distributor post'))
    );
  }

  /** PUT: update Distributor  */
  put(request: SOPutDistributor): Observable<SORespDistributor> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespDistributor>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespDistributor, resp) }),
      tap((response: SORespDistributor) => this.log(`edited Distributor w/ uid=${uid}`)),
      catchError(this.handleError<SORespDistributor>('Distributor put'))
    );
  }

  /** DELETE: delete the Distributor from the server */
  delete(request: SODeleteDistributor): Observable<SORespDistributor> {
    return this.httpClient.delete<SORespDistributor>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Distributor uid=${request.uid}`)),
      catchError(this.handleError<SORespDistributor>('delete Distributor'))
    );
  }
}
