import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';

import { SOPostTemaga } from './so-post-temaga';
import { SOGetTemaga } from './so-get-temaga';
import { SOPutTemaga } from './so-put-temaga';
import { SODeleteTemaga } from './so-delete-temaga';
import {SORespTemaga} from './so-resp-temaga';
import {SOPluralTemaga} from './so-plural-temaga';

import {MMEntityService} from '../../../engine/multiverse/mm-entity-service';
import {EntityCoreProtocol} from '../../../engine/shared/entity-view/entity-core-protocol';
import {MMEntityServiceBase} from '../../../engine/multiverse/mm-entity-service-base';
import {HttpHeaders} from "@angular/common/http";


export class EOTemagaService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'temaga',
      entityCore
    );
  }

  /** GET Temaga by id. Will 404 if id not found */
  get(request: SOGetTemaga): Observable<SOPluralTemaga> {
    return this.httpClient.get<SOPluralTemaga>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralTemaga, resp) }),
      tap(_ => this.log(`fetched Temaga`)),
      catchError(this.handleError<SOPluralTemaga>(`get Temaga`))
    );
  }

  /** POST: add a new Temaga to the server */
  post(request: SOPostTemaga): Observable<SORespTemaga> {
    return this.httpClient.post<SORespTemaga>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespTemaga, resp) }),
      tap((response: SORespTemaga) => this.log(`added Temaga w/ id=${response.uid}`)),
      catchError(this.handleError<SORespTemaga>('Temaga post'))
    );
  }

  /** PUT: update Temaga  */
  put(request: SOPutTemaga): Observable<SORespTemaga> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespTemaga>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespTemaga, resp) }),
      tap((response: SORespTemaga) => this.log(`edited Temaga w/ uid=${uid}`)),
      catchError(this.handleError<SORespTemaga>('Temaga put'))
    );
  }

  /** DELETE: delete the Temaga from the server */
  delete(request: SODeleteTemaga): Observable<SORespTemaga> {
    return this.httpClient.delete<SORespTemaga>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Temaga uid=${request.uid}`)),
      catchError(this.handleError<SORespTemaga>('delete Temaga'))
    );
  }
}
