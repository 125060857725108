import {ENActive} from '../../enums';


export class SOPutItem {

  constructor(
    public uid: string,
    public name: string = null,
    public description: string = null,
    public status: ENActive = null,
    public barCode: string = null,
    public retailPrice: number = null,
    public cost: number = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutItem(
      'uid' in params ? params.uid : null,
      'name' in params ? params.name : null,
      'description' in params ? params.description : null,
      'status' in params ? params.status : null,
      'barCode' in params ? params.barCode : null,
      'retailPrice' in params ? params.retailPrice : null,
      'cost' in params ? params.cost : null,
    );
  }
}
