import {SORespTemfield} from '../../core/temfield/so-resp-temfield';
import {TemfieldCore} from './temfield.core';
import {ListEntry} from '../../../engine/shared/list-entry';
import {EntityAttributesForDisplay} from '../../../engine/shared/entity-attributes-for-display';
import {DetailEntry, DetailFactory, DetailFactoryProtocol, EditableType} from '../../../engine/shared/detail-entry';
import {ActiveUserService} from '../../../engine/shared/active-user/active-user.service';
import {BaseDetailType} from "../../../engine/shared/base-detail";
import {EntityViewSpecProtocol} from "../../../engine/shared/entity-view/entity-view-spec-protocol";
import {getHeading} from "../../../engine/shared/display-option-utilities";
import {putTemfieldFormFieldOptions} from './put-temfield-form-fields';
import {SOGetTemfield} from '../../core/temfield/so-get-temfield';
import {SOGetCustomField} from '../../core/custom-field/so-get-custom-field';
import {SOGetItem} from '../../core/item/so-get-item';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {DisplayVectorStringFactory} from "../../../engine/config/display-vector";
import {displayOptions} from "../../display-options";
import {ListSpecType} from "../../../engine/shared/entity-view/list-spec";
import {SPECTER_SPEC} from "../../specter-spec";
import {getCore} from "../../../engine/shared/entity-view/get-core";

export class TemfieldDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespTemfield = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(displayOptions);
    let heading;

    const attributes = []

    const valueDetail = new DetailEntry(
        'Value',
        entity.value,
        'value',
        BaseDetailType.text,
        null,
        EditableType.always,
        putTemfieldFormFieldOptions.value
    )
    attributes.push(valueDetail)


    const customFieldCore = getCore(SPECTER_SPEC, activeUserService, listEntry.entityCore.httpClient, 'CustomFieldCore');
    customFieldCore.soGet = SOGetCustomField.construct({
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, customFieldCore)
    customFieldCore.title = heading ? heading : customFieldCore.title
    customFieldCore.listTitle = 'Custom field'

    if (entity.customField) {
        displayStringFactory.reconfigure(entity.customField, entityViewSpec.entityCore.type)
    }
    customFieldCore.configureForDisplay(entityViewSpec)

    const customFieldDetail = new DetailEntry(
      heading ? heading : customFieldCore.listTitle,
      entity.customField ? displayStringFactory.primaryString : null,
      'customField',
      BaseDetailType.pushedDetail,
      customFieldCore,
      EditableType.never
    )
    if (entity.customField) {
      customFieldDetail.cachedListEntry = customFieldCore.makeListEntry(entityViewSpec, customFieldCore, entity.customField, 0)
    }
    customFieldDetail.singleFieldSelect = true
    attributes.push(customFieldDetail);

    const itemCore = getCore(SPECTER_SPEC, activeUserService, listEntry.entityCore.httpClient, 'ItemCore');
    itemCore.soGet = SOGetItem.construct({
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, itemCore)
    itemCore.title = heading ? heading : itemCore.title
    itemCore.listTitle = 'Item'

    if (entity.item) {
        displayStringFactory.reconfigure(entity.item, entityViewSpec.entityCore.type)
    }
    itemCore.configureForDisplay(entityViewSpec)

    const itemDetail = new DetailEntry(
      heading ? heading : itemCore.listTitle,
      entity.item ? displayStringFactory.primaryString : null,
      'item',
      BaseDetailType.pushedDetail,
      itemCore,
      EditableType.never
    )
    if (entity.item) {
      itemDetail.cachedListEntry = itemCore.makeListEntry(entityViewSpec, itemCore, entity.item, 0)
    }
    itemDetail.singleFieldSelect = true
    attributes.push(itemDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}