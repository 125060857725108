import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';

import { SOPostCustomField } from './so-post-custom-field';
import { SOGetCustomField } from './so-get-custom-field';
import { SOPutCustomField } from './so-put-custom-field';
import { SODeleteCustomField } from './so-delete-custom-field';
import {SORespCustomField} from './so-resp-custom-field';
import {SOPluralCustomField} from './so-plural-custom-field';

import {MMEntityService} from '../../../engine/multiverse/mm-entity-service';
import {EntityCoreProtocol} from '../../../engine/shared/entity-view/entity-core-protocol';
import {MMEntityServiceBase} from '../../../engine/multiverse/mm-entity-service-base';
import {HttpHeaders} from "@angular/common/http";


export class EOCustomFieldService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'custom_field',
      entityCore
    );
  }

  /** GET CustomField by id. Will 404 if id not found */
  get(request: SOGetCustomField): Observable<SOPluralCustomField> {
    return this.httpClient.get<SOPluralCustomField>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralCustomField, resp) }),
      tap(_ => this.log(`fetched CustomField`)),
      catchError(this.handleError<SOPluralCustomField>(`get CustomField`))
    );
  }

  /** POST: add a new CustomField to the server */
  post(request: SOPostCustomField): Observable<SORespCustomField> {
    return this.httpClient.post<SORespCustomField>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespCustomField, resp) }),
      tap((response: SORespCustomField) => this.log(`added CustomField w/ id=${response.uid}`)),
      catchError(this.handleError<SORespCustomField>('CustomField post'))
    );
  }

  /** PUT: update CustomField  */
  put(request: SOPutCustomField): Observable<SORespCustomField> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespCustomField>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespCustomField, resp) }),
      tap((response: SORespCustomField) => this.log(`edited CustomField w/ uid=${uid}`)),
      catchError(this.handleError<SORespCustomField>('CustomField put'))
    );
  }

  /** DELETE: delete the CustomField from the server */
  delete(request: SODeleteCustomField): Observable<SORespCustomField> {
    return this.httpClient.delete<SORespCustomField>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted CustomField uid=${request.uid}`)),
      catchError(this.handleError<SORespCustomField>('delete CustomField'))
    );
  }
}
