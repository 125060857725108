import {ActiveUserService} from '../../../engine/shared/active-user/active-user.service';
import {HttpClient} from '@angular/common/http';
import {EOSubscriptionService} from '../../core/subscription/eo-subscription';
import {SubscriptionListEntryFactory} from './subscription-list-entry-factory';
import {SubscriptionDetailFactory} from './subscription-detail-factory';
import {SOGetSubscription} from '../../core/subscription/so-get-subscription';
import {RAPutSubscription} from './ra-put-subscription';
import {RAPostSubscription} from './ra-post-subscription';
import {RADeleteSubscription} from './ra-delete-subscription';
import {AbstractEntityCore} from "../../../engine/shared/entity-view/abstract-entity-core";
import {EntityCoreProtocol} from "../../../engine/shared/entity-view/entity-core-protocol";
import {EntityType} from '../../entity-types';
import {SPECTER_SPEC} from "../../specter-spec";

export class SubscriptionCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = EntityType.subscription;
  title = 'Subscription';
  listEntryFactory = new SubscriptionListEntryFactory();
  detailFactory = new SubscriptionDetailFactory();

  entityService: EOSubscriptionService;
  soGetClass = SOGetSubscription;
  soGet: SOGetSubscription;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOSubscriptionService
    );
    this.adapters = [
      new RAPostSubscription(this),
      new RAPutSubscription(this),
      new RADeleteSubscription(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetSubscription();
    this.soGet.queryOptions.limit = this.limit;
  }


}