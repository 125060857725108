import {BannerProtocol} from "../../protocols/banner-protocol";
import {ActiveUserService} from "../../shared/active-user/active-user.service";
import {UserCore} from "../../../app/entities/bv-user/user.core";
import {HttpClient} from "@angular/common/http";
import {SOGetUser} from "../../../app/core/user/so-get-user";
import {getCore} from "../../shared/entity-view/get-core";
import {SPECTER_SPEC} from "../../../app/specter-spec";
import {ListSpecType} from "../../shared/entity-view/list-spec";
import {SOGetPrice} from "../../../app/core/price/so-get-price";
import {SORespPrice} from "../../../app/core/price/so-resp-price";


export class BannerEmailVerificationRequired implements BannerProtocol {

  disabled = true;
  text = 'Thank you for signing up, please verify email';
  showAction = true;
  actionText = 'Resend verification';
  activeUserService: ActiveUserService
  httpClient: HttpClient

  verificationSent = false;

  showLogo = true;
  showLogout = true;

  handleAction() {
    if (this.activeUserService && !this.verificationSent) {

      const userCore = new UserCore(this.activeUserService, this.httpClient)
      const request = new SOGetUser(this.activeUserService.user.uid)
      request.predicate = 'resendEmailVerification'
      userCore.entityService.get(request).subscribe(resp => {
        if (resp && resp.objects.length > 0) {
          this.verificationSent = true;
          this.text = 'Verification sent'
          this.actionText = 'Close'
        }
      })
    } else if (this.activeUserService && this.verificationSent) {
      this.disabled = true;
    }

    if (this.activeUserService && !this.activeUserService.hasPaymentAccount) {

      const pricesCore = getCore(SPECTER_SPEC, this.activeUserService, this.httpClient, 'PriceCore');
      pricesCore.entityService.get(new SOGetPrice()).subscribe( resp => {

        if (resp) {
          for (const price of resp.objects) {
            console.log('getting prices')
            console.log(price)
            window.location.replace(price.exRef)
          }
        }
      })
    }
  }
}
