import {SORespSection} from '../../core/section/so-resp-section';
import {SectionCore} from './section.core';
import {ListEntry} from '../../../engine/shared/list-entry';
import {EntityAttributesForDisplay} from '../../../engine/shared/entity-attributes-for-display';
import {DetailEntry, DetailFactory, DetailFactoryProtocol, EditableType} from '../../../engine/shared/detail-entry';
import {ActiveUserService} from '../../../engine/shared/active-user/active-user.service';
import {BaseDetailType} from "../../../engine/shared/base-detail";
import {EntityViewSpecProtocol} from "../../../engine/shared/entity-view/entity-view-spec-protocol";
import {getHeading} from "../../../engine/shared/display-option-utilities";
import {putSectionFormFieldOptions} from './put-section-form-fields';
import {SOGetSection} from '../../core/section/so-get-section';
import {SOGetQuestion} from '../../core/question/so-get-question';
import {DisplayVectorStringFactory} from "../../../engine/config/display-vector";
import {displayOptions} from "../../display-options";
import {ListSpecType} from "../../../engine/shared/entity-view/list-spec";
import {SPECTER_SPEC} from "../../specter-spec";
import {getCore} from "../../../engine/shared/entity-view/get-core";

export class SectionDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespSection = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(displayOptions);
    let heading;

    const attributes = []

    const titleDetail = new DetailEntry(
        'Title',
        entity.title,
        'title',
        BaseDetailType.text,
        null,
        EditableType.always,
        putSectionFormFieldOptions.title
    )
    attributes.push(titleDetail)

    const numberDetail = new DetailEntry(
        'Number',
        entity.number,
        'number',
        BaseDetailType.text,
        null,
        EditableType.always,
        putSectionFormFieldOptions.number
    )
    attributes.push(numberDetail)


    const questionsCore = getCore(SPECTER_SPEC, activeUserService, listEntry.entityCore.httpClient, 'QuestionCore');
    questionsCore.soGet = SOGetQuestion.construct({
      section: new SOGetSection(entity.uid),
    });
    heading = getHeading(entityViewSpec.entityCore, questionsCore)
    questionsCore.title = heading ? heading : questionsCore.title
    questionsCore.listTitle = 'Questions'

    questionsCore.configureForDisplay(entityViewSpec)

    const questionsDetail = new DetailEntry(
      heading ? heading : questionsCore.listTitle,
      null,
      'questions',
      BaseDetailType.embeddedList,
      questionsCore,
      EditableType.never
    )
    attributes.push(questionsDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}