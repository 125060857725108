import {Component, Input, OnInit} from '@angular/core';
import {Banner} from "../../../shared/banner";

@Component({
  selector: 'app-sv-banner',
  templateUrl: './sv-banner.component.html',
  styleUrls: ['./sv-banner.component.scss']
})
export class SvBannerComponent implements OnInit {

  @Input()
  banner: Banner

  constructor() { }

  ngOnInit(): void {
  }

}
