import {Component} from '@angular/core';
import {EntityViewSpecProtocol} from "../../../shared/entity-view/entity-view-spec-protocol";
import {HttpClient} from "@angular/common/http";
import {ActiveUserService} from "../../../shared/active-user/active-user.service";
import {Router} from "@angular/router";
import {FormField, FormFieldType, getDetailTypeFromFormField} from "../../../shared/form-field";
import {FormViewProtocol} from "../../../protocols/form-view-protocol";
import {TokenCore} from "../../../../app/entities/bv-token/token.core";
import {SOPostToken} from "../../../../app/core/token/so-post-token";
import {SOGetUser} from "../../../../app/core/user/so-get-user";
import {FormViewBase} from "../../../shared/form-view-base";
import {DetailEntry} from "../../../shared/detail-entry";
import {SvEntityViewComponent} from "../../entity/sv-entity-view/sv-entity-view.component";
import {AttributeViewSpec} from "../../../shared/attribute-view-spec";
import {SOGetRoleGrant} from "../../../../app/core/role-grant/so-get-role-grant";

@Component({
  selector: 'app-sv-login',
  templateUrl: './sv-login.component.html',
  styleUrls: ['./sv-login.component.scss']
})
export class SvLoginComponent extends FormViewBase implements FormViewProtocol {

  entityCore: TokenCore
  entityViewSpec: EntityViewSpecProtocol;
  parentEntityViewComponent: SvEntityViewComponent;
  loading: boolean;
  parentEntity;
  selectedEntity;
  formInitialized = false

  isPost = true;
  tokenSuccess = false;

  constructor(
    public httpClient: HttpClient,
    public activeUserService: ActiveUserService,
    public router: Router
  ) {
    super()
    this.entityCore = new TokenCore(activeUserService, httpClient)
    const password = new FormField('password', 'Password', null, FormFieldType.password, true)
    password.inputType = 'password'
    this.entityCore.postHelper.formFieldOptions = [
      new FormField('username', 'Username', null, FormFieldType.text, true),
      password,
    ]
    this.entityCore.postHelper.delegate = this
    this.activeUserService.loginView = this
  }

  clickSubmitFormButton() {
    this.loading = true
    this.entityCore.postHelper.request = new SOPostToken(
      null,
      new SOGetRoleGrant(
        null,
        null,
        null,
        new SOGetUser(
          null,
          this.entityCore.postHelper.form.value.username,
          this.entityCore.postHelper.form.value.password
        )
      )
    )
    this.entityCore.postHelper.submitDisabled = true
    for (const field of this.entityCore.postHelper.formFields) {
      field.disabled = true
    }
    this.entityCore.postHelper.submitForm();
  }

  submitComplete(listEntry, showNew) {
    this.tokenSuccess = true
    this.activeUserService.loginSuccess(listEntry.entity)
  }

  submitError(message): void {
    this.entityCore.postHelper.submitDisabled = false
    for (const field of this.entityCore.postHelper.formFields) {
      field.disabled = false
    }
    this.loading = false
    this.errorMessage = message
  }


  deleteComplete() {
  }

  onSelectionChange(detail: DetailEntry, data: any) {
    this.errorMessage = null
  }

  cancel() {
    this.router.navigate([''])
  }

  makeAttributeViewSpec(formField: FormField) {
    const detail = new DetailEntry(
      formField.identifierString,
      null,
      formField.identifierString,
      getDetailTypeFromFormField(formField.type)
    )
    detail.editEnabled = true;
    detail.editableFormField = formField
    return new AttributeViewSpec(
      detail,
      this.entityCore.postHelper.form,
      this
    )
  }
}
