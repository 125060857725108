<div class="list-container" fxLayout="column">
  <div fxLayout="row" fxLayoutAlign="start center" style="width: 100%">
    <mat-form-field [appearance]="appearance">
      <mat-label>{{heading}}</mat-label>
      <div fxLayout="row" fxLayoutAlign="space-between center">
<!--        <mat-chip-list #chipList aria-label="selection">-->
<!--          <mat-chip-->
<!--            *ngFor="let chip of chips"-->
<!--            [selectable]="selectable"-->
<!--            [removable]="removable"-->
<!--            (removed)="remove(chip)">-->
<!--            {{chip.lineHeading[0]}}-->
<!--            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>-->
<!--          </mat-chip>-->
<!--          <input-->
<!--            placeholder="{{placeholderText}}"-->
<!--            #chipInput-->
<!--            [formControl]="chipCtrl"-->
<!--            [matAutocomplete]="auto"-->
<!--            [matChipInputFor]="chipList"-->
<!--            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"-->
<!--            (keyup)="keyUp($event)"-->
<!--            (matChipInputTokenEnd)="add($event)">-->
<!--        </mat-chip-list>-->
        <input
          placeholder="{{placeholderText}}"
          #chipInput
          [formControl]="chipCtrl"
          [matAutocomplete]="auto"
          matInput
          (keyup)="keyUp($event)">
<!--        <mat-progress-spinner *ngIf="loading" mode="indeterminate" color="primary" diameter="15"></mat-progress-spinner>-->
      </div>
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
        <mat-progress-bar *ngIf="loading" style="height: 3px;" mode="indeterminate" color="primary"></mat-progress-bar>
        <div *ngIf="!loading" style="height: 3px; width: 100%"></div>
        <mat-option *ngIf="showCreateNew" [value]="chipCtrl.value" (onSelectionChange)="createNewTrue()">
          + Create new {{heading.toLowerCase()}} {{chipCtrl.value}}
        </mat-option>
        <div *ngIf="entityViewSpec.listBody && entityViewSpec.listBody.sections">
          <div *ngFor="let section of entityViewSpec.listBody.sections" class="section-header">
            <div *ngFor="let listEntry of section.listEntries" [ngClass]="border(listEntry)">
              <mat-option [value]="listEntry">
                <div style="word-break: break-all; white-space: normal; line-height: 20px">
                {{listEntry.lineHeading}}
                </div>
              </mat-option>
            </div>
          </div>
        </div>
      </mat-autocomplete>
    </mat-form-field>
    <!--<div class="loading-add" fxLayout="row" fxLayoutAlign="start center">

      <mat-icon class="add-button" (click)="startPost(null)">
        add
      </mat-icon>
    </div>-->
  </div>
</div>
