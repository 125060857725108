import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';

import { SOPostConstraint } from './so-post-constraint';
import { SOGetConstraint } from './so-get-constraint';
import { SOPutConstraint } from './so-put-constraint';
import { SODeleteConstraint } from './so-delete-constraint';
import {SORespConstraint} from './so-resp-constraint';
import {SOPluralConstraint} from './so-plural-constraint';

import {MMEntityService} from '../../../engine/multiverse/mm-entity-service';
import {EntityCoreProtocol} from '../../../engine/shared/entity-view/entity-core-protocol';
import {MMEntityServiceBase} from '../../../engine/multiverse/mm-entity-service-base';
import {HttpHeaders} from "@angular/common/http";


export class EOConstraintService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'constraint',
      entityCore
    );
  }

  /** GET Constraint by id. Will 404 if id not found */
  get(request: SOGetConstraint): Observable<SOPluralConstraint> {
    return this.httpClient.get<SOPluralConstraint>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralConstraint, resp) }),
      tap(_ => this.log(`fetched Constraint`)),
      catchError(this.handleError<SOPluralConstraint>(`get Constraint`))
    );
  }

  /** POST: add a new Constraint to the server */
  post(request: SOPostConstraint): Observable<SORespConstraint> {
    return this.httpClient.post<SORespConstraint>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespConstraint, resp) }),
      tap((response: SORespConstraint) => this.log(`added Constraint w/ id=${response.uid}`)),
      catchError(this.handleError<SORespConstraint>('Constraint post'))
    );
  }

  /** PUT: update Constraint  */
  put(request: SOPutConstraint): Observable<SORespConstraint> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespConstraint>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespConstraint, resp) }),
      tap((response: SORespConstraint) => this.log(`edited Constraint w/ uid=${uid}`)),
      catchError(this.handleError<SORespConstraint>('Constraint put'))
    );
  }

  /** DELETE: delete the Constraint from the server */
  delete(request: SODeleteConstraint): Observable<SORespConstraint> {
    return this.httpClient.delete<SORespConstraint>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Constraint uid=${request.uid}`)),
      catchError(this.handleError<SORespConstraint>('delete Constraint'))
    );
  }
}
