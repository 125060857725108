import {FormField, FormFieldType, SelectionOption} from '../../../engine/shared/form-field';
import {EntityType} from "../../entity-types";


const quantityField = new FormField(
  'quantity',
  'Quantity',
  null,
  FormFieldType.text,
  true,
)
const itemField = new FormField(
  'item',
  'Item',
  EntityType.item,
  FormFieldType.autoCompleteList,
  true,
)
const locationField = new FormField(
  'location',
  'Location',
  EntityType.location,
  FormFieldType.autoCompleteList,
  true,
)


export const postTemlocaFormFieldOptions: {[key: string]: FormField} = {
  quantity: quantityField,
  item: itemField,
  location: locationField,
};