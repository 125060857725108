import {FormField, FormFieldType, SelectionOption} from '../../../engine/shared/form-field';
import {EntityType} from "../../entity-types";


const providerField = new FormField(
  'provider',
  'Provider',
  null,
  FormFieldType.text,
  false,
)
const userField = new FormField(
  'user',
  'User',
  EntityType.user,
  FormFieldType.pushedSelectionList,
  false,
)


export const putIdentityFormFieldOptions: {[key: string]: FormField} = {
  provider: providerField,
  user: userField,
};