import {SOPostTemfield} from '../../core/temfield/so-post-temfield';
import {TemfieldCore} from './temfield.core';
import {SOGetTemfield} from '../../core/temfield/so-get-temfield';
import {FormField, SelectionOption} from '../../../engine/shared/form-field';
import {postTemfieldFormFieldOptions} from './post-temfield-form-fields';
import {AdapterProtocol} from '../../../engine/shared/entity-construction/adapter-protocol';
import {BasePost} from '../../../engine/shared/entity-construction/base-post';
import {CustomFieldCore} from '../bv-custom-field/custom-field.core';
import {SOGetCustomField} from '../../core/custom-field/so-get-custom-field';
import {ItemCore} from '../bv-item/item.core';
import {SOGetItem} from '../../core/item/so-get-item';
import {OrganizationCore} from '../bv-organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';

export class RAPostTemfield extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postTemfieldFormFieldOptions);

  RequestClass = SOPostTemfield

  constructor(
    public entityCore: TemfieldCore
  ) {
    super();
    if (postTemfieldFormFieldOptions.customField) {
      postTemfieldFormFieldOptions.customField.makeCore = true;
    }
    if (postTemfieldFormFieldOptions.item) {
      postTemfieldFormFieldOptions.item.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier(
        'customField',
        SOGetCustomField,
      ),
      this.getFormValueFromIdentifier('value'),
      this.getFormValueFromIdentifier(
        'item',
        SOGetItem,
      ),
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}