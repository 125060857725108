import {FormField, FormFieldType, SelectionOption} from '../../../engine/shared/form-field';
import {EntityType} from "../../entity-types";


const nameField = new FormField(
  'name',
  'Name',
  null,
  FormFieldType.text,
  false,
)
const autoApplyField = new FormField(
  'autoApply',
  'Auto apply',
  null,
  FormFieldType.boolean,
  false,
  true,
  false
)
const activeField = new FormField(
  'active',
  'Active',
  null,
  FormFieldType.boolean,
  false,
  true,
  true
)


export const putCustomFieldFormFieldOptions: {[key: string]: FormField} = {
  name: nameField,
  autoApply: autoApplyField,
  active: activeField,
};