import {FormField, FormFieldType, SelectionOption} from '../../../engine/shared/form-field';
import {EntityType} from "../../entity-types";
import {ENSesMessageType} from '../../enums';


const dataField = new FormField(
  'data',
  'Data',
  null,
  FormFieldType.text,
  true,
)
const messageTypeField = new FormField(
  'messageType',
  'Message type',
  null,
  FormFieldType.enumeration,
  true,
)
messageTypeField.enumeration = ENSesMessageType


export const postSesMessageFormFieldOptions: {[key: string]: FormField} = {
  data: dataField,
  messageType: messageTypeField,
};