import {SvNavigatorComponent} from "../../../structure/sv-navigator/sv-navigator.component";
import {AttributesSpec} from "../../../../shared/entity-view/detail-spec";
import {UserCore} from "../../../../../app/entities/bv-user/user.core";
import {SOGetUser} from "../../../../../app/core/user/so-get-user";
import {EntityViewSpecProtocol} from "../../../../shared/entity-view/entity-view-spec-protocol";
import {ListEntry} from "../../../../shared/list-entry";
import {EntityAttributesForDisplay} from "../../../../shared/entity-attributes-for-display";
import {DetailEntry} from "../../../../shared/detail-entry";
import {BaseDetailType} from "../../../../shared/base-detail";
import {Button, ButtonPressProtocol} from "../../../../shared/header-spec";
import {PasswordProcessor} from "./password-processor";
import {SvEntityAttributesViewComponent} from "../../../entity/sv-entity-attributes-view/sv-entity-attributes-view.component";

export class UserProcessor extends UserCore implements ButtonPressProtocol {

  navigator: SvNavigatorComponent
  entityViewSpec: EntityViewSpecProtocol

  prepareStartingEntityViewSpec(avRoute: SvNavigatorComponent) {
    avRoute.loading = true;
    this.entityService.get(new SOGetUser(this.activeUserService.user.uid)).subscribe(resp => {
      if (resp) {
        this.selectedEntity = resp.objects[0];
        console.log(this.selectedEntity)
        avRoute.setInitialEntityViewSpec(this.startingEntityViewSpec(avRoute))
      }
    })
  }

  startingEntityViewSpec(avRoute: SvNavigatorComponent) {

    return new AttributesSpec(
      this.makeListEntry(null, this, this.selectedEntity),
      null,
      null,
      avRoute
    )
  }

  makeEntityAttributesForDisplay(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry): EntityAttributesForDisplay {
    this.entityViewSpec = entityViewSpec;
    this.navigator = entityViewSpec.navigator;
    const attributeBody = this.detailFactory.makeDetail(entityViewSpec, entityViewSpec.listEntry, this.activeUserService);
    const passwordDetail = new DetailEntry(
      'Password',
      null,
      'password',
      BaseDetailType.button
    )
    passwordDetail.button = new Button('password', 'Change password')
    passwordDetail.button.buttonPressHandler = this;
    attributeBody.attributes.splice(1, 0, passwordDetail)
    return attributeBody
  }

  buttonPress(button: Button): void {
    if (button.identifier === 'password') {
      const passwordCore = new PasswordProcessor(this.activeUserService, this.httpClient)
      const spec = new AttributesSpec(
        passwordCore.makeListEntry(this.entityViewSpec, passwordCore, this.entityViewSpec.listEntry.entity),
        this.entityViewSpec,
        null,
        this.navigator
      )
      this.navigator.addEntityViewSpec(spec)
    }
  }

  finalOverrides(entityViewSpec: EntityViewSpecProtocol, parentView: SvEntityAttributesViewComponent) {
    parentView.headerSpec.showDelete = false;
  }
}
