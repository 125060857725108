import {FormField, FormFieldType, SelectionOption} from '../../../engine/shared/form-field';
import {EntityType} from "../../entity-types";


const mediaField = new FormField(
  'media',
  'Media',
  EntityType.media,
  FormFieldType.pushedSelectionList,
  true,
)
const itemField = new FormField(
  'item',
  'Item',
  EntityType.item,
  FormFieldType.autoCompleteList,
  true,
)


export const postAttachmentFormFieldOptions: {[key: string]: FormField} = {
  media: mediaField,
  item: itemField,
};