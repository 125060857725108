import {Component, Input, OnInit} from '@angular/core';
import {HeaderProtocol, MenuItem} from '../../../protocols/header-protocol';
import {ListEntry} from '../../../shared/list-entry';
import {SvEntityViewComponent} from '../sv-entity-view/sv-entity-view.component';
import {AddButton, DetailEntry} from '../../../shared/detail-entry';
import {PostSpec} from '../../../shared/entity-view/post-spec';
import {EntityViewSpecProtocol} from '../../../shared/entity-view/entity-view-spec-protocol';
import {ListSpec, ListSpecType} from '../../../shared/entity-view/list-spec';
import {EntityViewSpecType} from '../../../shared/entity-view/entity-view-spec-type';
import {FormField, FormFieldType, getDetailTypeFromFormField} from "../../../shared/form-field";
import {FormViewProtocol} from "../../../protocols/form-view-protocol";
import {FormViewBase} from "../../../shared/form-view-base";
import {HeaderSpec} from "../../../shared/header-spec";
import {AttributeViewSpec} from "../../../shared/attribute-view-spec";
import {SPECTER_SPEC} from "../../../../app/specter-spec";
import {MatSnackBar} from "@angular/material/snack-bar";
import {PostViewSpecType} from "../../../shared/post-view-spec-type";
import {FileUploadService} from "../../../shared/file-upload/file-upload.service";
import {getHeading} from "../../../shared/display-option-utilities";


@Component({
  selector: 'app-sv-post',
  templateUrl: './sv-post.component.html',
  styleUrls: ['./sv-post.component.css']
})
export class SvPostComponent extends FormViewBase implements OnInit, HeaderProtocol, FormViewProtocol {

  detailBody;
  headerSpec = new HeaderSpec();
  saveEnabled = false;
  selectedEntity = null;
  parentEntity;

  isPost = true;

  get loading() {
    return this.entityViewSpec.entityCore.postHelper.requestInProgress
  };

  attributeViewSpecs: AttributeViewSpec[] = []

  viewInitialized = false;

  @Input()
  parentEntityViewComponent: SvEntityViewComponent;

  @Input()
  set entityViewSpec(entityViewSpec: PostSpec) {
    if (!this.viewInitialized && entityViewSpec) {
      this.initialize(entityViewSpec);
    }
  }

  private initialize(entityViewSpec: PostSpec) {
    this.viewInitialized = true
    this._entityViewSpec = entityViewSpec;
    this.headerSpec = new HeaderSpec(`Create new ${entityViewSpec.entityCore.title.toLowerCase()}`, true);
    this.headerSpec.showPostButton = false;
    this.headerSpec.showDelete = false;
    entityViewSpec.entityCore.postHelper.delegate = this;
    this.parentEntity = entityViewSpec.entityCore.postHelper.parentEntity;
    this.attributeViewSpecs = [];

    for (const field of entityViewSpec.entityCore.postHelper.formFields) {
      if (entityViewSpec.subType === PostViewSpecType.media && field.type === FormFieldType.file) {
        this.entityViewSpec.entityCore.postHelper.fileUploadService = this.fileUploadService;
        this.attributeViewSpecs.push(
          this.makeAttributeViewSpecFromFormField(field)
        )
      } else if (entityViewSpec.subType !== PostViewSpecType.media) {
        this.attributeViewSpecs.push(
          this.makeAttributeViewSpecFromFormField(field)
        )
      }
    }
    for (const action of entityViewSpec.entityCore.postHelper.actions) {
      action.init();
    }
  }

  get entityViewSpec(): PostSpec {
    return this._entityViewSpec;
  }
  _entityViewSpec: PostSpec;

  get formInitialized() {
    return this.entityViewSpec.formInitialized
  }

  set formInitialized(value: boolean) {
    this.entityViewSpec.formInitialized = value
  }

  get navigator() {
    return this.parentEntityViewComponent.parentRouteView
  }

  get posting() {
    return (
      this.entityViewSpec.parentEntityViewSpec.detailEntry
      && this.entityViewSpec.parentEntityViewSpec.detailEntry.addButton === AddButton.post
    ) || (!this.entityViewSpec.parentEntityViewSpec.detailEntry && !this.entityViewSpec.parentEntityViewSpec.entityCore.newIsSelectionFromList);
  }

  get selectingFromList() {

    if (this.entityViewSpec.parentEntityViewSpec.detailEntry
      && this.entityViewSpec.parentEntityViewSpec.detailEntry.addButton === AddButton.selectFromList) {
      return true;
    } else {
      return this.entityViewSpec.parentEntityViewSpec.type === EntityViewSpecType.list
        && !this.entityViewSpec.parentEntityViewSpec.detailEntry
        && this.entityViewSpec.parentEntityViewSpec.entityCore.newIsSelectionFromList;
    }
  }

  get selectFromCore() {
    if (this.entityViewSpec.parentEntityViewSpec.entityCore.newIsSelectionFromList) {
      return this.entityViewSpec.parentEntityViewSpec.entityCore.newSelectionCore;
    } else {
      return this.entityViewSpec.parentEntityViewSpec.detailEntry.selectFromCore;
    }
  }

  get selectFromListSpec() {
    return new ListSpec(
      this.selectFromCore,
      this.entityViewSpec.parentEntityViewSpec,
      this.entityViewSpec,
      this.parentEntityViewComponent.parentRouteView
    );
  }

  constructor(
    public snackBar: MatSnackBar,
    private fileUploadService: FileUploadService
  ) {
    super()
  }

  submitSearchForm(query) {
  }

  searchFieldKeyup() {
  }


  clickSearchIcon(data) {

  }

  clickDeleteButton() {

  }

  clickSaveButton() {
    this.clickSubmitFormButton();
  }

  removeChild(listEntry: ListEntry): void {
  }

  ngOnInit(): void {
  }


  clickHeading(key) {
  }

  closeDetailView(appendEntry: ListEntry = null) {
    this.entityViewSpec.entityCore.postHelper.unregisterDelegate();
    this.parentEntityViewComponent.closeDetailView();
  }

  menuClick(item: MenuItem): void {
  }

  startPost(listSpec: ListSpec) {
  }

  selectListEntry(listEntry: ListEntry): void {
    console.log('selected entry in pos');
    console.log(this.entityViewSpec);
    this.errorMessage = null;
    this.saveEnabled = true;
    // this.entityViewSpec.entityCore.postHelper.onSelectionChange(this.selectFromCore.type, listEntry);

    if (!this.entityViewSpec.entityCore.requiresConfirm) {
      this.entityViewSpec.entityCore.postHelper.submitForm();
    }
  }

  clickSubmitFormButton() {
    console.log('clicking submit form button')
    // this.loading = true;
    this.entityViewSpec.entityCore.postHelper.submitForm();
  }

  submitError(message: string) {
    // this.loading = false;
    this.snackBar.open(message)
  }

  onSelectionChange(detail: DetailEntry, data: any) {
    this.errorMessage = null;
    this.entityViewSpec.entityCore.postHelper.onSelectionChange(detail.editableFormField, data);
  }

  submitComplete(listEntry: ListEntry) {
    // this.loading = false;
    console.log('submit complete');
    console.log(this.entityViewSpec.parentEntityViewSpec);
    listEntry.onlyShowOne = this.entityViewSpec.parentEntityViewSpec.entityCore.onlyShowOne;
    const chainPostEntity = SPECTER_SPEC.chainPost[listEntry.entityCore.type.valueOf()]

    listEntry.showAfterPost = true;

    if (!chainPostEntity) {
      this.parentEntityViewComponent.postComplete(listEntry, this.entityViewSpec);
    } else {
      this.parentEntityViewComponent.postComplete(listEntry, this.entityViewSpec);
    }

    if (this.entityViewSpec.parentEntityViewSpec.entityCore.showQuickAdd) {
      this.initialize(this.entityViewSpec);
    }

  }

  presentUserWithMessage(message: string, duration: number) {
    this.snackBar.open(message);
  }

  cancel() {
    this.closeDetailView();
  }

  makeAttributeViewSpecFromFormField(formField: FormField) {
    const detail = new DetailEntry(
      formField.identifierString,
      null,
      formField.identifierString,
      getDetailTypeFromFormField(formField.type)
    )
    detail.editEnabled = true;
    detail.editableFormField = formField
    const resp = new AttributeViewSpec(
      detail,
      this.entityViewSpec.entityCore.postHelper.form,
      this
    )
    resp.compact = this.entityViewSpec.narrow
    resp.chipListAdd = this.entityViewSpec.chipListAdd
    return resp
  }

  exportToExcel() {
  }

  showHistory(entityViewSpec: EntityViewSpecProtocol) {
  }

  startTask(param, identifier) {

  }

  taskComplete(core, parentEntityViewSpec, listEntry) {
  }

  showMessage(message: string) {

  }

  deleteComplete() {
  }

  clickLaunchButton(launchButtonOne: any): void {
  }

  returnPress() {

  }

  clickPushDetail(detailEntry: DetailEntry) {
    throw new Error("Method not implemented.");
  }
  clickPushList(detailEntry: DetailEntry) {
    console.log(detailEntry)

    if (detailEntry.editableFormField.type == FormFieldType.pushedSelectionList) {
      detailEntry.entityCore = SPECTER_SPEC.makeCore(
        this.entityViewSpec.entityCore.activeUserService,
        this.entityViewSpec.entityCore.httpClient,
        detailEntry.editableFormField.entityType
        )
      detailEntry.entityCore.newIsSelectionFromList = true
      this.navigator.pushedSelectionListFormField = detailEntry.editableFormField
      detailEntry.entityCore.title = `Select ${detailEntry.editableFormField.displayText}`
      detailEntry.entityCore.listTitle = detailEntry.entityCore.title
      detailEntry.entityCore.configureForDisplay(this.entityViewSpec.parentEntityViewSpec)

    } else {
      throw new Error("Method not implemented.");
    }

    this.parentEntityViewComponent.clickPushList(
      detailEntry, this.entityViewSpec
    )
  }

  showDetails() {

  };

}
