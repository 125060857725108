import {SORespUser} from '../../core/user/so-resp-user';
import {UserCore} from './user.core';
import {ListEntry} from '../../../engine/shared/list-entry';
import {EntityAttributesForDisplay} from '../../../engine/shared/entity-attributes-for-display';
import {DetailEntry, DetailFactory, DetailFactoryProtocol, EditableType} from '../../../engine/shared/detail-entry';
import {ActiveUserService} from '../../../engine/shared/active-user/active-user.service';
import {BaseDetailType} from "../../../engine/shared/base-detail";
import {EntityViewSpecProtocol} from "../../../engine/shared/entity-view/entity-view-spec-protocol";
import {getHeading} from "../../../engine/shared/display-option-utilities";
import {putUserFormFieldOptions} from './put-user-form-fields';
import {SOGetUser} from '../../core/user/so-get-user';
import {SOGetIdentity} from '../../core/identity/so-get-identity';
import {SOGetRoleGrant} from '../../core/role-grant/so-get-role-grant';
import {SOGetCertificate} from '../../core/certificate/so-get-certificate';
import {SOGetOrganizationInvite} from '../../core/organization-invite/so-get-organization-invite';
import {DisplayVectorStringFactory} from "../../../engine/config/display-vector";
import {displayOptions} from "../../display-options";
import {ListSpecType} from "../../../engine/shared/entity-view/list-spec";
import {SPECTER_SPEC} from "../../specter-spec";
import {getCore} from "../../../engine/shared/entity-view/get-core";

export class UserDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespUser = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(displayOptions);
    let heading;

    const attributes = []

    const usernameDetail = new DetailEntry(
        'Username',
        entity.username,
        'username',
        BaseDetailType.text,
        null,
        EditableType.never,
    )
    attributes.push(usernameDetail)

    const givenNameDetail = new DetailEntry(
        'Given name',
        entity.givenName,
        'givenName',
        BaseDetailType.text,
        null,
        EditableType.always,
        putUserFormFieldOptions.givenName
    )
    attributes.push(givenNameDetail)

    const surnameDetail = new DetailEntry(
        'Surname',
        entity.surname,
        'surname',
        BaseDetailType.text,
        null,
        EditableType.always,
        putUserFormFieldOptions.surname
    )
    attributes.push(surnameDetail)

    const emailDetail = new DetailEntry(
        'Email',
        entity.email,
        'email',
        BaseDetailType.text,
        null,
        EditableType.always,
        putUserFormFieldOptions.email
    )
    attributes.push(emailDetail)

    const emailPendingDetail = new DetailEntry(
        'Email pending',
        entity.emailPending,
        'emailPending',
        BaseDetailType.text,
        null,
        EditableType.never,
    )
    attributes.push(emailPendingDetail)

    const emailVerifiedDetail = new DetailEntry(
        'Email verified',
        entity.emailVerified,
        'emailVerified',
        BaseDetailType.toggle,
        null,
        EditableType.never,
    )
    attributes.push(emailVerifiedDetail)

    const dateCreatedDetail = new DetailEntry(
        'Date created',
        activeUserService.time.niceDate(entity.dateCreated),
        'dateCreated',
        BaseDetailType.text,
        null,
        EditableType.never,
    )
    attributes.push(dateCreatedDetail)


    const roleGrantsCore = getCore(SPECTER_SPEC, activeUserService, listEntry.entityCore.httpClient, 'RoleGrantCore');
    roleGrantsCore.soGet = SOGetRoleGrant.construct({
      user: new SOGetUser(entity.uid),
    });
    heading = getHeading(entityViewSpec.entityCore, roleGrantsCore)
    roleGrantsCore.title = heading ? heading : roleGrantsCore.title
    roleGrantsCore.listTitle = 'Role grants'

    roleGrantsCore.configureForDisplay(entityViewSpec)

    const roleGrantsDetail = new DetailEntry(
      heading ? heading : roleGrantsCore.listTitle,
      null,
      'roleGrants',
      BaseDetailType.embeddedList,
      roleGrantsCore,
      EditableType.never
    )
    attributes.push(roleGrantsDetail);

    const invitesCore = getCore(SPECTER_SPEC, activeUserService, listEntry.entityCore.httpClient, 'OrganizationInviteCore');
    invitesCore.soGet = SOGetOrganizationInvite.construct({
      user: new SOGetUser(entity.uid),
    });
    heading = getHeading(entityViewSpec.entityCore, invitesCore)
    invitesCore.title = heading ? heading : invitesCore.title
    invitesCore.listTitle = 'Invites'

    invitesCore.configureForDisplay(entityViewSpec)

    const invitesDetail = new DetailEntry(
      heading ? heading : invitesCore.listTitle,
      null,
      'invites',
      BaseDetailType.embeddedList,
      invitesCore,
      EditableType.never
    )
    attributes.push(invitesDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}