import {formFieldTypeToViewTypeMap, detailTypeToViewTypeMap} from "./attribute-view-map";
import {EntityType} from "../../app/entity-types";
import {PostListItemCompleteProtocol} from "../protocols/post-list-item-complete-protocol";
import {PutEntityCompleteProtocol} from "../protocols/put-entity-complete-protocol";
import {PrepareRequestProtocol} from "../protocols/prepare-request-protocol";
import {FileUploadService} from "./file-upload/file-upload.service";
import {BlobStorageOptions} from "./blob-storage-options";
import {entityCoreNameMap} from "../../app/entity-core-map";
import {Button} from "./header-spec";
import {SPECTER_SPEC} from "../../app/specter-spec";
import {getCore} from "./entity-view/get-core";
import {ActiveUserService} from "./active-user/active-user.service";
import {HttpClient} from "@angular/common/http";
import {Banner} from "./banner";
import {BannerEmailVerificationRequired} from "../views/pre-built/banner-email-verification-required";

export class SpecterAppSpec {

  unprotectedPaths = ['register', 'login']
  customEntityProcessors = {}

  postPrepareRequestProcessors: PrepareRequestProtocol[] = []
  postListItemCompleteProcessors: PostListItemCompleteProtocol[] = []
  putEntityCompleteProcessors: PutEntityCompleteProtocol[];

  formFieldTypeToViewTypeMap = formFieldTypeToViewTypeMap
  detailTypeToViewTypeMap = detailTypeToViewTypeMap

  chainPost = {}
  mediaEntityTypes: EntityType[] = [];

  fileUploadService: FileUploadService

  entityCoreNameMap = entityCoreNameMap;
  help = false;
  helpPath = 'section';

  showSettings = true;
  settingsPath = 'user';

  notifications = false;
  notificationPath: string;
  topBanner = new BannerEmailVerificationRequired();
  summary: string = 'Coolest company Eva making coolest product eva';
  company: string = 'Coolest company Eva LLC';

  softwareVersion: string = '1';
  specterVersion: string = '1';
  clientId: string

  get specterId() {
    return `${this.specterVersion}|${this.softwareVersion}|${this.clientId}`
  }

  constructor(
    public home : string,
    public title: string,
    public entityCoreMap
  ) {
    this.loadClientId()
  }

  loadClientId() {
    this.clientId = localStorage.getItem('clientId')
    console.log(`[INFO]: loading client id ${this.clientId}`)
    if (!this.clientId) {
      console.log(`[INFO]: no client id - refreshing`)
      this.clientId = new Date().getTime().toString()
      localStorage.setItem('clientId', this.clientId)
    }
  }

  makeCore(activeUserService: ActiveUserService, httpClient: HttpClient, entityType: EntityType) {
    const CoreClass = this.entityCoreMap[entityType]
    return getCore(SPECTER_SPEC, activeUserService, httpClient, CoreClass.name);
  }
}
