import { Type } from 'class-transformer';
import { SORespPrice } from '../price/so-resp-price';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ENSubscriptionStatus} from '../../enums';
import {ObjectiveProtocol} from '../../../engine/protocols/objective-protocol';
import {EntityType} from "../../entity-types";


export class SORespSubscription implements ObjectiveProtocol {

  entityType: EntityType = EntityType.subscription;
  uid: string;
  @Type(() => SORespPrice)
  price: SORespPrice = null;
  @Type(() => Date)
  dateCreated: Date;
  @Type(() => Date)
  dateUpdated: Date;
  @Type(() => Date)
  dateExpires: Date = null;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;
  exRef: string = null;
  status: ENSubscriptionStatus;
}