import {ActiveUserService} from '../../../engine/shared/active-user/active-user.service';
import {HttpClient} from '@angular/common/http';
import {EOPickupService} from '../../core/pickup/eo-pickup';
import {PickupListEntryFactory} from './pickup-list-entry-factory';
import {PickupDetailFactory} from './pickup-detail-factory';
import {SOGetPickup} from '../../core/pickup/so-get-pickup';
import {RAPutPickup} from './ra-put-pickup';
import {RAPostPickup} from './ra-post-pickup';
import {RADeletePickup} from './ra-delete-pickup';
import {AbstractEntityCore} from "../../../engine/shared/entity-view/abstract-entity-core";
import {EntityCoreProtocol} from "../../../engine/shared/entity-view/entity-core-protocol";
import {EntityType} from '../../entity-types';
import {SPECTER_SPEC} from "../../specter-spec";
import {FormControl, FormGroup} from "@angular/forms";
import {SOGetOrganization} from '../../core/organization/so-get-organization'

export class PickupCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = EntityType.pickup;
  title = 'Pickup';
  listEntryFactory = new PickupListEntryFactory();
  detailFactory = new PickupDetailFactory();

  entityService: EOPickupService;
  soGetClass = SOGetPickup;
  soGet: SOGetPickup;

  searchEnabled = true;

  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOPickupService
    );
    this.adapters = [
      new RAPostPickup(this),
      new RAPutPickup(this),
      new RADeletePickup(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetPickup();
    this.soGet.organization = new SOGetOrganization(
      this.activeUserService.getScope('organization').uid
    );
    this.soGet.queryOptions.limit = this.limit;
    this.soGet.queryOptions.sortKey = 'dateCreated';
    this.soGet.queryOptions.descending = true;
  }


}