import {TemlocaCore} from "../../../entities/bv-temloca/temloca.core";
import {ListEntry} from "../../../../engine/shared/list-entry";
import {DeleteCompleteProtocol} from "../../../../engine/protocols/delete-complete-protocol";
import {EntityViewSpecProtocol} from "../../../../engine/shared/entity-view/entity-view-spec-protocol";
import {SODeleteTemloca} from "../../../core/temloca/so-delete-temloca";

export class TemlocaProcessor extends TemlocaCore {

  startRemoveListEntry(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, view: DeleteCompleteProtocol) {
    this.entityService.delete(new SODeleteTemloca(listEntry.entity.uid)).subscribe(resp => {
      if (resp) {
        const quantityAttribute = entityViewSpec.parentEntityViewSpec.childrenEntityAttributeViewSpecs.find(
          obj => obj.detailEntry.identifier == 'quantity'
        )
        quantityAttribute.detailEntry.value = quantityAttribute.detailEntry.value - listEntry.entity.quantity
        entityViewSpec.parentEntityViewSpec.listEntry.entity.quantity = quantityAttribute.detailEntry.value
        view.deleteComplete();
      }
    })
  }
}
