import {FormField, FormFieldType, SelectionOption} from '../../../engine/shared/form-field';
import {EntityType} from "../../entity-types";


const dataField = new FormField(
  'data',
  'Data',
  null,
  FormFieldType.text,
  true,
)
const itemImportField = new FormField(
  'itemImport',
  'Item import',
  EntityType.itemImport,
  FormFieldType.pushedSelectionList,
  true,
)
const dispatchField = new FormField(
  'dispatch',
  'Dispatch',
  EntityType.dispatch,
  FormFieldType.pushedSelectionList,
  true,
)


export const postItemImportLineFormFieldOptions: {[key: string]: FormField} = {
  data: dataField,
  itemImport: itemImportField,
  dispatch: dispatchField,
};