import {DispatchCore} from "../../../entities/bv-dispatch/dispatch.core";
import {EntityViewSpecProtocol} from "../../../../engine/shared/entity-view/entity-view-spec-protocol";
import {EntityType} from "../../../entity-types";
import {Button, ButtonPressProtocol} from "../../../../engine/shared/header-spec";
import {SvNavigatorComponent} from "../../../../engine/views/structure/sv-navigator/sv-navigator.component";
import {ListSpec, ListSpecType} from "../../../../engine/shared/entity-view/list-spec";
import {DispatchMultiSelect} from "./dispatch-multi-select";
import {SORespPickup} from "../../../core/pickup/so-resp-pickup";
import {MenuItem, MenuItemType} from "../../../../engine/protocols/header-protocol";
import {SORespDispatch} from "../../../core/dispatch/so-resp-dispatch";
import {EntityAttributesForDisplay} from "../../../../engine/shared/entity-attributes-for-display";
import {ListEntry} from "../../../../engine/shared/list-entry";
import {EntityCoreProtocol} from "../../../../engine/shared/entity-view/entity-core-protocol";
import {ENProgress} from "../../../enums";
import {FormFieldType, SelectionOption} from "../../../../engine/shared/form-field";
import {Validators} from "@angular/forms";
import {SOPutDispatch} from "../../../core/dispatch/so-put-dispatch";
import {SOGetPickup} from "../../../core/pickup/so-get-pickup";
import {DeleteCompleteProtocol} from "../../../../engine/protocols/delete-complete-protocol";


export class DispatchProcessor extends DispatchCore implements ButtonPressProtocol {

  parentPickup: SORespPickup;
  navigator: SvNavigatorComponent;

  locationSort = new MenuItem('locationSort', 'Location sort', this, MenuItemType.multiselect, true)
  orderSort = new MenuItem('orderSort', 'Order sort', this, MenuItemType.multiselect, false)

  initListHeaderSpec() {
    super.initListHeaderSpec();
  }

  configureForDisplay(entityViewSpec: ListSpec) {
    super.configureForDisplay(entityViewSpec)

    if (entityViewSpec.entityCore.type == EntityType.pickup) {

      this.listSpecType = ListSpecType.checklist
      this.checklistDisabled = true;
      if (entityViewSpec.listEntry.entity.status == ENProgress.active) {
        this.checklistDisabled = false;
      }

      if ([ENProgress.active, ENProgress.pending].includes(entityViewSpec.listEntry.entity.status)) {
        this.listItemDeleteDisabled = false;
      }


      this.listHeaderSpec.menuItems = [
        this.locationSort,
        this.orderSort
      ]

      this.navigator = entityViewSpec.navigator;
      this.parentPickup = entityViewSpec.listEntry.entity;

      this.setSelectDispatchButtonIfNecessary(entityViewSpec.listEntry.entity);

      this.listHeaderSpec.showFilter = true
      this.listHeaderSpec.filterOpenInitial = true

    } else if (entityViewSpec.entityCore.type == EntityType.temloca) {

      this.listHeaderSpec.heading = "Allocations and dispatches"
      this.listHeaderSpec.showPostButton = false

      super.removePostFields(['line', 'pickup'])

      // const selectionField = new FormField('add', 'Add or subtract', null, FormFieldType.selection, true, true, 'add')
      const selectionField = this.postHelper.formFieldOptions.find(obj => obj.identifierString == 'isAllocation');
      selectionField.displayText = 'Add or subtract';
      selectionField.type = FormFieldType.selection;
      selectionField.hasDefault = true;
      selectionField.defaultValue = true;
      selectionField.selectionOptions = [
        new SelectionOption('Add', true),
        new SelectionOption('Subtract', false),
      ]

      const quantityField = this.postHelper.formFieldOptions.find(obj => obj.identifierString == 'quantity')
      quantityField.validators.push(
        Validators.min(0)
      )

      this.soGet.queryOptions.descending = true;
      this.soGet.queryOptions.sortKey = 'dateCreated';
      this.sortKey = 'dateCreated';

    } else if (entityViewSpec.entityCore.type == EntityType.line) {

      this.listHeaderSpec.showPostButton = false
      super.removePostFields(['isAllocation', 'notes'])
    }
  }

  private setSelectDispatchButtonIfNecessary(entity) {
    const multiSelectButton = new Button('multiSelectAdd', 'Select', 'playlist_add')
    multiSelectButton.buttonPressHandler = this
    const dex = this.listHeaderSpec.buttons.findIndex(obj => obj.identifier === 'multiSelectAdd')
    if (['pending', 'active'].includes(entity.status)) {
      if (dex == -1) {
        this.listHeaderSpec.buttons.push(multiSelectButton)
      }
    } else {
      if (dex != -1) {
        this.listHeaderSpec.buttons.splice(dex, 1)
      }
    }
  }

  makeEntityAttributesForDisplay(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry): EntityAttributesForDisplay {
    const resp = super.makeEntityAttributesForDisplay(entityViewSpec, listEntry);
    const removeDetails = ['isAllocation', 'complete']

    if (!listEntry.entity.line) {
      removeDetails.push('line', 'pickup')
    }

    for (const identifier of removeDetails) {
      const dex = resp.attributes.findIndex(obj => obj.identifier == identifier)
      resp.attributes.splice(dex, 1)
    }
    return resp
  }

  makeListEntry(parentEntityViewSpec: EntityViewSpecProtocol, entityCore: EntityCoreProtocol, entity: SORespDispatch, section: number = 0): ListEntry {

    let resp: ListEntry

    if (![EntityType.pickup, EntityType.line].includes(parentEntityViewSpec.entityCore.type)) {

      const heading = `Quantity change - ${
        entity.isAllocation ? 'add' : 'subtract'
      } ${
        entity.quantity
      }${
        entity.line ? ` - Order: ${entity.line.order.name}` : `${entity.notes ? `: ${entity.notes}` : ''}`
      }${entity.complete ? '' : ' [pending]'}`


      resp = new ListEntry(
        entityCore,
        entity,
        [heading],
        [],
        this.activeUserService.time.niceShortDateAndTime(entity.dateCreated),
        section
      );

    } else {
      resp = super.makeListEntry(parentEntityViewSpec, entityCore, entity, section);
    }

    if (entity.complete) {
      resp.checked = true;
      // resp.trailingText = this.activeUserService.time.niceShortDateAndTime(entity.dateUpdated)
    }
    resp.trailingText = this.activeUserService.time.niceShortDateAndTime(entity.dateCreated)

    console.log(resp)

    return resp
  }

  buttonPress(button: Button): void {

    if (button.identifier === 'multiSelectAdd') {
      const dispatchCore = new DispatchMultiSelect(this.activeUserService, this.httpClient)
      dispatchCore.soGet.predicate = 'pickup::None'
      dispatchCore.showAdd = false;
      dispatchCore.showQuickAdd = false;
      dispatchCore.parentPickup = this.parentPickup;

      const viewSpec = new ListSpec(
        dispatchCore,
        button.header.parent.entityViewSpec,
        null,
        this.navigator,
        true
      )
      viewSpec.subType = ListSpecType.checklist
      this.navigator.addEntityViewSpec(
        viewSpec
      )
    }
  }

  sortEntities() {
    if (this.locationSort.value) {
      this.entities.sort(
        (a: SORespDispatch, b: SORespDispatch) =>
          a.temloca.location.name < b.temloca.location.name ? -1 : a.temloca.location.name > b.temloca.location.name ? 1 : 0
      );
    } else if (this.orderSort) {
      this.entities.sort(
        (a: SORespDispatch, b: SORespDispatch) =>
          a.line.order.name < b.line.order.name ? -1 : a.line.order.name > b.line.order.name ? 1 : 0
      );
    }
  }

  menuClick(item: MenuItem, entityViewSpec: EntityViewSpecProtocol) {
    console.log(item)
    if (item.identifier === this.orderSort.identifier && !item.value) {
      this.sortByOrder(entityViewSpec);
    } else if (item.identifier === this.locationSort.identifier && !item.value) {
      console.log('sorting by location name')
      this.sortByLocation(entityViewSpec);
    }
  }

  private sortByLocation(entityViewSpec: EntityViewSpecProtocol) {
    this.locationSort.value = true;
    this.orderSort.value = false;
    entityViewSpec.entityCore.entities.sort(
      (a: SORespDispatch, b: SORespDispatch) =>
        a.temloca.location.name < b.temloca.location.name ? -1 : a.temloca.location.name > b.temloca.location.name ? 1 : 0
    );
    entityViewSpec.listBody.resetListBodyFromEntities(entityViewSpec.entityCore.entities, entityViewSpec)
  }

  private sortByOrder(entityViewSpec: EntityViewSpecProtocol) {
    console.log('sorting by order ref')
    this.locationSort.value = false;
    this.orderSort.value = true;
    entityViewSpec.entityCore.entities.sort(
      (a: SORespDispatch, b: SORespDispatch) =>
        a.line.order.name < b.line.order.name ? -1 : a.line.order.name > b.line.order.name ? 1 : 0
    );
    entityViewSpec.listBody.resetListBodyFromEntities(entityViewSpec.entityCore.entities, entityViewSpec)
  }

  handleParentChange(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry) {
    const dispatchSpec = entityViewSpec.childrenEntityAttributeViewSpecs.find(obj => obj.detailEntry && obj.detailEntry.identifier === 'dispatches')
    this.checklistDisabled = listEntry.entity.status != ENProgress.active;
    if (listEntry.entity.status == ENProgress.completed) {
      for (const entity of dispatchSpec.detailEntry.entityCore.entities) {
        entity.complete = true
      }
    }
    this.setSelectDispatchButtonIfNecessary(listEntry.entity)
  }

  postCompletePreListEntryReturn(newEntity: SORespDispatch, parentEntityViewSpec: EntityViewSpecProtocol) {
    super.postCompletePreListEntryReturn(newEntity, parentEntityViewSpec);

    // update temloca
    const temlocaListSpec = parentEntityViewSpec.parentEntityViewSpec

    if (temlocaListSpec.entityCore.type === EntityType.temloca) {
      const temloca = temlocaListSpec.entityCore.entities.find(
        obj => obj.uid === parentEntityViewSpec.listEntry.entity.uid
      )

      temlocaListSpec.listBody.resetListBodyFromEntities(
        temlocaListSpec.entityCore.entities,
        temlocaListSpec
      )
      parentEntityViewSpec.listEntry = temlocaListSpec.listBody.sections[0].listEntries.find(
        obj => obj.entity.uid === temloca.uid
      )
      const temlocaQuantityAttribute = parentEntityViewSpec.childrenEntityAttributeViewSpecs.find(obj => obj.detailEntry.identifier === 'quantity')
      temlocaQuantityAttribute.detailEntry.value = temloca.quantity

      // update item
      const itemListSpec = temlocaListSpec.parentEntityViewSpec.parentEntityViewSpec
      const item = itemListSpec.entityCore.entities.find(
        obj => obj.uid === temlocaListSpec.parentEntityViewSpec.listEntry.entity.uid
      )
      item.quantity = newEntity.isAllocation ? item.quantity + newEntity.quantity : item.quantity - newEntity.quantity

      itemListSpec.listBody.resetListBodyFromEntities(
        itemListSpec.entityCore.entities,
        itemListSpec
      )

      const itemQuantityAttribute = temlocaListSpec.parentEntityViewSpec.childrenEntityAttributeViewSpecs.find(
        obj => obj.detailEntry.identifier === 'quantity'
      )
      itemQuantityAttribute.detailEntry.value = temloca.quantity

    }
  }

  startRemoveListEntry(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, view: DeleteCompleteProtocol) {
    const request = new SOPutDispatch(listEntry.entity.uid);
    request.pickup = new SOGetPickup();
    request.pickup.predicate = 'Remove';
    this.entityService.put(request).subscribe(resp => {
      if (resp) {
        view.deleteComplete();
      }
    })
  }
}
