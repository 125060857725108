import {ActiveUserService} from '../../../engine/shared/active-user/active-user.service';
import {HttpClient} from '@angular/common/http';
import {EOOrderService} from '../../core/order/eo-order';
import {OrderListEntryFactory} from './order-list-entry-factory';
import {OrderDetailFactory} from './order-detail-factory';
import {SOGetOrder} from '../../core/order/so-get-order';
import {RAPutOrder} from './ra-put-order';
import {RAPostOrder} from './ra-post-order';
import {RADeleteOrder} from './ra-delete-order';
import {AbstractEntityCore} from "../../../engine/shared/entity-view/abstract-entity-core";
import {EntityCoreProtocol} from "../../../engine/shared/entity-view/entity-core-protocol";
import {EntityType} from '../../entity-types';
import {SPECTER_SPEC} from "../../specter-spec";
import {FormControl, FormGroup} from "@angular/forms";
import {SOGetOrganization} from '../../core/organization/so-get-organization'

export class OrderCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = EntityType.order;
  title = 'Order';
  listEntryFactory = new OrderListEntryFactory();
  detailFactory = new OrderDetailFactory();

  entityService: EOOrderService;
  soGetClass = SOGetOrder;
  soGet: SOGetOrder;

  searchEnabled = true;

  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOOrderService
    );
    this.adapters = [
      new RAPostOrder(this),
      new RAPutOrder(this),
      new RADeleteOrder(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetOrder();
    this.soGet.organization = new SOGetOrganization(
      this.activeUserService.getScope('organization').uid
    );
    this.soGet.queryOptions.limit = this.limit;
    this.soGet.queryOptions.sortKey = 'dateCreated';
    this.soGet.queryOptions.descending = true;
  }


}