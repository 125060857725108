import { SOGetPickup } from '../pickup/so-get-pickup';


export class SOPutDispatch {

  constructor(
    public uid: string,
    public complete: boolean = null,
    public notes: string = null,
    public pickup: SOGetPickup = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutDispatch(
      'uid' in params ? params.uid : null,
      'complete' in params ? params.complete : null,
      'notes' in params ? params.notes : null,
      'pickup' in params ? params.pickup : null,
    );
  }
}
