import { Type } from 'class-transformer';
import { SORespSubscription } from '../subscription/so-resp-subscription';
import {ObjectiveProtocol} from '../../../engine/protocols/objective-protocol';
import {EntityType} from "../../entity-types";


export class SORespInvoice implements ObjectiveProtocol {

  entityType: EntityType = EntityType.invoice;
  uid: string;
  ref: string;
  exRef: string = null;
  amountDue: number;
  amountPaid: number;
  amountRemaining: number;
  paid: boolean = false;
  @Type(() => Date)
  dateUpdated: Date;
  @Type(() => Date)
  dateCreated: Date;
  @Type(() => Date)
  dateDue: Date;
  @Type(() => Date)
  periodStart: Date;
  @Type(() => Date)
  periodEnd: Date;
  @Type(() => SORespSubscription)
  subscription: SORespSubscription;
}