import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';

import { SOPostViewGrant } from './so-post-view-grant';
import { SOGetViewGrant } from './so-get-view-grant';
import { SOPutViewGrant } from './so-put-view-grant';
import { SODeleteViewGrant } from './so-delete-view-grant';
import {SORespViewGrant} from './so-resp-view-grant';
import {SOPluralViewGrant} from './so-plural-view-grant';

import {MMEntityService} from '../../../engine/multiverse/mm-entity-service';
import {EntityCoreProtocol} from '../../../engine/shared/entity-view/entity-core-protocol';
import {MMEntityServiceBase} from '../../../engine/multiverse/mm-entity-service-base';
import {HttpHeaders} from "@angular/common/http";


export class EOViewGrantService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'view_grant',
      entityCore
    );
  }

  /** GET ViewGrant by id. Will 404 if id not found */
  get(request: SOGetViewGrant): Observable<SOPluralViewGrant> {
    return this.httpClient.get<SOPluralViewGrant>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralViewGrant, resp) }),
      tap(_ => this.log(`fetched ViewGrant`)),
      catchError(this.handleError<SOPluralViewGrant>(`get ViewGrant`))
    );
  }

  /** POST: add a new ViewGrant to the server */
  post(request: SOPostViewGrant): Observable<SORespViewGrant> {
    return this.httpClient.post<SORespViewGrant>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespViewGrant, resp) }),
      tap((response: SORespViewGrant) => this.log(`added ViewGrant w/ id=${response.uid}`)),
      catchError(this.handleError<SORespViewGrant>('ViewGrant post'))
    );
  }

  /** PUT: update ViewGrant  */
  put(request: SOPutViewGrant): Observable<SORespViewGrant> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespViewGrant>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespViewGrant, resp) }),
      tap((response: SORespViewGrant) => this.log(`edited ViewGrant w/ uid=${uid}`)),
      catchError(this.handleError<SORespViewGrant>('ViewGrant put'))
    );
  }

  /** DELETE: delete the ViewGrant from the server */
  delete(request: SODeleteViewGrant): Observable<SORespViewGrant> {
    return this.httpClient.delete<SORespViewGrant>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted ViewGrant uid=${request.uid}`)),
      catchError(this.handleError<SORespViewGrant>('delete ViewGrant'))
    );
  }
}
