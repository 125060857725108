import {ListEntry} from '../shared/list-entry';
import {FormGroup} from '@angular/forms';
import {DetailEntry} from '../shared/detail-entry';
import {EntityViewSpecProtocol} from '../shared/entity-view/entity-view-spec-protocol';
import {Button, HeaderSpec} from "../shared/header-spec";
import {EntityCoreProtocol} from "../shared/entity-view/entity-core-protocol";
import {SvHeaderComponent} from "../views/structure/sv-header/sv-header.component";

export enum MenuItemType {
  button,
  multiselect,
  excel,
  date,
  search,
  staticFile,
  excelTemplate,
}

export class MenuItem {
  header: SvHeaderComponent;
  get viewSpec() {
    return this.header.parent.entityViewSpec;
  }

  constructor(
    public identifier: string,
    public displayText: string,
    public entityCore: EntityCoreProtocol,
    public menuItemType: MenuItemType = MenuItemType.button,
    public value: any = null
  ) {
  }

  click() {
    this.entityCore.menuClick(this, this.viewSpec);
  }

  process(data) {
    this.entityCore.processMenuItem(this, data);
  }
}

export class LaunchButton {
  constructor(
    public detailEntry: DetailEntry = null,
    public icon = 'insert_chart'
  ) {
  }
}

export interface HeaderProtocol {

  headerSpec: HeaderSpec;
  loading: boolean;

  entityViewSpec: EntityViewSpecProtocol;

  clickDeleteButton();
  clickLaunchButton(launchButtonOne: any): void;

  clickHeading(key);
  closeDetailView(appendEntry: ListEntry);
  selectListEntry(listEntry: ListEntry, sourceListSpec: EntityViewSpecProtocol): void;
  removeChild(listEntry: ListEntry, fromEntityViewSpec: EntityViewSpecProtocol): void;

  showHistory(entityViewSpec: EntityViewSpecProtocol);

  exportToExcel();

  startTask(entityViewSpec: EntityViewSpecProtocol, identifier): void;
  taskComplete(core,  parentEntityViewSpec, listEntry);
  showMessage(message: string): void;

  clickSearchIcon(data);

  submitSearchForm(query);
  searchFieldKeyup();

  showDetails(): void;
}
