import {SOPostIdentity} from '../../core/identity/so-post-identity';
import {IdentityCore} from './identity.core';
import {SOGetIdentity} from '../../core/identity/so-get-identity';
import {FormField, SelectionOption} from '../../../engine/shared/form-field';
import {postIdentityFormFieldOptions} from './post-identity-form-fields';
import {AdapterProtocol} from '../../../engine/shared/entity-construction/adapter-protocol';
import {BasePost} from '../../../engine/shared/entity-construction/base-post';

export class RAPostIdentity extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postIdentityFormFieldOptions);

  RequestClass = SOPostIdentity

  constructor(
    public entityCore: IdentityCore
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('idToken'),
      this.getFormValueFromIdentifier('accessToken'),
      this.getFormValueFromIdentifier('provider'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}