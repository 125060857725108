import { SOGetCustomField } from '../custom-field/so-get-custom-field';
import { SOGetItem } from '../item/so-get-item';
import { SOGetOrganization } from '../organization/so-get-organization';


export class SOPostTemfield {

  constructor(
    public customField: SOGetCustomField,
    public value: string,
    public item: SOGetItem,
    public organization: SOGetOrganization,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostTemfield(
      'customField' in params ? params.customField : null,
      'value' in params ? params.value : null,
      'item' in params ? params.item : null,
      'organization' in params ? params.organization : null,
    );
  }
}
