import {FormField, FormFieldType, SelectionOption} from '../../../engine/shared/form-field';
import {EntityType} from "../../entity-types";


const idTokenField = new FormField(
  'idToken',
  'Id token',
  null,
  FormFieldType.text,
  true,
)
const accessTokenField = new FormField(
  'accessToken',
  'Access token',
  null,
  FormFieldType.text,
  true,
)
const providerField = new FormField(
  'provider',
  'Provider',
  null,
  FormFieldType.text,
  true,
)


export const postIdentityFormFieldOptions: {[key: string]: FormField} = {
  idToken: idTokenField,
  accessToken: accessTokenField,
  provider: providerField,
};