import {PriceCore} from "../../../entities/bv-price/price.core";
import {EntityCoreProtocol} from "../../../../engine/shared/entity-view/entity-core-protocol";
import {ListEntry} from "../../../../engine/shared/list-entry";
import {EntityViewSpecType} from "../../../../engine/shared/entity-view/entity-view-spec-type";
import {EntityType} from "../../../entity-types";
import {SORespPrice} from "../../../core/price/so-resp-price";
import {EntityViewSpecProtocol} from "../../../../engine/shared/entity-view/entity-view-spec-protocol";

export class PriceProcessor extends PriceCore {

  makeListEntry(
    parentEntityViewSpec: EntityViewSpecProtocol,
    entityCore: EntityCoreProtocol,
    entity: SORespPrice,
    section: number = 0
  ): ListEntry {

    const entry = super.makeListEntry(parentEntityViewSpec, entityCore, entity, section);

    if (parentEntityViewSpec.entityCore.type === EntityType.organization) {
      entry.link = entity.exRef;
      entry.linkText = !this.activeUserService.user.emailVerified ? 'Verify email to buy' : 'Buy';
      entry.linkDisabled = !this.activeUserService.user.emailVerified;
    }

    return entry
  }

}
