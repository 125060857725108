import {Component, Input} from '@angular/core';
import {HeaderProtocol, MenuItem} from '../../../protocols/header-protocol';
import {ListEntry} from '../../../shared/list-entry';
import {ActiveUserService} from '../../../shared/active-user/active-user.service';
import {DetailEntry} from '../../../shared/detail-entry';
import {MatSnackBar} from '@angular/material/snack-bar';
import {HttpClient} from '@angular/common/http';
import {EntityViewSpecProtocol} from '../../../shared/entity-view/entity-view-spec-protocol';
import {EntityViewSpecType} from '../../../shared/entity-view/entity-view-spec-type';
import {AttributesSpec} from '../../../shared/entity-view/detail-spec';
import {EntityCoreProtocol} from '../../../shared/entity-view/entity-core-protocol';
import {PostSpec} from '../../../shared/entity-view/post-spec';
import {ListSpec} from '../../../shared/entity-view/list-spec';
import {HeaderSpec} from "../../../shared/header-spec";
import {SvNavigatorComponent} from "../../structure/sv-navigator/sv-navigator.component";
import {SPECTER_SPEC} from "../../../../app/specter-spec";
import {FileUploadService} from "../../../shared/file-upload/file-upload.service";

@Component({
  selector: 'app-sv-entity-view',
  templateUrl: './sv-entity-view.component.html',
  styleUrls: ['./sv-entity-view.component.css']
})
export class SvEntityViewComponent implements HeaderProtocol {

  @Input()
  entityViewSpec: EntityViewSpecProtocol;

  @Input()
  parentRouteView: SvNavigatorComponent;

  headerSpec = new HeaderSpec()
  loading: boolean = false;

  get isList() {
    return this.entityViewSpec.type === EntityViewSpecType.list;
  }

  get isDetail() {
    return this.entityViewSpec.type === EntityViewSpecType.detail;
  }

  get isPost() {
    return this.entityViewSpec.type === EntityViewSpecType.post;
  }

  get isCalendar(): any {
    return this.entityViewSpec.type === EntityViewSpecType.calendar;
  }

  get isAutoComplete() {
    return this.entityViewSpec.type === EntityViewSpecType.autoComplete;
  }

  get isStatus(): any {
    return this.entityViewSpec.type === EntityViewSpecType.status;
  }

  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
    private snackBar: MatSnackBar,
    public fileUploadService: FileUploadService
  ) {
  }


  clickSearchIcon(data) {
    this.parentRouteView.addEntityViewSpec(data);
  }

  clickDeleteButton() {
    console.log('click delete button in core');
  }

  submitSearchForm(query) {
  }

  searchFieldKeyup() {
  }

  startPost(parentEntityViewSpec: EntityViewSpecProtocol) {
    console.log('starting post')
    const entityCore: EntityCoreProtocol = this.entityViewSpec.type === EntityViewSpecType.list ? this.entityViewSpec.entityCore : parentEntityViewSpec.entityCore;
    this.parentRouteView.addEntityViewSpec(
      new PostSpec(
        entityCore,
        parentEntityViewSpec,
        this.parentRouteView,
      ),
      this.entityViewSpec.index + 1
    );
  }

  startTask(entityViewSpec: EntityViewSpecProtocol, identifier): void {
    console.log('starting task in sv-entity-view');
    console.log(entityViewSpec);
    this.entityViewSpec.entityCore.startTask(this, entityViewSpec, identifier);
  }

  taskComplete(core, parentEntityViewSpec, listEntry) {
    console.log(parentEntityViewSpec);
    this.parentRouteView.addEntityViewSpec(
      new AttributesSpec(
        listEntry,
        parentEntityViewSpec,
        null,
        this.parentRouteView,
      ),
      this.entityViewSpec.index + 1
    );
  }

  showMessage(message: string) {

  }

  showHistory(entityViewSpec: EntityViewSpecProtocol) {
    this.clickPushList(entityViewSpec.detailEntry, entityViewSpec);
  }

  clickHeading(key) {
  }

  closeDetailView(appendEntry: ListEntry = null) {
    console.log('closing detail');
    if (this.entityViewSpec.type === EntityViewSpecType.detail) {
      console.log('closing detail 1');
      const entityViewSpecIndex = this.parentRouteView.entityViewSpecs.findIndex(obj =>
        obj.type === EntityViewSpecType.detail && obj.listEntry === this.entityViewSpec.listEntry
      );
      this.parentRouteView.updateEntityViewSpec(entityViewSpecIndex);
    } else if (this.entityViewSpec.type === EntityViewSpecType.list && appendEntry == null) {
      console.log('closing detail 3');
      const entityViewSpecIndex = this.parentRouteView.entityViewSpecs.findIndex(obj =>
        obj.type === EntityViewSpecType.list && obj.entityCore === this.entityViewSpec.entityCore
      );
      this.parentRouteView.updateEntityViewSpec(entityViewSpecIndex);
    } else if (this.entityViewSpec.type === EntityViewSpecType.list && appendEntry != null) {
      console.log('closing detail 3');
      this.entityViewSpec.addEntryToList(appendEntry, null);
      this.parentRouteView.entityViewSpecs.pop();
    } else if (this.entityViewSpec.type === EntityViewSpecType.post) {
      console.log('popping detail');
      this.parentRouteView.entityViewSpecs.pop();
    }

  }

  menuClick(item: MenuItem): void {
  }

  selectListEntry(listEntry: ListEntry, sourceListSpec: EntityViewSpecProtocol): void {
    listEntry.entityCore.selectedEntity = listEntry.entity;
    console.log('selected list entry, in entity view')
    console.log(this.entityViewSpec.entityCore)
    console.log(sourceListSpec)

    if (sourceListSpec && sourceListSpec.detailEntry && sourceListSpec.detailEntry.singleFieldSelect) {
      // case: selecting from list for detail form
      const initialEntity = sourceListSpec.parentEntityViewSpec.listEntry.entity[sourceListSpec.detailEntry.identifier]
       if (
         initialEntity && initialEntity.uid
         && listEntry.entity && listEntry.entity.uid
         && initialEntity.uid !== listEntry.entity.uid
       ) {
         sourceListSpec.parentEntityViewSpec.listEntry.entity[sourceListSpec.detailEntry.identifier] = listEntry.entity;
         sourceListSpec.parentEntityViewSpec.hasMods = true;
       }
      this.parentRouteView.entityViewSpecs.pop();

    } else {

      const entityViewSpec = listEntry.entityCore.getEntityViewSpecFromList(listEntry, sourceListSpec, this.parentRouteView);
      const existingEntityViewSpecIndex = this.parentRouteView.entityViewSpecs.findIndex(obj =>
        obj.listEntry && obj.listEntry === listEntry
      );
      if (existingEntityViewSpecIndex === -1) {
        if (this.entityViewSpec.index + 1 < this.parentRouteView.entityViewSpecs.length) {
          this.parentRouteView.updateEntityViewSpec(this.entityViewSpec.index + 1, entityViewSpec);
        } else {
          this.parentRouteView.addEntityViewSpec(entityViewSpec);
        }
      }

    }


  }

  postComplete(listEntry: ListEntry, entityViewSpecForPost: EntityViewSpecProtocol) {
    console.log('INFO: submit complete in AvCoreComponent from the following core spec');
    console.log(entityViewSpecForPost);
    console.log(listEntry);

    let postSpecIndex;
    if (this.parentRouteView) {
      postSpecIndex = entityViewSpecForPost.index.valueOf();
      if (postSpecIndex >= 0) {
        this.parentRouteView.entityViewSpecs.pop();
      }
    }

    if (listEntry.snackBarText) {
      this.snackBar.open(listEntry.snackBarText);
    }

    console.log('INFO: before show after post');
    if (listEntry.showAfterPost && !listEntry.entityCore.showQuickAdd) {
      console.log('INFO: Show after post enabled, adding core spec');
      this.parentRouteView.addEntityViewSpec(
        new AttributesSpec(
          listEntry,
          entityViewSpecForPost.parentEntityViewSpec,
          null,
          this.parentRouteView,
        ),
        postSpecIndex
      );
    }

    if ([EntityViewSpecType.list, EntityViewSpecType.autoComplete].includes(entityViewSpecForPost.parentEntityViewSpec.type)) {

      console.log(`INFO: Posted item's parent is a list, adding the following item to list that follows`);
      console.log(listEntry);

      entityViewSpecForPost.parentEntityViewSpec.addEntryToList(listEntry, null);

      const customProcessor = SPECTER_SPEC.postListItemCompleteProcessors.find(obj => obj.entityType == entityViewSpecForPost.entityCore.type)
      if (customProcessor) {
        customProcessor.postListItemComplete(entityViewSpecForPost.parentEntityViewSpec, listEntry, this)
      }

      console.log('Reconfigured list view spec is')
      console.log(entityViewSpecForPost.parentEntityViewSpec)


    }
  }

  clickPushList(detailEntry: DetailEntry, entityViewSpec: EntityViewSpecProtocol) {
    if (!this.parentRouteView.entityViewSpecs.find(obj => obj.detailEntry && obj.detailEntry === detailEntry)) {
      this.parentRouteView.updateEntityViewSpec(
        entityViewSpec.index + 1,
        new ListSpec(
          detailEntry.entityCore,
          this.entityViewSpec,
          null,
          this.parentRouteView,
          true,
          detailEntry
        )
      );
    }
  }

  clickPushDetail(detailEntry: DetailEntry, entityViewSpec: EntityViewSpecProtocol) {
    if (!this.parentRouteView.entityViewSpecs.find(obj => obj.detailEntry && obj.detailEntry === detailEntry)) {
      this.parentRouteView.updateEntityViewSpec(
        entityViewSpec.index + 1,
        new AttributesSpec(
          detailEntry.cachedListEntry,
          this.entityViewSpec,
          null,
          this.parentRouteView,
          true
        )
      );
    }
  }

  removeChild(listEntry: ListEntry, fromEntityViewSpec: EntityViewSpecProtocol): void {
    console.log('removing child');
    console.log(fromEntityViewSpec);
    console.log(fromEntityViewSpec.parentEntityViewSpec);
    console.log(fromEntityViewSpec.parentEntityViewSpec.type);
    console.log(fromEntityViewSpec.parentEntityViewSpec.type === EntityViewSpecType.list);


    if (fromEntityViewSpec.parentEntityViewSpec.type === EntityViewSpecType.list) {
      console.log('is list removing entry');
      fromEntityViewSpec.parentEntityViewSpec.removeEntryFromList(listEntry, null);
    }

    if (this.parentRouteView) {
      this.parentRouteView.entityViewSpecs.pop();
    }
  }

  exportToExcel() {
  }

  clickLaunchButton(launchButtonOne: any): void {
  }

  showDetails() {

  };
}
