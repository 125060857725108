import { Type } from 'class-transformer';
import {ObjectiveProtocol} from '../../../engine/protocols/objective-protocol';
import {EntityType} from "../../entity-types";


export class SORespBulkitem implements ObjectiveProtocol {

  entityType: EntityType = EntityType.bulkitem;
  uid: string;
  data: any;
}