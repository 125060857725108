import {EntityType} from "../../app/entity-types";
import {DisplaySeparator} from "./display-separator";
import {ObjectiveProtocol} from "../protocols/objective-protocol";
import {stringSentenceCase} from "../shared/display-option-utilities";


export interface DisplayVectorKeyProtocol {
  key: string

  sectionHeading: string;
  hideIfNull: boolean;

  makeFinalString(entity, showLabels: boolean, displayVectorBundleOptions: DisplayVectorBundle[]): string;
}


export class DisplayVectorEntity implements DisplayVectorKeyProtocol {

  get key() {
    return EntityType[this.entityType]
  }

  get sectionHeading() {
    return this.heading ? this.heading : EntityType[this.entityType]
  }

  constructor(
    public entityType: EntityType,
    public approachedFrom: EntityType = null,
    public heading: string = null,
    public hideIfNull: boolean = false
  ) {
  }

  makeFinalString(entity, showLabels: boolean, displayVectorBundleOptions: DisplayVectorBundle[]): string {
    // Note - this only works if the entity attribute name == the entity type
    return new DisplayVectorStringFactory(
      displayVectorBundleOptions,
      entity[EntityType[this.entityType]],
      this.approachedFrom
    ).primaryString
  };
}

export class DisplayVectorKey implements DisplayVectorKeyProtocol {

  get sectionHeading() {
    return this.heading ? this.heading : this.key
  }

  constructor(
    public key: string,
    public label: string = null,
    public heading: string = null,
    public hideIfNull: boolean = false
  ) {
  }

  makeFinalString(entity, showLabels: boolean, displayVectorBundleOptions: DisplayVectorBundle[]): string {

    if (!entity[this.key] && this.hideIfNull) {
      return null
    } else {
      let resp = `${entity[this.key]}`
      if (showLabels || this.label) {
        const label = this.label ? this.label : this.key;
        resp = `${label}: ${entity[this.key]}`;
      }
      return resp
    }
  };
}

export class DisplayVectorText implements DisplayVectorKeyProtocol {

  get sectionHeading() {
    return this.key
  }

  constructor(
    public key: string,
    public hideIfNull: boolean = false
  ) {
  }

  makeFinalString(entity, showLabels: boolean, displayVectorBundleOptions: DisplayVectorBundle[]): string {
    return this.key
  };
}

export class DisplayVectorComponent {

  constructor(
    public vectorKeys: DisplayVectorKeyProtocol[],
    public separator: DisplaySeparator = DisplaySeparator.space,
    public useEntityAsHeading: boolean = true
  ) {
  }

  makeFinalString(entity, showLabels: boolean, displayVectorBundleOptions: DisplayVectorBundle[]) {
    let resp = ''
    const finalTexts = []
    for (let num = 0; num < this.vectorKeys.length; num++) {
      const text = this.vectorKeys[num].makeFinalString(entity, showLabels, displayVectorBundleOptions)
      if (text || (!text && !this.vectorKeys[num].hideIfNull)) {
        finalTexts.push(text)
      }
    }
    for (let num = 0; num < finalTexts.length; num++) {
      let separator = ''
      if (num !== finalTexts.length - 1) {
        separator = this.separator
      }
        resp = `${
          resp
        }${
          finalTexts[num] ? finalTexts[num] : ''
        }${
          separator
        }`
    }
    return resp;
  }
}

export class DisplayVector {

  get sectionHeading(): string {
    if (this.heading) {
      return this.heading.sectionHeading
    } else if (this.primaryVector && this.primaryVector.useEntityAsHeading) {
      let resp = ''
      for (let num = 0; num < this.primaryVector.vectorKeys.length; num++) {
        let separator = ''
        if (num !== this.primaryVector.vectorKeys.length - 1) {
          separator = this.primaryVector.separator
        }
        resp = `${
          resp
        }${
          this.primaryVector.vectorKeys[num].sectionHeading
        }${
          separator
        }`
      }
      return resp;
    } else {
      return null
    }
  }

  constructor(
    public approachedFrom: EntityType,
    public primaryVector: DisplayVectorComponent,
    public secondaryVectors: DisplayVectorComponent[] = null,
    public accentVector: DisplayVectorComponent = null,
    public showLabels: boolean = false,
    private heading: DisplayVectorKeyProtocol = null
  ) {
  }

  makePrimaryString(entity: ObjectiveProtocol, displayVectorBundleOptions: DisplayVectorBundle[]): string {
    return this.primaryVector.makeFinalString(entity, this.showLabels, displayVectorBundleOptions)
  };

  makeSecondaryStrings(entity: ObjectiveProtocol, displayVectorBundleOptions: DisplayVectorBundle[]): string[] {
    let resp = []
    for (const vector of this.secondaryVectors) {
      resp.push(vector.makeFinalString(entity, this.showLabels, displayVectorBundleOptions))
    }
    return resp
  };

  makeAccentString(entity: ObjectiveProtocol, displayVectorBundleOptions: DisplayVectorBundle[]): string {
    return this.accentVector.makeFinalString(entity, this.showLabels, displayVectorBundleOptions)
  };
}


export class DisplayVectorBundle {

  constructor(
    public entityType: EntityType,
    public displayVectors: DisplayVector[]
  ) {
  }

  makeSectionHeading(approachedFrom: EntityType): string {
    // console.log(approachedFrom)
    let heading: string;
    const vector = this.displayVectors.find(obj => obj.approachedFrom == approachedFrom)
    // console.log(vector)
    if (vector && vector.sectionHeading) {
      heading = vector.sectionHeading
      // console.log(heading)
    } else {
      heading = stringSentenceCase(this.entityType)
      // console.log(heading)
    }
    return heading
  };

}

export class DisplayVectorStringFactory {

  private displayVector: DisplayVector
  private defaultDisplayVector: DisplayVector
  private displayVectorBundle: DisplayVectorBundle

  private secondaryVectors: DisplayVectorComponent[]

  get primaryString() {
    if (this.displayVector && this.displayVector.primaryVector) {
      return this.displayVector.makePrimaryString(this.entity, this.displayVectorBundleOptions);
    } else if (this.entity) {
      if (this.entity['name']) {
        return this.entity['name']
      } else if (this.entity['title']) {
        return this.entity['title']
      } else if (this.entity['ref']) {
        return this.entity['ref']
      } else {
        return this.entity.uid
      }
    } else {
      return ''
    }
  }

  get secondaryStrings() {
    // console.log('making secondary strings')
    if (this.displayVector && this.displayVector.secondaryVectors && this.displayVector.secondaryVectors.length >= 0) {
      // console.log('have secondary vectors on requested vector')
      // console.log(this.displayVector.makeSecondaryStrings(this.entity, this.displayVectorBundleOptions))
      return this.displayVector.makeSecondaryStrings(this.entity, this.displayVectorBundleOptions);
    } else {
      // console.log('no secondary vectors on requested vector')
      if (this.secondaryVectors && this.secondaryVectors.length > 0) {
        // console.log('have secondary vectors on default vector')
        return this.defaultDisplayVector.makeSecondaryStrings(this.entity, this.displayVectorBundleOptions)
      }
      return []
    }
  }

  get accentString() {
    if (this.displayVector && this.displayVector.accentVector) {
      return this.displayVector.makeAccentString(this.entity, this.displayVectorBundleOptions);
    } else {
      return null
    }
  }

  get combinedString() {
    // console.log(this.displayVector)
    if (this.displayVector && this.displayVector.secondaryVectors && this.displayVector.secondaryVectors.length > 0) {
      // console.log('have secondary vectors')
      let resp = ''
      const strings = [this.primaryString]
      // console.log(strings)
      strings.push(this.secondaryStrings)
      // console.log(strings)
      for (let num = 0; num < strings.length; num++) {
        resp = resp + strings[num]
        // console.log(resp)
        if (num < strings.length - 1) {
          resp = resp + ' '
        }
        // console.log(resp)
      }
      return resp
    } else {
      // console.log('no secondary vectors')
      return this.primaryString
    }
  }

  constructor(
    public displayVectorBundleOptions: DisplayVectorBundle[],
    private entity: ObjectiveProtocol = null,
    private approachedFrom: EntityType = null
  ) {
    if (entity) {
      // console.log(entity)
      // console.log(`Entity ${EntityType[entity.entityType]}`)
      // console.log(`Approached from ${EntityType[approachedFrom]}`)
      this.reconfigure(entity, approachedFrom);
    }
  }

  reconfigure(entity: ObjectiveProtocol, approachedFrom: EntityType = null) {
    this.entity = entity;
    this.approachedFrom = approachedFrom;
    this.initialize();
  };

  private initialize() {
    this.displayVectorBundle = this.displayVectorBundleOptions.find(obj => obj.entityType === this.entity.entityType)
    if (this.displayVectorBundle) {
      this.displayVector = this.displayVectorBundle.displayVectors.find(obj => obj.approachedFrom === this.approachedFrom)
      // console.log(this.displayVector)
      if (this.approachedFrom !== null) {
        this.defaultDisplayVector = this.displayVectorBundle.displayVectors.find(obj => obj.approachedFrom === null)
        if (this.defaultDisplayVector) {
          // the only reason I am setting this is for from reason it won't work when I directly call it
          this.secondaryVectors = this.defaultDisplayVector.secondaryVectors
        }
      }
    }
  }
}
