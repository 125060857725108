import { SOGetUser } from '../user/so-get-user';
import { SOGetToken } from '../token/so-get-token';
import { MMCredential } from '../../../engine/multiverse/mm-credential';


export class SOPutCertificate {

  constructor(
    public uid: string,
    public user: SOGetUser = null,
    public credential: MMCredential = null,
    public tokens: SOGetToken[] = null,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPutCertificate(
      'uid' in params ? params.uid : null,
      'user' in params ? params.user : null,
      'credential' in params ? params.credential : null,
      'tokens' in params ? params.tokens : null,
    );
  }
}
