import {BaseDetailType} from './base-detail';
import {FormField} from './form-field';
import {ListEntry} from './list-entry';
import {ListSpec} from './entity-view/list-spec';
import {AttributesSpec} from './entity-view/detail-spec';
import {EntityViewSpecProtocol} from './entity-view/entity-view-spec-protocol';
import {EntityCoreProtocol} from './entity-view/entity-core-protocol';
import {ActiveUserService} from "./active-user/active-user.service";
import {EntityAttributesForDisplay} from "./entity-attributes-for-display";
import {getHeading} from "./display-option-utilities";
import {Button} from "./header-spec";


export enum EditableType {
  never,
  always,
  entityEnabled,
  localEnabled
}


export enum AddButton {
  post,
  selectFromList,
}


export interface DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService): EntityAttributesForDisplay

}


export class DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService): EntityAttributesForDisplay {

    const details = []

    for (const key of Object.keys(listEntry.entity)) {
      details.push(
        new DetailEntry(
          key,
          listEntry.entity[key],
          key
        )
      )
    }

    return new EntityAttributesForDisplay(details)
  }
}


export class DetailEntry {

  file;
  hideIfNoFile = false;
  requireConfirmOnPost = false;
  singleFieldSelect = false;

  isChecked = false;

  entity;

  listSpec: ListSpec;
  attributesSpec: AttributesSpec;

  parentNameInPanel: string;

  rightColumn = false;

  removable = false;

  hintText;
  warn = false;
  dateStartView = 'month';

  listStyle = 'normal';

  _editEnabled;
  showAsSecondaryDetail = false;
  button: Button;

  get editEnabled() {
    return this._editEnabled
  };
  set editEnabled(value) {
    this._editEnabled = value;
    if (this.editableFormField) {
      this.editableFormField.disabled = !value
    }
  }

  get hasChanges() {
    return this.editableFormField && this.value !== this.editableFormField.value
  }

  constructor(
    public name: string,
    public value: any,
    public identifier: string,
    public detailType: BaseDetailType = BaseDetailType.text,
    public entityCore: EntityCoreProtocol = null,
    public editableType: EditableType = EditableType.never,
    public editableFormField: FormField = null,
    public cachedListEntry: ListEntry = null,
    public tabs: DetailEntry = null,
    public addButton: AddButton = AddButton.post,
    public selectFromCore: EntityCoreProtocol = null
  ) {
  }

  makeListSpec(detailEntry: DetailEntry = null, parentEntityViewSpec: EntityViewSpecProtocol = null,
               parentPanelEntityViewSpec: EntityViewSpecProtocol = null, showClose = false): ListSpec {
    this.listSpec = new ListSpec(this.entityCore, parentEntityViewSpec, parentPanelEntityViewSpec, parentEntityViewSpec.navigator, showClose, detailEntry);
    return this.listSpec;
  }

  makeAttributesSpec(detailEntry: DetailEntry = null, parentEntityViewSpec: EntityViewSpecProtocol = null,
                 parentPanelEntityViewSpec: EntityViewSpecProtocol = null, showClose = false): AttributesSpec {
    this.attributesSpec = new AttributesSpec(
      detailEntry.cachedListEntry,
      parentEntityViewSpec,
      parentPanelEntityViewSpec,
      parentEntityViewSpec.navigator,
      showClose
    );
    this.attributesSpec.embedded = detailEntry.detailType === BaseDetailType.embeddedDetail
      || detailEntry.detailType === BaseDetailType.embeddedList;
    return this.attributesSpec;
  }

  getLabel(entityViewSpec: EntityViewSpecProtocol) {
    if (!this.entityCore) {
      return this.name
    } else {
      const heading = getHeading(entityViewSpec.parentEntityViewSpec.entityCore, this.entityCore)
      return heading ? heading : this.name
    }
  }
}
