import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';

import { SOPostLine } from './so-post-line';
import { SOGetLine } from './so-get-line';
import { SOPutLine } from './so-put-line';
import { SODeleteLine } from './so-delete-line';
import {SORespLine} from './so-resp-line';
import {SOPluralLine} from './so-plural-line';

import {MMEntityService} from '../../../engine/multiverse/mm-entity-service';
import {EntityCoreProtocol} from '../../../engine/shared/entity-view/entity-core-protocol';
import {MMEntityServiceBase} from '../../../engine/multiverse/mm-entity-service-base';
import {HttpHeaders} from "@angular/common/http";


export class EOLineService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'line',
      entityCore
    );
  }

  /** GET Line by id. Will 404 if id not found */
  get(request: SOGetLine): Observable<SOPluralLine> {
    return this.httpClient.get<SOPluralLine>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralLine, resp) }),
      tap(_ => this.log(`fetched Line`)),
      catchError(this.handleError<SOPluralLine>(`get Line`))
    );
  }

  /** POST: add a new Line to the server */
  post(request: SOPostLine): Observable<SORespLine> {
    return this.httpClient.post<SORespLine>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespLine, resp) }),
      tap((response: SORespLine) => this.log(`added Line w/ id=${response.uid}`)),
      catchError(this.handleError<SORespLine>('Line post'))
    );
  }

  /** PUT: update Line  */
  put(request: SOPutLine): Observable<SORespLine> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespLine>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespLine, resp) }),
      tap((response: SORespLine) => this.log(`edited Line w/ uid=${uid}`)),
      catchError(this.handleError<SORespLine>('Line put'))
    );
  }

  /** DELETE: delete the Line from the server */
  delete(request: SODeleteLine): Observable<SORespLine> {
    return this.httpClient.delete<SORespLine>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Line uid=${request.uid}`)),
      catchError(this.handleError<SORespLine>('delete Line'))
    );
  }
}
