<div fxLayout="row" fxLayoutAlign="start center">
  <div *ngIf="parent.entityViewSpec" fxLayout="{{layout}}" style="padding: 10px">
    <mat-card *ngFor="let listEntry of parent.entityViewSpec.listBody.sections[0].listEntries"
              style="margin-right: 10px; margin-bottom: 10px">
      <mat-card-header>
        <mat-card-title>{{listEntry.fullHeading}}</mat-card-title>
        <mat-card-subtitle>{{listEntry.trailingText}}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <p *ngFor="let line of listEntry.lines">{{line}}</p>
      </mat-card-content>
      <mat-card-actions *ngIf="listEntry.link">
        <button mat-button
                matTooltip="{{listEntry.tooltip}}"
                (click)="clickEntry(listEntry)" [disabled]="listEntry.linkDisabled">{{listEntry.linkText}}</button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
