import { Type } from 'class-transformer';
import { SORespOrganization } from '../organization/so-resp-organization';
import {ENActive} from '../../enums';
import {ObjectiveProtocol} from '../../../engine/protocols/objective-protocol';
import {EntityType} from "../../entity-types";


export class SORespItem implements ObjectiveProtocol {

  entityType: EntityType = EntityType.item;
  uid: string;
  name: string;
  description: string = null;
  status: ENActive = ENActive.active;
  quantity: number = 0;
  @Type(() => Date)
  dateCreated: Date;
  @Type(() => Date)
  dateUpdated: Date;
  barCode: string = null;
  retailPrice: number = null;
  cost: number = null;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;
}