<div class="event" fxLayout="column"
     [ngClass]="{'available': listEntry.entity.status == 'available'}">
  <mat-progress-bar *ngIf="loading" class="progress-bar" mode="indeterminate" color="primary"></mat-progress-bar>
  <div *ngIf="!loading" class="progress-bar"></div>
  <div (click)="parent.selectListEntry(listEntry)" fxLayout="column">
    <div class="line-heading">
      {{listEntry.trailingText}}
    </div>
    <div fxLayout="column">
      <div *ngFor="let line of listEntry.lineHeading" class="line">
        {{line}}
      </div>

      <div *ngFor="let line of listEntry.subLines" class="line">
        {{line}}
      </div>
    </div>
  </div>
</div>
