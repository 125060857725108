import {FormField, FormFieldType, SelectionOption} from '../../../engine/shared/form-field';
import {EntityType} from "../../entity-types";


const userField = new FormField(
  'user',
  'User',
  EntityType.user,
  FormFieldType.pushedSelectionList,
  false,
)
const credentialField = new FormField(
  'credential',
  'Credential',
  null,
  FormFieldType.text,
  false,
)
const tokensField = new FormField(
  'tokens',
  'Tokens',
  EntityType.token,
  FormFieldType.pushedSelectionList,
  false,
)


export const putCertificateFormFieldOptions: {[key: string]: FormField} = {
  user: userField,
  credential: credentialField,
  tokens: tokensField,
};