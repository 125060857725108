import {SORespAttachment} from '../../core/attachment/so-resp-attachment';
import {AttachmentCore} from './attachment.core';
import {ListEntry} from '../../../engine/shared/list-entry';
import {EntityAttributesForDisplay} from '../../../engine/shared/entity-attributes-for-display';
import {DetailEntry, DetailFactory, DetailFactoryProtocol, EditableType} from '../../../engine/shared/detail-entry';
import {ActiveUserService} from '../../../engine/shared/active-user/active-user.service';
import {BaseDetailType} from "../../../engine/shared/base-detail";
import {EntityViewSpecProtocol} from "../../../engine/shared/entity-view/entity-view-spec-protocol";
import {getHeading} from "../../../engine/shared/display-option-utilities";
import {putAttachmentFormFieldOptions} from './put-attachment-form-fields';
import {SOGetAttachment} from '../../core/attachment/so-get-attachment';
import {SOGetMedia} from '../../core/media/so-get-media';
import {SOGetItem} from '../../core/item/so-get-item';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {DisplayVectorStringFactory} from "../../../engine/config/display-vector";
import {displayOptions} from "../../display-options";
import {ListSpecType} from "../../../engine/shared/entity-view/list-spec";
import {SPECTER_SPEC} from "../../specter-spec";
import {getCore} from "../../../engine/shared/entity-view/get-core";

export class AttachmentDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespAttachment = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(displayOptions);
    let heading;

    const attributes = []


    const mediaCore = getCore(SPECTER_SPEC, activeUserService, listEntry.entityCore.httpClient, 'MediaCore');
    mediaCore.soGet = SOGetMedia.construct({
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, mediaCore)
    mediaCore.title = heading ? heading : mediaCore.title
    mediaCore.listTitle = 'Media'
    mediaCore.listSpecType = ListSpecType.thumbList

    if (entity.media) {
        displayStringFactory.reconfigure(entity.media, entityViewSpec.entityCore.type)
    }
    mediaCore.configureForDisplay(entityViewSpec)

    const mediaDetail = new DetailEntry(
      heading ? heading : mediaCore.listTitle,
      entity.media ? displayStringFactory.primaryString : null,
      'media',
      BaseDetailType.pushedDetail,
      mediaCore,
      EditableType.never
    )
    if (entity.media) {
      mediaDetail.cachedListEntry = mediaCore.makeListEntry(entityViewSpec, mediaCore, entity.media, 0)
    }
    mediaDetail.singleFieldSelect = true
    attributes.push(mediaDetail);

    const itemCore = getCore(SPECTER_SPEC, activeUserService, listEntry.entityCore.httpClient, 'ItemCore');
    itemCore.soGet = SOGetItem.construct({
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, itemCore)
    itemCore.title = heading ? heading : itemCore.title
    itemCore.listTitle = 'Item'

    if (entity.item) {
        displayStringFactory.reconfigure(entity.item, entityViewSpec.entityCore.type)
    }
    itemCore.configureForDisplay(entityViewSpec)

    const itemDetail = new DetailEntry(
      heading ? heading : itemCore.listTitle,
      entity.item ? displayStringFactory.primaryString : null,
      'item',
      BaseDetailType.pushedDetail,
      itemCore,
      EditableType.never
    )
    if (entity.item) {
      itemDetail.cachedListEntry = itemCore.makeListEntry(entityViewSpec, itemCore, entity.item, 0)
    }
    itemDetail.singleFieldSelect = true
    attributes.push(itemDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}