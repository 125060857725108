import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';

import { SOPostPickup } from './so-post-pickup';
import { SOGetPickup } from './so-get-pickup';
import { SOPutPickup } from './so-put-pickup';
import { SODeletePickup } from './so-delete-pickup';
import {SORespPickup} from './so-resp-pickup';
import {SOPluralPickup} from './so-plural-pickup';

import {MMEntityService} from '../../../engine/multiverse/mm-entity-service';
import {EntityCoreProtocol} from '../../../engine/shared/entity-view/entity-core-protocol';
import {MMEntityServiceBase} from '../../../engine/multiverse/mm-entity-service-base';
import {HttpHeaders} from "@angular/common/http";


export class EOPickupService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'pickup',
      entityCore
    );
  }

  /** GET Pickup by id. Will 404 if id not found */
  get(request: SOGetPickup): Observable<SOPluralPickup> {
    return this.httpClient.get<SOPluralPickup>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralPickup, resp) }),
      tap(_ => this.log(`fetched Pickup`)),
      catchError(this.handleError<SOPluralPickup>(`get Pickup`))
    );
  }

  /** POST: add a new Pickup to the server */
  post(request: SOPostPickup): Observable<SORespPickup> {
    return this.httpClient.post<SORespPickup>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespPickup, resp) }),
      tap((response: SORespPickup) => this.log(`added Pickup w/ id=${response.uid}`)),
      catchError(this.handleError<SORespPickup>('Pickup post'))
    );
  }

  /** PUT: update Pickup  */
  put(request: SOPutPickup): Observable<SORespPickup> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespPickup>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespPickup, resp) }),
      tap((response: SORespPickup) => this.log(`edited Pickup w/ uid=${uid}`)),
      catchError(this.handleError<SORespPickup>('Pickup put'))
    );
  }

  /** DELETE: delete the Pickup from the server */
  delete(request: SODeletePickup): Observable<SORespPickup> {
    return this.httpClient.delete<SORespPickup>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted Pickup uid=${request.uid}`)),
      catchError(this.handleError<SORespPickup>('delete Pickup'))
    );
  }
}
