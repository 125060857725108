import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';

import { SOPostView } from './so-post-view';
import { SOGetView } from './so-get-view';
import { SOPutView } from './so-put-view';
import { SODeleteView } from './so-delete-view';
import {SORespView} from './so-resp-view';
import {SOPluralView} from './so-plural-view';

import {MMEntityService} from '../../../engine/multiverse/mm-entity-service';
import {EntityCoreProtocol} from '../../../engine/shared/entity-view/entity-core-protocol';
import {MMEntityServiceBase} from '../../../engine/multiverse/mm-entity-service-base';
import {HttpHeaders} from "@angular/common/http";


export class EOViewService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'view',
      entityCore
    );
  }

  /** GET View by id. Will 404 if id not found */
  get(request: SOGetView): Observable<SOPluralView> {
    return this.httpClient.get<SOPluralView>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralView, resp) }),
      tap(_ => this.log(`fetched View`)),
      catchError(this.handleError<SOPluralView>(`get View`))
    );
  }

  /** POST: add a new View to the server */
  post(request: SOPostView): Observable<SORespView> {
    return this.httpClient.post<SORespView>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespView, resp) }),
      tap((response: SORespView) => this.log(`added View w/ id=${response.uid}`)),
      catchError(this.handleError<SORespView>('View post'))
    );
  }

  /** PUT: update View  */
  put(request: SOPutView): Observable<SORespView> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespView>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespView, resp) }),
      tap((response: SORespView) => this.log(`edited View w/ uid=${uid}`)),
      catchError(this.handleError<SORespView>('View put'))
    );
  }

  /** DELETE: delete the View from the server */
  delete(request: SODeleteView): Observable<SORespView> {
    return this.httpClient.delete<SORespView>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted View uid=${request.uid}`)),
      catchError(this.handleError<SORespView>('delete View'))
    );
  }
}
