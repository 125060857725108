export enum MediaDef {
  'px200' = 'px200',
  'px400' = 'px400',
  'px800' = 'px800',
  'pxOrig' = 'pxOrig',
}


export class SOMediaSpec {
  definition: MediaDef
  url: string
}

export const MEDIA_DEF_DISPLAY_NAME = {
  px200: '200 px',
  px400: '400 px',
  px800: '800 px',
  pxOrig: 'Original'
}

