import {SOPutSection} from '../../core/section/so-put-section';
import {SectionCore} from './section.core';
import {SOGetSection} from '../../core/section/so-get-section';
import {FormField, SelectionOption} from '../../../engine/shared/form-field';
import {putSectionFormFieldOptions} from './put-section-form-fields';
import {AdapterProtocol} from '../../../engine/shared/entity-construction/adapter-protocol';
import {BasePut} from '../../../engine/shared/entity-construction/base-put';

export class RAPutSection extends BasePut implements AdapterProtocol {
  formFieldOptions = Object.values(putSectionFormFieldOptions);

  RequestClass = SOPutSection

  constructor(
    public entityCore: SectionCore
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.selectedEntity.uid,
      this.getFormValueFromIdentifier('title'),
      this.getFormValueFromIdentifier('number'),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}