import {SOPostLocation} from '../../core/location/so-post-location';
import {LocationCore} from './location.core';
import {SOGetLocation} from '../../core/location/so-get-location';
import {FormField, SelectionOption} from '../../../engine/shared/form-field';
import {postLocationFormFieldOptions} from './post-location-form-fields';
import {AdapterProtocol} from '../../../engine/shared/entity-construction/adapter-protocol';
import {BasePost} from '../../../engine/shared/entity-construction/base-post';
import {OrganizationCore} from '../bv-organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';

export class RAPostLocation extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postLocationFormFieldOptions);

  RequestClass = SOPostLocation

  constructor(
    public entityCore: LocationCore
  ) {
    super();
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('name'),
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
      this.getFormValueFromIdentifier('description'),
    )
  }

  prepareRequestWithQuickCreate(value) {
    this.prepareRequest();
    this.request.name = value
  }

  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}