import {DetailEntry} from '../detail-entry';
import {EntityCoreProtocol} from './entity-core-protocol';
import {SvNavigatorComponent} from '../../views/structure/sv-navigator/sv-navigator.component';
import {EntityViewSpecType} from './entity-view-spec-type';
import {EntityViewSpec} from './entity-view-spec';
import {EntityViewSpecProtocol} from './entity-view-spec-protocol';
import {PostViewSpecType} from "../post-view-spec-type";
import {SPECTER_SPEC} from "../../../app/specter-spec";

export class PostSpec extends EntityViewSpec implements EntityViewSpecProtocol {

  type = EntityViewSpecType.post
  subType = PostViewSpecType.form

  detailEntry: DetailEntry;
  showHeader = true;
  compact = false;
  chipListAdd = false;
  embedded = false;
  horizontalForm = false;
  narrow = false;
  showSubmitButton = true;

  get index() {
    return this.navigator.entityViewSpecs.findIndex(obj => obj === this);
  }

  constructor(
    private entityCoreInput: EntityCoreProtocol,
    private parentEntityViewSpecInput: EntityViewSpecProtocol,
    public navigator: SvNavigatorComponent,
    public showClose = true,
  ) {
    super();
    this._entityCore = entityCoreInput
    this.parentEntityViewSpec = parentEntityViewSpecInput
    this.childrenEntityViewSpecs.push(parentEntityViewSpecInput);
    if (SPECTER_SPEC.mediaEntityTypes.includes(entityCoreInput.type)) {
      this.subType = PostViewSpecType.media;
    }
  }
}
