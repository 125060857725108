import {Component, Input} from '@angular/core';
import {FormViewBase} from "../../../shared/form-view-base";
import {HeaderProtocol, MenuItem} from "../../../protocols/header-protocol";
import {FormViewProtocol} from "../../../protocols/form-view-protocol";
import {Button, ButtonPressProtocol, HeaderSpec} from "../../../shared/header-spec";
import {EntityAttributesForDisplay} from "../../../shared/entity-attributes-for-display";
import {SvEntityViewComponent} from "../sv-entity-view/sv-entity-view.component";
import {EntityViewSpecProtocol} from "../../../shared/entity-view/entity-view-spec-protocol";
import {ActiveUserService} from "../../../shared/active-user/active-user.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {HttpClient} from "@angular/common/http";
import {ExcelService} from "../../../shared/excel-service";
import {ListSpec} from "../../../shared/entity-view/list-spec";
import {ListEntry} from "../../../shared/list-entry";
import {FormFieldType} from '../../../shared/form-field';
import {BaseDetailType} from '../../../shared/base-detail';
import {DetailEntry, EditableType} from '../../../shared/detail-entry';
import {DeleteCompleteProtocol} from "../../../protocols/delete-complete-protocol";
import {AttributeViewSpec} from "../../../shared/attribute-view-spec";
import {MessagePromptProtocol} from "../../../protocols/message-prompt-protocol";
import {AttributesLayout} from "../../../shared/attributes-layout";
import {EntityViewSpecType} from "../../../shared/entity-view/entity-view-spec-type";

@Component({
  selector: 'app-sv-entity-attributes-view',
  templateUrl: './sv-entity-attributes-view.component.html',
  styleUrls: ['./sv-entity-attributes-view.component.scss']
})
export class SvEntityAttributesViewComponent extends FormViewBase implements HeaderProtocol, FormViewProtocol, ButtonPressProtocol, DeleteCompleteProtocol, MessagePromptProtocol {

  BaseDetailType = BaseDetailType;
  EditableType = EditableType;
  FormFieldType = FormFieldType;

  loading = false;
  count = 0;
  editing: boolean;
  headerSpec: HeaderSpec = new HeaderSpec();
  detailBody = new EntityAttributesForDisplay();
  entityAttributeViewSpecs: AttributeViewSpec[] = []
  parentEntity;
  showSecondaryDetails = false;
  closeDetail = false;
  private initialized = false;
  private hasEditables = false;
  private hasDisabledEditables = false;

  AttributesLayout = AttributesLayout;
  attributesLayout: AttributesLayout = AttributesLayout.vertical;

  @Input()
  parentEntityViewComponent: SvEntityViewComponent;

  @Input()
  set entityViewSpec(entityViewSpec: EntityViewSpecProtocol) {
    if (entityViewSpec && !this.initialized) {
      console.log('initializing attributes view')
      console.log(entityViewSpec)
      this.initialize(entityViewSpec)
    }
  }

  private _entityViewSpec: EntityViewSpecProtocol;
  get entityViewSpec(): EntityViewSpecProtocol {
    return this._entityViewSpec;
  }

  private _hasChanges = false
  attributeViewSpec: any;

  get hasChanges() {
    return this._hasChanges
  };

  set hasChanges(value: boolean) {
    this._hasChanges = value
    this.headerSpec.saveButton.disabled = !value;
  }

  get formInitialized() {
    return this.entityViewSpec.formInitialized
  }

  set formInitialized(value: boolean) {
    this.entityViewSpec.formInitialized = value
  }

  get showTwoColumns(): boolean {
    if (this.activeUserService.isHandset) {
      return false;
    } else if (this._entityViewSpec.embedded) {
      return false;
    } else {
      return this._entityViewSpec.listEntry.entityCore.showTwoColumns;
    }
  }

  get parentPanelEntityViewSpec(): EntityViewSpecProtocol {
    return this._entityViewSpec.parentPanelEntityViewSpec ? this._entityViewSpec.parentPanelEntityViewSpec : this._entityViewSpec;
  }

  get selectedEntity() {
    return this._entityViewSpec.listEntry.entity;
  }

  get putHelper() {
    return this._entityViewSpec.entityCore.putHelper;
  }

  get deleteHelper() {
    return this._entityViewSpec.entityCore.deleteHelper;
  }

  get isPost() {
    return this.entityViewSpec && this.entityViewSpec.type === EntityViewSpecType.post
  }

  constructor(
    public activeUserService: ActiveUserService,
    private snackBar: MatSnackBar,
    public httpClient: HttpClient,
    private excelService: ExcelService
  ) {
    super()
  }

  private initialize(entityViewSpec: EntityViewSpecProtocol) {
    this.initialized = true;
    this._entityViewSpec = entityViewSpec;
    this._navigator = entityViewSpec.navigator
    this.headerSpec = entityViewSpec.entityCore.makeHeaderSpecForEntityAttributesView(entityViewSpec);
    this.headerSpec.editButton.buttonPressHandler = this;
    this.headerSpec.saveButton.buttonPressHandler = this;

    if (entityViewSpec.entityCore.attributesLayout) {
      this.attributesLayout = entityViewSpec.entityCore.attributesLayout;
    }

    this.detailBody = entityViewSpec.entityCore.makeEntityAttributesForDisplay(entityViewSpec, entityViewSpec.listEntry);
    if (this.putHelper) {
      this.putHelper.delegate = this;
      this.parentEntity = this.putHelper.parentEntity;
      entityViewSpec.entityCore.setEditingEnabled(entityViewSpec, this);
      this.putHelper.patchFormOverride();
      this.hasChanges = entityViewSpec.hasMods;
    }
    if (this.deleteHelper) {
      this.deleteHelper.delegate = this;
    }
    for (const detail of this.detailBody.attributes) {
      this.entityAttributeViewSpecs.push(
        new AttributeViewSpec(detail, this.putHelper.form, this)
      )
    }
    entityViewSpec.childrenEntityAttributeViewSpecs = this.entityAttributeViewSpecs;
    entityViewSpec.entityCore.finalOverrides(entityViewSpec, this)
  }

  submitSearchForm(query) {
  }

  searchFieldKeyup() {
  }

  clickDeleteButton() {
    console.log('click delete button in attributes view');
    this.loading = true;
    this.deleteHelper.delete(this.selectedEntity);
  }

  clickSaveButton() {
    console.log('click save button in detail');
    this.loading = true;
    this.putHelper.submitForm();
  }

  clickSubmitFormButton() {
    this.clickSaveButton();
  }

  startPost(listSpec: ListSpec) {
    this.parentEntityViewComponent.startPost(listSpec);
  }

  showHistory(entityViewSpec: EntityViewSpecProtocol) {
    this.parentEntityViewComponent.showHistory(entityViewSpec);
  }

  clickHeading(key) {
  }

  closeDetailView(newListItem) {
    this.entityViewSpec.entityCore.putHelper.unregisterDelegate();
    this.parentEntityViewComponent.closeDetailView(newListItem);
    this._entityViewSpec.listEntry.entityCore.selectedEntity = null;
  }

  menuClick(item: MenuItem): void {
  }

  clickSearchIcon(data) {

  }

  onSelectionChange(detail: DetailEntry, data: any) {
    console.log('on selection change');
    console.log(detail);
    if (
      detail.detailType
      && (
        detail.detailType === BaseDetailType.submitButton
        || detail.detailType === BaseDetailType.fetchField
      )
    ) {
      this.loading = true;
    }

    if (detail.hasChanges) {
      this.hasChanges = true;
      this.putHelper.onSelectionChange(detail.editableFormField, data);
    } else {
      this.hasChanges = false;
    }
  }

  selectListEntry(listEntry: ListEntry, sourceListSpec: EntityViewSpecProtocol): void {
    this.parentEntityViewComponent.selectListEntry(listEntry, sourceListSpec);
  }

  clickPushList(detailEntry: DetailEntry) {
    this.parentEntityViewComponent.clickPushList(detailEntry, this._entityViewSpec);
  }

  clickPushDetail(detailEntry: DetailEntry) {
    console.log(detailEntry)
    this.parentEntityViewComponent.clickPushDetail(detailEntry, this._entityViewSpec);
  }

  checkIfEditEnabledForDetail(detail: DetailEntry) {
    if (!this.activeUserService.canEdit(this._entityViewSpec.entityCore, detail)) {
      return false
    } if (detail.editableType === EditableType.always) {
      return true
    } else if (detail.editableType === EditableType.never) {
      return false
    } else if (detail.editableType === EditableType.localEnabled && this.editing) {
      return true
    } else {
      return false
    }
  }

  cancel() {
  }

  submitComplete(listEntry, showNew) {
    this.loading = false;
    console.log('submit complete, about to update entity');
    this.hasChanges = false;

    this.snackBar.open('Save complete');

    if (listEntry.entity.checked === null || listEntry.entity.checked === undefined) {
      console.log('about to update entityViewSpec');
      console.log(this._entityViewSpec.parentEntityViewSpec);
      this._entityViewSpec.parentEntityViewSpec.updateEntryInList(this._entityViewSpec.listEntry, listEntry);
      this._entityViewSpec.listEntry = listEntry;
      this._entityViewSpec.hasMods = false;
      this.entityViewSpec = this._entityViewSpec;
    } else {
      // this._entityViewSpec.parentEntityViewSpec.updateEntryInList(this._entityViewSpec.listEntry, listEntry);
    }

    // this.closeDetailView(null)
    if (listEntry.snackBarText) {
      this.snackBar.open(listEntry.snackBarText);
    }

    for (const attribute of this.entityAttributeViewSpecs) {
      if (attribute.detailEntry.entityCore) {
        attribute.detailEntry.entityCore.handleParentChange(this.entityViewSpec, listEntry)
      }
    }

    if (this.closeDetail) {
      this.closeDetailView(null)
      this.navigator.editEnabled = false;
    }
  }

  submitError(message): void {
    this.loading = false;
    this.snackBar.open(`ERROR - ${message}`);
  }

  removeChild(listEntry: ListEntry): void {
  }

  deleteComplete() {
    this.snackBar.open('success');
    this.parentEntityViewComponent.removeChild(this._entityViewSpec.listEntry, this._entityViewSpec);
  }

  exportToExcel() {
  }

  startTask(entityViewSpec: EntityViewSpecProtocol, identifier): void {
    console.log('starting task in sv-attributes');
    this.parentEntityViewComponent.startTask(entityViewSpec, identifier);
  }

  taskComplete(core, parentEntityViewSpec, listEntry) {

  }

  showMessage(message: string) {

  }

  exportDetailToExcel(detail: DetailEntry) {

    this.excelService.exportCoreAsExcelFile(detail.entityCore);

  }

  clickLaunchButton(launchButtonOne: any): void {

    if (launchButtonOne.detailEntry.detailType === BaseDetailType.pushedList) {
      this.parentEntityViewComponent.clickPushList(launchButtonOne.detailEntry, this._entityViewSpec);
    } else {
      this.parentEntityViewComponent.clickPushDetail(launchButtonOne.detailEntry, this._entityViewSpec);
    }
  }

  private toggleEditEnabled() {

    if (this.editing) {
      this.disableEditing();
    } else {
      this.enableEditing();
    }
  }

  buttonPress(button: Button): void {
    if (button.identifier === 'edit') {
      this.toggleEditEnabled();
    } else if (button.identifier === 'save') {
      this.clickSaveButton();
    }
  }

  enableEditing() {
    this.navigator.editEnabled = true;
    this.editing = true;
    this.headerSpec.saveButton.show = true;
    this.setFieldEditing();
  }

  disableEditing() {
    this.navigator.editEnabled = false;
    this.editing = false;
    this.setFieldEditing()
    this.headerSpec.saveButton.show = this.hasEditables;
    this.headerSpec.editButton.show = this.hasDisabledEditables;
  }

  private setFieldEditing() {
    for (const detail of this.detailBody.attributes) {
      detail.editEnabled = this.checkIfEditEnabledForDetail(detail)
      if (
        detail.editEnabled
        && detail.editableFormField
      ) {
        this.hasEditables = true;
        let value = this.selectedEntity[detail.editableFormField.identifierString]
        if (value && value.uid) {
          value = value.uid
        }
        console.log(`patching form value - ${detail.identifier} to ${value}`)
        if (this.selectedEntity && value) {
          this.putHelper.form.patchValue(
            {[detail.editableFormField.identifierString]: value}
          );
        }
      }
      if (
        detail.editableType === EditableType.localEnabled
      ) {
        this.hasDisabledEditables = true;
      }
    }
  }

  promptMessage(message: string, duration: number) {
    this.snackBar.open(message);
  }

  showDetails() {
    this.showSecondaryDetails = !this.showSecondaryDetails;
  };
}
