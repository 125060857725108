import { SOGetOrganization } from '../organization/so-get-organization';


export class SOPostCustomField {

  constructor(
    public name: string,
    public organization: SOGetOrganization,
    public autoApply: boolean = false,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostCustomField(
      'name' in params ? params.name : null,
      'autoApply' in params ? params.autoApply : null,
      'organization' in params ? params.organization : null,
    );
  }
}
