<form [formGroup]="form" *ngIf="detail" class="attribute">

  <app-sv-text-attribute-view *ngIf="getShowView(AttributeViewType.text)"
                              [attributeViewSpec]="attributeViewSpec"></app-sv-text-attribute-view>
  <app-sv-selection-attribute-view *ngIf="getShowView(AttributeViewType.selection)"
                                   [attributeViewSpec]="attributeViewSpec"></app-sv-selection-attribute-view>
  <app-sv-date-attribute-view *ngIf="getShowView(AttributeViewType.date)"
                              [attributeViewSpec]="attributeViewSpec"></app-sv-date-attribute-view>
  <app-sv-datetime-attribute-view *ngIf="getShowView(AttributeViewType.datetime)"
    [attributeViewSpec]="attributeViewSpec"></app-sv-datetime-attribute-view>
  <app-sv-textarea-attribute-view *ngIf="getShowView(AttributeViewType.textarea)"
                                  [attributeViewSpec]="attributeViewSpec"></app-sv-textarea-attribute-view>
  <app-sv-link-attribute-view *ngIf="getShowView(AttributeViewType.link)"
                              [attributeViewSpec]="attributeViewSpec"></app-sv-link-attribute-view>
  <app-sv-file-attribute-view *ngIf="getShowView(AttributeViewType.file)"
                              [attributeViewSpec]="attributeViewSpec"></app-sv-file-attribute-view>
  <app-sv-checkbox-attribute-view *ngIf="getShowView(AttributeViewType.checkbox)"
                                  [attributeViewSpec]="attributeViewSpec"></app-sv-checkbox-attribute-view>
  <app-sv-toggle-attribute-view *ngIf="getShowView(AttributeViewType.toggle)"
                                  [attributeViewSpec]="attributeViewSpec"></app-sv-toggle-attribute-view>
  <app-sv-markdown-attribute-view *ngIf="getShowView(AttributeViewType.markdown)"
                                  [attributeViewSpec]="attributeViewSpec"></app-sv-markdown-attribute-view>
  <app-sv-signature-attribute-view *ngIf="getShowView(AttributeViewType.signature)"
                                   [attributeViewSpec]="attributeViewSpec"></app-sv-signature-attribute-view>
  <app-sv-pushed-entity-list-attribute-view *ngIf="getShowView(AttributeViewType.pushedEntityList)"
                                            [attributeViewSpec]="attributeViewSpec"></app-sv-pushed-entity-list-attribute-view>
  <app-sv-pushed-entity-attributes-attribute-view *ngIf="getShowView(AttributeViewType.pushedEntity)"
                                                  [attributeViewSpec]="attributeViewSpec"></app-sv-pushed-entity-attributes-attribute-view>
  <app-sv-embedded-entity-attributes-attribute-view *ngIf="getShowView(AttributeViewType.embeddedEntity)"
                                   [attributeViewSpec]="attributeViewSpec"></app-sv-embedded-entity-attributes-attribute-view>
  <app-sv-embedded-entity-list-attribute-view *ngIf="getShowView(AttributeViewType.embeddedList)"
                                                    [attributeViewSpec]="attributeViewSpec"></app-sv-embedded-entity-list-attribute-view>
  <app-sv-toggle-group-attribute-view *ngIf="getShowView(AttributeViewType.toggleGroup)"
                                              [attributeViewSpec]="attributeViewSpec"></app-sv-toggle-group-attribute-view>


  <h2 class="section-header" *ngIf="detail.detailType == BaseDetailType.header">{{detail.name}}</h2>

  <h2 class="warning" *ngIf="detail.detailType == BaseDetailType.warning">{{detail.name}}</h2>
  <button mat-stroked-button
          *ngIf="(detail.detailType == BaseDetailType.button || (detail.detailType == BaseDetailType.submitButton && detail.editEnabled))"

          (click)="detail.button.buttonPressHandler.buttonPress(detail.button)">{{detail.name}}
  </button>

  <div *ngIf="detail.detailType == BaseDetailType.fetchField" class="fetch-field" fxLayout="row"
       fxLayoutAlign="space-between">
    <h3>{{detail.name}}: {{detail.value}}</h3>
    <button mat-stroked-button
            *ngIf="detail.editEnabled"
            (click)="parent.onSelectionChange(detail, null)">SET
    </button>
  </div>

<!--  <button mat-stroked-button *ngIf="detail.detailType == BaseDetailType.excelExport"-->
<!--          (click)="parent.exportDetailToExcel(detail)">-->
<!--    {{detail.name}}-->
<!--  </button>-->

</form>
