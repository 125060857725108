import {Component, Input, OnInit} from '@angular/core';
import {ActiveUserService} from '../../../shared/active-user/active-user.service';
import {ListEntry} from '../../../shared/list-entry';
import {HeaderProtocol, LaunchButton, MenuItem, MenuItemType} from '../../../protocols/header-protocol';
import {ExcelService} from '../../../shared/excel-service';
import {BaseDetailType} from '../../../shared/base-detail';
import {SvEntityViewComponent} from '../sv-entity-view/sv-entity-view.component';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {EntityViewSpecProtocol} from '../../../shared/entity-view/entity-view-spec-protocol';
import {ListSpec} from '../../../shared/entity-view/list-spec';
import {HeaderSpec} from "../../../shared/header-spec";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ListSpecType} from "../../../shared/entity-view/list-spec";
import {DeleteCompleteProtocol} from "../../../protocols/delete-complete-protocol";
import {MessagePromptProtocol} from "../../../protocols/message-prompt-protocol";
import {getHeading} from "../../../shared/display-option-utilities";


@Component({
  selector: 'app-sv-list',
  templateUrl: './sv-list.component.html',
  styleUrls: ['./sv-list.component.css']
})
export class SvListComponent implements OnInit, HeaderProtocol, DeleteCompleteProtocol, MessagePromptProtocol {

  ListSpecType = ListSpecType

  pulledList = false;
  headerSpec: HeaderSpec = new HeaderSpec();
  hasMore = false;
  loading = false;
  initialized = false;
  deletingEntry: ListEntry
  parentEntity

  @Input()
  parentEntityViewComponent: SvEntityViewComponent;

  @Input()
  set entityViewSpec(entityViewSpec: ListSpec) {
    if (!this.initialized && entityViewSpec) {
      this.initialize(entityViewSpec);
    }
  }

  get entityViewSpec(): ListSpec {
    return this._entityViewSpec;
  }

  _entityViewSpec: ListSpec;

  get showHeader() {
    return this.entityViewSpec && ![ListSpecType.chipList].includes(this.entityViewSpec.subType)
  }

  get detailEntry() {
    return this.entityViewSpec.detailEntry;
  }

  get navigator() {
    return this.parentEntityViewComponent.parentRouteView;
  }

  constructor(
    public activeUserService: ActiveUserService,
    public excelService: ExcelService,
    public router: Router,
    public httpsClient: HttpClient,
    public snackBar: MatSnackBar
  ) {
  }

  initialize(entityViewSpec: ListSpec) {
    console.log(`setting list view spec ${entityViewSpec.entityCore.type}`)
    console.log(entityViewSpec)
    this.initialized = true;
    entityViewSpec.fromListFetched = false;
    this._entityViewSpec = entityViewSpec;
    entityViewSpec.entityCore.delegate = this;
    if (entityViewSpec && !this.pulledList) {
      this.pulledList = true;
      entityViewSpec.entityCore.fetchList(entityViewSpec);
      this.initHeaderSpec(entityViewSpec);
    }
  }

  private initHeaderSpec(entityViewSpec: ListSpec) {


    this.headerSpec = entityViewSpec.entityCore.listHeaderSpec;
    if (this.headerSpec) {
      this.headerSpec.showCloseButton = entityViewSpec.showClose;
      this.headerSpec.showBack = entityViewSpec.index > 0;
      this.headerSpec.searchEnabled = !this.entityViewSpec.parentPanelEntityViewSpec ? entityViewSpec.entityCore.searchEnabled : false;
      if (entityViewSpec.entityCore.lastQuery && entityViewSpec.entityCore.lastQuery !== '') {
        this.headerSpec.searchForm.patchValue({
          search: entityViewSpec.entityCore.lastQuery
        })
      }
    }
  }

  submitSearchForm(query) {
    this.loading = true;
    this.entityViewSpec.entityCore.search(query, this.entityViewSpec);

  }

  searchFieldKeyup() {
  }

  border(listEntry: ListEntry) {
    if (listEntry.borderColor) {
      return listEntry.borderColor === 'green' ? `green-border` : `red-border`;
    } else {
      return null;
    }
  }

  clickSearchIcon(data) {
  }

  clickDeleteButton() {

  }

  clickSaveButton() {

  }

  listFetched(entities, entityViewSpec, hasMore) {
    this.loading = false;
    this.hasMore = hasMore;
    console.log(`list fetched in ${this.entityViewSpec.entityCore.type}`);
    entityViewSpec.listBody.resetListBodyFromEntities(entities, entityViewSpec);
    console.log(`About to reset list spec after list fetched ${entityViewSpec.entityCore.type}`)
    console.log(entityViewSpec.listBody.sections[0].listEntries)
    entityViewSpec.fromListFetched = true;
    this.initialize(entityViewSpec);
  }

  moreFetched(newEntities, hasMore) {
    this.loading = false;
    this.hasMore = hasMore;
    this.entityViewSpec.listBody.addEntitiesToListBody(this.entityViewSpec, newEntities);
  }

  startPost(listSpec: ListSpec) {
    // NOTE: using this.listSpec because, the listSpec input can be null, but I always want the listSpec for this list
    this.parentEntityViewComponent.startPost(this.entityViewSpec);
  }

  startTask(entityViewSpec: EntityViewSpecProtocol, identifier): void {
    this.loading = true;
    console.log('starting task in sv-List');
    console.log(this.parentEntityViewComponent);
    this.parentEntityViewComponent.startTask(this.entityViewSpec, identifier);
  }

  taskComplete(core, parentEntityViewSpec, listEntry) {
    this.loading = false;
  }

  showMessage(message: string) {
    this.snackBar.open(message)
  }

  showHistory(listSpec: ListSpec) {
    // NOTE: using this.listSpec because, the listSpec input can be null, but I always want the listSpec for this list
    this.parentEntityViewComponent.showHistory(this.entityViewSpec);
  }

  closeDetailView(appendEntry: ListEntry) {
    this.parentEntityViewComponent.closeDetailView(appendEntry);
  }

  ngOnInit(): void {

  }

  selectListEntry(listEntry: ListEntry) {
    console.log('selected list entry');
    if (!listEntry.disabled && this.entityViewSpec.selectionList) {
      this.navigator.selectionFromPushedList(listEntry)
    } else if (!listEntry.disabled) {
      listEntry.entityCore.selectedEntity = listEntry.entity;
      this.parentEntityViewComponent.selectListEntry(listEntry, this.entityViewSpec);
    }
  }

  removeChild(listEntry: ListEntry): void {
    console.log('removing entry');
    this.entityViewSpec.listBody.splice(listEntry);
    // this.listBody.splice(listEntry)
  }

  clickButtonOne() {
  }

  menuClick(item: MenuItem): void {
    console.log('menu click');
    if (item.menuItemType === MenuItemType.excel) {
      this.exportToExcel();
    } else {
      this.entityViewSpec.entityCore.menuClick(item, this.entityViewSpec);
    }
  }


  deselectEntity() {
  }

  clickHeading(key) {
  }

  exportToExcel() {
    console.log('exporting to excel');
    console.log(this.entityViewSpec);
    this.excelService.exportCoreAsExcelFile(this.entityViewSpec.entityCore);
  }

  fetchMore() {
    this.entityViewSpec.entityCore.fetchMore();
  }

  clickLaunchButton(launchButtonOne: LaunchButton): void {

    if (launchButtonOne.detailEntry.detailType === BaseDetailType.pushedList) {
      this.parentEntityViewComponent.clickPushList(launchButtonOne.detailEntry, this.entityViewSpec);
    } else {
      this.parentEntityViewComponent.clickPushDetail(launchButtonOne.detailEntry, this.entityViewSpec);
    }
  }

  remove(listEntry: ListEntry) {
    this.entityViewSpec.entityCore.deleteHelper.delegate = this
    this.deletingEntry = listEntry
    this.loading = true;
    this.entityViewSpec.entityCore.startRemoveListEntry(this.entityViewSpec, listEntry, this)
  }

  deleteComplete() {
    console.log('delete complete')
    this.loading = false;
    let dex = this.entityViewSpec.entityCore.entities.findIndex(obj => obj.uid === this.deletingEntry.entity.uid)
    this.entityViewSpec.entityCore.entities.splice(dex, 1)
    this.removeChild(this.deletingEntry)
    this.deletingEntry = null
  }

  submitError(message): void {
    this.loading = false;
    this.snackBar.open(`ERROR - ${message}`);
  }

  promptMessage(message: string, duration: number) {
    this.snackBar.open(message);
  }

  showDetails() {

  };
}
