import {Injectable} from "@angular/core";
import {InnerSidenavProtocol} from "../protocols/inner-sidenav-protocol";
import {ActiveUserService} from "./active-user/active-user.service";
import {HttpClient} from "@angular/common/http";
import {SOGetUser} from "../../app/core/user/so-get-user";
import {SvSidenavComponent} from "../views/structure/sv-sidenav/sv-sidenav.component";
import {SideNavItem} from "./side-nav-item";
import {RoleGrantCore} from "../../app/entities/bv-role-grant/role-grant.core";
import {SOPostToken} from "../../app/core/token/so-post-token";
import {SOGetRoleGrant} from "../../app/core/role-grant/so-get-role-grant";
import {TokenCore} from "../../app/entities/bv-token/token.core";

@Injectable({
  providedIn: 'root'
})
export class InnerSidenavHandler implements InnerSidenavProtocol {

  roleGrantCore: RoleGrantCore;
  delegate: SvSidenavComponent;

  tokenCore: TokenCore;

  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient
  ) {
    this.roleGrantCore = new RoleGrantCore(activeUserService, httpClient)
    this.tokenCore = new TokenCore(activeUserService, httpClient)
  }

  startOpen() {
    this.delegate.innerNavLoading = true
    this.roleGrantCore.soGet.user = new SOGetUser(this.activeUserService.user.uid)
    this.roleGrantCore.entityService.get(this.roleGrantCore.soGet).subscribe(resp => {
      if (resp) {
        const items = []
        for (const grant of resp.objects) {
          items.push(
            new SideNavItem(
              grant.organization.name,
              grant,
              grant.role.name,
            )
          )
        }
        this.delegate.setInnerItems(items);
      }
    })
  }

  navItemSelected(navItem: SideNavItem) {
    this.delegate.innerNavLoading = true
    const postToken = new SOPostToken(
      null,
      new SOGetRoleGrant(
        navItem.data.uid,
      )
    )
    this.tokenCore.entityService.post(postToken).subscribe(resp => {
      this.delegate.innerNavLoading = false
      this.activeUserService.token = resp
      // this.activeUserService.activeGrant = navItem.data
    })
  }
}
