<div class="fill-container">
  <div class="center-box-outer">
    <mat-progress-bar *ngIf="loading" class="progress-bar" mode="indeterminate" color="primary"></mat-progress-bar>
    <div *ngIf="!loading" class="progress-bar"></div>
    <div *ngIf="emailFormGroup && !success" class="center-box-inner" fxLayout="column">
      <h1>Please enter your email</h1>
      <form class="main-form" [formGroup]="emailFormGroup" fxLayout="column" (ngSubmit)="clickSubmitFormButton()">

        <mat-form-field
          appearance="standard">
          <mat-label>Email</mat-label>
          <input matInput
                 type="text"
                 id="email"
                 name="email"
                 formControlName="email"
                 (keyup)="keyup($event)">
        </mat-form-field>
        <button mat-stroked-button type="submit"
                color="accent" [disabled]="!emailFormGroup.valid">Submit</button>
      </form>
      <button mat-stroked-button class="cancel-button" (click)="cancel()" color="primary">Cancel</button>
      <h6 *ngIf="errorMessage">{{errorMessage}}</h6>
    </div>
    <div *ngIf="emailFormGroup && success" class="center-box-inner">
      <h1>Please check your email</h1>
      <button mat-stroked-button class="cancel-button" (click)="cancel()" color="primary">Back to login</button>
    </div>
    <div *ngIf="passwordFormGroup" class="center-box-inner" fxLayout="column">
      <h1>Please enter your new password</h1>
      <form class="main-form" [formGroup]="passwordFormGroup" fxLayout="column" (ngSubmit)="clickSubmitFormButton()">

        <mat-form-field
          appearance="standard">
          <mat-label>Password</mat-label>
          <input matInput
                 [type]="'password'"
                 id="password"
                 name="password"
                 formControlName="password"
                 (keyup)="keyup($event)">
        </mat-form-field>

        <mat-form-field
          appearance="standard">
          <mat-label>Confirm password</mat-label>
          <input matInput
                 [type]="'password'"
                 id="passwordTwo"
                 name="passwordTwo"
                 formControlName="passwordTwo"
                 (keyup)="keyup($event)">
        </mat-form-field>

        <button mat-stroked-button type="submit"
                color="accent" [disabled]="!passwordFormGroup.valid">Submit</button>
      </form>
      <button mat-stroked-button class="cancel-button" (click)="cancel()" color="primary">Cancel</button>
      <h6 *ngIf="errorMessage">{{errorMessage}}</h6>
    </div>
  </div>
</div>
