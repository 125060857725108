import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {MenuItem} from "../../../protocols/header-protocol";
import {SvHeaderComponent} from "../sv-header/sv-header.component";
import {ExcelService} from "../../../shared/excel-service";
import {ReadCompleteProtocol} from "../../../protocols/read-complete-protocol";

export class UploadButtonInput {
  constructor(
    public menuItem: MenuItem,
    public header: SvHeaderComponent
  ) {
  }
}


@Component({
  selector: 'app-sv-upload-button',
  templateUrl: './sv-upload-button.component.html',
  styleUrls: ['./sv-upload-button.component.scss']
})
export class SvUploadButtonComponent implements ReadCompleteProtocol {

  @Input()
  set uploadButtonInput(value: UploadButtonInput) {
    this.menuItem = value.menuItem;
    this.header = value.header;
  }

  @ViewChild('uploader')
  uploader: ElementRef;

  menuItem: MenuItem
  header: SvHeaderComponent

  constructor(
    private excelService: ExcelService
  ) {
  }

  uploadFile($event) {
    this.header.parent.loading = true;
    const data = $event.target.files[0];
    if (data.type === 'text/csv') {
      this.excelService.readCsv(data, this)
    } else {
      this.excelService.readFirstSheet(data, this);
    }
  }

  readComplete(data) {
    this.header.parent.loading = false;
    this.menuItem.process(data)
    this.uploader.nativeElement.value = ""
  }
}
