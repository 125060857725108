<form [formGroup]="form">
  <div fxLayout="row" fxLayoutAlign="start center">
    <mat-slide-toggle *ngIf="!detail.editEnabled" [checked]="checked" [disabled]="true">
    </mat-slide-toggle>
    <div *ngIf="!detail.editEnabled" style="margin-left: 10px">
      {{detail.getLabel(parentView.entityViewSpec)}}
    </div>
  </div>
  <mat-slide-toggle *ngIf="detail.editEnabled" [formControlName]="detail.editableFormField.identifierString"
                    (change)="onSelectionChange()">
    {{detail.getLabel(parentView.entityViewSpec)}}
  </mat-slide-toggle>
</form>
