import {SOPostDispatch} from '../../core/dispatch/so-post-dispatch';
import {DispatchCore} from './dispatch.core';
import {SOGetDispatch} from '../../core/dispatch/so-get-dispatch';
import {FormField, SelectionOption} from '../../../engine/shared/form-field';
import {postDispatchFormFieldOptions} from './post-dispatch-form-fields';
import {AdapterProtocol} from '../../../engine/shared/entity-construction/adapter-protocol';
import {BasePost} from '../../../engine/shared/entity-construction/base-post';
import {LineCore} from '../bv-line/line.core';
import {SOGetLine} from '../../core/line/so-get-line';
import {TemlocaCore} from '../bv-temloca/temloca.core';
import {SOGetTemloca} from '../../core/temloca/so-get-temloca';
import {PickupCore} from '../bv-pickup/pickup.core';
import {SOGetPickup} from '../../core/pickup/so-get-pickup';
import {OrganizationCore} from '../bv-organization/organization.core';
import {SOGetOrganization} from '../../core/organization/so-get-organization';

export class RAPostDispatch extends BasePost implements AdapterProtocol {
  formFieldOptions = Object.values(postDispatchFormFieldOptions);

  RequestClass = SOPostDispatch

  constructor(
    public entityCore: DispatchCore
  ) {
    super();
    if (postDispatchFormFieldOptions.line) {
      postDispatchFormFieldOptions.line.makeCore = true;
    }
    if (postDispatchFormFieldOptions.temloca) {
      postDispatchFormFieldOptions.temloca.makeCore = true;
    }
    if (postDispatchFormFieldOptions.pickup) {
      postDispatchFormFieldOptions.pickup.makeCore = true;
    }
  }

  onSet() {
    super.onSet()
  }

  prepareRequest() {
    this.request = new this.RequestClass(
      this.getFormValueFromIdentifier('quantity'),
      this.getFormValueFromIdentifier(
        'temloca',
        SOGetTemloca,
      ),
      this.getFormValueFromIdentifier(
        'organization',
        SOGetOrganization,
        true,
      ),
      this.getFormValueFromIdentifier('isAllocation'),
      this.getFormValueFromIdentifier(
        'line',
        SOGetLine,
      ),
      this.getFormValueFromIdentifier('notes'),
      this.getFormValueFromIdentifier(
        'pickup',
        SOGetPickup,
      ),
    )
  }


  onSelectionChange(field: FormField) {
    super.onSelectionChange(field)
  }

  fetchOptions(field: FormField) {
    super.fetchOptions(field)
  }

  getSelectionOption(field: FormField, entity): SelectionOption {
    return super.getSelectionOption(field, entity)
  }
}