import { Type } from 'class-transformer';
import { SORespOrganization } from '../organization/so-resp-organization';
import {SOMediaSpec} from '../../../engine/multiverse/so-media-spec';
import {ObjectiveProtocol} from '../../../engine/protocols/objective-protocol';
import {EntityType} from "../../entity-types";


export class SORespMedia implements ObjectiveProtocol {

  entityType: EntityType = EntityType.media;
  uid: string;
  @Type(() => SOMediaSpec)
  links: SOMediaSpec[];
  name: string = '';
  description: string = '';
  size: number;
  @Type(() => Date)
  dateCreated: Date;
  @Type(() => SORespOrganization)
  organization: SORespOrganization;
}