import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';

import { SOPutHello } from './so-put-hello';
import { SOGetHello } from './so-get-hello';
import { SOPostHello } from './so-post-hello';
import {SORespHello} from './so-resp-hello';
import {SOPluralHello} from './so-plural-hello';

import {MMEntityService} from '../../../engine/multiverse/mm-entity-service';
import {EntityCoreProtocol} from '../../../engine/shared/entity-view/entity-core-protocol';
import {MMEntityServiceBase} from '../../../engine/multiverse/mm-entity-service-base';
import {HttpHeaders} from "@angular/common/http";


export class EOHelloService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'hello',
      entityCore
    );
  }

  /** GET Hello by id. Will 404 if id not found */
  get(request: SOGetHello): Observable<SOPluralHello> {
    return this.httpClient.get<SOPluralHello>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralHello, resp) }),
      tap(_ => this.log(`fetched Hello`)),
      catchError(this.handleError<SOPluralHello>(`get Hello`))
    );
  }

  /** POST: add a new Hello to the server */
  post(request: SOPostHello): Observable<SORespHello> {
    return this.httpClient.post<SORespHello>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespHello, resp) }),
      tap((response: SORespHello) => this.log(`added Hello w/ id=${response.uid}`)),
      catchError(this.handleError<SORespHello>('Hello post'))
    );
  }

  /** PUT: update Hello  */
  put(request: SOPutHello): Observable<SORespHello> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespHello>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespHello, resp) }),
      tap((response: SORespHello) => this.log(`edited Hello w/ uid=${uid}`)),
      catchError(this.handleError<SORespHello>('Hello put'))
    );
  }

  delete(request: any): string {
    return 'NOT IMPLEMENTED';
  }
}
