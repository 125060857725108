

export function stdTimezoneOffset(date: Date) {
  const jan = new Date(date.getFullYear(), 0, 1);
  const jul = new Date(date.getFullYear(), 6, 1);
  return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
}

export function isDstObserved(date: Date) {
  return date.getTimezoneOffset() < stdTimezoneOffset(date);
}


export class DisplayTimeHelper {


  private adjustDateToSetTimeZone(utcDate: Date) {
    const date = new Date(utcDate);

    return date;
    // return utcDate;
  }


  niceShortNumericDateNoTime(date) {
    date = this.adjustDateToSetTimeZone(date);
    return date ? new Date(date).toLocaleString(
      'en-us', {month: 'numeric', day: 'numeric'}
    ) : '';
  }

  niceShortDateNoTime(date) {
    date = this.adjustDateToSetTimeZone(date);
    return date ? new Date(date).toLocaleString(
      'en-us', {weekday: 'short', month: 'short', day: 'numeric'}
    ) : '';
  }

  niceMonth(date) {
    date = this.adjustDateToSetTimeZone(date);
    return date ? new Date(date).toLocaleString(
      'en-us', {month: 'long'}
    ) : '';
  }

  niceMonthAndYear(date) {
    date = this.adjustDateToSetTimeZone(date);
    return date ? new Date(date).toLocaleString(
      'en-us', {year: 'numeric', month: 'long'}
    ) : '';
  }

  niceShortDayForBoard(date) {
    date = this.adjustDateToSetTimeZone(date);
    console.log('For board');
    console.log(date);
    return date ? new Date(date).toLocaleString(
      'en-us', {timeZone: 'US/Mountain' , weekday: 'short', month: 'numeric', day: 'numeric'}
    ) : '';
  }

  niceHour(date: Date) {
    return date ? date.toLocaleString(
      'en-us', {hour: 'numeric', minute: 'numeric'}
    ) : '';
  }

  niceDate(date) {
    date = this.adjustDateToSetTimeZone(date);
    return date ? new Date(date).toLocaleString(
      'en-us', {weekday: 'short', month: 'short', day: 'numeric', hour12: true, hour: '2-digit', minute: '2-digit'}
    ) : '';
  }

  niceShortDateAndTime(date) {
    date = this.adjustDateToSetTimeZone(date);
    return date ? new Date(date).toLocaleString(
      'en-us', {year: 'numeric', month: 'numeric', day: 'numeric', hour12: true, hour: '2-digit', minute: '2-digit'}
    ) : '';
  }

  niceDateYearMonthForFilename(date) {
    date = this.adjustDateToSetTimeZone(date);
    return date ? new Date(date).toLocaleString(
      'en-us', {year: 'numeric', month: 'numeric'}
    ) : '';
  }

  niceDateYearMonthDayForFilename(date) {
    date = this.adjustDateToSetTimeZone(date);
    return date ? new Date(date).toLocaleString(
      'en-us', {year: 'numeric', month: 'numeric', day: 'numeric'}
    ) : '';
  }

  niceTime(date) {
    date = this.adjustDateToSetTimeZone(date);
    return date ? new Date(date).toLocaleString(
      'en-us', {hour12: true, hour: '2-digit', minute: '2-digit'}
    ) : '';
  }

  niceVeryShortDateAndTime(date) {
    date = this.adjustDateToSetTimeZone(date);
    return date ? new Date(date).toLocaleString(
      'en-us', {month: 'numeric', day: 'numeric', hour12: true, hour: '2-digit', minute: '2-digit'}
    ) : '';
  }

  niceFullDate(date) {
    date = this.adjustDateToSetTimeZone(date);
    return date ? new Date(date).toLocaleString(
      'en-us', {year: 'numeric', month: 'short', day: 'numeric'}
    ) : '';
  }

}
