import {MMQueryOptions} from '../../../engine/multiverse/mm-query-options';
import {QueryProtocol} from "../../../engine/protocols/query-protocol";


export class SOGetOrganization implements QueryProtocol {

  constructor(
    public uid: string = null,
    public identifier: string = null,
    public exRefPayment: string = null,
    public predicate: string = null,
    public queryOptions: MMQueryOptions = new MMQueryOptions(),
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOGetOrganization(
      'uid' in params ? params.uid : null,
      'identifier' in params ? params.identifier : null,
      'exRefPayment' in params ? params.exRefPayment : null,
      'predicate' in params ? params.predicate : null,
      'queryOptions' in params ? params.queryOptions : new MMQueryOptions(),
    );
  }
}
