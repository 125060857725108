<!--<button *ngIf="!detail.editEnabled" mat-stroked-button-->
<!--        (click)="parentView.clickPushDetail(detail)" style="min-width: 150px" color="accent">-->
<!--  {{detail.name}}-->
<!--</button>-->
<app-sv-entity-view *ngIf="detail.editEnabled && !detail.value"
                    [entityViewSpec]="getAutoCompleteListSpec(detail.editableFormField)"
                    [parentRouteView]="parentView.navigator"></app-sv-entity-view>

<div fxLayout="row" fxLayoutAlign="space-between" *ngIf="!detail.editEnabled || detail.value">
  <div fxLayout="row" fxLayoutAlign="start center">
    <h3 *ngIf="!hideAttributeLabels" style="margin-bottom: 0px">{{detail.name}}:</h3>
    <button mat-stroked-button
            *ngIf="detail.value"
            (click)="parentView.clickPushDetail(detail)"
            color="accent">
      {{detail.value}}
    </button>
  </div>
  <div>
    <button mat-stroked-button
            *ngIf="detail.editEnabled && detail.removable && detail.value"
            (click)="remove()"
            color="accent">
      REMOVE
    </button>
    <button mat-stroked-button
            class="single-field-select"
            *ngIf="detail.editEnabled"
            (click)="parentView.clickPushList(detail)"
            color="accent">
      SELECT
    </button>
  </div>
</div>
