import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {classToPlain, plainToClass} from 'class-transformer';

import { SOPostItemImportLine } from './so-post-item-import-line';
import { SOGetItemImportLine } from './so-get-item-import-line';
import { SOPutItemImportLine } from './so-put-item-import-line';
import { SODeleteItemImportLine } from './so-delete-item-import-line';
import {SORespItemImportLine} from './so-resp-item-import-line';
import {SOPluralItemImportLine} from './so-plural-item-import-line';

import {MMEntityService} from '../../../engine/multiverse/mm-entity-service';
import {EntityCoreProtocol} from '../../../engine/shared/entity-view/entity-core-protocol';
import {MMEntityServiceBase} from '../../../engine/multiverse/mm-entity-service-base';
import {HttpHeaders} from "@angular/common/http";


export class EOItemImportLineService extends MMEntityServiceBase implements MMEntityService {

  constructor(
    public entityCore: EntityCoreProtocol,
  ) {
    super(
      'item_import_line',
      entityCore
    );
  }

  /** GET ItemImportLine by id. Will 404 if id not found */
  get(request: SOGetItemImportLine): Observable<SOPluralItemImportLine> {
    return this.httpClient.get<SOPluralItemImportLine>(this.makeGetUri(request), this.httpOptions).pipe(
      map(resp => { return plainToClass(SOPluralItemImportLine, resp) }),
      tap(_ => this.log(`fetched ItemImportLine`)),
      catchError(this.handleError<SOPluralItemImportLine>(`get ItemImportLine`))
    );
  }

  /** POST: add a new ItemImportLine to the server */
  post(request: SOPostItemImportLine): Observable<SORespItemImportLine> {
    return this.httpClient.post<SORespItemImportLine>(this.coreUrl + this.route, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespItemImportLine, resp) }),
      tap((response: SORespItemImportLine) => this.log(`added ItemImportLine w/ id=${response.uid}`)),
      catchError(this.handleError<SORespItemImportLine>('ItemImportLine post'))
    );
  }

  /** PUT: update ItemImportLine  */
  put(request: SOPutItemImportLine): Observable<SORespItemImportLine> {
    const uid = request.uid;
    const uri = `${this.coreUrl}${this.route}/${uid}`;
    return this.httpClient.put<SORespItemImportLine>(uri, request, this.httpOptions).pipe(
      map(resp => { return plainToClass(SORespItemImportLine, resp) }),
      tap((response: SORespItemImportLine) => this.log(`edited ItemImportLine w/ uid=${uid}`)),
      catchError(this.handleError<SORespItemImportLine>('ItemImportLine put'))
    );
  }

  /** DELETE: delete the ItemImportLine from the server */
  delete(request: SODeleteItemImportLine): Observable<SORespItemImportLine> {
    return this.httpClient.delete<SORespItemImportLine>(this.makeDeleteUri(request), this.httpOptions).pipe(
      tap(_ => this.log(`deleted ItemImportLine uid=${request.uid}`)),
      catchError(this.handleError<SORespItemImportLine>('delete ItemImportLine'))
    );
  }
}
