import {ActiveUserService} from '../../../engine/shared/active-user/active-user.service';
import {HttpClient} from '@angular/common/http';
import {EODispatchService} from '../../core/dispatch/eo-dispatch';
import {DispatchListEntryFactory} from './dispatch-list-entry-factory';
import {DispatchDetailFactory} from './dispatch-detail-factory';
import {SOGetDispatch} from '../../core/dispatch/so-get-dispatch';
import {RAPutDispatch} from './ra-put-dispatch';
import {RAPostDispatch} from './ra-post-dispatch';
import {RADeleteDispatch} from './ra-delete-dispatch';
import {AbstractEntityCore} from "../../../engine/shared/entity-view/abstract-entity-core";
import {EntityCoreProtocol} from "../../../engine/shared/entity-view/entity-core-protocol";
import {EntityType} from '../../entity-types';
import {SPECTER_SPEC} from "../../specter-spec";
import {SOGetOrganization} from '../../core/organization/so-get-organization'

export class DispatchCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = EntityType.dispatch;
  title = 'Dispatch';
  listEntryFactory = new DispatchListEntryFactory();
  detailFactory = new DispatchDetailFactory();

  entityService: EODispatchService;
  soGetClass = SOGetDispatch;
  soGet: SOGetDispatch;

  showQuickAdd = true;

  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EODispatchService
    );
    this.adapters = [
      new RAPostDispatch(this),
      new RAPutDispatch(this),
      new RADeleteDispatch(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetDispatch();
    this.soGet.organization = new SOGetOrganization(
      this.activeUserService.getScope('organization').uid
    );
    this.soGet.queryOptions.limit = this.limit;
  }

  checkCheckbox(entity, checked: boolean) {
    const request = this.putHelper.RequestClass.construct({
      'uid': entity.uid,
      'complete': checked,
    })
    this.entityService.put(request).subscribe(resp => {
      if (resp) {
        const customProcessor = SPECTER_SPEC.putEntityCompleteProcessors.find(obj => obj.entityType == this.type)
        if (customProcessor) {
          customProcessor.putEntityComplete(resp, this.delegate)
        }
      }
    })
  }


}