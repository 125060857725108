import {FormField, FormFieldType, SelectionOption} from '../../../engine/shared/form-field';
import {EntityType} from "../../entity-types";


const nameField = new FormField(
  'name',
  'Name',
  null,
  FormFieldType.text,
  true,
)
const autoApplyField = new FormField(
  'autoApply',
  'Auto apply',
  null,
  FormFieldType.boolean,
  true,
  true,
  false
)


export const postCustomFieldFormFieldOptions: {[key: string]: FormField} = {
  name: nameField,
  autoApply: autoApplyField,
};