import {OrganizationInviteCore} from "../../../../../app/entities/bv-organization-invite/organization-invite.core";
import {EntityViewSpecProtocol} from "../../../../shared/entity-view/entity-view-spec-protocol";
import {SOGetOrganizationInvite} from "../../../../../app/core/organization-invite/so-get-organization-invite";
import {SOGetUser} from "../../../../../app/core/user/so-get-user";
import {EntityType} from "../../../../../app/entity-types";
import {SvNavigatorComponent} from "../../../structure/sv-navigator/sv-navigator.component";

export class BaseOrganizationInviteProcessor extends OrganizationInviteCore {

  showAdd = false;

  configureForDisplay(entityViewSpec: EntityViewSpecProtocol) {
    super.configureForDisplay(entityViewSpec);
    super.removePostFields(['user'])

    if (entityViewSpec && entityViewSpec.entityCore.type === EntityType.organization) {
      this.showAdd = true;
      this.listHeaderSpec.showPostButton = true;
    }
  }

  prepareStartingEntityViewSpec(avRoute: SvNavigatorComponent) {
    const startingSpec = this.startingEntityViewSpec(avRoute)
    avRoute.setInitialEntityViewSpec(
      startingSpec
    )
  }

  fetchList(entityViewSpec: EntityViewSpecProtocol) {
    if (!entityViewSpec.parentEntityViewSpec) {
      console.log('no parent view spect')
      this.soGet = new SOGetOrganizationInvite(
        null,
        new SOGetUser(this.activeUserService.user.uid)
      )
    }
    super.fetchList(entityViewSpec);
  }

}
