import {ActiveUserService} from '../../../engine/shared/active-user/active-user.service';
import {HttpClient} from '@angular/common/http';
import {EOProductService} from '../../core/product/eo-product';
import {ProductListEntryFactory} from './product-list-entry-factory';
import {ProductDetailFactory} from './product-detail-factory';
import {SOGetProduct} from '../../core/product/so-get-product';
import {RAPutProduct} from './ra-put-product';
import {RAPostProduct} from './ra-post-product';
import {RADeleteProduct} from './ra-delete-product';
import {AbstractEntityCore} from "../../../engine/shared/entity-view/abstract-entity-core";
import {EntityCoreProtocol} from "../../../engine/shared/entity-view/entity-core-protocol";
import {EntityType} from '../../entity-types';
import {SPECTER_SPEC} from "../../specter-spec";

export class ProductCore extends AbstractEntityCore implements EntityCoreProtocol {

  type = EntityType.product;
  title = 'Product';
  listEntryFactory = new ProductListEntryFactory();
  detailFactory = new ProductDetailFactory();

  entityService: EOProductService;
  soGetClass = SOGetProduct;
  soGet: SOGetProduct;


  constructor(
    public activeUserService: ActiveUserService,
    public httpClient: HttpClient,
  ) {
    super(
      activeUserService,
      httpClient,
      EOProductService
    );
    this.adapters = [
      new RAPostProduct(this),
      new RAPutProduct(this),
      new RADeleteProduct(this),
    ];
    this.resetSoGet()
    this.setDefaultAdapters();
    this.finalInit();
  }

  resetSoGet() {
    this.soGet = new SOGetProduct();
    this.soGet.queryOptions.limit = this.limit;
  }


}