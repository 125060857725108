import { Type } from 'class-transformer';
import {ObjectiveProtocol} from '../../../engine/protocols/objective-protocol';
import {EntityType} from "../../entity-types";


export class SORespUser implements ObjectiveProtocol {

  entityType: EntityType = EntityType.user;
  uid: string;
  username: string;
  givenName: string;
  surname: string;
  email: string = null;
  emailPending: string = null;
  emailVerified: boolean = false;
  @Type(() => Date)
  dateCreated: Date;
}