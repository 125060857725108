<form [formGroup]="form">

  <h3 *ngIf="!detail.editEnabled"
      [ngClass]="{'warn': detail.warn}">
    {{detail.name}}: {{detail.value}}
  </h3>

  <mat-form-field *ngIf="detail.editEnabled"
                  appearance="{{detail.editableFormField.appearance}}"
                  color="{{detail.editableFormField.color}}">
    <mat-label>{{detail.editableFormField.getLabel(parentView.entityViewSpec)}}</mat-label>
    <textarea matInput
              [id]="detail.editableFormField.identifierString"
              [name]="detail.editableFormField.identifierString"
              [formControlName]="detail.editableFormField.identifierString"
              (change)="onSelectionChange()">
            </textarea>
  </mat-form-field>

</form>
