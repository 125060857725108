import {SORespTemdisa} from '../../core/temdisa/so-resp-temdisa';
import {TemdisaCore} from './temdisa.core';
import {ListEntry} from '../../../engine/shared/list-entry';
import {EntityAttributesForDisplay} from '../../../engine/shared/entity-attributes-for-display';
import {DetailEntry, DetailFactory, DetailFactoryProtocol, EditableType} from '../../../engine/shared/detail-entry';
import {ActiveUserService} from '../../../engine/shared/active-user/active-user.service';
import {BaseDetailType} from "../../../engine/shared/base-detail";
import {EntityViewSpecProtocol} from "../../../engine/shared/entity-view/entity-view-spec-protocol";
import {getHeading} from "../../../engine/shared/display-option-utilities";
import {putTemdisaFormFieldOptions} from './put-temdisa-form-fields';
import {SOGetTemdisa} from '../../core/temdisa/so-get-temdisa';
import {SOGetItem} from '../../core/item/so-get-item';
import {SOGetDistributor} from '../../core/distributor/so-get-distributor';
import {SOGetOrganization} from '../../core/organization/so-get-organization';
import {DisplayVectorStringFactory} from "../../../engine/config/display-vector";
import {displayOptions} from "../../display-options";
import {ListSpecType} from "../../../engine/shared/entity-view/list-spec";
import {SPECTER_SPEC} from "../../specter-spec";
import {getCore} from "../../../engine/shared/entity-view/get-core";

export class TemdisaDetailFactory extends DetailFactory implements DetailFactoryProtocol {

  makeDetail(entityViewSpec: EntityViewSpecProtocol, listEntry: ListEntry, activeUserService: ActiveUserService) {
    const entity: SORespTemdisa = listEntry.entity;
    console.log(entity);
    const displayStringFactory = new DisplayVectorStringFactory(displayOptions);
    let heading;

    const attributes = []


    const itemCore = getCore(SPECTER_SPEC, activeUserService, listEntry.entityCore.httpClient, 'ItemCore');
    itemCore.soGet = SOGetItem.construct({
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, itemCore)
    itemCore.title = heading ? heading : itemCore.title
    itemCore.listTitle = 'Item'

    if (entity.item) {
        displayStringFactory.reconfigure(entity.item, entityViewSpec.entityCore.type)
    }
    itemCore.configureForDisplay(entityViewSpec)

    const itemDetail = new DetailEntry(
      heading ? heading : itemCore.listTitle,
      entity.item ? displayStringFactory.primaryString : null,
      'item',
      BaseDetailType.pushedDetail,
      itemCore,
      EditableType.always,
      putTemdisaFormFieldOptions.item
    )
    if (entity.item) {
      itemDetail.cachedListEntry = itemCore.makeListEntry(entityViewSpec, itemCore, entity.item, 0)
    }
    itemDetail.singleFieldSelect = true
    attributes.push(itemDetail);

    const distributorCore = getCore(SPECTER_SPEC, activeUserService, listEntry.entityCore.httpClient, 'DistributorCore');
    distributorCore.soGet = SOGetDistributor.construct({
      organization: new SOGetOrganization(activeUserService.getScope('organization').uid),
    });
    heading = getHeading(entityViewSpec.entityCore, distributorCore)
    distributorCore.title = heading ? heading : distributorCore.title
    distributorCore.listTitle = 'Distributor'

    if (entity.distributor) {
        displayStringFactory.reconfigure(entity.distributor, entityViewSpec.entityCore.type)
    }
    distributorCore.configureForDisplay(entityViewSpec)

    const distributorDetail = new DetailEntry(
      heading ? heading : distributorCore.listTitle,
      entity.distributor ? displayStringFactory.primaryString : null,
      'distributor',
      BaseDetailType.pushedDetail,
      distributorCore,
      EditableType.always,
      putTemdisaFormFieldOptions.distributor
    )
    if (entity.distributor) {
      distributorDetail.cachedListEntry = distributorCore.makeListEntry(entityViewSpec, distributorCore, entity.distributor, 0)
    }
    distributorDetail.singleFieldSelect = true
    attributes.push(distributorDetail);

    return new EntityAttributesForDisplay(attributes);

  }
}