<form [formGroup]="form">

  <h3 *ngIf="!detail.editEnabled"
      [ngClass]="{'warn': detail.warn}">
    {{detail.name}}: {{detail.value}}
  </h3>

  <mat-form-field *ngIf="detail.editEnabled"
                  appearance="{{detail.editableFormField.appearance}}"
                  color="{{detail.editableFormField.color}}">
    <mat-label>{{detail.editableFormField.getLabel(parentView.entityViewSpec)}}</mat-label>
    <input matInput [matDatepicker]="picker" [formControlName]="detail.editableFormField.identifierString"
           (dateChange)="onSelectionChange()">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker startView="{{detail.dateStartView}}"></mat-datepicker>
  </mat-form-field>

</form>
