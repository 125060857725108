import { SOGetOrganization } from '../organization/so-get-organization';


export class SOPostPickup {

  constructor(
    public ref: string,
    public organization: SOGetOrganization,
  ) {
  }
  static construct(params: {[key: string]: any}) {
    return new SOPostPickup(
      'ref' in params ? params.ref : null,
      'organization' in params ? params.organization : null,
    );
  }
}
